import { Select } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { CommonAPI } from 'src/apis'
import { ClassesApi } from 'src/apis/classes'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonGroupFilter from 'src/components/base/button/ButtonGroupFilter'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import AreaTable from 'src/components/classroom/area/AreaTable'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { FACILITY_STATUS, PageLink } from 'src/constants'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { ITabs } from 'src/type'
import { IAreaList } from 'src/type/area'
import { IProvinceList } from 'src/type/common'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: TITLE_OPTIONS_AREA.facilityList,
  },
]
const fieldNames = ['search', 'province_code', 'fromDate', 'toDate']

const initialValues = {
  search: '',
  province_code: '',
  status: '',
  fromDate: '',
  toDate: '',
}

const AreaClass = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [areaList, setAreaList] = useState<IAreaList>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [provinceList, setProvinceList] = useState<IProvinceList>({
    metadata: { page_index: 0, page_size: 0, total_pages: 0, total_records: 0 },
    provinces: [],
  })

  const searchParams = new URLSearchParams(search)

  const queryParams = {
    search: searchParams.get('search') ?? '',
    province_code: searchParams.get('province_code') ?? '',
    status: searchParams.get('status') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const { control, getValues, reset, setValue, watch } = useForm({
    mode: 'onChange',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      search: getValues('search'),
      province_code: replaceValueAll(getValues('province_code')),
      status: replaceValueAll(getValues('status')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    search: queryParams?.search?.trimStart()?.trimEnd() ?? '',
    province_code: queryParams.province_code ?? '',
    status: queryParams.status ?? '',
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
    page_index: queryParams.page_index ?? 1,
    page_size: queryParams.page_size ?? 10,
  })
  const getParams = (
    search: string,
    province_code: string,
    status?: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    dateField?: string
  ) => ({
    search,
    province_code,
    status,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  const { toggleCheckAll, isCheckedAll } = useChecked(areaList?.areas)

  const fetchAreaList = async (pageIndex: number = 1, pageSize: number = 10, params?: Object) => {
    try {
      setLoading(true)
      const res = await ClassesApi.getAreaClasses({
        page_index: pageIndex,
        page_size: pageSize,
        params,
      })
      setAreaList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const fetchProvince = async (pageIndex: number = 1, pageSize: number = 10, params?: Object) => {
    try {
      const res = await CommonAPI.getProvince(pageIndex, pageSize, params)
      setProvinceList((prev) => {
        return {
          metadata: res.data.metadata,
          provinces: prev?.provinces
            ?.concat(res.data.provinces)
            ?.filter((item, index, self) => index === self.findIndex((t) => t.code === item.code)),
        }
      })
    } catch (error) {}
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('search').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('province_code')),
        replaceValueAll(getValues('status')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setLoading(true)
    delete cleanedParams.search
    const filterParam = { ...cleanedParams }
    if (getValues('search').trimStart().trimEnd()) {
      filterParam['search'] = `name=${getValues('search')},code=${getValues('search')}`
    }
    fetchAreaList(1, queryParams.page_size || 10, filterParam)
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })
    setLoading(true)
    navigate(PageLink.CLASSROOM_AREA)
    fetchAreaList(1, 10)
  }

  useEffect(() => {
    const fromDate = watch('fromDate')
    const toDate = watch('toDate')

    if (fromDate && toDate && moment(toDate).isBefore(fromDate)) {
      setValue('toDate', moment(fromDate).endOf('day').toDate())
    }

    if (toDate && fromDate && moment(fromDate).isAfter(toDate)) {
      setValue('fromDate', moment(toDate).startOf('day').toDate())
    }
  }, [watch('fromDate'), watch('toDate')])

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  useEffect(() => {
    fetchProvince(1, 10)
  }, [])

  return (
    <PageLayouts pageTitle={TITLE_OPTIONS_AREA.facilityList} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-9 border-0 pt-10 row'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='search'
              placeholder='Search by name and code'
              defaultValue={queryParams?.search}
              onSubmit={onSubmit}
              isListScreen
            />
            <HookFormSelectAntd
              name='province_code'
              placeholder='Area'
              control={control}
              defaultValue={queryParams?.province_code ?? ''}
              classNameHeight='sapp-h-40'
              handleNextPage={() => {
                handleNextPage(
                  provinceList?.metadata.total_pages,
                  provinceList?.metadata.page_index,
                  provinceList?.metadata.page_size,
                  fetchProvince
                )
              }}
            >
              {provinceList?.provinces?.map((province, index) => (
                <Option key={province.code + index} value={province.code}>
                  {province.name}
                </Option>
              ))}
            </HookFormSelectAntd>
            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              defaultValue={queryParams?.province_code ?? ''}
              classNameHeight='sapp-h-40'
            >
              {FACILITY_STATUS?.map((item: { label: string; value: string }) => (
                <Option key={item?.label} value={item?.value}>
                  {item?.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <ButtonGroupFilter
          onSubmit={onSubmit}
          onReset={handleResetFilter}
          loading={loading}
          onCreate={() => navigate(PageLink.CLASSROOM_AREA_CREATE)}
          createTitle={'Create Area'}
        />
        <AreaTable
          areaList={areaList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchAreaList={fetchAreaList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          isCheckedAll={isCheckedAll}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(AreaClass)
