import {useState, useEffect} from 'react'
import {getAPIUser, getAPIUserSelect} from 'src/apis/user'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import {IStudents, IStudentList} from 'src/type/students'
import useChecked from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import TableMembers from './TableMembers'
import {useParams} from 'react-router-dom'
import {NotificationAPI} from 'src/apis/notifications'
import {DESCRIPTION_POPUPCONFIRM} from 'src/constants/lang'
// import TableUsers from './tableUsers'

interface IProps {
  open: boolean
  setOpen: any
  user?: any
  setUser?: React.Dispatch<React.SetStateAction<IStudents[] | undefined>>
  fetchUserGroup: any
}

const AddUserToGroup = ({open, setOpen, user, setUser, fetchUserGroup}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [userState, setUserState] = useState<IStudentList>()
  const {id} = useParams()
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

  const getParams = (
    text: string,
    type: string,
    sortType: string,
    fromDate: string,
    toDate: string,
    selectType: string
  ) => ({
    text,
    type,
    sortType,
    fromDate,
    toDate,
  })
  const queryParams = {
    text: '',
    type: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchListUsers = (page_index?: number, page_size?: number, params?: any) => {
    ;(async () => {
      params.status = 'ACTIVE'
      try {
        const response = await getAPIUser({
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: params,
        })
        setUserState(response?.data)
        setLoading(true)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    })()
  }

  const {
    checkedList,
    listDataChecked,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
    setDefaultChecked,
  } = useChecked<IStudents>(userState?.users ?? undefined)

  const {confirm, contextHolder} = useConfirm()

  useEffect(() => {
    if (open && user) {
      setDefaultChecked(user)
    }
  }, [open])

  const handleClose = () => {
    setOpen()
    setSelectedUserIds([])
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    if (id) {
      const res = await NotificationAPI.addStudentGroup(id ? selectedUserIds : checkedList, id)
      if (res.success) {
        fetchUserGroup(1, 10, '')
      }
    }
    setUser && setUser(listDataChecked)
    handleClose()
  }

  const fetchListUserSelected = (page_index?: number, page_size?: number, params?: Object) => {
    ;(async () => {
      try {
        const response = await getAPIUserSelect({
          id: id,
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: params,
        })
        setUserState(response?.data)
        setLoading(true)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    })()
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Member</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && !id && (
              <div className='fw-bold sapp-fs-16px text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0 sapp-fs-16px'
              classNameSubmit='fw-bold me-5 sapp-fs-16px'
              // loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <TableMembers
            userState={userState}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListUsers={fetchListUsers}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
            fetchListUserSelected={fetchListUserSelected}
            setUserState={setUserState}
            setSelectedUserIds={setSelectedUserIds}
            selectedUserIds={selectedUserIds}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddUserToGroup
