import { Skeleton } from 'antd'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'
import TinyEditor from 'src/components/form/editor'
import './HookFormEditor.scss'
import { RESOURCE_LOCATION } from '../upload-file/ModalUploadFile/UploadFileInterface'
import { Dispatch, SetStateAction } from 'react'

export const DEFAULT_EDITOR_VALUE = ''

interface Props {
  name: string
  control: Control<any>
  defaultValue?: any
  className?: string
  height?: number
  skeleton?: boolean
  math?: boolean
  placeholder?: string
  required?: boolean
  label?: string
  subLabel?: string
  labelClass?: string
  guideline?: string[]
  handleChange?: any
  disabled?: boolean
  editorKey?: number | string
  uploadVideoImage?: boolean
  loading?: boolean
  resourceLocation: RESOURCE_LOCATION
  object_id: string | null | undefined
  setDataFile?: Dispatch<SetStateAction<any>>
  isGradeForm?: boolean
  is_resource?: boolean
}

const HookFormEditor = ({
  name,
  control,
  defaultValue = DEFAULT_EDITOR_VALUE,
  className,
  height,
  skeleton,
  math,
  placeholder,
  label,
  subLabel,
  required,
  guideline,
  handleChange,
  // labelClass = 'd-flex align-items-center fs-6 fw-bold form-label',
  disabled,
  editorKey,
  uploadVideoImage = true,
  loading,
  resourceLocation,
  object_id,
  setDataFile,
  isGradeForm = false,
  is_resource,
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            {!skeleton ? (
              <>
                {label && (
                  <div className='d-flex'>
                    <div>
                      <SappLabel label={label} required={required} />
                    </div>
                    {subLabel && (
                      <div className='px-1 hook-form-editor-sublabel sapp-fs-14 fw-semibold lh-sm'>
                        {subLabel}
                      </div>
                    )}
                  </div>
                )}
                <TinyEditor
                  onChange={(e) => {
                    onChange(e)
                    handleChange && handleChange(e)
                  }}
                  editorKey={editorKey}
                  valueText={defaultValue}
                  className={`${className} ${error ? 'tox-tinymce_error' : ''}`}
                  height={height}
                  math={math}
                  placeholder={placeholder}
                  disabled={disabled}
                  uploadVideoImage={uploadVideoImage}
                  resourceLocation={resourceLocation}
                  object_id={object_id}
                  setDataFile={setDataFile}
                  isGradeForm={isGradeForm}
                  is_resource={is_resource}
                />
                <div>
                  <GuidelineField guideline={guideline} />
                  <ErrorMessage>{error?.message ?? ''}</ErrorMessage>
                </div>
              </>
            ) : (
              <>
                <Skeleton.Button active size='small' className='mb-1' />
                <Skeleton.Input size='large' active className='hook-form-editors_skeleton' />
              </>
            )}
          </>
        )
      }}
    />
  )
}

export default HookFormEditor
