import { useUserContext } from 'src/context/UserProvider'
import ActionCell from '../base/action/ActionCell'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  Action: any
  data: any
}
const ClassesAction = ({ Action, data }: IProps) => {
  const { profileMe } = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_CLASS_GR.EDIT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderEditCourseContent = profileMe?.roles?.some(
    (role: Role) =>
      // role.permissions?.includes(TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS) ||
      role.permissions?.includes(TITLE_CLASS_GR.EDIT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const isDisabled =
    !data?.is_student_studying_normal_course &&
    !data?.is_student_studying_foundation_course &&
    data?.status !== 'ENDED'
  return (
    <>
      <ActionCell>
        {allowRenderEdit && (
          <div className='menu-item px-3' onClick={() => Action('edit', data)}>
            <div className='menu-link px-3'>Edit</div>
          </div>
        )}
        {allowRenderEditCourseContent && (
          <div className='menu-item px-3' onClick={() => Action('content', data)}>
            <div className='menu-link px-3'>Edit Course Content</div>
          </div>
        )}
        {isDisabled && data.type !== 'FOUNDATION' && allowRenderEdit && (
          <>
            <div className='menu-item px-3' onClick={() => Action('duration', data)}>
              <div className='menu-link px-3'>Update Duration</div>
            </div>
          </>
        )}
      </ActionCell>
    </>
  )
}
export default ClassesAction
