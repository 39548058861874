import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import LoadingTable from 'src/common/LoadingTable'
import { HEADER_ENTRANCE_TEST_LIST } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { format, formatDistanceToNow } from 'date-fns'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTableProfile from './SappTableProfile'

interface IProps {
  RecentHistoryData: any
  loading: boolean
  // setLoading: Dispatch<SetStateAction<boolean>>
  fetchHistory: (currentPage: number, pageSize: number, id?: any) => void
  id: any
}

// define headers
const headers = [
  {
    label: '#',
    className: 'min-w-50px',
  },
  {
    label: 'Login Time',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Location',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Action',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'IP AdDress',
    className: 'min-w-100px pe-5',
  },
]

const RecentHistory = ({ RecentHistoryData, loading, fetchHistory, id }: IProps) => {
  const { contextHolder } = useConfirm()
  const { setValue } = useForm<any>({
    mode: 'onChange',
  })
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchHistory(page_index, page_size, id)
  }

  useEffect(() => {
    RecentHistoryData?.userActivities
      ?.map((e: any) => ({ label: e.status, value: e.status }))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, RecentHistoryData])

  return (
    <div className='card-body py-4'>
      {contextHolder}
      <SappTableProfile
        headers={headers}
        loading={loading}
        data={RecentHistoryData}
        isCheckedAll={''}
        onChange={() => {}}
        hasCheck={false}
      >
        {loading ? (
          <>
            {HEADER_ENTRANCE_TEST_LIST.map((header, i) => (
              <LoadingTable key={header} headers={HEADER_ENTRANCE_TEST_LIST} />
            ))}
          </>
        ) : (
          <>
            {RecentHistoryData?.userActivities?.map((e: any, index: number) => {
              const loginTime = new Date(e.updated_at)
              const currentTime = new Date()

              const timeDifference = currentTime.getTime() - loginTime.getTime()

              let formattedTime

              if (timeDifference < 24 * 60 * 60 * 1000) {
                formattedTime = formatDistanceToNow(loginTime, { addSuffix: true })
              } else {
                formattedTime = format(loginTime, 'HH:mm:ss dd/MM/yyyy')
              }
              return (
                <tr key={index} className='table-row-underline'>
                  <td className='fw-semibold sapp-fs-14'>
                    {index +
                      1 +
                      (RecentHistoryData?.meta?.page_index - 1) *
                        RecentHistoryData?.meta?.page_size}
                  </td>
                  <td className='fw-semibold text-gray-800 sapp-fs-14'>{formattedTime}</td>
                  <td className='fw-semibold sapp-fs-14'>{e.location}</td>
                  <td className='fw-semibold sapp-fs-14'>{e.action}</td>
                  <td className='fw-semibold sapp-fs-14'>{e.ip}</td>
                </tr>
              )
            })}
          </>
        )}
      </SappTableProfile>
      <PagiantionSAPP
        currentPage={RecentHistoryData?.meta?.page_index || 1}
        pageSize={RecentHistoryData?.meta?.page_size || 10}
        totalItems={RecentHistoryData?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}
export default RecentHistory
