import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import { EntranceApi } from 'src/apis/entrance-test'
import { SubjectAPI } from 'src/apis/subject'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ModalImportClasses from 'src/components/classes/ModalImportClasses'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { OPTIONS_OF_STATUS, TITLE_OPTIONS_ENTRANCE } from 'src/constants/entrancetest'
import { LANG_ENTRANCE_TEST, LANG_SIDEBAR } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_QUIZ_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useChecked from 'src/hooks/use-checked'
import { ITabs, Role } from 'src/type'
import { ICourseCategoies } from 'src/type/courses'
import { ISubject, ISubjectList } from 'src/type/subject'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import TableTest from './tableTest '

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Entrance Tests',
  },
]
const fieldNames = [
  'name',
  'course_category_id',
  'course_level_id',
  'subject_id',
  'sortType',
  'fromDate',
  'toDate',
  'status',
]
const initialValues: any = {
  name: '',
  course_category_id: '',
  course_level_id: '',
  subject_id: '',
  sortType: '',
  fromDate: '',
  toDate: '',
  status: '',
  quizType: 'ENTRANCE_TEST',
}
const EntranceTestList = () => {
  const [EntranceTestList, setEntranceTestList] = useState<any>({
    quizzes: [{}],
    metadata: {},
  })
  const { profileMe } = useUserContext()
  const allowRenderCreateEntranceTest = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_QUIZ_GR.CREATE_QUIZ) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const [openImportClass, setOpenImportClass] = useState<boolean>(false)
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const searchParams = new URLSearchParams(search)
  const [dateField, setDateField] = useState<string>('updated_at')
  const queryParams = {
    name: searchParams.get('name') ?? '',
    course_category_id: searchParams.get('course_category_id'),
    subject_id: searchParams.get('subject_id'),
    sortType: searchParams.get('sortType'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    status: searchParams.get('status') as string,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    instruction_mode: searchParams.get('instruction_mode') ?? '',
    quizType: 'ENTRANCE_TEST',
  }
  const validationSchema = z.object({
    name: z.string().optional(),
    course_category_id: z.string().optional(),
    subject_id: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: getValues('name'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      subject_id: replaceValueAll(getValues('subject_id')),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
      status: replaceValueAll(getValues('status')),
      page_index: currenPage,
      page_size: size,
      quizType: 'ENTRANCE_TEST',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    name: queryParams.name.trimStart().trimEnd() ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    subject_id: queryParams.subject_id ?? '',
    sortType: queryParams.sortType,
    fromDate: queryParams.fromDate,
    toDate: queryParams.toDate,
    status: queryParams.status ?? '',
  })

  const getParams = (
    name: string,
    course_category_id: string,
    subject_id: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    status?: string,
    quizType?: string,
    dateField?: string
  ) => ({
    name,
    course_category_id,
    subject_id,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    status,
    quizType,
    dateField,
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    EntranceTestList?.quizzes
  )
  const fetchEntranceTestList = async (currentPage: number, pageSize: number, params?: any) => {
    try {
      const res = await EntranceApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: {
          ...params,
          quizType: 'ENTRANCE_TEST',
          dateField: 'created_at',
        },
      })
      setEntranceTestList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('subject_id')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        replaceValueAll(getValues('status'))
      )
    )

    setLoading(true)

    fetchEntranceTestList(1, queryParams.page_size || 10, cleanedParams)

    handleChangeParams(1, queryParams.page_size || 10)
  }
  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setDateField('')
    setLoading(true)
    navigate(PageLink.ENTRANCE_TEST_LIST)
    fetchEntranceTestList(1, 10)
    setSubjects(undefined)
  }

  const fetchCourseCategory = async () => {
    try {
      const res = await CoursesAPI.getCategory({
        page_index: 1,
        page_size: 10,
        params: { getType: 'ALL' },
      })
      setCourseCategory(res?.data)
    } catch (error) {}
  }
  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 100 })
        setCourseCategory(res?.data)
        if (watch('course_category_id') && watch('course_category_id') !== 'all') {
          await getSubjects({ params: { course_category_ids: watch('course_category_id') } })
        }
      } catch (error) {}
    }
    fetchCourseCategory()
  }, [])
  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )
  const dataAll = EntranceTestList?.quizzes
  const listTest: any[] = []
  const listSubject: { label: any; value: any }[] = []
  const listProgram: { label: any; value: any }[] = []

  dataAll.forEach((quizzes: any) => {
    listProgram.push({
      label: quizzes.quiz_type || '',
      value: quizzes.quiz_type || '',
    })

    listSubject.push({
      label: quizzes.subject || '',
      value: quizzes.subject || '',
    })
  })
  const handleCreate = () => {
    navigate(PageLink.ENTRANCE_CREATE_TEST_LIST)
  }

  /**
   * @description state lưu giá trị của subject
   */

  const [subjects, setSubjects] = useState<ISubjectList>()
  const requestOngoingRef = useRef(false)

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  const getSubjects = async ({ params }: any) => {
    const resMentor = await fetchSubjects(1, 20, params)
    setSubjects(resMentor?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ params: { name: e, course_category_id: watch('course_category_id') } })
    }
  }, 500)

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('subject_id', '')
    if (watch('course_category_id') !== 'all') {
      await getSubjects({ params: { course_category_id: watch('course_category_id') } })
    }
  }

  return (
    <PageLayouts pageTitle={TITLE_OPTIONS_ENTRANCE.listEntrance} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-9 border-0 pt-6'>
          <ListFilterLayout>
            {/* begin:: Search */}
            <HookFormTextField
              control={control}
              name='name'
              placeholder='Search'
              defaultValue={queryParams?.name}
              onSubmit={onSubmit}
              isListScreen
            />

            <HookFormSelectAntd
              size='large'
              control={control}
              name='course_category_id'
              placeholder='Program'
              defaultValue={queryParams?.course_category_id ?? ''}
              showSearch
              classNameHeight='sapp-h-40'
              onChange={handleCourseCategoryChange}
            >
              {newCourseCategory?.map((course_category_ids) => (
                <Option key={course_category_ids.value} value={course_category_ids.value}>
                  {course_category_ids.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              control={control}
              name='subject_id'
              placeholder='Subject'
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchSubject(e)
              }}
              handleNextPage={(e: any) =>
                handleNextPageSubject({
                  name: e,
                  course_category_id: watch('course_category_id'),
                })
              }
              showSearch
              classNameHeight='sapp-h-40'
              loading={loading}
              defaultValue={queryParams?.subject_id ?? ''}
            >
              {subjectCourse?.map((subject) => (
                <Option key={subject.value} value={subject.value}>
                  {subject.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              size='large'
              defaultValue={queryParams?.sortType ?? ''}
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              size='large'
              defaultValue={queryParams?.status ?? ''}
              // defaultValue={searchParams.get('status') as string}
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_OF_STATUS.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />

            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-6 '>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>
          <>
            {allowRenderCreateEntranceTest && (
              <ButtonIconPrimary
                iconName='plus'
                title={LANG_ENTRANCE_TEST.addEntranceTest}
                onClick={() => navigate(`${PageLink.ENTRANCE_EDIT_TEST_LIST}/undefined`)}
                size='small'
              />
            )}
          </>
        </div>
        <TableTest
          EntranceTestList={EntranceTestList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchEntranceTestList={fetchEntranceTestList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        <ModalImportClasses open={openImportClass} setOpen={setOpenImportClass} />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(EntranceTestList)
