import {fetchFormData, fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {ISpeaker, ISpeakerUpdate} from 'src/type/shop/speaker'

export class ShopSpeakerAPI {
  static getSpeakers(id: string): Promise<IResponse<ISpeaker[]>> {
    return fetcher(`/shop-events/${id}/speaker`)
  }

  static getSpeaker(id: string): Promise<IResponse<ISpeaker>> {
    return fetcher(`/shop-events/speakers/${id}`)
  }

  static uploadAvatar = (data: {id: string; file: File}) => {
    const formData = new FormData()
    formData.append('thumbnail_url', data.file)
    return fetchFormData({url: `/shop-events/speakers/${data.id}/avatar`, formData})
  }

  static createSpeaker({data}: {data: ISpeakerUpdate}): Promise<IResponse<ISpeaker>> {
    return fetcher('/shop-events/speakers', {method: 'POST', data: data})
  }

  static updateSpeaker({
    id,
    data,
  }: {
    id: string
    data: ISpeakerUpdate
  }): Promise<IResponse<ISpeakerUpdate>> {
    return fetcher(`/shop-events/speakers/${id}`, {method: 'PUT', data: data})
  }

  static deleteSpeaker(id: string) {
    return fetcher(`/shop-events/speakers/${id}`, {
      method: 'DELETE',
    })
  }
}
