import { fetcher, getBaseUrl } from 'src/services/request'
import { IResponse } from 'src/type'
import { IAreaList } from 'src/type/area'
import { IClass } from 'src/type/classes'
import { IMentorClassList, IMentorList } from 'src/type/mentors'
import { IPartTeacherResponse, ITeacherClassList, ITeacherList } from 'src/type/teacher'

export class ClassesApi {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`classes?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getAreaClasses({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IAreaList>> {
    return fetcher(`areas?page_index=${page_index}&page_size=${page_size}`, {
      params: { ...params },
    })
  }

  static getAPIStaffs({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/staffs?page_index=${page_index}&page_size=${page_size}`, {
      method: 'GET',
      params: params,
    })
  }
  static createClass({ data }: { data: IClass }): Promise<IResponse<IClass>> {
    return fetcher(getBaseUrl() + '/classes', { method: 'POST', data: data })
  }

  //TODO: viết interface không sử dụng any
  static updateClass(data: any): Promise<IResponse<any>> {
    return fetcher(`/classes`, { method: 'PUT', data })
  }

  static detail(id: string | undefined): Promise<IResponse<any>> {
    return fetcher(`/classes/${id}`)
  }

  static getClassStudent({
    class_id,
    page_index,
    page_size,
    params,
  }: {
    class_id?: string | undefined
    page_index?: number
    page_size?: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(
      `/classes/${class_id}/students?page_index=${page_index}&page_size=${page_size}`,
      { method: 'GET', params: params }
    )
  }

  //TODO: viết interface không sử dụng any
  static addStudentInClass(data: any): Promise<IResponse<any>> {
    return fetcher(`classes/students`, {
      method: 'POST',
      data,
    })
  }

  //TODO: viết interface không sử dụng any
  static deleteStudentInClass(data: any): Promise<IResponse<any>> {
    return fetcher(`classes/student`, {
      method: 'DELETE',
      data,
    })
  }

  static transferStudentInclass(
    classId: string,
    transfer_from: string,
    transfer_to: string
  ): Promise<IResponse<any>> {
    return fetcher(`classes/${classId}/students/transfer`, {
      method: 'PUT',
      data: {
        transfer_from: transfer_from,
        transfer_to: transfer_to,
      },
    })
  }

  static cancelTransferStudentInClass(
    classId: string,
    transfer_from: string
  ): Promise<IResponse<any>> {
    return fetcher(`classes/${classId}/students/${transfer_from}/revert-transfer`, {
      method: 'PUT',
    })
  }

  static allowFoundation(user: string[], classId: string | undefined): Promise<any> {
    return fetcher('classes/student-pass-foundation', {
      method: 'PATCH',
      data: {
        user: user,
        classId: classId,
      },
    })
  }

  static getStudentCourseContent(id: string): Promise<IResponse<any>> {
    return fetcher(`courses/${id}/sections/compact`)
  }

  static getExceptedSectionStudent({
    id,
    student_id,
  }: {
    id: string
    student_id: string
  }): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/students/${student_id}`)
  }

  static getExamsBySubject({
    page_index,
    page_size,
    id,
    student_id,
  }: {
    page_index: number
    page_size: number
    id: string
    student_id: string
  }) {
    return fetcher(
      `classes/${id}/students/${student_id}/exam-subject?page_index=${page_index}&page_size=${page_size}`,
      { method: 'GET' }
    )
  }

  //TODO: viết interface không sử dụng any
  static editCourseContentClass(data: any, id: string): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/course-content`, { method: 'PUT', data: data })
  }

  //TODO: viết interface không sử dụng any
  static editCourseContentStudent(
    data: any,
    id: string,
    student_id: string
  ): Promise<IResponse<any>> {
    return fetcher(`classes/${id}/students/${student_id}/course-content`, {
      method: 'PUT',
      data: data,
    })
  }

  static getListExam({
    id,
    page_index,
    page_size,
    params,
  }: {
    id?: string | undefined
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/examination/${id}?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getListExamBySubject({
    page_index,
    page_size,
    params,
    course_id,
  }: {
    page_index: number
    page_size: number
    params?: Object
    course_id: string
  }): Promise<IResponse<any>> {
    return fetcher(
      `classes/exam-subject?page_index=${page_index}&page_size=${page_size}&course_id=${course_id}`,
      {
        params: params,
      }
    )
  }

  static updateUserDuration(data: any): Promise<IResponse<any>> {
    return fetcher(`/classes/duration`, { method: 'PUT', data })
  }

  static getCourseDetail({
    id,
    params,
  }: {
    id?: string | number | undefined
    params?: Object
  }): Promise<any> {
    return fetcher(`classes/${id}/user/course/detail`, { method: 'GET', params: params })
  }

  static getCodes(page_index: number, page_size: number, params?: Object): Promise<IResponse<any>> {
    return fetcher(`classes/codes?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  // lấy các mentor qua bảng trung gian
  static getClassMentor({
    class_id,
    page_index,
    page_size,
    params,
  }: {
    class_id?: string | undefined
    page_index?: number
    page_size?: number
    params?: { text?: string; fromDate?: Date; toDate?: Date; sortType?: string; to_add?: boolean }
  }): Promise<IResponse<IMentorClassList>> {
    return fetcher(`/classes/${class_id}/mentors?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  // lấy các trực tiếp data của mentor
  static getMentors({
    class_id,
    page_index,
    page_size,
    params,
  }: {
    class_id?: string | undefined
    page_index?: number
    page_size?: number
    params?: { text?: string; fromDate?: Date; toDate?: Date; sortType?: string; to_add?: boolean }
  }): Promise<IResponse<IMentorList>> {
    return fetcher(`/classes/${class_id}/mentors?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  /**
   * Function: Lấy danh sách các teacher để thêm vào các part của khoá học
   */
  static getTeachers({
    id,
    page_index,
    page_size,
    params,
  }: {
    id?: string | undefined
    page_index?: number
    page_size?: number
    params?: { text?: string; fromDate?: Date; toDate?: Date; sortType?: string; to_add?: boolean }
  }): Promise<IResponse<ITeacherList>> {
    return fetcher(`/classes/${id}/lectures?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  /**
   * Function: Lấy các part theo part trong teacher
   */
  static getTeacherInClass({
    id,
    page_index,
    page_size,
    params,
  }: {
    id?: string | undefined
    page_index?: number
    page_size?: number
    params?: { text?: string; fromDate?: Date; toDate?: Date; sortType?: string; to_add?: boolean }
  }): Promise<IResponse<ITeacherClassList>> {
    return fetcher(`/classes/${id}/lectures?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  /**
   * Function: Lấy các teacher theo part trong course
   */
  static getPartInClass(id?: string): Promise<IResponse<IPartTeacherResponse>> {
    return fetcher(`/classes/${id}/part/lectures`)
  }

  static getTestQuiz(
    page_index: number,
    page_size: number,
    class_id: string | undefined,
    params?: Object
  ): Promise<IResponse<any>> {
    return fetcher(
      `class-quiz?page_index=${page_index}&page_size=${page_size}&class_id=${class_id}`,
      {
        params: params,
      }
    )
  }

  static getTestQuizDetail(
    id: string,
    page_index: number,
    page_size: number,
    params?: Object | undefined
  ): Promise<IResponse<any>> {
    return fetcher(`class-quiz/${id}?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static updateAccessTimeTestQuiz({
    id,
    data,
  }: {
    id: string
    data: { start_time: Date | null; end_time: Date | null }
  }): Promise<string> {
    return fetcher(`class-quiz/${id}`, {
      method: 'PUT',
      data,
    })
  }

  static deleteAccessTimeTestQuiz({ id }: { id: string }): Promise<any> {
    return fetcher(`class-quiz/${id}/remove-duration`)
  }

  static updateAccessTimeUser({
    id,
    data,
  }: {
    id: string
    data: { start_time: Date | null; end_time: Date | null }
  }): Promise<string> {
    return fetcher(`class-quiz/user-quiz/${id}`, {
      method: 'PUT',
      data,
    })
  }
}
