import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'
import {RolesAPI} from 'src/apis/roles'
import {useEffect, useState} from 'react'
import {IRoles} from 'src/type/roles'
import {useNavigate, useParams} from 'react-router-dom'
import PermissionDetail from 'src/components/roles/detail/PermissionDetail'
import toast from 'react-hot-toast'
import ListStaff from 'src/components/roles/list-staff/ListStaff'
import AddRole from 'src/components/roles/add-role/AddRole'
import './RoleDetail.scss'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.ROLES}`,
    title: 'Roles List',
  },
  {
    link: ``,
    title: 'Role Detail',
  },
]

const RoleDetail = () => {
  const {id} = useParams()
  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<IRoles>()
  const [loading, setLoading] = useState<boolean>(true)
  const [openModelRole, setOpenModelRole] = useState<boolean>(false)

  const getRoleDetail = async (id: string) => {
    setLoading(true)
    try {
      const res = await RolesAPI.detail({
        id: id,
      })
      setRoleData(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const deleteRole = async (role_code: string | number) => {
    setLoading(true)
    try {
      await RolesAPI.delete(role_code)
      toast.success('Delete successful')
      navigate(PageLink.ROLES)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      getRoleDetail(id)
    }
  }, [])
  return (
    <PageLayouts pageTitle={'Role Detail'} breadcrumbs={breadcrumbs}>
      <div className='row g-5 g-xl-9'>
        <PermissionDetail
          role={roleData}
          staffCount={roleData?.staff_count ?? 0}
          deleteRole={deleteRole}
          setOpen={setOpenModelRole}
        />
        <ListStaff loading={loading} setLoading={setLoading} roleData={roleData} />
      </div>
      <AddRole
        open={openModelRole}
        setOpen={setOpenModelRole}
        id={roleData?.id as string}
        roleData={roleData}
        functionCallBack={() => {
          getRoleDetail(id as string)
        }}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(RoleDetail)
