import { FC, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import { StaffAPI } from 'src/apis/staffs'
import PopupConfirm from 'src/common/PopupConfirm'
import ModalResetPassword from 'src/components/user-list/components/user-edit-modal/ModalResetPassword'
import UserAction from 'src/components/user-management/UserAction'
import { PageLink, STAFF_PROFILE } from 'src/constants'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import ModalEditEmail from 'src/components/user-list/components/user-edit-modal/ModalEditEmail'

type Props = {
  id: any
  status: 'BLOCKED'
  email: string
}

const StaffActionsCell: FC<Props> = ({ id, status, email }) => {
  const [openBlocked, setOpenBlocked] = useState(false)
  const [openModalReset, setOpenModalReset] = useState<boolean>(false)
  const { clearSelected } = useListView()
  const [openEditEmail, setOpenEditEmail] = useState(false)
  const [openModalShowPass, setOpenModalShowPass] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const queryClient = useQueryClient()
  const { query, refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const blockUser = useMutation(() => StaffAPI.blocked([id]), {
    onSuccess: () => {
      queryClient.invalidateQueries([`teachers-${query}`])
      clearSelected()
      toast.success('Blocked Successfully!')
      setOpenBlocked(false)
      cancel(true)
    },
    onError: () => {},
  })

  const handleShowPassword = async () => {
    await StaffAPI.resetPassword(id)
      .then((res: any) => {
        toast.success(res?.data?.message)
        setOpenModalReset(false)
        setOpenModalShowPass(true)
        setNewPassword(res?.data?.password)
      })
      .catch((err) => {
        // if (err?.response?.data?.error?.code === '403|000000') {
        //   toast.error(DENIED_PERMISSIONS)
        // }
      })
  }

  return (
    <>
      <UserAction
        setOpenReset={setOpenModalReset}
        setOpenBlocked={setOpenBlocked}
        status={status}
        linkProfile={`${STAFF_PROFILE}/${id}/overview`}
        setOpenEditEmail={setOpenEditEmail}
        linkEdit={`${PageLink.UPDATE_STAFF}/${id}`}
      />

      <ModalResetPassword
        open={openModalShowPass}
        setOpen={setOpenModalShowPass}
        newPassword={newPassword}
        content='Mật khẩu mới của nhân viên là:'
      />
      <PopupConfirm
        open={openBlocked}
        setOpen={setOpenBlocked}
        onClick={async () => await blockUser.mutateAsync()}
      />
      <PopupConfirm
        open={openModalReset}
        setOpen={setOpenModalReset}
        onClick={handleShowPassword}
        body='Bạn có chắc chắn muốn đổi mật khẩu không?'
        okButtonCaption='Yes, reset!'
      />
      <ModalEditEmail
        id={id}
        open={openEditEmail}
        setOpen={setOpenEditEmail}
        isActive={isActive}
        setIsActive={setIsActive}
        currentEmail={email}
        refetch={cancel}
      />
    </>
  )
}

export { StaffActionsCell }
