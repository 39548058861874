import { PageLink } from 'src/constants'
import { UserExportHelper } from 'src/helper/export'
import { useEffect, useState } from 'react'
import ModalImportStaff from '../ModalImportStaff'
import SAPPActionButton from 'src/common/SAPPActionButton'
import { useNavigate } from 'react-router-dom'
import { Role } from 'src/type'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_STAFF_GR } from 'src/constants/permission'

interface IProps {
  valueStatus: string | null
  valueSortBy: string | null
  searchTerm: string
  sortRole: string | null
  fromDate: Date | null
  toDate: Date | null
  sex: string | null
  location?: any
}

const StaffListToolbar = ({
  searchTerm,
  valueSortBy,
  valueStatus,
  sortRole,
  fromDate,
  toDate,
  sex,
  location,
}: IProps) => {
  const [openUpload, setOpenUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isLocation, setIsLocation] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    setIsLocation(location.pathname)
  }, [location.pathname])

  const exportStaff = async () => {
    setLoading(true)
    try {
      await UserExportHelper.exportToXlsxStaff(
        valueStatus,
        valueSortBy,
        searchTerm,
        sortRole,
        fromDate,
        toDate,
        sex
      )
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const handleAddStaff = () => {
    navigate(PageLink.CREATE_STAFF)
  }

  const { profileMe } = useUserContext()

  const allowRenderImport = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.CREATE_STAFF) ||
      // (role.permissions?.includes(TITLE_GR.IMPORT_USER_GR) && location === PageLink.STUDENTS) ||
      // (role.permissions?.includes(TITLE_GR.IMPORT_USER_GR) && location === PageLink.TEACHERS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <>
      <SAPPActionButton
        onClick={exportStaff}
        setOpen={setOpenUpload}
        onClickAdd={handleAddStaff}
        titleAdd='Create'
        loading={loading}
        location={isLocation}
        allowRenderImport={allowRenderImport}
      />
      <ModalImportStaff open={openUpload} setOpen={setOpenUpload} />
    </>
  )
}

export { StaffListToolbar }
