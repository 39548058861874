export interface ILifeCycles {
  course_category_id: string
  id: string
  stage: string
  user_id: string
}

export interface IParticipants {
  created_at: Date
  finished_at: Date
  ratio_score?: string
  id: string
  quiz: {
    course_category: {
      id: string
      name: string
    }
    id: string
    name: string
  }
  quiz_id: string
  updated_at: string
  user_contacts: {
    email: string
    id: string
    is_default: boolean
    phone: string
  }
  user_detail: {
    full_name: string
    acca_level: string
    cma_level: string
    cfa: string
    id: string
  }
  user_id: string
  hubspot_lifecycles: ILifeCycles[]
  is_publish_detail: boolean
  quiz_user_instances?: [
    {
      id: string
      source: string
    }
  ]
}

export enum CONTACT_LIFECYCLE_STAGE {
  MQL = 'MQL',
  SQL = 'SQL',
  OPP = 'OPP',
  CUS = 'CUS',
}
