import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GradeAPI } from 'src/apis/grade'
import QuestionDetails from 'src/components/grade/QuestionDetails'
import withAuthComponents from 'src/components/auth/with-auth-components'
import FinishAlertModal from 'src/components/grade/attempt-info/FinishAlertModal'
import Recommendation from 'src/components/grade/recommendation/Recommendation'
import { LANG_GRADE_PAGE } from 'src/constants/lang'
import { IAttemptAnswerData, IFilterTabProps, IQuizAttemptInfo } from 'src/type/grade'
import './Grade.scss'
import AttemptInfo from 'src/components/grade/attempt-info/AttemptInfo'
import { DEFAULT_ATTEMPT_DATA } from 'src/constants/grade'
import '@fortune-sheet/react/dist/index.css'

const Grade = () => {
  const { gradeId } = useParams()
  const [quizAttemptInfo, setQuizAttemptInfo] = useState<IQuizAttemptInfo>({
    gradeId: '',
    quizName: '',
    multipleChoiceScores: 0,
    essayScores: 0,
    comment: '',
    recommendation: '',
    status: '',
  })
  const [essayScores, setEssayScores] = useState<number>(0)
  const [attemptAnswerData, setAttemptAnswerData] =
    useState<IAttemptAnswerData>(DEFAULT_ATTEMPT_DATA)
  const [openRecommendation, setOpenRecommendation] = useState<boolean>(false)
  const [componentPosition, setComponentPosition] = useState<object>({
    top: 100,
    left: '35%',
    width: 565,
    height: 500,
  })
  const [filteredTabs, setFilteredTabs] = useState<IFilterTabProps[]>([])
  const [headerHeight, setHeaderHeight] = useState<number>(96)
  const attemptInfoRef = useRef<HTMLDivElement | null>(null)

  const getQuizAttemptInfo = async () => {
    try {
      const result = await GradeAPI.getQuizAttemptInfo(gradeId || '')
      if (result) {
        setEssayScores(result?.data?.essay_grade || 0)
        return result
      }
    } catch (error) {
      console.error(error)
    }
  }

  useLayoutEffect(() => {
    const getAPIData = async () => {
      const result = await getQuizAttemptInfo()
      if (result) {
        const resultData = {
          gradeId: result?.data?.id,
          quizName: result?.data?.title,
          multipleChoiceScores: result?.data?.multiple_choice_grade,
          essayScores: result?.data?.essay_grade,
          comment: result?.data?.comment,
          recommendation: result?.data?.recommendation,
          status: result?.data?.status,
        }
        setQuizAttemptInfo(resultData)
      }
    }
    getAPIData()
  }, [])

  useEffect(() => {
    const updateHeaderHeight = () => {
      if (attemptInfoRef.current) {
        setHeaderHeight(attemptInfoRef.current.clientHeight)
      }
    }

    // Initialize height on component mount
    updateHeaderHeight()

    // Observe size changes
    const observer = new ResizeObserver(updateHeaderHeight)
    if (attemptInfoRef.current) {
      observer.observe(attemptInfoRef.current)
    }

    // Cleanup observer when the component unmounts
    return () => {
      if (attemptInfoRef.current) {
        observer.unobserve(attemptInfoRef.current)
      }
    }
  }, [])

  return (
    <div className='sapp-grade-page grade-bg-1 d-flex flex-column min-vh-100 vh-100 position-relative overflow-hidden'>
      <div className='sapp-layout-header-grade position-sticky top-0' ref={attemptInfoRef}>
        <AttemptInfo
          quizInfo={quizAttemptInfo}
          essayScores={essayScores}
          attemptAnswerData={attemptAnswerData}
          handleChangeAttemptData={setAttemptAnswerData}
          setOpenRecommendation={setOpenRecommendation}
          filteredTabs={filteredTabs}
          setFilteredTabs={setFilteredTabs}
        />
      </div>
      {attemptAnswerData?.id && attemptAnswerData?.is_present ? (
        <QuestionDetails
          attemptAnswerData={attemptAnswerData}
          questionGradingId={attemptAnswerData?.question_id}
          getQuizAttemptInfo={getQuizAttemptInfo}
          gradeStatus={quizAttemptInfo?.status || ''}
          setFilteredTabs={setFilteredTabs}
          getMaxHeight={`calc(100vh - ${headerHeight}px)`}
        />
      ) : (
        <div className='layout-left flex-grow-1 overflow-y-auto'>
          <div className='sapp-no-question'>{LANG_GRADE_PAGE.chooseQuestToView}</div>
        </div>
      )}
      <Recommendation
        gradeId={gradeId || ''}
        openRecomendation={openRecommendation}
        setOpenRecomendation={setOpenRecommendation}
        componentPosition={componentPosition}
        setComponentPosition={setComponentPosition}
        quizInfo={quizAttemptInfo}
        gradeStatus={quizAttemptInfo?.status || ''}
      />
      <FinishAlertModal quizId={quizAttemptInfo.gradeId} setQuizAttemptInfo={setQuizAttemptInfo} />
    </div>
  )
}

export default withAuthComponents(Grade)
