import React, { Dispatch, SetStateAction } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { IClassDetail } from 'src/type/classes'
import ClassProfileDetail from './ClassProfileDetail'
import { CLASS_PROFILE_URL } from 'src/constants/classes'
import SettingClassUser from './setting/SettingClassUser'
import ListStudent from './user/ListStudent'
import ListMentorClass from './user/ListMentorClass'
import ListTeacher from './user/ListTeacher'
import ListTestQuiz from './user/test-quiz/ListTestQuiz'

interface IProps {
  classDetail: IClassDetail | undefined
  loading: boolean
}

const RenderClassProfile = ({ classDetail, loading }: IProps) => {
  const location = useLocation()
  const { id } = useParams()

  const renderClass = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case CLASS_PROFILE_URL.OVERVIEW:
        return <ClassProfileDetail classDetail={classDetail} loading={loading} />
      case CLASS_PROFILE_URL.SETTING:
        return <SettingClassUser classDetail={classDetail} loading={loading} />
      case CLASS_PROFILE_URL.STUDENTS:
        return <ListStudent classDetail={classDetail} loading={loading} />
      case CLASS_PROFILE_URL.MENTORS:
        return <ListMentorClass classDetail={classDetail} loading={loading} />
      case CLASS_PROFILE_URL.TEACHERS:
        return <ListTeacher classDetail={classDetail} loading={loading} />
      case CLASS_PROFILE_URL.EXAMINATION:
        return <div>examination</div>
      case CLASS_PROFILE_URL.MARK:
        return <div>mark</div>
      case CLASS_PROFILE_URL.TESTQUIZ:
        return <ListTestQuiz isDuration={classDetail?.duration_type === 'FIXED'} />
      default:
        return <ClassProfileDetail classDetail={classDetail} loading={loading} />
    }
  }
  return <>{classDetail && renderClass()}</>
}

export default RenderClassProfile
