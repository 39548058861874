import { useState, useEffect, Dispatch, SetStateAction } from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import SupporterTable from '../SupporterTable'
import { CommentAPI } from 'src/apis/comments'
import { ISupporterInGroup, ISupporterList } from 'src/type/comment'
import toast from 'react-hot-toast'

interface IProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  id: string
  fetchUserGroup: (pageIndex?: number, pageSize?: number, params?: Object) => void
}

interface IQueryParams {
  text?: string
  sort_type?: string
  fromDate?: string | Date
  toDate?: string | Date
  page_index?: number
  page_size?: number
}

const AddUserToGroup = ({ open, setOpen, id, fetchUserGroup }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<ISupporterList>()

  const getParams = (
    text: string,
    sort_type: string,
    fromDate?: string | Date,
    toDate?: string | Date,
    dateField?: string
  ) => ({
    text,
    sort_type,
    fromDate,
    toDate,
    dateField,
  })

  const queryParams = {
    text: '',
    sort_type: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const fetchListUsers = async (page_index?: number, page_size?: number, params?: IQueryParams) => {
    setLoading(true)
    try {
      const res = await CommentAPI.getSupporterList({
        id,
        pageIndex: page_index ?? 1,
        pageSize: page_size ?? 10,
        params: {
          ...params,
          filter_type: 'NOT_SELECTED',
        },
      })
      setList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<ISupporterInGroup>(list?.data ?? undefined)

  const { confirm, contextHolder } = useConfirm()

  useEffect(() => {
    fetchListUsers(1, 10)
  }, [open])

  const handleClose = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: DESCRIPTION_POPUPCONFIRM,
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    try {
      if (!id) return
      const res = await CommentAPI.createSupporter(id, { supporter_ids: checkedList })
      if (res.data.data.users.length > 0) {
        const message = res.data.data.error_message
        res.data.data.users.map((user) => {
          toast.error(`${user}: ${message} !`)
        })
      } else {
        toast.success('Add Supporters Successfully !')
      }
      setOpen(false)
      toggleCheckAll(false)
      fetchUserGroup()
    } catch (error) {}
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Supporter</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && !id && (
              <div className='fw-bold sapp-fs-16px text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0 sapp-fs-16px'
              classNameSubmit='fw-bold me-5 sapp-fs-16px'
              loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <SupporterTable
            userState={list}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListSupporter={fetchListUsers}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
            setUserState={setList}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddUserToGroup
