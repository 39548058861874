import React, { useEffect, useRef, useState } from 'react'
import HookFormTextField from '../base/textfield/HookFormTextField'
import { Control } from 'react-hook-form'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import { FILTER_SELECTALL_SORTBY, PageLink, STATUS_EVENT_TEST } from 'src/constants'
import { Select } from 'antd'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import ButtonIcon from '../base/button/ButtonIcon'
import { KTIcon } from 'src/_metronic/helpers'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { ICourseCategoies } from 'src/type/courses'
import { CoursesAPI } from 'src/apis/courses'
import { EventAPI } from 'src/apis/events'
import { debounce } from 'lodash'
import { ISubject } from 'src/type/subject'
import { OPTIONS_OF_STATUS } from 'src/constants/entrancetest'
import ButtonIconPrimary from '../ui/button-icon-primary/ButtonIconPrimary'
import { useNavigate } from 'react-router-dom'
const { Option } = Select

interface IProps {
  control: Control
  onSubmit: () => void
  handleReset: () => void
  setValue: any
  watch: any
  queryParams: any
  loading: boolean
}

const HeaderEventTest = ({
  control,
  onSubmit,
  handleReset,
  watch,
  setValue,
  queryParams,
  loading,
}: IProps) => {
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [subjects, setSubjects] = useState<any>()
  const requestOngoingRef = useRef(false)
  const navigate = useNavigate()

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.events.concat(res.data.events)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await EventAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const getSubjects = async (params: Object) => {
    const res = await fetchSubjects(1, 20, params)
    setSubjects(res?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    if (watch('course_category_id')) {
      getSubjects({ params: { name: e, course_category_id: watch('course_category_id') } })
    }
  }, 500)

  const subjectCourse = subjects?.events?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('event_id', '')
    await getSubjects({ course_category_id: watch('course_category_id') })
  }

  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 100 })
        setCourseCategory(res?.data)
        if (watch('course_category_id')) {
          await getSubjects({ course_category_ids: watch('course_category_id') })
        }
      } catch (error) {}
    }
    fetchCourseCategory()
  }, [])

  return (
    <>
      <div className='px-9 border-0 pt-6'>
        <div className='row'>
          {/* begin:: Search */}
          <div className='col-xl-3 col-sm-4'>
            <div className='card-title justify-content-center mx-0 mt-0'>
              <div>
                <HookFormTextField
                  control={control}
                  name='name'
                  placeholder='Search'
                  defaultValue={queryParams?.name}
                  onSubmit={onSubmit}
                  isListScreen
                />
              </div>
            </div>
          </div>
          <div className='col-xl-3 col-sm-4'>
            <HookFormSelectAntd
              control={control}
              name='course_category_id'
              placeholder='Program'
              defaultValue={queryParams?.course_category_id ?? ''}
              showSearch
              classNameHeight='sapp-h-40'
              onChange={handleCourseCategoryChange}
            >
              {courseCategory?.course_categories &&
                courseCategory?.course_categories
                  ?.map((subject: any) => ({
                    label: subject.name,
                    value: subject.id,
                  }))
                  .map((course_category_ids: any) => (
                    <Option key={course_category_ids.value} value={course_category_ids.value}>
                      {course_category_ids.label}
                    </Option>
                  ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4'>
            <HookFormSelectAntd
              control={control}
              name='event_id'
              placeholder='Event'
              onSearch={(e: any) => {
                if (e === undefined) {
                  return
                }
                debounceSearchSubject(e)
              }}
              handleNextPage={(e: any) =>
                handleNextPageSubject({
                  name: e,
                  course_category_id: watch('course_category_id'),
                })
              }
              showSearch
              classNameHeight='sapp-h-40'
              // loading={loading}
              defaultValue={queryParams?.event_id ?? ''}
            >
              {subjectCourse?.map((subject: any) => (
                <Option key={subject.value} value={subject.value}>
                  {subject.label}
                </Option>
              ))}
              <></>
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4 mt-xl-0 mt-5'>
            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              size='large'
              defaultValue={queryParams?.sortType ?? ''}
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              size='large'
              defaultValue={queryParams?.status ?? ''}
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_OF_STATUS.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </div>
          {/* <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />
          </div>
          <div className='col-xl-3 col-sm-4 mt-5'>
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </div> */}
        </div>
      </div>
      <div className='card-header border-0 pt-6 px-9'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={handleReset}
            disabled={loading}
            loading={loading}
          />
        </div>
        <ButtonIconPrimary
          iconName='plus'
          title={LANG_SIDEBAR.createEvent}
          onClick={() => navigate(`${PageLink.CREATE_EVENT_TEST}/undefined`)}
          size='small'
        />
      </div>
    </>
  )
}

export default HeaderEventTest
