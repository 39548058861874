import { UseFormReturn } from 'react-hook-form'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'

const GeneralContent = ({ useFormProp }: { useFormProp: UseFormReturn<any> }) => {
  const { control, getValues } = useFormProp

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <div className='text-gray-800 fw-bold sapp-mb-24px sapp-fs-20px'>General</div>
        <div className='sapp-mb-24px fv-row'>
          <HookFormTextField
            control={control}
            name='title'
            label='Title'
            required
            className='sapp-h-45px'
          />
        </div>
        <div className='sapp-mb-24px fv-row'>
          <HookFormEditor
            height={500}
            label='Describe'
            name='content'
            control={control}
            className='w-100 fs-6'
            math={true}
            defaultValue={getValues('content')}
            resourceLocation={RESOURCE_LOCATION.MEDIA}
            object_id={undefined}
          />
        </div>
      </div>
    </div>
  )
}

export default GeneralContent
