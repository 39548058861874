import {LayoutProvider} from './_metronic/layout/core'
import {MasterInit} from './_metronic/layout/MasterInit'
import AppRouter from './routes/AppRouter'
import '../node_modules/@syncfusion/ej2-base/styles/material.css'
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css'
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css'
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css'
import '../node_modules/@syncfusion/ej2-lists/styles/material.css'
// import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css'
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css'
import '../node_modules/@syncfusion/ej2-grids/styles/material.css'
import {Spin} from 'antd'
import {useLoadingContext} from './context/LoadingContext'
// import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
export default function App() {
  const {loading} = useLoadingContext()
  return (
    <LayoutProvider>
      <AppRouter />
      <Spin size='large' spinning={loading} fullscreen></Spin>
      <MasterInit />
    </LayoutProvider>
  )
}
