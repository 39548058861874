/* eslint-disable array-callback-return */
import { zodResolver } from '@hookform/resolvers/zod'
import { useLayoutEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { StoryAPI } from 'src/apis/story'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { PageLink, VALIDATION_FIELD } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { IResponse, Role } from 'src/type'
import { ITopic } from 'src/type/question-bank'
import { IStories, IStory } from 'src/type/story'
import { z } from 'zod'
import TopicList from './TopicList'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_STORY_GR } from 'src/constants/permission'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { RESOURCE_LOCATION } from '../base/upload-file/ModalUploadFile/UploadFileInterface'

// interface IUploadFiles {
//   // file_name: string
//   type: string
//   resource_id: string
// }

const defaultValues = {
  name: '',
  description: '',
  // files: [],
  topic: [],
}
const FormStory = () => {
  const params = useParams()
  const location = useLocation()
  const { confirm, contextHolder } = useConfirm()

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState(true)
  const navigate = useNavigate()
  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    description: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    // files: z.array(z.any().optional()).default([]),
    topic: z.array(z.any().optional()).default([]),
  })
  const [question, setQuestion] = useState<ITopic[]>()
  const useFormProp = useForm<IStory>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })

  // const handleSetUploadFiles = (files: any) => {
  //   setUploadFiles(
  //     files.map((e: {id: string; name: string; resource_id: string}) => ({
  //       resource_id: e.resource_id || e.id,
  //       id: e.resource_id || e.id,
  //       name: e.name,
  //       type: 'attached',
  //     }))
  //   )
  // }
  // const handleChangeParams = (currenPage: number, size: number) => {
  //   const queryParam = {
  //     page_index: currenPage,
  //     page_size: size,
  //   }

  //   const queryString = Object.entries(queryParam)
  //     .map(([key, value]) => `${key}=${value}`)
  //     .join('&')

  //   navigate(`?${queryString}`)
  // }
  const { handleSubmit, control, setValue } = useFormProp
  const [defaultEditor, setDefaultEditor] = useState<any>()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const loadData = async (index?: number, size?: number) => {
    setLoading(true)
    try {
      const { data } = await StoryAPI.getStoryById(
        params.id || '',
        index ?? currentPage,
        size ?? pageSize
      )
      const result = data?.data
      if (result) {
        setValue('name', result?.name)
        setValue('description', result.description)
        setDefaultEditor(result?.description)
        setQuestion(data)
      } else {
        setTimeout(() => {
          navigate(`${PageLink.STORY}`)
        })
      }
    } catch (error: any) {
      setTimeout(() => {
        navigate(`${PageLink.STORY}`)
      })
    } finally {
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    if (params.id) {
      loadData()
      setLoadingData(false)
    } else {
      setValue('name', '')
      setValue('description', '')
      setQuestion([])
      // setUploadFiles([])
      setLoadingData(false)
    }
  }, [params.id, currentPage, pageSize])

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const onSubmit = async (story: IStory, type?: 'add' | 'import') => {
    const saveStory: IStory = {
      name: (story.name ?? '').trim(),
      description: (story.description ?? '').trim(),
    }
    try {
      let response: IResponse<IStories>
      const requestParams = {
        ...saveStory,
        display_type: 'VERTICAL',
        text_editor_files: textEditorFiles,
      }
      if (params.id) {
        response = await StoryAPI.update(params.id, {
          ...requestParams,
        })
        if (response?.success) {
          navigate(`${PageLink.STORY}`)
          setFileEditor([])
        }
      } else {
        Object.assign(requestParams, { topic: [] })
        response = await StoryAPI.createStories(requestParams)
        if (response?.success) {
          navigate(`${PageLink.STORY}/${response?.data?.id}`)
          setFileEditor([])
        }
      }

      toast.success('Case study saved success')
      // if (params.id) {
      //   return
      // }
    } finally {
      setLoading(false)
    }
  }

  // const HandleOnDeleteTopic = (id: string | undefined) => {
  //   confirm({
  //     okButtonCaption: 'Yes',
  //     cancelButtonCaption: 'No',
  //     body: ['Bạn có chắc chắn muốn xóa không?'],
  //     onClick: () => {
  //       setQuestion((question) => question?.filter((e) => e.id !== id))
  //     }
  //   })
  // }
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(`${PageLink.STORY + (location.search || '')}`),
    })
  }
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)
  const { profileMe } = useUserContext()
  const allowRender = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_STORY_GR.EDIT_STORY) ||
      hasPermission(role, TITLE_STORY_GR.CREATE_STORY) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <div>
      {contextHolder}
      <div className='card h-xl-100'>
        <div className='card-body border-0 d-block p-10'>
          <div className='topic_name card-title d-flex flex-wrap sapp-mb-32px w-100'>
            <HookFormTextField
              label='Case Study Name'
              required
              control={control}
              disabled={!allowRender}
              name='name'
              placeholder='Case Study Name'
              skeleton={loading}
            ></HookFormTextField>
          </div>
          <div className='topic_description'>
            <HookFormEditor
              label='Description'
              placeholder='Description'
              height={508}
              disabled={!allowRender}
              name='description'
              control={control}
              math={true}
              defaultValue={defaultEditor}
              required
              skeleton={loading}
              resourceLocation={RESOURCE_LOCATION.CASE_STUDY}
              object_id={params?.id ? params?.id : undefined}
              setDataFile={setFileEditor}
            />
          </div>
        </div>
        <div className='d-flex border-0 justify-content-end card-footer px-9 pt-0 pb-7'>
          {allowRender && (
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Save'
              okOnClick={() => {
                handleSubmit((data: any) => {
                  setLoading(true)
                  setTimeout(() => {
                    onSubmit(data)
                  })
                })()
              }}
              className='justify-content-between d-flex'
              classNameCancel='fw-bold me-5'
              classNameSubmit='w-bold'
              loading={loading}
            />
          )}
        </div>
      </div>
      {params.id && (
        <div className='card mt-2 mt-xl-8'>
          <TopicList
            confirm={confirm}
            topic={question}
            setTopic={setQuestion}
            title='List Item Set'
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            caseStudyId={params.id}
            reload={loadData}
          ></TopicList>
        </div>
      )}
    </div>
  )
}

export default FormStory
