import {Dispatch, SetStateAction, useState} from 'react'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappModal from 'src/components/base/SappModal'
import useChecked from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import {ClassesApi} from 'src/apis/classes'
import TableTeacher from './TableTeacher'
import {IPartTeacher, ITeacher, ITeacherInClass, ITeacherList} from 'src/type/teacher'

interface IProps {
  open: {isOpen: boolean; sectionId: string}
  setOpen: Dispatch<SetStateAction<{isOpen: boolean; sectionId: string}>>
  classId?: string
  section: IPartTeacher[] | undefined
  setSection: Dispatch<SetStateAction<IPartTeacher[] | undefined>>
}

const AddTeacherToClass = ({open, setOpen, classId, setSection, section}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [teacherList, setTeacherList] = useState<ITeacherList>()
  const getParams = (
    text: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string,
    dateField?: string
  ) => ({
    text,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const queryParams = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }
  const fetchListUsers = async (
    page_index?: number,
    page_size?: number,
    params?: {text?: string; fromDate?: Date; toDate?: Date; sortType?: string; to_add?: boolean}
  ) => {
    try {
      if (open.sectionId) {
        const teacherIds = section
          ?.find((item: IPartTeacher) => item.id === open.sectionId)
          ?.lectures?.map((lecture) => lecture.id)
        const option = {
          ...params,
          to_add: true,
          part_id: open.sectionId,
          selected_lectures: teacherIds,
        }
        const res = await ClassesApi.getTeachers({
          id: classId,
          page_index: page_index || 1,
          page_size: page_size || 10,
          params: option,
        })
        setTeacherList(res.data)
      }
    } catch (err) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  const {checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll} =
    useChecked<ITeacher>(teacherList?.data ?? undefined)

  const {confirm, contextHolder} = useConfirm()

  const handleClose = () => {
    toggleCheckAll(false)
    setOpen((prev) => ({isOpen: false, sectionId: prev.sectionId}))
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn hủy không?',
      onClick: handleClose,
    })
  }

  const handleSubmit = async (lecture: ITeacherInClass[]) => {
    try {
      setLoading(true)
      setSection((prev) => {
        let current = prev?.find((item: IPartTeacher) => item.id === open.sectionId)
        current?.lectures.push(...lecture)
        return prev
      })

      toggleCheckAll(false)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
      setOpen((prev) => ({isOpen: false, sectionId: prev.sectionId}))
    }
  }

  return (
    <>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={open.isOpen}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>Add Lecturer</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-bold fs-6 text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={() => handleSubmit(listDataChecked)}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <TableTeacher
            open={open}
            teacherList={teacherList}
            loading={loading}
            setLoading={setLoading}
            handleChangeParams={() => {}}
            fetchListUsers={fetchListUsers}
            filterParams={{}}
            getParams={getParams}
            queryParams={queryParams}
            checkedList={checkedList}
            toggleCheck={toggleCheck}
            toggleCheckAll={toggleCheckAll}
            isCheckedAll={isCheckedAll}
          />
        </div>
      </SappModal>
    </>
  )
}
export default AddTeacherToClass
