import { useLocation, useParams } from 'react-router-dom'
import { ROOM_PROFILE_URL } from 'src/constants/classroom'
import RoomProfileDetail from 'src/pages/classroom/room/detail/RoomProfileDetail'
import RoomProfileSetting from 'src/pages/classroom/room/detail/RoomProfileSetting'
import { IRoom } from 'src/type/area'

interface IProps {
  roomDetail: IRoom | undefined
  loading: boolean
  refetch: () => void
}

const RenderRoomProfile = ({ roomDetail, loading, refetch }: IProps) => {
  const location = useLocation()
  const { id } = useParams()

  const renderClass = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case ROOM_PROFILE_URL.OVERVIEW:
        return <RoomProfileDetail roomDetail={roomDetail} loading={loading} />
      case ROOM_PROFILE_URL.SETTING:
        return <RoomProfileSetting roomDetail={roomDetail} loading={loading} refetch={refetch} />
      default:
        return <RoomProfileDetail roomDetail={roomDetail} loading={loading} />
    }
  }
  return <>{roomDetail && renderClass()}</>
}

export default RenderRoomProfile
