import {FC, useMemo} from 'react'
import {useListView} from 'src/components/teacher-list/components/core/ListViewProvider'
import {ID} from 'src/_metronic/helpers'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'

type Props = {
  id: ID
}

const TeacherSelectionCell: FC<Props> = ({id}) => {
  const {selected, onSelect} = useListView()
  const isSelected = useMemo(() => selected.includes(id), [id, selected])
  return (
    <SAPPCheckbox
      checkTarget='#kt_table_users .form-check-input'
      checked={isSelected}
      ktCheck={isSelected}
      onChange={() => onSelect(id)}
    />
  )
}

export {TeacherSelectionCell}
