import React, {useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import PopupConfirm from 'src/common/PopupConfirm'
import {MenuComponent} from 'src/_metronic/assets/ts/components'
import ActionCell from 'src/components/base/action/ActionCell'
import {ClassesApi} from 'src/apis/classes'

interface IProps {
  id: any
  classId: string | undefined
  getClassStudents: () => void
}

export const ClassActions = ({id, classId, getClassStudents}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItem = async () => {
    try {
      const res = await ClassesApi.deleteStudentInClass({
        user: [id],
        classId,
      })
      if (res) {
        toast.success('Delete Successfully!')
        getClassStudents()
      }
    } catch (error) {
      toast.error('Delete Failed!')
    } finally {
      setOpenModalDelete(false)
    }
  }

  return (
    <>
      <ActionCell customWidth='w-150px'>
        <div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
          <div className='menu-link px-3'>Delete</div>
        </div>
      </ActionCell>
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteItem}
        body='Bạn có chắc chắn muốn xóa không?'
      />
    </>
  )
}
