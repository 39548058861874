import cmndBack from 'src/_metronic/assets/media/auth/cmndmatsau.png'
import cmndFront from 'src/_metronic/assets/media/auth/cmndmattruoc.png'
import SappDrawer from 'src/components/base/SappDrawer'
import { BREAKPOINT } from 'src/constants'
import useWindowWidth from 'src/hooks/use-width'
import { IStudentDetail } from 'src/type/students'

interface IProps {
  studentDetail: IStudentDetail | undefined
  open: boolean
  setOpen: (flag: boolean) => void
  profileMe: IStudentDetail | undefined | null
  allowRender: boolean | undefined
  id: string | undefined
}

const IdSide = ({ url, title }: { url: string; title: string }) => {
  return (
    <div className='col-12 col-lg-6'>
      <img src={url} alt='' className='img-fluid' />
      <p className='text-center mt-4 mb-0 text-gray-800 fst-italic fw-normal lh-sm fs-6'>{title}</p>
    </div>
  )
}

const StudentCMNDDetail = ({ studentDetail, open, setOpen, allowRender, id }: IProps) => {
  const widthWindow = useWindowWidth()
  let identity_card_front = null
  let identity_card_back = null

  if (widthWindow > BREAKPOINT.xs) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['280x180']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['280x180']
  }

  if (widthWindow > BREAKPOINT.md) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['320x190']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['320x190']
  }

  if (widthWindow > BREAKPOINT.xl) {
    identity_card_front = studentDetail?.detail?.identity_card_image_front?.['370x220']
    identity_card_back = studentDetail?.detail?.identity_card_image_back?.['370x220']
  }

  return (
    <SappDrawer
      placement={'right'}
      open={open}
      title={'CCCD/CMND'}
      handleClose={() => setOpen(false)}
      width='50%'
      handleSubmit={() => setOpen(false)}
      okButtonCaption='Edit'
      showCancelBtn={false}
    >
      <div className='row gy-8'>
        <IdSide
          url={
            identity_card_front ??
            studentDetail?.detail?.identity_card_image_front?.ORIGIN ??
            cmndFront
          }
          title='Mặt trước'
        />
        <IdSide
          url={
            identity_card_back ??
            studentDetail?.detail?.identity_card_image_back?.ORIGIN ??
            cmndBack
          }
          title='Mặt sau'
        />
      </div>
    </SappDrawer>
  )
}

export default StudentCMNDDetail
