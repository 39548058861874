import React from 'react'

const CourseType = ({
  type,
}: {
  type: 'NORMAL_COURSE' | 'TRIAL_COURSE' | 'FOUNDATION_COURSE' | 'PRACTICE_COURSE'
}) => {
  return (
    <div className='badge badge-light fw-bolder'>
      {type === 'FOUNDATION_COURSE'
        ? 'Foundation Course'
        : type === 'TRIAL_COURSE'
        ? 'Trial Course'
        : type === 'PRACTICE_COURSE'
        ? 'Practice Course'
        : 'Normal Course'}
    </div>
  )
}

export default CourseType
