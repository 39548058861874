import {useParams} from 'react-router-dom'
import CreateGroupComponent from 'src/components/groups/CreateGroupComponent'
import PageLayouts from 'src/components/layout/PageLayouts'

const CreateAndUpdateGroup = () => {
  const {id} = useParams()

  return (
    <PageLayouts
      pageTitle=''
      titleTab={`${id ? 'Update Group' : 'Create Group'}`}
      breadcrumbs={[]}
      backgroudColor='bg-white'
      showSideBar={false}
      removePadding={true}
      showFooter={false}
    >
      <CreateGroupComponent />
    </PageLayouts>
  )
}
export default CreateAndUpdateGroup
