import { format } from 'date-fns'
import { FC } from 'react'
import { FORMAT_DATETIME } from 'src/constants'

type Props = {
  created_at?: Date
  updated_at?: Date
  titleStart?: string
  titleFinish?: string
}

const DatetimeColumn: FC<Props> = ({
  created_at,
  updated_at,
  titleStart = 'Created',
  titleFinish = 'Updated',
}) => (
  <>
    <div className='text-nowrap'>
      {created_at ? `${titleStart}: ${format(new Date(created_at), FORMAT_DATETIME)}` : '-'}{' '}
    </div>
    <div className='text-nowrap'>
      {updated_at ? `${titleFinish}: ${format(new Date(updated_at), FORMAT_DATETIME)}` : '-'}{' '}
    </div>
  </>
)

export default DatetimeColumn
