import { IResponse } from 'preview-activity/dist/shared/interfaces'
import { fetcher } from 'src/services/request'
import { Department } from 'src/type/department'

export class DepartmentAPI {
  static get({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<Department[]>> {
    return fetcher(`departments?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
}
