import {fetcher} from 'src/services/request'

interface IResponseSyncHubspotReprocess {
  success: boolean
  data: number
}

export class SyncHubspotAPI {
  static countLearnTrialTicketFail(): Promise<IResponseSyncHubspotReprocess> {
    return fetcher(`/sync-hubspot/learn-trial/count-ticket-fail`, {method: 'GET'})
  }

  static countEntranceTestTicketFail(): Promise<IResponseSyncHubspotReprocess> {
    return fetcher(`/sync-hubspot/entrance-test/count-ticket-fail`, {method: 'GET'})
  }

  static reprocessLeanTrialTicketFail(): Promise<IResponseSyncHubspotReprocess> {
    return fetcher(`/sync-hubspot/learn-trial/reprocess-ticket-fail`, {method: 'GET'})
  }

  static reprocessEntranceTestTicketFail(): Promise<IResponseSyncHubspotReprocess> {
    return fetcher(`/sync-hubspot/entrance-test/reprocess-ticket-fail`, {method: 'GET'})
  }
}
