import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import { TimeIcon } from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import { EDateTime } from 'src/type'
import './datetimepicker.scss'

interface IProps {
  control: Control<any>
  defaultValue?: any
  name: string
  placeholder?: string
  label?: string
  className?: string
  guideline?: string[]
  onChange?: (data: Date | null) => void
  required?: boolean
  disabled?: boolean
  format?: string | undefined
  showTime?: any
  showNow?: boolean
  disabledDate?: (current: dayjs.Dayjs) => any
  disabledTime?: (current: dayjs.Dayjs | null) => any
  isListScreen?: boolean
  picker?: 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined
}

const HookFormDateTime = ({
  control,
  defaultValue,
  name,
  placeholder,
  label,
  className,
  guideline,
  onChange,
  required,
  disabled = false,
  format,
  showTime,
  showNow,
  disabledDate,
  disabledTime,
  isListScreen,
  picker,
}: IProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <>
            {label && <SappLabel label={label} required={required} />}
            <DatePicker
              style={{ width: '100%', height: 40 }}
              placeholder={placeholder}
              status={fieldState.error ? 'error' : undefined}
              ref={field.ref}
              name={field.name}
              format={format || EDateTime.dateFormat}
              onBlur={field.onBlur}
              value={field.value ? dayjs(field.value) : null} // Convert the date to dayjs object for the DateTimePicker
              onChange={(date) => {
                onChange && onChange(date ? date.toDate() : null)
                field.onChange(date ? date.toDate() : null) // Convert the dayjs object to a regular Date object
              }}
              className={`createDateRangePicker ${className ?? ''} ${
                isListScreen ? 'sapp-h-40' : 'sapp-h-45px'
              }`}
              // showTime
              disabled={disabled}
              showTime={showTime}
              showNow={showNow}
              disabledDate={disabledDate}
              disabledTime={disabledTime}
              suffixIcon={<TimeIcon />}
              picker={picker}
            />
            <div>
              <GuidelineField guideline={guideline} />
              <ErrorMessage>{fieldState.error?.message}</ErrorMessage>
            </div>
          </>
        )
      }}
    />
  )
}

export default HookFormDateTime
