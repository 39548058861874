import { fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IFeatureSectionList } from 'src/type/feature-section'

export class FeatureSectionApi {
  static getFeatureSections(
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponse<IFeatureSectionList>> {
    return fetcher(`feature-sections?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
}
