import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type'
import './Button.scss'

const ButtonPrimary = ({
  title,
  onClick,
  className = '',
  link,
  loading,
  type = 'button',
  bg,
  disabled,
  classNameTitle,
  isListScreen,
  height,
}: IButtonProps) => {
  if (link)
    return (
      <Link
        to={link}
        className={`${className ?? ''} btn btn-sm btn-${bg ?? 'primary'} align-self-center ${
          classNameTitle ?? ''
        }`}
      >
        {title}
      </Link>
    )

  return (
    <button
      disabled={disabled || loading}
      type={type}
      className={`${className} btn btn-${bg ?? 'primary'} ${
        isListScreen ? 'sapp-h-40' : 'sapp-h-45px'
      }`}
      onClick={onClick}
      style={{ height: height }}
    >
      {loading ? (
        <span className={`indicator-progress sapp-d-content ${className ?? ''}`}>
          {/* Please wait... */}
          <span className='spinner-border spinner-border-sm align-middle'></span>
        </span>
      ) : (
        <div
          className={`${classNameTitle ?? ''} indicator-label align-middle`}
          style={{ height: 'auto' }}
        >
          {title}
        </div>
      )}
    </button>
  )
}

export default ButtonPrimary
