import { Skeleton } from 'antd'
import clsx from 'clsx'
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import toast from 'react-hot-toast'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { ICourseCategoies, PROGRAM } from 'src/type/courses'
import styles from './TeacherPostForm.module.scss'

import { useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import { IStudentDetail } from 'src/type/students'
import { ITeacher } from 'src/type/teacher'
import SubjectCollapse from './SubjectCollapse'
import SubjectTemplate from './SubjectTemplate'

interface Iprops {
  control: Control<ITeacher, any>
  setValue: UseFormSetValue<ITeacher>
  setError: UseFormSetError<ITeacher>
  watch: UseFormWatch<ITeacher>
  teacherDetail?: IStudentDetail
}

const Subjects = ({ control, setError, watch, teacherDetail, setValue }: Iprops) => {
  const category_primary_id = watch('category_primary_id')
  const { id } = useParams()
  const { data, isLoading, isSuccess } = useQuery<ICourseCategoies>(
    ['courseCategory'],
    async () => {
      const res = await CoursesAPI.getCategory({
        page_index: 1,
        page_size: 50,
        params: {
          with_children: true,
        },
      })
      return res?.data
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  )

  const handleRadioChange = (value: string) => {
    if (value === cfa?.id || value === cma?.id) {
      const selectedItems = teacherTeachableInstancesForm?.filter(
        (instance) => instance.category_id === value
      )
      const validateSelection = () => {
        const isValid = selectedItems && selectedItems.length > 0
        if (!isValid) {
          setError(`teacher_teachable_instances.${1}.feature_section_ids`, {
            message: 'One subject must be selected',
          })
        }
        return isValid
      }

      // Validate the selection based on the value
      const isValid = validateSelection()

      if (isValid) {
        setValue('category_primary_id', value)
      }
    }

    // ACCA specific validation
    if (value === acca?.id) {
      const selectedItems = teacherTeachableInstancesForm?.filter(
        (instance) => instance.category_id === acca.id && instance.subject_id !== ''
      )

      const validateSelection = (id: string) => {
        const isValid = selectedItems && selectedItems.length > 0
        if (!isValid) {
          setError(`teacher_teachable_instances.${1}.feature_section_ids`, {
            message: 'One subject must be selected',
          })
        }
        return isValid
      }

      // Validate the selection based on the value
      const isValid = validateSelection(value)

      if (isValid) {
        setValue('category_primary_id', value)
      }
    }
  }

  const [cfa, cma, acca] = [PROGRAM.CFA, PROGRAM.CMA, PROGRAM.ACCA].map((program) =>
    data?.course_categories.find((item) => item.name === program)
  )

  const cfaLength = cfa?.subject_instances.filter(
    (subject) => subject.feature_section_instances.length > 0
  ).length
  const cmaLength = cma?.subject_instances.filter(
    (subject) => subject.feature_section_instances.length > 0
  ).length

  const teacherTeachableInstances = teacherDetail?.teacher_teachable_instances_mapping?.map(
    (item) => {
      return {
        subject_id: item.subject.id,
        category_id: item.category.id,
        feature_section_ids: item.feature_section_instances.map((item) => {
          return item.id
        }),
      }
    }
  )
  const teacherTeachableInstancesForm = watch('teacher_teachable_instances')

  const initialRenderRef = useRef(true)

  useEffect(() => {
    if (id && initialRenderRef.current && isSuccess) {
      initialRenderRef.current = false

      const cfaSubjectIds = cfa?.subject_instances
        ?.filter((subject) => subject.feature_section_instances.length > 0)
        ?.map((subject) => subject.id)

      const cmaSubjectIds = cma?.subject_instances
        ?.filter((subject) => subject.feature_section_instances.length > 0)
        .map((subject) => subject.id)

      if (cfaSubjectIds && cmaSubjectIds && cfaLength && cmaLength) {
        //* Make sure the subject_id have the same order as cfa, cma returned by get category
        const defaultCfa = teacherTeachableInstances
          ?.filter((instance) => instance.category_id === cfa?.id)
          .sort((a, b) => {
            return cfaSubjectIds.indexOf(a.subject_id) - cfaSubjectIds.indexOf(b.subject_id)
          })

        const defaultCma = teacherTeachableInstances
          ?.filter((instance) => instance.category_id === cma?.id)
          .sort((a, b) => {
            return cmaSubjectIds.indexOf(a.subject_id) - cmaSubjectIds.indexOf(b.subject_id)
          })

        const defaultAcca = teacherTeachableInstances?.filter(
          (instance) => instance.category_id === acca?.id
        )
        const orderedAcca = acca?.subject_instances
          .map((subject) => subject.id)
          .map(
            (subject_id) =>
              defaultAcca?.find((item) => item.subject_id === subject_id) || {
                category_id: '',
                subject_id: '',
                feature_section_ids: [],
              }
          )

        if (orderedAcca && defaultCfa && defaultCma) {
          const defaultInstances = new Array(
            defaultCfa?.length + defaultCma?.length + orderedAcca?.length
          ).fill({
            category_id: '',
            subject_id: '',
            feature_section_ids: [],
          })

          defaultInstances.splice(0, defaultCfa.length, ...defaultCfa)
          defaultInstances.splice(cfaLength, defaultCma.length, ...defaultCma)
          defaultInstances.splice(cfaLength + cmaLength, orderedAcca.length, ...orderedAcca)

          setValue('teacher_teachable_instances', defaultInstances)
        }
      }
    }
  }, [])

  if (isLoading) return <Skeleton />
  return (
    <>
      {cfaLength && cmaLength && (
        <>
          {/* CFA */}
          <SubjectTemplate
            title='CFA'
            control={control}
            id={id}
            category={cfa}
            handleRadioChange={() => handleRadioChange(cfa?.id || '')}
          >
            {cfa?.subject_instances
              ?.filter((subject) => subject.feature_section_instances.length > 0)
              ?.map((subject, index) => {
                return (
                  <SubjectCollapse
                    category={cfa}
                    control={control}
                    index={index}
                    setValue={setValue}
                    subject={subject}
                    watch={watch}
                    key={subject.id}
                  />
                )
              })}
          </SubjectTemplate>

          {/* CMA */}
          <SubjectTemplate
            title='CMA'
            control={control}
            id={id}
            category={cma}
            handleRadioChange={() => handleRadioChange(cma?.id || '')}
          >
            {cma?.subject_instances
              ?.filter((subject) => subject.feature_section_instances.length > 0)
              ?.map((subject, index) => {
                const offset = index + cfaLength
                return (
                  <SubjectCollapse
                    category={cma}
                    index={offset}
                    control={control}
                    setValue={setValue}
                    subject={subject}
                    watch={watch}
                    key={subject.id}
                  />
                )
              })}
          </SubjectTemplate>

          {/* ACCA */}
          <SubjectTemplate
            title='ACCA'
            control={control}
            id={id}
            category={acca}
            handleRadioChange={() => handleRadioChange(acca?.id || '')}
          >
            {acca?.subject_instances?.map((subject, index) => {
              const offset = cfa && cma ? index + cfaLength + cmaLength : index

              return (
                subject.feature_section_instances.length > 0 && (
                  <Controller
                    name={`teacher_teachable_instances.${offset}.subject_id`}
                    control={control}
                    key={subject.id}
                    render={({ field }) => {
                      return (
                        <label
                          className={clsx('d-flex gap-2 cursor-pointer', styles.sectionCheckbox)}
                          key={subject.id}
                          htmlFor={subject.id}
                        >
                          <SAPPCheckbox
                            checked={field.value === subject.id || false}
                            onChange={(e) => {
                              const updatedValue = e.target.checked ? subject.id : '' //* Assign section.id or clear the value
                              const selectedInstances = teacherTeachableInstancesForm
                                ?.filter(
                                  (instance) =>
                                    (instance.category_id === acca?.id &&
                                      instance.subject_id !== '') ||
                                    undefined
                                )
                                ?.reduce(
                                  (acc, current) => {
                                    return {
                                      subject_ids: [...acc.subject_ids, current.subject_id],
                                      category_id: current.category_id, //* Assuming all entries have the same category_id
                                    }
                                  },
                                  { subject_ids: [] as string[], category_id: '' }
                                )

                              const isLastChecked =
                                e.target.checked === false &&
                                selectedInstances?.subject_ids.length === 1 &&
                                selectedInstances.category_id === category_primary_id

                              if (isLastChecked) {
                                toast.error(
                                  `You cannot uncheck the last selected item of this Primary Responsibility`,
                                  {
                                    duration: 6000,
                                  }
                                )
                                return
                              }

                              if (updatedValue) {
                                setValue(
                                  `teacher_teachable_instances.${offset}.category_id`,
                                  acca.id
                                )
                              } else {
                                setValue(`teacher_teachable_instances.${offset}.category_id`, '')
                              }

                              field.onChange(updatedValue) // Update form state with a single value
                            }}
                            id={subject.id}
                          />
                          <span className={clsx('flex-grow-1', styles.strong)}>{subject.name}</span>
                        </label>
                      )
                    }}
                  />
                )
              )
            })}
          </SubjectTemplate>
        </>
      )}
    </>
  )
}

export default Subjects
