/* eslint-disable @typescript-eslint/no-unused-vars */
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PageLayouts from 'src/components/layout/PageLayouts'
import { FAQ_STATUS, FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { ITabs } from 'src/type'
import { KTIcon } from 'src/_metronic/helpers'
import { useForm } from 'react-hook-form'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { Select } from 'antd'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import { replaceValueAll } from 'src/utils/string'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { DELETE_POPUPCONFIRM, DESCRIPTION_POPUPCONFIRM, LANG_NEWS } from 'src/constants/lang'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import useChecked from 'src/hooks/use-checked'
import { useState } from 'react'
import { useConfirm } from 'src/hooks/use-confirm'
import { INewOrUpdatePopup } from 'src/type/shop/common'
import { ProductAPI } from 'src/apis/short-course/product'
import toast from 'react-hot-toast'
import useShopFilter from 'src/hooks/use-shop-filter'
import ListGrouping from 'src/components/base/list-grouping/ListGrouping'
import { IFAQList } from 'src/type/shop/faqs'
import { FaqsAPI } from 'src/apis/short-course/faqs'
import TableFAQs from 'src/components/shop/faqs/TableFAQs'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: '',
    title: 'FAQ',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'List FAQs',
  },
]

const ListFaqs = () => {
  const [openNewOrUpdate, setOpenNewOrUpdate] = useState<INewOrUpdatePopup>({
    isOpen: false,
    data: null,
    type: 'create',
  })
  const [faqList, setFaqList] = useState<IFAQList>()
  const [loading, setLoading] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [dateField, setDateField] = useState<string>('created_at')
  const page_index = parseInt(searchParams.get('page_index') as string)
  const page_size = parseInt(searchParams.get('page_size') as string)

  /**
   * Các trường filter của HookForm
   */
  const fieldNames = ['title', 'category_id', 'status', 'sortType', 'fromDate', 'toDate']

  /**
   * Giá trị khởi tạo cho các trường filter của HookForm
   */
  const initialValues: any = {
    title: '',
    category_id: '',
    status: '',
    sortType: '',
    fromDate: '',
    toDate: '',
  }

  /**
   * lấy các function trong hookCategory để filter category cha cho category hiện tại
   */
  const { dataList, getData, debounceGetData, handleNextPage } = useShopFilter({
    callback: FaqsAPI.getFaqCategories,
    type: 'faq',
    key: 'faqCategories',
  })

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<
    typeof initialValues
  >(faqList?.faqs)

  /**
   * Validate cho các trường filter
   */
  const validationSchema = z.object({
    title: z.string().optional(),
    category_id: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  const { control, handleSubmit, getValues, reset, watch, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  /**
   * Fetch data khi vào page hoặc load lại page khi cập nhật bản ghi
   * @param {number}currentPage - Page hiện tại
   * @param {number}pageSize - lượng bản ghi cho 1 page
   * @param {Object}params - các tham số để filter
   */
  const fetchFaqList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await FaqsAPI.getFaqs({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setFaqList(res?.data)
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false)
    }
  }

  /**
   * Reset all fields of filter
   */
  const handleResetFilter = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    setLoading(true)
    navigate(`${PageLink.SHOP_FAQS}`)
    setDateField('')
    toggleCheckAll(false)
    fetchFaqList(1, 10)
  }

  const navigate = useNavigate()

  /**
   * Mapping filter variable for calling api
   */
  const getParams = (
    title: string,
    category_id: string,
    status: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string
  ) => ({
    title,
    category_id,
    status,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
  })

  const queryParam = {
    title: searchParams.get('title'),
    category_id: searchParams.get('categoyr_id'),
    status: searchParams.get('status'),
    sortType: searchParams.get('sort_type'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
  }

  /**
   * Xử lý thay đổi các giá trị tham số cho việc filter
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      title: getValues('title'),
      category_id: getValues('category_id'),
      status: getValues('status'),
      sortType: replaceValueAll(getValues('sortType')),
      fromDate: formatDate(getValues('lastRegistry')?.fromDate) ?? '',
      toDate: formatDate(getValues('lastRegistry')?.toDate) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  /**
   * Handle Filter cho Products
   */
  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('lastRegistry')?.fromDate)
    const dateInfoToDate = getDateInfo(getValues('lastRegistry')?.toDate)

    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('title')?.trim(),
        replaceValueAll(getValues('category_id')),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('sortType')),
        getValues('lastRegistry')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('lastRegistry')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : ''
      )
    )
    handleChangeParams(page_index || 1, page_size || 10)

    setLoading(true)
    fetchFaqList(1, 10, cleanedParams)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParam.fromDate)
  const dateQueryToDate = getDateInfo(queryParam.toDate)
  const cleanedParams = cleanParamsAPI(
    getParams(
      queryParam.title ?? '',
      queryParam.category_id ?? '',
      queryParam.status ?? '',
      queryParam.sortType ?? '',
      queryParam.fromDate
        ? formatISOToDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParam.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : ''
    )
  )

  /**
   * reload lại data cho page
   * @param {boolean} refresh -  dùng để reset lại về trang đầu tiên
   */
  const fetchPage = (refresh: boolean = true) => {
    const params = new URLSearchParams(location.search)
    const page_index = params.get('page_index') && refresh ? Number(params.get('page_index')) : 1
    const page_size = params.get('page_size') && refresh ? Number(params.get('page_size')) : 10
    const category_id = params.get('category_id')
    const status = params.get('status')
    const title = params.get('title')
    fetchFaqList(page_index, page_size, { title, status, category_id })
  }

  /**
   * Delete Bulk Products
   */
  const deleteProducts = async (): Promise<void> => {
    if (!checkedList || checkedList.length <= 0) {
      return
    }
    setLoading(true)
    try {
      await FaqsAPI.deleteBulkFaq(checkedList)
      fetchPage(false)
      toast.success('Faqs deleted successfully!')
    } catch (error: any) {
      // do nothing
    } finally {
      toggleCheckAll(false)
      setLoading(false)
    }
  }

  const handleAddNew = () => {
    navigate(`${PageLink.FAQ_DETAIL}/${undefined}`)
  }

  return (
    <PageLayouts pageTitle='List FAQs' breadcrumbs={breadcrumbs}>
      <div className='card'>
        {/* start:: filter */}
        <div className='px-10 border-0 pt-10'>
          <div className=''>
            <div className='row'>
              {/* begin:: Search */}
              <div className='col-xl-3 col-sm-4'>
                <div className='card-title justify-content-center mb-0 mx-0'>
                  <HookFormTextField
                    control={control}
                    name='title'
                    placeholder='Search Faq'
                    isListScreen
                  />
                </div>
              </div>
              {/* end:: Search */}
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='category_id'
                  placeholder='Category'
                  control={control}
                  size='large'
                  showSearch
                  handleNextPage={handleNextPage}
                  onSearch={async (e) => {
                    debounceGetData(e)
                    return
                  }}
                  onFocus={async () => {
                    if (dataList?.data?.length <= 0) {
                      await getData()
                      return
                    }
                  }}
                  onChange={async (e: any) => {
                    if (e === undefined) {
                      debounceGetData()
                    }
                  }}
                  loading={dataList.loading}
                  allowClear
                  classNameHeight='sapp-h-40'
                >
                  {dataList.data?.map((e) => {
                    return (
                      <Option key={e.id} value={e.id}>
                        {e.name}
                      </Option>
                    )
                  })}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  size='large'
                  control={control}
                  name='status'
                  placeholder='Status'
                  classNameHeight='sapp-h-40'
                >
                  {FAQ_STATUS.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4'>
                <HookFormSelectAntd
                  name='sortType'
                  placeholder='Sort by'
                  control={control}
                  size='large'
                  classNameHeight='sapp-h-40'
                >
                  {FILTER_SELECTALL_SORTBY.map((status) => (
                    <Option key={status.label} value={status.value}>
                      {status.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-xl-3 col-sm-4 mt-5'>
                <RangeDateTimePicker
                  control={control}
                  name='lastRegistry'
                  allowClear={true}
                  placeholder={['From date', 'To date']}
                  className='sapp-h-datimepicker sapp-width-picker-range'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(onSubmit)}
              resetClick={handleResetFilter}
              disabled={loading}
              loading={loading}
            />
          </div>

          <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
            <div className='card-toolbar justify-content-end d-flex my-0 gap-2'>
              {checkedList.length > 0 && (
                <ListGrouping
                  selected={checkedList}
                  okClick={deleteProducts}
                  title={LANG_NEWS.deleteSelected}
                  body={DELETE_POPUPCONFIRM}
                  okButtonCaption='Yes'
                />
              )}
              {/* start:: button open modal */}
              <ButtonIcon title='New Ticket' isListScreen onClick={handleAddNew}>
                <KTIcon iconName='plus' className='fs-2' />
              </ButtonIcon>
              {/* end:: button open modal */}
            </div>
          </div>
        </div>
        {/* end:: filter */}

        {/* start:: table */}
        <TableFAQs
          faqList={faqList}
          setFaqList={setFaqList}
          cleanedParams={cleanedParams}
          loading={loading}
          setLoading={setLoading}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
          loadData={fetchFaqList}
          queryParam={queryParam}
          reloadPage={fetchPage}
          setOpenNewOrUpdate={setOpenNewOrUpdate}
        />
        {/* end:: table */}
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(ListFaqs)
