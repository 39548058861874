import React, {Dispatch, SetStateAction, useEffect} from 'react'
import {Control} from 'react-hook-form'
import {Col} from 'react-bootstrap'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {LIST_SECTION_OPTION} from 'src/constants/entrancetest'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {IStateEntranceTest} from 'src/type/entrancetest'

type Props = {
  control: Control<any>
  index: any
  editMode?: any
  setting: any
  setValue: any
  id: string | undefined
  setFileEditor: Dispatch<SetStateAction<any>>
  setFileEditorNotPass: Dispatch<SetStateAction<any>>
  listInfo: IStateEntranceTest | undefined
}

const SettingEntrance = ({
  control,
  index,
  editMode,
  setting,
  setValue,
  setFileEditor,
  setFileEditorNotPass,
  id,
  listInfo,
}: Props) => {
  useEffect(() => {
    Object.entries(setting).map((e) => {
      setValue(`setting.${index}.${e[0]}`, e[1])
    })
  }, [setting])

  return (
    <div className='w-full'>
      <div className='mb-10 '>
        <HookFormTextField
          label={LIST_SECTION_OPTION.pass_point}
          required
          control={control}
          name={`setting.${index}.pass_point`}
          placeholder=' '
          disabled={editMode || !listInfo?.can_edit_entrance_test_settings?.pass_point}
        />
      </div>
      <div>
        <HookFormEditor
          label={LIST_SECTION_OPTION.pass_note}
          required
          height={300}
          defaultValue={setting.pass_note}
          placeholder=' '
          name={`setting.${index}.pass_note`}
          control={control}
          math={true}
          disabled={editMode || !listInfo?.can_edit_entrance_test_settings?.pass_note}
          resourceLocation={RESOURCE_LOCATION.ENTRANCE_TEST}
          object_id={id || undefined}
          setDataFile={setFileEditor}
        />
      </div>
      <div>
        <Col className='mt-8'>
          <HookFormEditor
            label={LIST_SECTION_OPTION.failure_note}
            required
            defaultValue={setting.failure_note}
            height={300}
            placeholder=' '
            name={`setting.${index}.failure_note`}
            control={control}
            math={true}
            setDataFile={setFileEditorNotPass}
            resourceLocation={RESOURCE_LOCATION.ENTRANCE_TEST}
            object_id={undefined}
            disabled={!listInfo?.can_edit_entrance_test_settings?.failure_note}
          />
        </Col>
      </div>
    </div>
  )
}

export default SettingEntrance
