import React, { useState, useEffect } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import NotData from 'src/components/NotData'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import { IStudents } from 'src/type/students'
import { Link } from 'react-router-dom'
import AddUserToClass from '../add-user/AddUserToClass'
import { STUDENT_PROFILE } from 'src/constants'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import useChecked from 'src/hooks/use-checked'
import UserListGrouping from 'src/components/user-management/UserListGrouping'
import { useConfirm } from 'src/hooks/use-confirm'
import { ClassActions } from './ClassActions'
import ModalImportClassStudent from '../ModalImportClassStudent'
import './ListUser.scss'
import { ClassesApi } from 'src/apis/classes'
import { cleanParamsAPI } from 'src/utils'
import { toast } from 'react-hot-toast'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const headers = [
  {
    label: 'Code',
    key: 'code',
    className: 'sapp-flex-1 fs-7 lh-1 fw-bold min-w-70px',
  },
  {
    label: 'Student Name',
    key: 'student_name',
    className: 'fs-7 lh-1 fw-bold min-w-200px',
  },
  {
    label: 'Email',
    key: 'email',
    className: 'fs-7 lh-1 fw-bold min-w-250px',
  },
  {
    label: 'Phone',
    key: 'phone',
    className: 'fs-7 lh-1 fw-bold w-150px',
  },
  {
    label: 'Date',
    key: 'date',
    className: 'fs-7 lh-1 fw-bold w-100px',
  },
]

type Props = {
  title: string
  getValues: any
  classId: string
  setUserStudent: React.Dispatch<React.SetStateAction<any>>
}

const ListUser = ({ title, getValues, classId, setUserStudent }: Props) => {
  const [user, setUser] = useState<any>()
  const [userSearch, setUserSearch] = useState<any>()
  const [open, setOpen] = useState<boolean>(false)
  const [openBlocked, setOpenBlocked] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const { contextHolder } = useConfirm()
  const [totalUser, setTotalUser] = useState<number>(0)

  const { checkedList, listDataChecked, toggleCheck, toggleCheckAll, isCheckedAll } =
    useChecked<IStudents>(userSearch)

  const getParams = (text: string) => ({
    text,
  })

  const initialValues: any = {
    text: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const getClassStudents = async (page_index?: number, page_size?: number, params?: Object) => {
    try {
      const response = await ClassesApi.getClassStudent({
        class_id: classId,
        page_index: page_index || 1,
        page_size: page_size || 10,
        params: params,
      })
      if (response) {
        const getListUser = response?.data?.students.map((item: any) => {
          return item.user
        })
        setUser(response?.data)
        setUserSearch(getListUser)
        setTotalUser(response?.data?.meta?.total_records)
        setUserStudent(getListUser)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteClassStudents = async (id?: string | number, data?: Object) => {
    try {
      const res = await ClassesApi.deleteStudentInClass({
        user: data,
        classId: id,
      })
      if (res) {
        toast.success('Delete Successfully!')
        getClassStudents()
      }
    } catch (error) {
      console.error(error)
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassStudents(page_index, page_size, cleanedParams)
  }

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleClosePopup = () => {
    setOpen(false)
  }

  const handleSearchChange = (e: any) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getClassStudents(1, user?.meta?.page_size || 10, cleanedParams)
  }

  const blockUser = () => {
    setOpenBlocked(false)
    toggleCheckAll(!listDataChecked)
    deleteClassStudents(classId, checkedList)
  }

  useEffect(() => {
    getClassStudents()
  }, [])

  return (
    <div>
      {contextHolder}
      <div className='mb-5'>
        <div className='py-8 px-10'>
          <div
            className={`d-flex justify-content-between align-items-center gap-5 flex-wrap ${
              user?.length ? ' mb-5' : ''
            }`}
          >
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search Student'}
                smallSearch={true}
                isListScreen
              ></Search>
              <ButtonPrimary
                title='Search'
                onClick={() => {
                  handleSearchActive()
                }}
                className='ms-4'
                size='small'
              />
            </div>
            <>
              {checkedList.length > 0 ? (
                <div className='sapp-selected-user'>
                  <UserListGrouping
                    okButtonCaption='Yes'
                    cancelButtonCaption='No'
                    body='Bạn có chắc chắn muốn xóa không?'
                    selected={checkedList}
                    blockUser={blockUser}
                    openBlocked={openBlocked}
                    setOpenBlocked={setOpenBlocked}
                    title={'Delete Selected'}
                  />
                </div>
              ) : (
                <div className='d-flex justify-content-between'>
                  <ButtonIconPrimary
                    className='me-3'
                    iconName={'file-up'}
                    title={'Import Student'}
                    size='small'
                    onClick={() => setOpenUpload(true)}
                    iconType='outline'
                  />
                  <ButtonIconPrimary
                    iconName={'plus'}
                    title={'Add Student'}
                    size='small'
                    onClick={handleOnClick}
                  />
                </div>
              )}
            </>
          </div>
          <ModalImportClassStudent
            open={openUpload}
            setOpen={setOpenUpload}
            id={classId}
            getClassStudents={getClassStudents}
          />
          <h5 className='fw-bold fs-6 mt-8'>
            {title} ({totalUser || 0})
            {(totalUser || 0) > Number(getValues('capacity')) && (
              <span className='ms-5 text-danger'>
                * Số học viên đã vượt capacity của lớp {totalUser}/{Number(getValues('capacity'))}
              </span>
            )}
          </h5>
          <div className='card card-flush border-gray-300 h-xl-100 mt-3'>
            <div className='container'>
              <div className='row px-4 py-8'>
                <div className='table-responsive'>
                  <div className='table-responsive'>
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 border-0'>
                          <SAPPCheckbox
                            checked={isCheckedAll}
                            ktCheck={isCheckedAll}
                            className={'ps-0'}
                            onChange={() => {
                              toggleCheckAll(!isCheckedAll, true)
                            }}
                          />
                          {headers?.map((column) => (
                            <th key={column.label} className={column.className}>
                              {column.label}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-semibold'>
                        {(() => {
                          if (!totalUser) {
                            return (
                              <tr>
                                <td colSpan={7}>
                                  <NotData />
                                </td>
                              </tr>
                            )
                          }
                          return user?.students?.map((item: any, i: number) => {
                            const isChecked = checkedList.includes(item?.user_id)
                            return (
                              <tr className='border-0' key={item?.user_id ?? i}>
                                <td>
                                  <SAPPCheckbox
                                    checkTarget='#kt_table_users .form-check-input'
                                    checked={isChecked}
                                    ktCheck={isChecked}
                                    onChange={() => {
                                      toggleCheck(item.user_id!)
                                    }}
                                  />
                                </td>
                                <td className='fs-6 lh-1 fw-semibold sapp-item-column'>
                                  {item?.user?.key}
                                </td>
                                <td>
                                  <Link
                                    className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                                    to={`${STUDENT_PROFILE}/${item?.user_id}/overview`}
                                  >
                                    {item?.user?.detail?.full_name}
                                  </Link>
                                </td>
                                <td>{item?.user?.user_contacts?.[0]?.email}</td>
                                <td>{item?.user?.user_contacts?.[0]?.phone}</td>
                                <td>
                                  <DatetimeColumn
                                    created_at={item?.user?.created_at}
                                    updated_at={item?.user?.updated_at}
                                  />
                                </td>
                                <td className='text-end'>
                                  <ClassActions
                                    id={item?.user_id}
                                    classId={classId}
                                    getClassStudents={getClassStudents}
                                  />
                                </td>
                              </tr>
                            )
                          })
                        })()}
                      </tbody>
                    </table>
                  </div>
                </div>
                <PagiantionSAPP
                  currentPage={user?.meta?.page_index || 1}
                  pageSize={user?.meta?.page_size || 10}
                  totalItems={totalUser}
                  handlePaginationChange={handlePaginationChange}
                />
              </div>
            </div>
          </div>
          <AddUserToClass
            open={open}
            setOpen={handleClosePopup}
            type='STUDENT'
            id={classId}
            getClassStudents={getClassStudents}
          />
        </div>
      </div>
    </div>
  )
}

export default ListUser
