import {fetchFormData, fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {ISponsor, ISponsorUpdate} from 'src/type/shop/sponsor'

export class ShopSponsorAPI {
  static getSponsors(id: string): Promise<IResponse<ISponsor[]>> {
    return fetcher(`/shop-events/${id}/sponsor`)
  }

  static getSponsor(id: string): Promise<IResponse<ISponsor>> {
    return fetcher(`/shop-events/sponsors/${id}`)
  }

  static uploadAvatar = (data: {id: string; file: File}) => {
    const formData = new FormData()
    formData.append('thumbnail_url', data.file)
    return fetchFormData({url: `shop-events/sponsors/${data.id}/avatar`, formData})
  }

  static createSponsor({data}: {data: ISponsorUpdate}): Promise<IResponse<ISponsor>> {
    return fetcher('/shop-events/sponsors', {method: 'POST', data: data})
  }

  static updateSponsor({
    id,
    data,
  }: {
    id: string
    data: ISponsorUpdate
  }): Promise<IResponse<ISponsorUpdate>> {
    return fetcher(`/shop-events/sponsors/${id}`, {method: 'PUT', data: data})
  }

  static deleteSponsor(id: string) {
    return fetcher(`/shop-events/sponsors/${id}`, {
      method: 'DELETE',
    })
  }
}
