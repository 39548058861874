import { IPropsCheckbox } from 'src/type'
import { SIZES } from 'src/utils'
import './HookFormRadioTN.scss'

const STATE = {
  success: 'success',
  error: 'error',
  default: 'default',
}

const SAPPRadio = ({
  ktCheck,
  checkTarget,
  checked,
  onChange,
  className,
  disabled,
  name,
  value,
  state = 'default',
  size = 'medium',
}: IPropsCheckbox) => {
  return (
    <div className={`sapp-radio ${SIZES[size]}`}>
      <input
        name={name}
        className={`${STATE[state as keyof typeof STATE]} ${
          disabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'
        }`}
        type='radio'
        data-kt-check={ktCheck}
        data-kt-check-target={checkTarget}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value?.toString()}
      />
    </div>
  )
}

export default SAPPRadio
