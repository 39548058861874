import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import { z } from 'zod'
import { PageLink, VALIDATE_GROUP_NAME, VALIDATION_FIELD } from 'src/constants'
import { zodResolver } from '@hookform/resolvers/zod'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import CreateGroup from './components/CreateGroup'
import { CommentAPI } from 'src/apis/comments'
import SupporterList from './components/SupporterList'
import toast from 'react-hot-toast'
import { ISupportGroupDetail } from 'src/type/comment'
import sappAvatar from 'src/_metronic/assets/images/sapp.png'

interface IForm {
  name: string
  display_name: string
  course_category_id?: string
  subject_id?: string
  department_id?: string
  avatar?: any
}

const CreateUpdateGroup = () => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const { id } = useParams()
  const [isDisable, setIsDisable] = useState<boolean>(false)
  const [group, setGroup] = useState<ISupportGroupDetail>()

  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD })
      .max(1000, { message: VALIDATE_GROUP_NAME }),
    display_name: z.string().min(1, { message: VALIDATION_FIELD }),
    course_category_id: z.string().min(1, { message: VALIDATION_FIELD }),
    subject_id: z.string().min(1, { message: VALIDATION_FIELD }),
    department_id: z.string().min(1, { message: VALIDATION_FIELD }),
    avatar: z.any().optional(),
  })

  const groupForm = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      display_name: '',
      course_category_id: '',
      subject_id: '',
      department_id: '',
      avatar: '',
    },
  })

  const { setValue, handleSubmit } = groupForm

  interface Step {
    title: string
    status: string
  }

  const [newStep, setNewStep] = useState<Step[]>([
    {
      title: 'Group Settings',
      status: 'current',
    },
    {
      title: 'Add Supporter',
      status: '',
    },
  ])

  useEffect(() => {
    setLoading(true)
    fetchGroup()
  }, [newStep])

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn chắc chắn muốn thoát không?'],
      onClick: () => navigate(PageLink.LIST_SUPPORTER_GROUP),
    })
  }

  const handleNextStep = () => {
    setNewStep((prev: Step[]) => {
      const index = prev.findIndex((item) => item.status === 'current')
      return prev.map((item: Step, idx: number) => ({
        ...item,
        status: idx === index + 1 ? 'current' : idx < index + 1 ? 'active' : '',
      }))
    })
  }
  async function imageToFileWithBlob(imageUrl: string, fileName: string): Promise<File> {
    const blob = await (await fetch(imageUrl)).blob()
    return new File([blob], fileName, { type: blob.type })
  }

  const uploadAvatar = async (id: string, avatar?: File | string) => {
    if (!id || (typeof avatar === 'string' && avatar.length > 0)) return
    if (!avatar) {
      avatar = await imageToFileWithBlob(sappAvatar, 'sapp.png')
    }
    await CommentAPI.updateAvatar(id, { avatar: avatar })
  }

  const onSubmit = async (data: IForm) => {
    try {
      if (id) {
        if (newStep[0].status === 'current') {
          await CommentAPI.editSupportGroup(id, data)
          uploadAvatar(id, data?.avatar)
          toast.success('Update Group Successfully !')
          handleNextStep()
        } else {
          navigate(`/support-group`)
        }
      } else {
        const res = await CommentAPI.createSupportGroup(data)
        uploadAvatar(res.data.id, data.avatar)
        toast.success('Create Group Successfully !')
        navigate(`/support-group/${res.data.id}`)
        handleNextStep()
      }
    } catch (error) {}
  }

  const fetchGroup = async () => {
    if (!id) {
      setLoading(false)
      return
    } else {
      try {
        const res = await CommentAPI.getSupportGroup(id)
        setGroup(res.data)
        setIsDisable(!!res?.data?.supporters?.length && !!id)
        if (res.data) {
          setValue('name', res.data.name)
          setValue('display_name', res.data.display_name)
          setValue('course_category_id', res.data.course_category_id)
          setValue('subject_id', res.data.subject_id)
          setValue('department_id', res.data.department.id)
          setValue('avatar', res.data.avatar?.['50x50'])
        }
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <div className='mw-900px mx-auto px-2'>
      <div className='d-flex justify-content-center'>
        <div className='sapp-proress-bar-wrapper sapp-mw-520px w-100'>
          <Processbar
            step={newStep}
            setNewStep={setNewStep}
            isNext={!!id}
            okButtonCaption={id && newStep[0].status !== 'current' ? 'Finish' : 'Save'}
            onClick={handleSubmit(onSubmit)}
            onCancel={handleCancel}
            showCancel={newStep[0].status === 'current'}
            cancelButtonCaption={newStep[0].status !== 'current' ? 'Group List' : ''}
          />
        </div>
      </div>
      <div className='pb-0'>
        {contextHolder}
        {newStep[0].status === 'current' && (
          <CreateGroup groupFrom={groupForm} group={group} loading={loading} disable={isDisable} />
        )}
        {newStep[1].status === 'current' && <SupporterList />}
      </div>
    </div>
  )
}
export default CreateUpdateGroup
