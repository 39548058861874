import dayjs from 'dayjs'
import { KTCardBody } from 'src/_metronic/helpers'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'Kỳ Thi',
    className: 'sapp-min-w-100px',
  },
  {
    label: 'Mở Đăng ký',
    className: 'sapp-min-w-100px',
  },
  {
    label: 'Đăng ký sớm',
    className: 'sapp-min-w-100px',
  },
  {
    label: 'Đăng Ký chuẩn',
    className: 'sapp-min-w-100px',
  },
  {
    label: 'Ngày Thi Đầu',
    className: 'sapp-min-w-100px',
  },
  {
    label: 'Ngày thi cuối',
    className: 'sapp-min-w-100px',
  },
]
interface IProps {
  examList: any
  loading: boolean
  checkedList: any
  toggleCheck: any
  handlePaginationChange: any
}
// define headers

const TableExamCFA = ({
  examList,
  loading,
  checkedList,
  toggleCheck,
  handlePaginationChange,
}: IProps) => {
  let getExams = examList?.exams ?? []
  if (typeof getExams === 'string') {
    getExams = []
  }
  return (
    <KTCardBody className='px-0'>
      <SappTable
        headers={headers}
        loading={loading}
        data={examList?.exams}
        isCheckedAll={false}
        hasCheckAll={false}
        onChange={() => {}}
      >
        {loading ? (
          <>
            {/* {headers.map((header, i) => ( */}

            <LoadingTable headers={[...headers, 1]} />
            {/* ))} */}
          </>
        ) : (
          <>
            {examList?.map((e: any) => {
              const isChecked = checkedList[0]?.id?.includes(e.id)

              return (
                <tr key={e.id}>
                  <td>
                    <SAPPRadio
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      onChange={() => {
                        toggleCheck(e)
                      }}
                    />
                  </td>
                  {/* ID */}
                  <td className='text-start'>{e?.code_exam ?? '-'}</td>
                  {/* Kì Thi */}
                  <td className='text-start '>{e?.examination.name}</td>
                  <td className='text-start'>
                    {dayjs(e.examination.registration_opening_date).format('DD/MM/YYYY') || '-'}
                  </td>
                  <td className='text-start'>
                    {dayjs(e.examination.early_registration_deadline).format('DD/MM/YYYY') || '-'}
                  </td>
                  <td className='text-start'>
                    {dayjs(e.examination.standard_registration_deadline).format('DD/MM/YYYY') ||
                      '-'}
                  </td>
                  <td className='text-start'>{dayjs(e.start_date).format('DD/MM/YYYY') || '-'}</td>
                  <td className='text-start'>{dayjs(e.end_date).format('DD/MM/YYYY') || '-'}</td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={examList?.metadata?.page_index || 1}
        pageSize={examList?.metadata?.page_size || 10}
        totalItems={examList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}
export default TableExamCFA
