import PageLayouts from 'src/components/layout/PageLayouts'
import FormStory from 'src/components/story-management/FormStory'

import {PageLink} from 'src/constants'
import {LANG_SIDEBAR} from 'src/constants/lang'
import {ITabs} from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: ``,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: PageLink.STORY,
    title: LANG_SIDEBAR.listStories,
  },
  {
    link: PageLink.CREATE_STORY,
    title: LANG_SIDEBAR.createStory,
  },
]

const Story = () => {
  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.createStory} breadcrumbs={breadcrumbs}>
      <FormStory></FormStory>
    </PageLayouts>
  )
}

export default Story
