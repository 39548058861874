import React, {useState} from 'react'
import AddMore from 'src/common/AddMore'
import ActionCell from 'src/components/base/action/ActionCell'
import SappLabel from 'src/components/base/label/SappLabel'
import {INameAndId} from 'src/type/shop/common'
import AddRelatedProduct from './AddRelatedProduct'

const ProductRelated = ({
  confirm,
  useFormProp,
}: {
  confirm: (option: any) => void
  useFormProp: any
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const {setValue, watch} = useFormProp
  const relatedProduct = watch('related_production_ids')

  const handleAddProduct = (data: INameAndId[]) => {
    setValue('related_production_ids', data)
    setOpen(false)
  }

  const hanelDelete = (data: INameAndId) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        setValue(
          'related_production_ids',
          relatedProduct?.filter((item: INameAndId) => item.id !== data.id)
        )
      },
    })
  }

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <SappLabel label='Sản phẩm liên quan' isLabel />
        {relatedProduct?.map((data: INameAndId) => (
          <>
            <div key={data.id} className='d-flex justify-content-between px-5'>
              <div className='d-flex align-items-center'>
                <div className='text-gray-800 fw-semibold text-hover-primary mb-1 sapp-fs-14'>
                  {data.name}
                </div>
              </div>
              <div>
                <ActionCell className='sapp-h-50px'>
                  <div className='menu-item px-3' onClick={() => hanelDelete(data)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                </ActionCell>
              </div>
            </div>
            <div className='my-5' style={{border: '1px dashed #DBDFE9'}} />
          </>
        ))}
        <AddMore addNew={() => setOpen(true)} />
        <AddRelatedProduct
          initValue={relatedProduct}
          open={open}
          setOpen={setOpen}
          onAddFaq={handleAddProduct}
        />
      </div>
    </div>
  )
}

export default ProductRelated
