import React from 'react'

interface IProps {
  title: string
  percent?: any
}

const Progress = ({title, percent}: IProps) => {
  return (
    <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
      <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
        <span className='fw-semibold fs-6 text-gray-400'>{title}</span>
        <span className='fw-bold fs-6'>{percent}%</span>
      </div>
      <div className='h-5px mx-3 w-100 bg-light mb-3'>
        <div className='bg-primary rounded h-5px' style={{width: `${percent}%`}}></div>
      </div>
    </div>
  )
}

export default Progress
