import { useEffect, useState } from 'react'
import { STATUS_ALL } from 'src/constants/grade'
import { AnswerMatchingMapping, GradeStatus, Question, QuestionMatching } from 'src/type/grade'

interface IProps {
  data: Question
  defaultAnswer?: { question_id: string; answer_id: string; answer?: string }[]
  corrects?: AnswerMatchingMapping[]
  solution?: string
}

interface IProp {
  value: string
  className?: string
}

interface CorrectAnswer {
  id: string
  created_at: string
  updated_at: string
  deleted_at: string | null
  question_id: string | null
  answer: string
  is_correct: boolean
  feedback: string | null
  active: boolean | null
  answer_position: number
}

const QuestionCard = ({ value, className = 'sapp-arrowed-container' }: IProp) => (
  <div className={className}>{value}</div>
)

const MatchingQuestion = ({ data, defaultAnswer, corrects, solution }: IProps) => {
  const [defaultValue, setDefaultValue] = useState<Record<string, QuestionMatching | undefined>>({})
  const [correctAnswer, setCorrectAnswer] = useState<Record<string, CorrectAnswer>>({})
  useEffect(() => {
    let obj: Record<string, QuestionMatching | undefined> = {}
    let objCorrect: Record<string, CorrectAnswer> = {}
    for (let quest of data?.question_matchings || []) {
      if (defaultAnswer) {
        obj[quest?.id] = data?.question_matchings.find(
          (el) =>
            el?.answer?.id === defaultAnswer.find((e) => e?.question_id === quest?.id)?.answer_id
        )
      }
    }

    if (corrects) {
      for (let correct of corrects) {
        if (defaultAnswer) {
          objCorrect[correct?.id] = correct?.answer
        }
      }
      setCorrectAnswer(objCorrect)
    }
    setDefaultValue(obj)
  }, [defaultAnswer, data?.question_matchings])

  return (
    <>
      <div className='content' dangerouslySetInnerHTML={{ __html: data.question_content }}></div>
      <div className='sapp-matching-question'>
        {data?.question_matchings.map((e: QuestionMatching) => (
          <div className='d-flex flex-nowrap justify-content-between'>
            <QuestionCard
              value={e.content}
              className={`matching-arrowed-container ${
                defaultValue?.[e.id]?.answer?.id ? GradeStatus.GRADED : GradeStatus.UNGRADED
              } ${
                defaultValue?.[e.id]?.answer?.id === correctAnswer?.[e.id]?.id
                  ? STATUS_ALL.ANSWERS.CORRECTS
                  : STATUS_ALL.ANSWERS.INCORRECTS
              }`}
            />
            {defaultValue?.[e.id]?.answer?.id && (
              <div
                className={`match-result ${
                  defaultValue?.[e.id]?.answer?.id === correctAnswer?.[e.id]?.id
                    ? STATUS_ALL.ANSWERS.CORRECTS
                    : STATUS_ALL.ANSWERS.INCORRECTS
                }`}
              >
                {defaultValue?.[e.id]?.answer?.id && defaultValue[e.id]?.answer?.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='mt-8'>
        <h3>Correct Answer</h3>
        <div className='sapp-matching-question'>
          {data?.question_matchings?.map((e: QuestionMatching, index: number) => {
            return (
              <div className='d-flex flex-nowrap justify-content-between' key={e.id}>
                <QuestionCard
                  value={e?.content}
                  className='matching-arrowed-container correct-answer'
                />
                <div className='match-result correct-answer'>
                  {correctAnswer?.[e?.id]?.id && (
                    <div className='stext-state-success'>{correctAnswer[e?.id]?.answer}</div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MatchingQuestion
