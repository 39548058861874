import React, { useEffect, useState } from 'react'
import { QuestionBankAPI } from 'src/apis/question-bank'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import useChecked from 'src/hooks/use-checked'
import { useConfirm } from 'src/hooks/use-confirm'
import { IResponse } from 'src/type'
import { ITopic, ITopicList } from 'src/type/question-bank'
import { truncateString } from 'src/utils/string'
import toast from 'react-hot-toast'
import SappModal from 'src/components/base/SappModal'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappTable from 'src/components/base/SappTable'
import LoadingTable from 'src/common/LoadingTable'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { replaceValueAll } from 'src/utils/string'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { useForm } from 'react-hook-form'
import { Select } from 'antd'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { Link } from 'react-router-dom'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import { LANG_SIDEBAR } from 'src/constants/lang'

type Props = {
  open: boolean | string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  type: 'multiple'
  updateTopics: (data: any, update: boolean) => void
  caseStudyId: string
}

// define headers
const headers = [
  {
    label: 'Item Set name',
    className: 'min-w-300px',
  },
  {
    label: 'topic desctiption',
    className: 'min-w-300px',
  },
  {
    label: 'questions',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const ImportTopic = ({ open, setOpen, type, updateTopics, caseStudyId }: Props) => {
  const { Option } = Select
  const [loading, setLoading] = useState<boolean>(false)
  const [questionState, setQuestionState] = useState<IResponse<ITopicList>>()
  const {
    checkedList,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
    setDefaultChecked,
    listDataChecked,
  } = useChecked<ITopic>(questionState?.data.topics)
  const { confirm, contextHolder } = useConfirm()

  const getParams = (
    text: string,
    sortType: string,
    fromDate?: any,
    toDate?: any,
    dateField?: string
  ) => ({
    text,
    sortType,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })
  const queryParams = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    page_index: 1,
    page_size: 10,
  }

  const initialValues: any = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    dateField: '',
  }

  const [searchParams, setSearchParams] = useState<any>(initialValues)

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(searchParams.fromDate)
  const dateQueryToDate = getDateInfo(searchParams.toDate)

  const showSearchParams =
    searchParams.text || searchParams.sortType || searchParams.fromDate || searchParams.toDate

  const validationSchema = z.object({
    text: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })
  const fieldNames = ['text', 'type', 'sortType', 'fromDate', 'toDate']

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (open) {
      getListQuestions(type, 1, 10, {})
      setDefaultChecked([])
    }
    return () => {}
  }, [open])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    const cleanedParams = cleanParamsAPI(getParams('', '', '', '', 'created_at'))
    setSearchParams(initialValues)
    setLoading(true)
    getListQuestions(type, 1, 10, cleanedParams)
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trim()),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    getListQuestions(type, 1, queryParams?.page_size || 10, { ...cleanedParams })
  }

  const getListQuestions = (
    type: 'multiple',
    page_index?: number,
    page_size?: number,
    params?: Object
  ) => {
    if (typeof type === 'string') {
      ;(async () => {
        try {
          Object.assign(params, {
            case_study_story_id: caseStudyId,
            filter_type: 'NOT_SELECTED',
          })
          const response = await QuestionBankAPI.getTopic({
            page_index: page_index || 1,
            page_size: page_size || 10,
            params: params,
          })
          setQuestionState(response)
        } catch (e) {
          console.error(e)
        } finally {
          setLoading(false)
        }
      })()
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showSearchParams ? 'updated_at' : 'created_at'
      )
    )

    getListQuestions(type, page_index, page_size, cleanedParams)
  }

  const handleClose = () => {
    setOpen(false)
    toggleCheckAll(false)
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: 'Bạn có chắc chắn muốn hủy không?',
      onClick: handleClose,
    })
  }

  const handleSubmit = async () => {
    if (!checkedList || checkedList.length <= 0) {
      toast.error('You have not selected any topic!')
      return
    }
    await updateTopics({ add_topic_ids: checkedList }, true)
    setOpen(false)
  }
  return (
    <div>
      {contextHolder}
      <SappModal
        title=''
        hideHeader
        open={!!open}
        handleClose={handleCancel}
        dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
        classNameBody={'sapp-m-h-unset'}
        showFooter={false}
      >
        <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
          <div className='sapp-title-modal-create-class fs-3'>{LANG_SIDEBAR.addItemSet}</div>
          <div className='d-flex justify-content-between align-items-center'>
            {listDataChecked.length > 0 && (
              <div className='fw-bold fs-6 text-primary me-8'>
                {listDataChecked.length} Selected
              </div>
            )}
            <SAPPDialogButtonsCancelSubmit
              type='button'
              cancelClick={handleCancel}
              cancelButtonCaption='Cancel'
              okButtonCaption='Add'
              okOnClick={handleSubmit}
              className='justify-content-between d-flex flex-row-reverse'
              classNameCancel='fw-bold me-0'
              classNameSubmit='fw-bold me-5'
              // loading={loading}
            />
          </div>
        </div>
        <div className='px-7'>
          <div className='card-body py-5'>
            <div className='card-header border-0'>
              <div className='w-100'>
                <div className='row'>
                  {/* begin:: Search */}
                  <div className='col-xl-3 col-sm-4'>
                    <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                      <HookFormTextField
                        isListScreen
                        control={control}
                        name='text'
                        placeholder='Search'
                        defaultValue={queryParams?.text}
                        onSubmit={onSubmit}
                      />
                    </div>
                  </div>
                  {/* end:: Search */}
                  <div className='col-xl-3 col-sm-4 mt-2'>
                    <HookFormSelectAntd
                      name='sortType'
                      placeholder='Sort by'
                      control={control}
                      size='large'
                      defaultValue={queryParams?.sortType ?? ''}
                      className='sapp-fs-select fs-6'
                    >
                      {FILTER_SELECTALL_SORTBY.map((status) => (
                        <Option key={status.label} value={status.value}>
                          {status.label}
                        </Option>
                      ))}
                    </HookFormSelectAntd>
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2 '>
                    <HookFormDateTime
                      control={control}
                      name='fromDate'
                      placeholder='From date'
                      defaultValue={queryParams?.fromDate}
                    />
                  </div>
                  <div className='col-xl-3 col-sm-4 mt-2'>
                    <HookFormDateTime
                      control={control}
                      name='toDate'
                      placeholder='To date'
                      defaultValue={queryParams?.toDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-header border-0 pt-5 mb-5'>
              <div className='container m-0'>
                <div className='row'>
                  <div className='col-sm-4 col-xl-4 col-lg-8 px-md-0'>
                    <SAPPFIlterButton
                      titleReset='Reset'
                      titleSubmit='Search'
                      okClick={onSubmit}
                      resetClick={handleResetFilter}
                      disabled={loading}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <SappTable
              headers={headers}
              loading={loading}
              data={questionState?.data?.topics}
              isCheckedAll={isCheckedAll}
              hasCheckAll={true}
              onChange={() => {
                toggleCheckAll(!isCheckedAll, true)
              }}
            >
              {loading ? (
                <>
                  {[1, 2, 3, 4, 5].map((header, i) => (
                    <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
                  ))}
                </>
              ) : (
                <>
                  {questionState?.data?.topics?.map((question: any) => {
                    const isChecked = checkedList.includes(question.id!)
                    const totalQuestions =
                      parseInt(question?.num_of_multiple_choice_questions || (0 as number)) +
                      parseInt(question?.num_of_constructed_questions || (0 as number))

                    return (
                      <tr key={question.id}>
                        <td>
                          <SAPPCheckbox
                            checkTarget='#kt_table_users .form-check-input'
                            checked={isChecked}
                            ktCheck={isChecked}
                            onChange={() => {
                              toggleCheck(question.id!)
                            }}
                          />
                        </td>
                        <td className='text-start'>
                          <Link
                            className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                            to={`/topic/${question.id}`}
                          >
                            {question?.name}
                          </Link>
                        </td>
                        <td className='text-start'>
                          <div
                            className='show-text-editor text-right'
                            dangerouslySetInnerHTML={{
                              __html: truncateString(String(question?.description), 120),
                            }}
                          />
                        </td>
                        <td className='text-start'>{totalQuestions}</td>
                        <td className='text-start'>
                          <DatetimeColumn
                            created_at={question?.created_at}
                            updated_at={question?.updated_at}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </SappTable>
            <PagiantionSAPP
              currentPage={questionState?.data?.meta?.page_index ?? 1}
              handlePaginationChange={handlePaginationChange}
              pageSize={questionState?.data?.meta?.page_size ?? 10}
              totalItems={questionState?.data?.meta?.total_records}
            />
          </div>
        </div>
      </SappModal>
    </div>
  )
}

export default ImportTopic
