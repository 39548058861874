import React, {Dispatch, SetStateAction} from 'react'
import {Control, FieldError} from 'react-hook-form'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import {RESOURCE_LOCATION} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {TYPE_COURSE} from 'src/constants'

type Props = {
  error?: FieldError | undefined
  control: Control<any>
  index: number
  getValues: any
  setFileLearningChild: Dispatch<SetStateAction<any>>
  course_section_id: string
  type: string | undefined
}

const LearningOutcomeTab = ({
  control,
  index,
  getValues,
  setFileLearningChild,
  course_section_id,
  type,
}: Props) => {
  return (
    <HookFormEditor
      control={control}
      name={`outcomes.${index}.description`}
      placeholder=''
      defaultValue={getValues(`outcomes.${index}.description`)}
      label='Nội dung'
      required
      resourceLocation={RESOURCE_LOCATION.COURSE}
      object_id={type === TYPE_COURSE.LEARNINGOUTCOME ? course_section_id : undefined}
      setDataFile={setFileLearningChild}
    />
  )
}

export default LearningOutcomeTab
