import React from 'react'

import ModalImport from 'src/components/base/upload-file/ModalImport'
import { beforeUpload } from 'src/utils'

interface IProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  quizId: string
  title: string
  templateFile: string
  type?: 'STUDENT' | 'TEACHER' | 'STAFF' | 'CLASS' | 'CLASS_STUDENT' | 'TEST_PARTICIPANT'
  templateFileName?: string
  uploadUrl?: string
}

const ImportUploadModal = ({
  open,
  setOpen,
  quizId,
  title,
  templateFile,
  type,
  templateFileName,
  uploadUrl,
}: IProps) => {
  return (
    <ModalImport
      open={open}
      setOpen={setOpen}
      title={title}
      templateFile={templateFile}
      templateFileName={templateFileName ?? 'filemau.xlsx'}
      uploadUrl={uploadUrl ?? `quiz/${quizId}/import-participant`}
      beforeUpload={(file) => beforeUpload(file)}
      type={type}
    ></ModalImport>
  )
}
export default ImportUploadModal
