import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import SappLabel from 'src/components/base/label/SappLabel'
import { INameAndId } from 'src/type/shop/common'
import AddClass from './AddClass'

interface IChooseClass {
  isOpen: boolean
  type: 'main' | 'trail'
  data?: INameAndId
}

const ProductContent = ({ useFormProp }: { useFormProp: UseFormReturn<any> }) => {
  const [open, setOpen] = useState<IChooseClass>()
  const { watch, setValue, getValues } = useFormProp
  const mainClass = watch('main_class_id')
  const trailClass = watch('trial_class_id')

  const handleChooseMain = () => {
    setOpen({ isOpen: true, type: 'main', data: mainClass })
  }

  const handleChooseTrial = () => {
    setOpen({ isOpen: true, type: 'trail', data: trailClass })
  }

  const handleAddClass = (data: INameAndId | null) => {
    if (open?.type === 'main') {
      setValue('main_class_id', data)
    } else {
      setValue('trial_class_id', data)
    }
  }

  const watchMainClass = watch('main_class_id')
  const watchTrialClass = watch('trial_class_id')

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <div className='text-gray-800 fw-bold sapp-mb-24px sapp-fs-20px'>Content</div>
        <div className='sapp-mb-24px fv-row'>
          <SappLabel label='Main Class' required />
          <div className='fv-row position-relative'>
            <ButtonIcon
              title={mainClass?.name ?? 'Add main class'}
              className={`${
                mainClass?.name ? '' : 'sapp-text-disable'
              } sapp-h-45px d-flex justify-content-start align-items-center w-100 ps-5 bg-white`}
              customButton='btn btn-outline btn-active-light-dark border-gray-300 text-gray-800 p-0 position-relative fw-semibold'
              onClick={handleChooseMain}
            >
              {watchMainClass?.id ? (
                <KTIcon
                  iconName='notepad-edit'
                  className={`fs-3 text-gray-500 p-2 sapp-icon-last bg-gray-100`}
                  iconType='outline'
                />
              ) : (
                <KTIcon iconName='plus' className={`fs-2 text-gray-500`} iconType='outline' />
              )}
            </ButtonIcon>
          </div>
        </div>
        <div className='sapp-mb-24px fv-row'>
          <SappLabel label='Trial Class' required />
          <div className='fv-row position-relative'>
            <ButtonIcon
              title={trailClass?.name ?? 'Add trial class'}
              className={`sapp-h-45px d-flex justify-content-start align-items-center w-100 ps-5`}
              customButton={`btn btn-outline btn-outline-dark btn-active-light-dark border-gray-300 ${
                trailClass?.name ? '' : 'sapp-text-disable '
              } p-0 position-relative fw-semibold`}
              onClick={handleChooseTrial}
            >
              {watchTrialClass?.id ? (
                <KTIcon
                  iconName='notepad-edit'
                  className={`fs-3 text-gray-500 p-2 sapp-icon-last bg-gray-100`}
                  iconType='outline'
                />
              ) : (
                <KTIcon iconName='plus' className={`fs-2 text-gray-500`} iconType='outline' />
              )}
            </ButtonIcon>
          </div>
        </div>
      </div>

      {/* Modal */}
      <>
        {open?.type === 'main' && (
          <AddClass
            initValue={open?.data}
            open={open?.isOpen || false}
            setOpen={setOpen}
            onAddClass={handleAddClass}
          />
        )}
        {open?.type === 'trail' && (
          <AddClass
            initValue={open?.data}
            open={open?.isOpen || false}
            setOpen={setOpen}
            onAddClass={handleAddClass}
          />
        )}
      </>
      {/*  Modal */}
    </div>
  )
}

export default ProductContent
