import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false

function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page_index', 'page_size'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})

  const sortSex = isNotEmpty(state.sex)
    ? qs.stringify(state, {filter: ['sex'], skipNulls: true})
    : ''

  const sortClass = isNotEmpty(state.class)
    ? qs.stringify(state, {filter: ['class'], skipNulls: true})
    : ''

  const search = isNotEmpty(state.text)
    ? qs.stringify(state, {filter: ['text'], skipNulls: true})
    : ''

  const sortStatus = isNotEmpty(state.status)
    ? qs.stringify(state, {filter: ['status'], skipNulls: true})
    : ''

  const sortRole = isNotEmpty(state.role_code)
    ? qs.stringify(state, {filter: ['role_code'], skipNulls: true})
    : ''

  const sortType = isNotEmpty(state.sortType)
    ? qs.stringify(state, {filter: ['sortType'], skipNulls: true})
    : ''

    const sortFromDate = isNotEmpty(state.fromDate)
    ? qs.stringify(state, {filter: ['fromDate'], skipNulls: true})
    : ''

    const sortToDate = isNotEmpty(state.toDate)
    ? qs.stringify(state, {filter: ['toDate'], skipNulls: true})
    : ''

    const dateField = isNotEmpty(state.dateField)
    ? qs.stringify(state, {filter: ['dateField'], skipNulls: true})
    : ''


  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter, sortStatus, sortRole, sortType, sortSex, sortClass,sortFromDate,sortToDate, dateField]
    .filter((f) => f)
    .join('&')
}

function stringifyRequestQueryStudent(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ['page_index', 'page_size', 'type'],
    skipNulls: true,
  })
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})

  const sortSex = isNotEmpty(state.sex)
    ? qs.stringify(state, {filter: ['sex'], skipNulls: true})
    : ''

  // const sortClass = isNotEmpty(state.gender)
  //   ? qs.stringify(state, {filter: ['class'], skipNulls: true})
  //   : ''

  const search = isNotEmpty(state.text)
    ? qs.stringify(state, {filter: ['text'], skipNulls: true})
    : ''

  // const searchCma = isNotEmpty(state.cmaSeason)
  //   ? qs.stringify(state, {filter: ['cma_season'], skipNulls: true})
  //   : ''

  const sortStatus = isNotEmpty(state.status)
    ? qs.stringify(state, {filter: ['status'], skipNulls: true})
    : ''

  const sortType = isNotEmpty(state.sortType)
    ? qs.stringify(state, {filter: ['sortType'], skipNulls: true})
    : ''

    const sortFromDate = isNotEmpty(state.fromDate)
    ? qs.stringify(state, {filter: ['fromDate'], skipNulls: true})
    : ''

    // const sortAccaFromDate = isNotEmpty(state.accaFromDate)
    // ? qs.stringify(state, {filter: ['acca_from_date'], skipNulls: true})
    // : ''

    // const sortCfaFromDate = isNotEmpty(state.cfaFromDate)
    // ? qs.stringify(state, {filter: ['cfa_from_date'], skipNulls: true})
    // : ''

    const sortToDate = isNotEmpty(state.toDate)
    ? qs.stringify(state, {filter: ['toDate'], skipNulls: true})
    : ''

    // const sortAccaToDate = isNotEmpty(state.accaToDate)
    // ? qs.stringify(state, {filter: ['acca_to_date'], skipNulls: true})
    // : ''

    // const sortCfaToDate = isNotEmpty(state.cfaToDate)
    // ? qs.stringify(state, {filter: ['cfa_to_date'], skipNulls: true})
    // : ''

    // const sortExamType = isNotEmpty(state.examType)
    // ? qs.stringify(state, {filter: ['examType'], skipNulls: true})
    // : ''

    // const sortExamList = isNotEmpty(state.examList)
    // ? qs.stringify(state, {filter: ['examList'], skipNulls: true})
    // : ''

    const dateField = isNotEmpty(state.dateField)
    ? qs.stringify(state, {filter: ['dateField'], skipNulls: true})
    : ''

    const type_user = isNotEmpty(state.type_user)
    ? qs.stringify(state, {filter: ['type_user'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter, sortStatus, sortType, sortSex,sortFromDate,sortToDate, dateField, type_user]
    .filter((f) => f)
    .join('&')
}

function stringifyRequestQueryTeacher(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ['page_index', 'page_size', 'type'],
    skipNulls: true,
  })
  
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})

  const sortSex = isNotEmpty(state.sex)
    ? qs.stringify(state, {filter: ['sex'], skipNulls: true})
    : ''

  const search = isNotEmpty(state.text)
    ? qs.stringify(state, {filter: ['text'], skipNulls: true})
    : ''

  const sortStatus = isNotEmpty(state.status)
    ? qs.stringify(state, {filter: ['status'], skipNulls: true})
    : ''

    const sortFromDate = isNotEmpty(state.fromDate)
    ? qs.stringify(state, {filter: ['fromDate'], skipNulls: true})
    : ''

    const sortToDate = isNotEmpty(state.toDate)
    ? qs.stringify(state, {filter: ['toDate'], skipNulls: true})
    : ''

    
    const teacher_status = isNotEmpty(state.teacher_status)
    ? qs.stringify(state, {filter: ['teacher_status'], skipNulls: true})
    : ''
    
    const course_category_id = isNotEmpty(state.course_category_id)
    ? qs.stringify(state, {filter: ['course_category_id'], skipNulls: true})
    : ''
    
    const subject_id = isNotEmpty(state.subject_id)
    ? qs.stringify(state, {filter: ['subject_id'], skipNulls: true})
    : ''
    
    const feature_section_id = isNotEmpty(state.feature_section_id)
    ? qs.stringify(state, {filter: ['feature_section_id'], skipNulls: true})
    : ''

    const staff_ids = isNotEmpty(state.staff_ids)
    ? qs.stringify(state, {filter: ['staff_ids'], skipNulls: true})
    : ''

    const dateField = isNotEmpty(state.dateField)
    ? qs.stringify(state, {filter: ['dateField'], skipNulls: true})
    : ''


  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter, sortStatus, sortSex, sortFromDate,sortToDate, teacher_status, staff_ids, course_category_id , subject_id, feature_section_id, dateField]
    .filter((f) => f)
    .join('&')
}

function stringifyRequestQueryStory(state: QueryState): string {
  const pagination = qs.stringify(state, {
    filter: ['page_index', 'page_size', 'type'],
    skipNulls: true,
  })
  
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})

  const search = isNotEmpty(state.text)
    ? qs.stringify(state, {filter: ['text'], skipNulls: true})
    : ''

    const sortFromDate = isNotEmpty(state.fromDate)
    ? qs.stringify(state, {filter: ['fromDate'], skipNulls: true})
    : ''

    const sortToDate = isNotEmpty(state.toDate)
    ? qs.stringify(state, {filter: ['toDate'], skipNulls: true})
    : ''
    
    const dateField = isNotEmpty(state.dateField)
    ? qs.stringify(state, {filter: ['dateField'], skipNulls: true})
    : ''


  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter, sortFromDate,sortToDate,dateField]
    .filter((f) => f)
    .join('&')
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  isNotEmpty,
  stringifyRequestQueryStudent,
  stringifyRequestQueryTeacher,
  stringifyRequestQueryStory,
}
