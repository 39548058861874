import { Dispatch, SetStateAction } from 'react'
import PopupConfirm from 'src/common/PopupConfirm'
import ButtonDanger from 'src/components/base/button/ButtonDanger'

interface IProps {
  selected: Array<any>
  deleteItems: () => void
  openPopup: boolean
  setOpenPopup: Dispatch<SetStateAction<boolean>>
  title?: string
  loading?: boolean
  body?: string
  okButtonCaption?: string
  cancelButtonCaption?: string
  className?: string
}

const SAPPListGroup = ({
  selected,
  deleteItems,
  openPopup,
  setOpenPopup,
  title = 'Block Selected',
  loading,
  body,
  okButtonCaption,
  cancelButtonCaption,
  className = '',
}: IProps) => {
  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5 text-danger'>
        <span>{selected.length}</span>&nbsp;Selected
      </div>
      <ButtonDanger
        className={className + 'py-0'}
        title={title}
        onClick={() => setOpenPopup(true)}
        loading={loading}
      />
      <PopupConfirm
        open={openPopup}
        setOpen={setOpenPopup}
        onClick={deleteItems}
        body={body}
        okButtonCaption={okButtonCaption}
        cancelButtonCaption={cancelButtonCaption}
      />
    </div>
  )
}

export default SAPPListGroup
