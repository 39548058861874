import React from 'react'
import styles from '../button-primary/ButtonPrimary.module.scss'
import { IButtonProps } from 'src/type/button'
import { KTIcon } from 'src/_metronic/helpers'

interface IProps extends IButtonProps {
  iconName: string
}

const ButtonIconPrimary = ({
  title,
  size = 'large',
  disabled,
  loading,
  iconName,
  iconType,
  onClick,
  className = '',
}: IProps) => {
  const heightClass = size === 'large' ? 'sapp-h-45px' : 'sapp-h-40'

  return (
    <button
      className={`btn ${styles['sapp-btn--primary']} ${heightClass} btn-flex btn-center ${className}`}
      disabled={loading || disabled}
      onClick={onClick && onClick}
    >
      {loading ? (
        <span className={`indicator-progress sapp-d-content`}>
          <span className='spinner-border spinner-border-sm align-middle'></span>
        </span>
      ) : (
        <>
          <KTIcon iconName={iconName} iconType={iconType} />
          <span className='indicator-label'>{title}</span>
        </>
      )}
    </button>
  )
}

export default ButtonIconPrimary
