export const AREA_PROFILE = '/classroom/area/detail'
export const ROOM_PROFILE = '/classroom/room/detail'

export const AREA_PROFILE_URL = {
  OVERVIEW: 'overview',
  SETTING: 'setting',
  CLASSROOMLIST: 'roomlist',
}

export const ROOM_PROFILE_URL = {
  OVERVIEW: 'overview',
  SETTING: 'setting',
}

export const TITLE_OPTIONS_AREA = {
  facility: 'Facility',
  facilityList: 'Facility List',
  facilityDetail: 'Facility Detail',
  classroom: 'Classroom List',
  createFacility: 'Create Facility',
  name: 'Name',
  code: 'Code',
  status: 'Status',
  area: 'Area',
  totalRoom: 'Total Room',
  address: 'Address',
  editFacility: 'Edit Facility',
}

export const TITLE_OPTIONS_ROOM = {
  classroomlist: 'ClassRoom List',
  facilityDetail: 'Facility Detail',
  classroom: 'Classroom',
  createRoom: 'Create Classroom',
  name: 'Name',
  code: 'Code',
  classroomType: 'Classroom Type',
  facility: 'Facility',
  status: 'Status',
  address: 'Address',
  capacity: 'Capacity',
  deferredStudent: 'Deferred Students',
  newStudent: 'New Students',
  editFacility: 'Edit Facility',
}
