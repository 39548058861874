import { useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import ItemClassProfile from 'src/components/classes/profile/ItemClassProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { TITLE_OPTIONS_AREA, AREA_PROFILE } from 'src/constants/classroom'
import { IArea } from 'src/type/area'
import { mappingAddress } from 'src/utils'
interface IProps {
  areaDetail: IArea | undefined
  loading: boolean
}

const AreaProfileDetail = ({ areaDetail, loading }: IProps) => {
  const { id } = useParams()
  return (
    <>
      {loading ? (
        <LoadingProfileDetail loadingAvatar={false} />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Overview'
            titleButton='Edit Facility'
            link={`${AREA_PROFILE}/${id}/setting`}
          />

          <div className='card-body p-9'>
            <ItemProfile title={TITLE_OPTIONS_AREA.name} body={areaDetail?.name} />
            <ItemProfile title={TITLE_OPTIONS_AREA.code} body={areaDetail?.code} />
            <ItemClassProfile
              title={TITLE_OPTIONS_AREA.status}
              status={areaDetail?.status.toLocaleLowerCase()}
              className='mb-7'
            />
            <ItemProfile
              title={TITLE_OPTIONS_AREA.totalRoom}
              body={areaDetail?.total_room?.toString()}
            />
            <ItemProfile
              title={TITLE_OPTIONS_AREA.area}
              body={areaDetail?.ward?.district?.province?.name}
            />
            <ItemProfile
              title={TITLE_OPTIONS_AREA.address}
              body={mappingAddress([
                areaDetail?.address,
                areaDetail?.address,
                areaDetail?.ward?.district.name_with_type,
                areaDetail?.ward?.district?.province?.name_with_type,
              ])}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AreaProfileDetail
