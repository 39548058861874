import React, {useEffect, useState} from 'react'
import toast from 'react-hot-toast'
import {StoryAPI} from 'src/apis/story'
import SappDrawer from 'src/components/base/SappDrawer'
import SappTable from 'src/components/base/SappTable'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SAPPRadio from 'src/components/base/radiobutton/SAPPRadio'
import useChecked from 'src/hooks/use-checked'
import {useConfirm} from 'src/hooks/use-confirm'
import {IResponse} from 'src/type'
import {IStory, IStoryList} from 'src/type/story'
import {htmlToRaw} from 'src/utils'

type Props = {
  open: boolean | string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  story?: IStory
  setStory?: React.Dispatch<React.SetStateAction<IStory | undefined>>
}
const headers = [
  {
    key: 'name',
    label: '',
  },
]
const ChooseStoriesDrawer = ({open, setOpen, story, setStory}: Props) => {
  const [storyState, setStoryState] = useState<IResponse<IStoryList>>()
  const {checkedList, listDataChecked, toggleCheck, toggleCheckAll, setDefaultChecked} =
    useChecked<IStory>(storyState?.data?.stories)
  const {contextHolder} = useConfirm()

  useEffect(() => {
    getListCaseStudies(1, 10)
    if (story) {
      setDefaultChecked([story])
    }
  }, [open])

  const getListCaseStudies = (page_index: number = 1, page_size: number = 10) => {
    ;(async () => {
      try {
        const response = await StoryAPI.getAPIStory({
          page_index,
          page_size,
        })
        setStoryState(response)
      } catch (e) {}
    })()
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    if (!listDataChecked || listDataChecked.length <= 0) {
      toast.error('You have not selected any case study!')
      return
    }
    setStory && setStory(listDataChecked[0])
    setOpen(false)
  }

  return (
    <div>
      {contextHolder}
      <SappDrawer
        open={!!open}
        title='Case Studies'
        cancelButtonCaption={'Cancel'}
        okButtonCaption={'Save'}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        width='50%'
        confirmOnclose
      >
        <div className='modal-content rounded'>
          {/* start:: body modal */}

          <SappTable
            headers={headers}
            loading={false}
            data={storyState?.data?.stories}
            isCheckedAll={false}
            onChange={() => {}}
            hasCheck={false}
            showHeader={false}
          >
            {storyState?.data?.stories?.map((story) => {
              const isChecked = checkedList.includes(story.id!)
              return (
                <tr key={story.id}>
                  <td className='w-35px'>
                    <SAPPRadio
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheckAll(false)
                        toggleCheck(story.id!)
                      }}
                    />
                  </td>
                  <td>
                    <div className='sapp-text-truncate-1 text-wrap'>{htmlToRaw(story.name)}</div>
                  </td>
                </tr>
              )
            })}
          </SappTable>

          <PagiantionSAPP
            currentPage={storyState?.data?.meta?.page_index ?? 1}
            handlePaginationChange={(page: number, pageSize: number) => {
              getListCaseStudies(page, pageSize)
            }}
            pageSize={storyState?.data?.meta?.page_size ?? 10}
            totalItems={storyState?.data?.meta?.total_records}
          />
        </div>
        {/* end:: body modal */}
      </SappDrawer>
    </div>
  )
}

export default ChooseStoriesDrawer
