import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { CommonAPI } from 'src/apis'
import ClassroomApi from 'src/apis/classroom'
import HeaderTab from 'src/components/base/HeaderTab'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { FACILITY_STATUS, VALIDATION_FIELD } from 'src/constants'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { useConfirm } from 'src/hooks/use-confirm'
import { IArea } from 'src/type/area'
import { ICommonAddress, IDistrictList, IProvinceList, IWardList } from 'src/type/common'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  loading: boolean
  areaDetail: IArea | undefined
  refetch: () => void
}

interface IInputProps {
  name: string
  code: string
  province_code: string
  district_code: string
  ward_code: string
  address?: string
  status?: string
}

const AreaProfileSetting = ({ loading, areaDetail, refetch }: IProps) => {
  const { id } = useParams()
  const { confirm, contextHolder } = useConfirm()
  const isCheckEnded = areaDetail?.status === 'ENDED' ? true : false
  const [isLoading, setIsLoading] = useState<boolean>()

  const [provinceList, setProvinceList] = useState<IProvinceList | null>()
  const [districtList, setDistrictList] = useState<IDistrictList | null>()
  const [wardList, setWardList] = useState<IWardList | null>()

  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    code: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    province_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    district_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    ward_code: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    address: z.string().optional(),
    status: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  })

  const { handleSubmit, control, getValues, setValue } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
      province_code: '',
      district_code: '',
      ward_code: '',
      address: '',
      status: '',
    },
  })

  const fetchProvince = async (pageIndex: number = 1, pageSize: number = 20, params?: Object) => {
    try {
      const res = await CommonAPI.getProvince(pageIndex, pageSize, params)
      setProvinceList((prev) => {
        if (res?.data?.metadata?.page_index === 1) {
          return res.data
        } else {
          return {
            metadata: res.data.metadata,
            provinces: (prev?.provinces ?? [])
              ?.concat(res.data.provinces)
              ?.filter(
                (item, index, self) => index === self.findIndex((t) => t.code === item.code)
              ),
          }
        }
      })
    } catch (error) {}
  }

  const fetchDistrict = async (pageIndex: number = 1, pageSize: number = 20, params?: Object) => {
    try {
      const res = await CommonAPI.getDistrict(pageIndex, pageSize, params)
      setDistrictList((prev) => {
        if (res?.data?.metadata?.page_index === 1) {
          return res.data
        } else {
          return {
            metadata: res.data.metadata,
            districts: (prev?.districts ?? [])
              ?.concat(res.data.districts)
              .filter((item, index, self) => index === self.findIndex((t) => t.code === item.code)),
          }
        }
      })
    } catch (error) {}
  }

  const fetchWards = async (pageIndex: number = 1, pageSize: number = 20, params?: Object) => {
    try {
      const res = await CommonAPI.getWards(pageIndex, pageSize, params)
      setWardList((prev) => {
        if (res?.data?.metadata?.page_index === 1) {
          return res.data
        } else {
          return {
            metadata: res.data.metadata,
            wards: (prev?.wards ?? [])
              ?.concat(res.data.wards)
              .filter((item, index, self) => index === self.findIndex((t) => t.code === item.code)),
          }
        }
      })
    } catch (error) {}
  }

  const handleNextPage = async (
    totalPages?: number,
    pageIndex?: number,
    pageSize?: number,
    fetchData?: (page_index: number, page_size: number, params?: Object) => void,
    params?: Object
  ) => {
    if (totalPages && pageIndex && pageSize && pageIndex < totalPages && fetchData && pageSize) {
      fetchData(pageIndex + 1, pageSize, params)
    }
  }

  const initData = async () => {
    fetchProvince(1, 70)
    if (!isEmpty(id)) {
      const res = await ClassroomApi.getAreaClassDetail(id ?? '')
      setValue('name', res.data?.name ?? '')
      setValue('code', res.data?.code ?? '')
      setValue('address', res.data?.address ?? '')
      setValue('province_code', res.data?.ward?.district?.province?.code ?? '')
      setValue('district_code', res.data?.ward?.district?.code ?? '')
      setValue('ward_code', res.data?.ward?.code ?? '')
      setValue('status', res.data?.status ?? '')
      if (res.data?.ward?.district?.province?.code) {
        fetchProvince(1, 20, { search: `code=${res.data?.ward?.district?.province?.code}` })
      }
      if (res.data?.ward?.district?.province?.code) {
        await fetchDistrict(1, 20, { province_code: res.data?.ward?.district?.province?.code })
      }
      if (res.data?.ward?.district?.code) {
        await fetchWards(1, 20, { district_code: res.data?.ward?.district?.code })
      }
    }
  }

  const onSubmit: SubmitHandler<IInputProps> = async (data: IInputProps) => {
    try {
      setIsLoading(true)
      if (!id) return
      await ClassroomApi.editAreaClass(id, data)
      refetch()
      toast.success('Update Successfully!')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initData()
  }, [id, areaDetail])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title={TITLE_OPTIONS_AREA.editFacility} />
        <div className='collapse show'>
          <div className='card-body card-body px-10 pt-8 pb-4 row'>
            <div className='mb-8 col-12'>
              <HookFormTextField
                required
                control={control}
                name='name'
                placeholder='Name'
                disabled={isCheckEnded}
                label='Name'
              />
            </div>
            <div className='mb-8 col-12'>
              <HookFormTextField
                required
                control={control}
                name='code'
                placeholder='Code'
                disabled={!!id}
                label='Code'
              />
            </div>
            <div className='mb-8 col-12 row'>
              <div className='col-lg-4 col-12'>
                <HookFormSelectAntd
                  control={control}
                  name='province_code'
                  placeholder='Province'
                  label='Province'
                  onFocus={() => {
                    fetchProvince(1, 10)
                  }}
                  required
                  showSearch
                  onChange={(value) => {
                    if (!value) {
                      setValue('province_code', '')
                      setDistrictList(null)
                    } else {
                      fetchDistrict(1, 20, { province_code: value })
                    }
                    setValue('district_code', '')
                    setValue('ward_code', '')
                    setWardList(null)
                  }}
                  onSearch={(value: string) => {
                    fetchProvince(1, 10, { search: `name=${value}` })
                  }}
                  handleNextPage={() => {
                    handleNextPage(
                      provinceList?.metadata?.total_pages,
                      provinceList?.metadata?.page_index,
                      provinceList?.metadata?.page_size,
                      fetchProvince
                    )
                  }}
                >
                  {provinceList?.provinces?.map((province: ICommonAddress, index: number) => (
                    <Option key={province?.code + index} value={province.code}>
                      {province.name}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-lg-4 col-12'>
                <HookFormSelectAntd
                  control={control}
                  name='district_code'
                  placeholder='District'
                  showSearch
                  label='District'
                  required
                  onChange={(value) => {
                    if (value) {
                      fetchWards(1, 20, { district_code: value })
                    }
                    setValue('ward_code', '')
                    setWardList(null)
                  }}
                  onSearch={(value: string) => {
                    if (getValues('province_code') && value) {
                      fetchDistrict(1, 10, {
                        search: `name=${value}`,
                        province_code: getValues('province_code'),
                      })
                    }
                  }}
                  handleNextPage={() => {
                    handleNextPage(
                      districtList?.metadata?.total_pages,
                      districtList?.metadata?.page_index,
                      districtList?.metadata?.page_size,
                      fetchDistrict,
                      { province_code: getValues('province_code') }
                    )
                  }}
                >
                  {districtList?.districts?.map((district: ICommonAddress, index: number) => (
                    <Option key={district?.code + index} value={district?.code}>
                      {district?.name_with_type}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
              <div className='col-lg-4 col-12'>
                <HookFormSelectAntd
                  control={control}
                  name='ward_code'
                  placeholder='Ward'
                  showSearch
                  label='Ward'
                  onSearch={(value: string) => {
                    if (!!value && getValues('district_code')) {
                      fetchWards(1, 10, {
                        search: `name=${value}`,
                        district_code: getValues('district_code'),
                      })
                    }
                  }}
                  handleNextPage={() => {
                    handleNextPage(
                      wardList?.metadata?.total_pages,
                      wardList?.metadata?.page_index,
                      wardList?.metadata.page_size,
                      fetchWards,
                      { district_code: getValues('district_code') }
                    )
                  }}
                  required
                >
                  {wardList?.wards?.map((ward: ICommonAddress, index: number) => (
                    <Option key={ward?.code + index} value={ward?.code}>
                      {ward?.name_with_type}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </div>
            <div className='mb-8 col-12'>
              {!!id && (
                <HookFormSelectAntd
                  control={control}
                  name='status'
                  placeholder='Status'
                  showSearch
                  label='Status'
                  required
                >
                  {FACILITY_STATUS.map((item) => (
                    <Option key={item?.label} value={item?.value}>
                      {item?.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              )}
            </div>
            <div className='col-12'>
              <WarningText className='mb-8 mt-0' title='Địa chỉ cơ sở'>
                <ul>
                  <li className='sapp-content-alert mt-2'>Chỉ nhập số nhà, tên đường</li>
                </ul>
              </WarningText>
            </div>
            <div className='col-12 mb-8'>
              <HookFormTextField
                control={control}
                name='address'
                label='Address Detail'
                placeholder='Address Detail'
                required
              />
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <ButtonSecondary
              type='button'
              title='Cancel'
              className='me-5'
              onClick={() => {
                refetch()
                initData()
              }}
            />
            <ButtonPrimary
              type='submit'
              title='Save'
              loading={loading}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AreaProfileSetting
