import SappDrawer from 'src/components/base/SappDrawer'
import Accodian from './AccodianItems'
import { useEffect, useState } from 'react'
import { ClassesApi } from 'src/apis/classes'
import { TYPE_COURSE } from 'src/constants'
import { TreeHelper } from 'src/helper/tree'
import ParticipantDetail from 'src/components/entrance-test/ParticipantDetail'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  open: boolean
  setOpen: any
  data?: any
  title?: string
  type?: string
  id?: string
  exceptedSections?: any
  classId?: string
  refetch?: any
  studentId?: any
  dataModal?: any
}

const ModalProcessing = ({
  open,
  setOpen,
  title = 'Learning Process',
  type = 'process',
  id,
  exceptedSections,
  classId,
  refetch,
  studentId,
  dataModal,
}: IProps) => {
  const [checkedAll, setCheckedAll] = useState(true)
  const [loading, setLoading] = useState(false)
  // const checkAll = (status: boolean) => {
  //   setCheckedAll(status)
  //   // let checkedAll = true
  //   for (let e of explorerData) {
  //     toggleChecked(explorerData, e.id, status)
  //   }
  //   return true
  // }
  const handleConvertData = (data: any) => {
    const newData = [...data]
    for (let e of newData) {
      for (let el of e?.children) {
        if (el?.course_learning_outcome) {
          el.children = [
            {
              ...el?.course_learning_outcome,
              children: [],
              course_section_type: TYPE_COURSE.LEARNINGOUTCOME,
            },
            ...el.children,
          ]
        }
      }
    }
    return newData
  }
  const [explorerData, setExplorerData] = useState<any>([])

  const handleChecked = (e: string, status: boolean) => {
    setExplorerData((prev: any) => {
      const oldData = [...prev]
      const newData = toggleChecked(oldData, e, status)
      return newData
    })
  }
  // A function that recursively finds check if all node is checked
  function findNodeUnChecked(data: any): any {
    for (let node of data) {
      if (node.checked === false) {
        return false
      } else if (node.children && node.children.length > 0) {
        let result = findNodeUnChecked(node.children)
        if (!result) {
          return result
        }
      }
    }
    return true
  }
  function getUnchecked(data: any): any {
    // Initialize an empty array to store the results
    let result = []
    // Loop through each element in the data
    for (let element of data) {
      // Check if the element has checked = false
      if (!element.checked) {
        // Push the element to the result array
        result.push(element.id)
      }
      // Check if the element has children
      if (element.children) {
        // Recursively call the function on the children and concatenate the result array
        result = result.concat(getUnchecked(element.children))
      }
    }
    // Return the result array
    return result
  }
  // A function that takes an array of data and an id of a node
  function toggleChecked(data: any, id: string, checked: boolean) {
    // A helper function that recursively finds the node with the given id and returns it
    function findNode(data: any, id: string): any {
      for (let node of data) {
        if (node.id === id) {
          return node
        } else if (node.children && node.children.length > 0) {
          let result = findNode(node.children, id)
          if (result) {
            return result
          }
        }
      }
      return null
    }

    // A helper function that recursively updates the checked status of the node and its children
    function updateNode(node: any, checked: boolean) {
      node.checked = checked
      if (node.children && node.children.length > 0) {
        for (let child of node.children) {
          updateNode(child, checked)
        }
      }
    }

    // A helper function that recursively updates the checked status of the node and its ancestors
    function updateAncestors(data: any, node: any) {
      if (node.parent_id) {
        let parent = findNode(data, node.parent_id)
        if (parent) {
          // If at least one child of the parent are checked, then the parent is checked
          // Otherwise, the parent is not checked
          let oneChecked = false
          for (let child of parent.children) {
            if (child.checked) {
              oneChecked = true
              break
            }
          }
          parent.checked = oneChecked
          // Repeat the process for the parent's parent
          updateAncestors(data, parent)
        }
      }
    }

    // Find the node with the given id
    let node = findNode(data, id)
    if (node) {
      // Toggle the checked status of the node
      //   let checked = !node.checked
      // Update the node and its children
      updateNode(node, checked)
      // Update the node and its ancestors
      updateAncestors(data, node)
    }
    return data
  }

  const handleSubmit = async () => {
    if (classId && !studentId) {
      setLoading(true)
      const payload = {
        excepted_course_section_ids: [...getUnchecked(explorerData)],
      }

      try {
        await ClassesApi.editCourseContentClass(payload, classId)
        refetch()
        setOpen({ status: false })
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    } else if (classId && studentId) {
      setLoading(true)
      const payload = {
        excepted_course_section_ids: [...getUnchecked(explorerData)],
      }

      try {
        await ClassesApi.editCourseContentStudent(payload, classId, studentId)
        // refetch()
        setOpen({ status: false })
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    // if (id) {
    async function fetchCourseList() {
      if (id) {
        setLoading(true)
        try {
          let resExcept: any
          if (studentId && classId) {
            const res = await ClassesApi.getExceptedSectionStudent({
              id: classId,
              student_id: studentId,
            })
            resExcept = [...res.data.excepted_course_section]
          } else {
            resExcept = [...exceptedSections]
          }
          const res = await ClassesApi.getStudentCourseContent(id)
          setExplorerData(() => {
            let newData = []
            for (let e of res.data) {
              if (resExcept?.includes(e.id)) {
                newData.push({ ...e, checked: false })
              } else {
                newData.push({ ...e, checked: true })
              }
            }
            return [
              ...handleConvertData(
                TreeHelper.convertFromArray(newData, { convert_original: true })
              ),
            ]
          })
        } catch (err) {
          console.error(err)
        } finally {
          setLoading(false)
        }
      }
    }
    if (id) {
      fetchCourseList()
      // }
    }
  }, [id, exceptedSections, studentId, classId])
  useEffect(() => {
    setCheckedAll(findNodeUnChecked(explorerData))
  }, [explorerData])
  return (
    <SappDrawer
      open={open}
      width='50%'
      title={title}
      handleSubmit={handleSubmit}
      handleClose={() => setOpen({ status: false })}
      loading={loading}
      confirmOnclose={false}
      footer={() => {
        return (
          <div className='flex justify-end'>
            <ButtonSecondary
              title='Cancel'
              onClick={() => setOpen({ status: false })}
              className='me-0'
              size='small'
            />
          </div>
        )
      }}
    >
      <>
        {type === 'participants-detail' && (
          <div className='flex gap-3 mb-8'>
            <ParticipantDetail
              phone={dataModal?.user?.user_contacts[0]?.phone}
              email={dataModal?.user?.user_contacts[0]?.email}
              university={dataModal?.user?.university?.name}
              participantName={dataModal?.user?.detail?.full_name}
              type={dataModal?.user?.university_program?.name}
              major={dataModal?.user?.major?.name}
              language={dataModal?.user?.english_level?.name}
              testscore={Number(dataModal?.score)}
              scoreoftest={
                Number(dataModal?.multiple_choice_score) + Number(dataModal?.constructed_score)
              }
              ratio_score={dataModal?.ratio_score}
              scoremajor={
                Number(dataModal?.major_score) +
                  Number(dataModal?.user?.university?.score) +
                  Number(dataModal?.user?.major?.score) || '0'
              }
              typeofmajor={dataModal?.user?.major_classification}
              anotherScore={
                Number(dataModal?.english_score) +
                  Number(dataModal?.user?.university_program?.score) || '0'
              }
              manageScore={dataModal?.user?.english_classification}
              studyPlan={dataModal?.user?.learning_program}
              id={dataModal?.id}
            />
          </div>
        )}
        <div style={type === 'course-content' ? { marginLeft: '5px' } : {}}>
          {explorerData &&
            explorerData.map((item: any, index: number) => {
              return <Accodian explorer={item} type={type} action={handleChecked} key={item.id} />
            })}
        </div>
      </>
    </SappDrawer>
  )
}
export default ModalProcessing
