import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { FILTER } from 'src/constants/grade'
import clsx from 'clsx'

interface FilterOptionsProps {
  selectedFilter: string[]
  openGrid: boolean
  onFilterChange: (value: string[]) => void
}

const FilterOptions = ({ selectedFilter, openGrid, onFilterChange }: FilterOptionsProps) => {
  const handleCheck = (filter: string) => {
    let newFilter: string[]

    if (selectedFilter.includes(filter)) {
      // Remove if filter already selected
      newFilter = selectedFilter.filter((item) => item !== filter)
    } else {
      // If not selected, add it
      newFilter = [...selectedFilter, filter]
    }

    // Trigger callback to update current filter
    onFilterChange(newFilter)
  }

  return (
    <div className={clsx('filter', { active: openGrid })}>
      {FILTER.map((filter_item) => {
        let value = ''
        let label = ''

        if (Array.isArray(filter_item)) {
          value = filter_item.map((item) => item.value).join(',')
          label = filter_item.map((item) => item.label).join('/ ')
        } else {
          value = filter_item.value
          label = filter_item.label
        }

        return (
          <div key={value} className='d-flex sapp-select-question align-items-center'>
            <SAPPCheckbox
              id={`filter-${value}`}
              checked={selectedFilter.includes(value)}
              onChange={() => handleCheck(value)}
            />
            <label
              className={selectedFilter.includes(value) ? 'checked' : ''}
              htmlFor={`filter-${value}`}
            >
              {label}
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default FilterOptions
