import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PopupConfirm from 'src/common/PopupConfirm'
import { PageLink } from 'src/constants'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import ActionCell from '../base/action/ActionCell'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_STORY_GR, TITLE_TOPIC_GR } from 'src/constants/permission'
import { Role } from 'src/type'

interface IProps {
  id: any
  updateTopics: (data: any, update: boolean) => void
}

export const TopicActions = ({ id, updateTopics }: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const location = useLocation()
  const { profileMe } = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.EDIT_QUESTION_TOPIC) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDelete = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_TOPIC_GR.REMOVE_QUESTION_TOPIC) ||
      role.permissions?.includes(TITLE_STORY_GR.EDIT_STORY) ||
      role.permissions?.includes(TITLE_STORY_GR.CREATE_STORY) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const deleteItemTopic = async () => {
    setOpenModalDelete(false)
    await updateTopics({ remove_topic_ids: [id] }, false)
  }

  return (
    <>
      <ActionCell customWidth='w-150px'>
        {/* begin::Menu item */}
        {allowRenderEdit && (
          <Link to={`${PageLink.TOPIC}/${id}${location.search || ''}`} className='menu-item px-3'>
            <div className='menu-link px-3'>Edit</div>
          </Link>
        )}
        {/* end::Menu item */}
        {allowRenderDelete && (
          <div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
            <div className='menu-link px-3'>Delete</div>
          </div>
        )}

        {/* end::Menu item */}
      </ActionCell>
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteItemTopic}
        body='Bạn có chắc chắn muốn xóa không?'
      />
    </>
  )
}
