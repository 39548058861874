import {useEffect, useState} from 'react'
import {CoursesAPI} from 'src/apis/courses'
import './SelectedLearning.scss'
import {htmlToRaw} from 'src/utils'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
interface IProps {
  id: string
  handleDelete: (value: string) => void
  index?: number
}
const SelectedLearningOutCome = ({id, handleDelete, index}: IProps) => {
  const [data, setData] = useState<any>()
  useEffect(() => {
    async function getDetail() {
      const res = await CoursesAPI.getOutCome(id)
      setData(res.data)
    }
    getDetail()
  }, [id])
  return (
    <div className='sapp-learning-selected-container mb-4'>
      {/* <div className='sapp-learning-selected-header'> */}
      <div className='sapp-selected-name sapp-text-truncate-1 sapp-no-wrap-text-trunscate'>{`${
        (index || 0) + 1
      }. ${htmlToRaw(data?.description)}`}</div>
      {/* <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          onClick={() => handleDelete(id)}
        >
          <i className='ki-outline ki-cross fs-1' />
        </div> */}
      <ButtonIconOnly
        iconName='trash'
        onClick={() => handleDelete(id)}
        iconType='outline'
        bg={'sapp-custom-bg-transparent'}
        activeColor='danger'
        fs='2'
      />
      {/* </div> */}
      {/* <div>{data?.description}</div> */}
    </div>
  )
}
export default SelectedLearningOutCome
