import { zodResolver } from '@hookform/resolvers/zod'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { NotificationAPI } from 'src/apis/notifications'
import ButtonPrimary from 'src/components/base/button/ButtonPrimary'
import ButtonSecondary from 'src/components/base/button/ButtonSecondary'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import UploadMulti from 'src/components/base/upload-file/UploadMulti'
import { PageLink, VALIDATION_FIELD, VALIDATE_FIELD_MAX_LENGTH } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { INotification, NOTIFICATION_ACTION, NOTIFICATION_TYPE } from 'src/type/notification'
import { mergeImageToEditor } from 'src/utils/upload'
import { z } from 'zod'
import AddGroupToNoti from './AddGroupToNoti'
import './CreateNotiComponent.scss'
import dayjs from 'dayjs'
import HookFormCheckBox from 'src/components/base/checkbox/HookFormCheckBox'
import ErrorMessage from 'src/common/ErrorMessage'
import { isUndefined } from 'lodash'
import utc from 'dayjs/plugin/utc'
import { Skeleton } from 'antd'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { CODE_ADMIN, TITLE_NOTIFICATIONS_GR } from 'src/constants/permission'
import { SendtoDropDownIcon } from 'src/common/icons'
import { convertUTCToLocalTime } from 'src/utils/time'

dayjs.extend(utc)

interface IProps {
  id?: string
}

export interface NotificationChildRef {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
  handleCancel: () => void
}

export const ACTION_STATUS = [
  {
    label: 'Hẹn giờ gửi',
    value: 'TIMER',
  },
  {
    label: 'Gửi ngay',
    value: 'IMMEDIATE',
  },
]

export const PINNED_OPTION = [
  {
    label: 'Yes',
    value: 'PINNED',
  },
  {
    label: 'No',
    value: '',
  },
]

const validationSchema = z
  .object({
    title: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(500, VALIDATE_FIELD_MAX_LENGTH('Name', 500)),
    typeNoti: z.any(),
    typeEmail: z.any(),
    typePinned: z.any(),
    groups: z
      .array(
        z.object({
          id: z.string(),
          name: z.string(),
        })
      )
      .default([]),

    action: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    send_time: z.any(),
    send_finish_time: z.any(),
    content: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    files: z.any(),
  })
  .superRefine(({ typePinned, groups }, refinementContext) => {
    if (groups?.length === 0 && typePinned !== NOTIFICATION_TYPE.PINNED) {
      return refinementContext.addIssue({
        code: z.ZodIssueCode.custom,
        message: VALIDATION_FIELD,
        path: ['groups'],
      })
    }
  })

const CreateNotiComponent = forwardRef<NotificationChildRef, IProps>(({ id }, ref) => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)
  const [openAddGroup, setOpenAddGroup] = useState(false)
  const [defaultContent, setDefaultContent] = useState<string>()
  const [disableNotification, setDisableNotification] = useState<boolean>(false)
  const [disableNotPinnedType, setDisableNotPinnedType] = useState<boolean>(false)
  const [disablePinnedType, setDisablePinnedType] = useState<boolean>(false)

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm<INotification>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (id) {
      ;(async () => {
        setLoading(true)
        try {
          const { data } = await NotificationAPI.getNotificationById(id)

          if (data) {
            setValue('title', data?.title)
            setValue('typeNoti', ['ALL', 'NOTIFICATION'].includes(data?.type) as any)
            setValue('typeEmail', ['ALL', 'EMAIL'].includes(data?.type) as any)
            setValue(
              'typePinned',
              data?.type === NOTIFICATION_TYPE.PINNED ? NOTIFICATION_TYPE.PINNED : ''
            )
            setValue(
              'groups',
              data.groups?.map((e) => ({ id: e.id, name: e.name }))
            )
            setValue('action', data?.action)
            setValue('send_time', convertUTCToLocalTime(data.send_time))
            setValue('send_finish_time', convertUTCToLocalTime(data?.send_finish_time as any))
            setValue('content', data.content)
            const content = await mergeImageToEditor(data.content, [])
            setDefaultContent(content)
            if (!data.files) {
              return
            }
            setValue(
              'files',
              data.files.map((e) => ({
                resource_id: e.resource.id,
                id: e.resource.id,
                name: e.resource.name,
                type: 'attached',
              }))
            )
            // Disable edit noti
            if (
              data?.status === 'SENT' ||
              data?.status === 'CANCEL' ||
              data?.status === 'RETRIEVE'
            ) {
              setDisableNotification(true)
            }
          }
        } catch (error) {
        } finally {
          setLoading(false)
        }
      })()
    }
  }, [id])

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  /**
   * Xử lý hành động hủy bỏ bằng cách hiển thị hộp thoại xác nhận và điều hướng trở lại nếu được xác nhận.
   */
  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Có',
      cancelButtonCaption: 'Không',
      body: ['Bạn chắc chắn muốn thoát không?'],
      onClick: () => navigate(PageLink.NOTIFICATIONS),
    })
  }

  /**
   * Submit form bằng cách cập nhật hoặc tạo Notification và điều hướng đến trang Notification.
   * @param {INotification} data - Dữ liệu Notification.
   */
  const onSubmit = handleSubmit(async (data: INotification) => {
    const isTypeEmail = getValues('typeEmail')
    const isTypeNoti = getValues('typeNoti')
    const isTypePinned = getValues('typePinned')
    const isTimerAction = watch('action') === NOTIFICATION_ACTION.TIMER
    const hasSendTime = !isUndefined(getValues('send_time'))

    if (isTypeEmail || isTypeNoti || isTypePinned || (isTimerAction && hasSendTime)) {
      try {
        const submitData = {
          ...data,
          send_time: isTimerAction ? data.send_time : undefined,
          send_finish_time: data.send_finish_time ? data?.send_finish_time : undefined,
          type:
            isTypeNoti && isTypeEmail
              ? 'ALL'
              : isTypeNoti
              ? 'NOTIFICATION'
              : isTypeEmail
              ? 'EMAIL'
              : isTypePinned
              ? 'PINNED'
              : '',
          files: data.type !== 'NOTIFICATION' ? data.files : undefined,
          groups: data.groups?.map((e) => e.id),
          text_editor_files: textEditorFiles,
        }

        if (id) {
          await NotificationAPI.updateNotification(id, submitData)
        } else {
          await NotificationAPI.createNotification(submitData)
        }

        navigate(PageLink.NOTIFICATIONS)
      } catch (error) {}
    }

    if (!getValues('typePinned')) {
      if (isUndefined(getValues('typeEmail')) && isUndefined(getValues('typeNoti'))) {
        setError('errorType', { message: VALIDATION_FIELD })
      } else {
        setError('errorType', { message: '' })
      }
    }

    if (watch('action') === NOTIFICATION_ACTION.TIMER && isUndefined(getValues('send_time'))) {
      setError('send_time', { message: VALIDATION_FIELD })
    }

    if (
      (watch('action') === NOTIFICATION_ACTION.TIMER ||
        watch('action') === NOTIFICATION_ACTION.IMMEDIATE) &&
      isUndefined(getValues('send_finish_time'))
    ) {
      setError('send_finish_time', { message: VALIDATION_FIELD })
    }
  })

  useImperativeHandle(ref, () => {
    return {
      onSubmit,
      handleCancel,
    }
  })

  useEffect(() => {
    setError('errorType', { message: '' })
    handleDisablePinnedType(getValues('typeEmail') as string, getValues('typeNoti') as string)
  }, [watch('typeEmail'), watch('typeNoti')])

  // Xóa msg error của group khi có thay đổi
  useEffect(() => {
    if (watch('groups')) {
      watch('groups').length > 0 && setError('groups', { message: '' })
    }
  }, [watch('groups')])

  const filesFormAddNew = watch('files')
  const watchGroups = watch('groups')
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditNotification = id
    ? !profileMe?.roles?.some(
        (role: Role) =>
          hasPermission(role, TITLE_NOTIFICATIONS_GR.EDIT_NOTIFICATION) ||
          role.code === CODE_ADMIN.SUPER_ADMIN
      )
    : false

  useEffect(() => {
    const pinnedType = getValues('typePinned')
    disableIsNotPinnedType(pinnedType as string)
  }, [watch('typePinned')])

  /**
   * Xử lý sự kiện khi chọn Pinned Notification thì disable hai lựa chọn Email và Notification
   *
   *
   */
  const disableIsNotPinnedType = (pinnedType: string) => {
    if (pinnedType) {
      setError('errorType', { message: '' })
      setDisableNotPinnedType(true)
    } else {
      setDisableNotPinnedType(false)
    }
  }

  /**
   * Xử lý sự kiện khi chọn một trong hai type là Email hoặc Notification
   * thì disable radio Pinned Notification.
   *
   */
  const handleDisablePinnedType = (emailType: string, notiType: string) => {
    if (emailType || notiType) {
      setValue('typePinned', '')
      setDisablePinnedType(true)
    } else {
      setDisablePinnedType(false)
    }
  }

  /**
   * Xử lý sự kiện onchange cho input file, cập nhật trường 'files' trong form.
   * @param {any} files - Các file đã chọn.
   */
  const handleOnchangeFile = (files: any) => {
    setValue(
      'files',
      files.map((e: { id: string; name: string; resource_id: string }) => ({
        resource_id: e.resource_id || e.id,
        id: e.resource_id || e.id,
        name: e.name,
        type: 'attached',
      }))
    )
    setFileEditor(files)
  }

  const handlePopupRemove = (groupIdToRemove: string) => {
    confirm({
      okButtonCaption: 'Có',
      cancelButtonCaption: 'Không',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => removeGroupById(groupIdToRemove),
    })
  }

  /**
   * Xóa một group khỏi trường 'groups' bằng ID.
   * @param {string} groupIdToRemove - ID của group cần xóa.
   */
  const removeGroupById = (groupIdToRemove: string) => {
    const updatedGroups = getValues('groups').filter((g) => g.id !== groupIdToRemove)
    setValue('groups', updatedGroups)
  }

  /**
   * Đặt giá trị cho trường 'groups'.
   * @param {any} group - Dữ liệu groups.
   */
  const setGroups = (group: any) => {
    setValue('groups', group)
  }

  /**
   * Xác định xem một ngày có bị vô hiệu hóa hay không.
   * @param {dayjs.Dayjs} current - Ngày hiện tại.
   * @returns {boolean} - True nếu ngày bị vô hiệu hóa, ngược lại là false.
   */
  const disabledDate = (current: dayjs.Dayjs) => {
    const localCurrent = dayjs.utc(current).local() // Chuyển đổi về múi giờ địa phương
    return localCurrent.isBefore(dayjs(), 'day') // Kiểm tra nếu thời gian hiện tại đã qua
  }

  /**
   * Xác định xem một thời gian có bị vô hiệu hóa hay không.
   * @param {dayjs.Dayjs} current - Thời gian hiện tại.
   * @returns {Object} - Một đối tượng chỉ định giờ và phút bị vô hiệu hóa.
   */
  const disabledTime = (current: dayjs.Dayjs | null) => {
    let now = new Date()

    if (current && current.isSame(now, 'day')) {
      let hour = now.getHours()
      let minute = now.getMinutes()

      return {
        disabledHours: () => {
          let hours = Array.from({ length: hour }, (x, i) => i)
          if (minute > 30) {
            hours.push(hour)
          }
          return hours
        },
        disabledMinutes: (selectedHour: any) => {
          if (selectedHour === hour) {
            return Array.from({ length: minute + 1 }, (x, i) => i)
          }
          return []
        },
      }
    }
  }

  return (
    <div>
      {contextHolder}
      <div className='px-10'>
        <div>
          <SappLabel required label={'Title'}></SappLabel>
          <div className='sapp-mb-32px'>
            <HookFormTextField
              control={control}
              name='title'
              placeholder=''
              disabled={disableNotification || allowRenderEditNotification}
              skeleton={loading}
            ></HookFormTextField>
          </div>
        </div>
        <div className='sapp-mb-32px'>
          <SappLabel required label={`Pinned Notification`}></SappLabel>
          <div className='w-20'>
            <HookFormRadioGroup
              control={control}
              name='typePinned'
              options={PINNED_OPTION}
              direction='horizontal'
              separator={false}
              justify='start'
              itemWidth={'250px'}
              disabled={disablePinnedType || Boolean(id)}
              loading={loading}
            />
          </div>
        </div>
        {getValues('typePinned') !== NOTIFICATION_TYPE.PINNED && (
          <div className='sapp-mb-32px'>
            <SappLabel required label={`Type`} className={'mb-5'}></SappLabel>
            <div className='d-flex'>
              <div className='w-250px'>
                <HookFormCheckBox
                  name='typeNoti'
                  control={control}
                  title='Notification'
                  classLabel='me-0'
                  className='sapp-checkbox--icon'
                  disabled={
                    disableNotification || disableNotPinnedType || allowRenderEditNotification
                  }
                  loading={loading}
                />
              </div>
              <div className='w-250px'>
                <HookFormCheckBox
                  name='typeEmail'
                  control={control}
                  title='Email'
                  classLabel='ms-0'
                  className='sapp-checkbox--icon'
                  disabled={
                    disableNotification || disableNotPinnedType || allowRenderEditNotification
                  }
                  loading={loading}
                />
              </div>
            </div>
            {errors.errorType?.message && <ErrorMessage>{errors.errorType?.message}</ErrorMessage>}
          </div>
        )}
        {getValues('typePinned') !== NOTIFICATION_TYPE.PINNED && (
          <div className='sapp-mb-32px'>
            {loading ? (
              <Skeleton.Button active block />
            ) : (
              <SappLabel required label={`Send to`}></SappLabel>
            )}
            {loading ? (
              <Skeleton.Button active block className='sapp-h-45px' />
            ) : (
              <div
                onClick={(e) => {
                  const target = e.target as HTMLElement
                  if (
                    !target.classList.contains('sapp-create-noti_group_icon') &&
                    !disableNotification &&
                    !allowRenderEditNotification
                  ) {
                    setOpenAddGroup(true)
                  }
                }}
                className='d-flex sapp-create-noti_group align-items-center'
              >
                <div className='sapp-create-noti_group_tags d-flex align-items-center'>
                  {watchGroups?.map((e) => {
                    return (
                      <div key={e.id} className='sapp-create-noti_group_items'>
                        <span className='sapp-create-noti_group_text'>{e.name}</span>
                        <div
                          onClick={(f) => {
                            if (!disableNotification && !allowRenderEditNotification) {
                              f.stopPropagation()
                              handlePopupRemove(e.id)
                            }
                          }}
                          className='d-flex'
                        >
                          <KTIcon
                            iconType='outline'
                            iconName='cross'
                            className={`fs-2 sapp-create-noti_group_icon`}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='ms-auto'>
                  <SendtoDropDownIcon />
                </div>
              </div>
            )}
            {errors?.groups?.message && <ErrorMessage>{errors?.groups?.message}</ErrorMessage>}
          </div>
        )}
        <div className='sapp-mb-32px'>
          <SappLabel required label={`Action`}></SappLabel>
          <div className='d-flex w-full'>
            <div className='w-50'>
              <HookFormRadioGroup
                control={control}
                name='action'
                options={ACTION_STATUS}
                direction='horizontal'
                separator={false}
                justify='start'
                itemWidth={'250px'}
                disabled={disableNotification || allowRenderEditNotification}
                loading={loading}
              />
            </div>

            {getValues('typePinned') !== NOTIFICATION_TYPE.PINNED &&
              watch('action') === NOTIFICATION_ACTION.TIMER && (
                <div className='w-50'>
                  <HookFormDateTime
                    control={control}
                    name='send_time'
                    showTime={{ minuteStep: 30, minDate: new Date() }}
                    format='DD/MM/YYYY HH:mm'
                    showNow={false}
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                    disabled={disableNotification || allowRenderEditNotification}
                    placeholder='Start'
                  />
                </div>
              )}

            {watch('action') === NOTIFICATION_ACTION.IMMEDIATE &&
              getValues('typePinned') === NOTIFICATION_TYPE.PINNED && (
                <div className='w-50'>
                  <HookFormDateTime
                    control={control}
                    name='send_finish_time'
                    showTime={{ minuteStep: 30, minDate: new Date() }}
                    format='DD/MM/YYYY HH:mm'
                    showNow={false}
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                    disabled={disableNotification || allowRenderEditNotification}
                    placeholder='End'
                  />
                </div>
              )}
          </div>
          {getValues('typePinned') === NOTIFICATION_TYPE.PINNED &&
            watch('action') === NOTIFICATION_ACTION.TIMER && (
              <div className='d-flex mt-6'>
                <div className='w-50'>
                  <HookFormDateTime
                    control={control}
                    name='send_time'
                    showTime={{ minuteStep: 30, minDate: new Date() }}
                    format='DD/MM/YYYY HH:mm'
                    showNow={false}
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                    disabled={disableNotification || allowRenderEditNotification}
                    placeholder='Start'
                  />
                </div>
                <div className={`w-50 sapp-margin-left-10`}>
                  <HookFormDateTime
                    control={control}
                    name='send_finish_time'
                    showTime={{ minuteStep: 30, minDate: new Date() }}
                    format='DD/MM/YYYY HH:mm'
                    showNow={false}
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                    disabled={disableNotification || allowRenderEditNotification}
                    placeholder='End'
                  />
                </div>
              </div>
            )}
        </div>
        <div className='sapp-mb-32px'>
          <HookFormEditor
            label='Nội dung'
            subLabel='(Nội dung hiển thị tốt nhất trong giới hạn 50 từ)'
            required
            control={control}
            name={'content'}
            height={600}
            defaultValue={defaultContent}
            disabled={disableNotification || allowRenderEditNotification}
            uploadVideoImage={false}
            skeleton={loading}
            resourceLocation={RESOURCE_LOCATION.NOTIFICATION}
            object_id={undefined}
          />
        </div>
        {watch('typeEmail') && (
          <div className='d-flex flex-column sapp-mb-32px'>
            <UploadMulti
              fileList={filesFormAddNew}
              setFileList={handleOnchangeFile}
              error={errors.files}
              resourceLocation={RESOURCE_LOCATION.NOTIFICATION}
              label='Attachment'
              guideline={[
                'Định dạng cho phép pdf, docx, doc, xls, xlsx, csv, txt, ppt, pptx, zip. Kích thước tối đa 500MB.',
              ]}
              disabled={disableNotification || allowRenderEditNotification}
              object_id={id ? id : undefined}
            />
          </div>
        )}
      </div>

      <AddGroupToNoti
        open={openAddGroup}
        setOpen={setOpenAddGroup}
        group={watchGroups}
        setGroup={setGroups}
      />
    </div>
  )
})
export default CreateNotiComponent
