import React from 'react'
import { Row, Col } from 'antd'

interface ListFilterLayoutProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode
  gutter?: [number, number]
  xs?: number
  sm?: number
  xl?: number
}

const ListFilterLayout: React.FC<ListFilterLayoutProps> = ({
  children,
  gutter = [16, 16],
  xs = 24,
  sm = 12,
  xl = 6,
  className,
  ...rest
}) => {
  const flattenedChildren = React.Children.toArray(children) // Flatten children

  return (
    <div className={className} {...rest}>
      <Row gutter={gutter}>
        {flattenedChildren.map((child, index) => (
          <Col key={index} xs={xs} sm={sm} xl={xl}>
            {child}
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default ListFilterLayout
