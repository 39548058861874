import {zodResolver} from '@hookform/resolvers/zod'
import {Select} from 'antd'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import LoadingTable from 'src/common/LoadingTable'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import {FILTER_SELECTALL_SORTBY, SELECT_TYPE} from 'src/constants'
import {cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo} from 'src/utils'
import {replaceValueAll} from 'src/utils/string'
import {z} from 'zod'

const {Option} = Select

interface IProps {
  notificationId: string | undefined
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  groupState: {groups: any; metadata: any} | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchListGroups: (currentPage?: number, pageSize?: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: 'Group Name',
    className: 'min-w-150px',
  },
  {
    label: 'Members',
    className: 'w-150px',
  },
  {
    label: 'Date',
    className: 'w-150px pe-5',
  },
]

const TableGroup = ({
  notificationId,
  groupState: userState,
  loading,
  setLoading,
  fetchListGroups: fetchListUsers,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
}: IProps) => {
  const initialValues: any = {
    name: '',
    type: '',
    sort_type: '',
    fromDate: '',
    toDate: '',
    selectType: '',
  }
  const [page_index, setPage_index] = useState(1)
  const [page_size, setPage_size] = useState(10)
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const validationSchema = z.object({
    name: z.string().optional(),
    type: z.string().optional(),
    sort_type: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    selectType: z.string().optional(),
  })
  const fieldNames = ['name', 'type', 'sort_type', 'fromDate', 'toDate', 'selectType']

  // Using validate for input
  const {control, getValues, reset, setValue} = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const handlePaginationChange = (page_index: number, page_size: number) => {
    setLoading(true)
    const dateInfoFromDate = getDateInfo(searchParams.fromDate)
    const dateInfoToDate = getDateInfo(searchParams.toDate)
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.name,
        searchParams?.type,
        searchParams?.sort_type,
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        searchParams?.selectType
      )
    )
    fetchListUsers(page_index, page_size, {...cleanedParams, dateField: 'updated_at'})
  }

  useEffect(() => {
    setLoading(true)
    const dateInfoFromDate = searchParams.fromDate
    const dateInfoToDate = searchParams.toDate
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.name,
        searchParams?.type,
        searchParams?.sort_type,
        searchParams.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        searchParams.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        searchParams?.selectType
      )
    )
    fetchListUsers(page_index, page_size, cleanedParams)
  }, [])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    setSearchParams(initialValues)
    setLoading(true)
    fetchListUsers(1, 10)
  }

  const onSubmit = () => {
    const dateRange = getValues('dateTimeRange')

    const fromDate = dateRange?.fromDate
    const toDate = dateRange?.toDate

    const dateInfoFromDate = getDateInfo(fromDate)
    const dateInfoToDate = getDateInfo(toDate)

    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('name')),
        replaceValueAll(getValues('type')),
        replaceValueAll(getValues('sort_type')),
        fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        replaceValueAll(getValues('selectType'))
      )
    )

    setSearchParams(cleanedParams)
    setLoading(true)

    fetchListUsers(1, queryParams.page_size || 10, {...cleanedParams, dateField: 'updated_at'})
  }
  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='name'
                  placeholder='Search'
                  defaultValue={queryParams?.name}
                  onSubmit={() => onSubmit()}
                />
              </div>
            </div>
            {/* end:: Search */}
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='sort_type'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sort_type ?? ''}
                className='sapp-fs-select fs-6'
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 w-80 '>
              <RangeDateTimePicker
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 '>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 mt-xl-5'>
              <HookFormSelectAntd
                name='selectType'
                placeholder='Selected'
                control={control}
                size='large'
                defaultValue={queryParams?.selectType ?? ''}
                className='sapp-fs-select fs-6'
              >
                {!notificationId ? (
                  <Option key={'ALL'} value={'ALL'}>
                    ALL
                  </Option>
                ) : (
                  SELECT_TYPE.map((item) => (
                    <Option key={item.label} value={item.value}>
                      {item.label}
                    </Option>
                  ))
                )}
              </HookFormSelectAntd>
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 pl-0 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
                classNameSubmit='sapp-fs--default'
                classNameCancel='sapp-fs--default'
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={userState?.groups}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {userState?.groups?.map((group: any, index: number) => {
              const isChecked = checkedList.includes(group.id)

              return (
                <tr key={group.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(group.id!)
                      }}
                    />
                  </td>
                  <td className='text-start'>
                    <div className='sapp-text-truncate-2 sapp-table-title-des text-wrap text-break'>
                      {group?.name}
                    </div>
                  </td>
                  <td className='text-start'>{group?.members || 0}</td>
                  <td className='text-start'>
                    <DatetimeColumn created_at={group?.created_at} updated_at={group?.updated_at} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={userState?.metadata?.page_index || 1}
        pageSize={userState?.metadata?.page_size || 10}
        totalItems={userState?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableGroup
