import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'

interface IProp {
  title: string
  className?: string
  link?: string
  titleButton?: string
}

const HeaderTab = ({ title, className = '', titleButton = '', link = '' }: IProp) => {
  return (
    <div className={`card-header align-items-center ${className}`}>
      <div className='card-title m-0'>
        <h3 className='fw-bold m-0'>{title}</h3>
      </div>
      {!!titleButton && <ButtonPrimary title={titleButton} link={link} size='small' />}
    </div>
  )
}

export default HeaderTab
