import EditClassProfile from './EditClassProfile'

interface IProps {
  classDetail: any | undefined
  loading: boolean
}

const SettingClassUser = ({ classDetail, loading }: IProps) => {
  return <EditClassProfile loading={loading} classDetail={classDetail} />
}

export default SettingClassUser
