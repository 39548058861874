import { format } from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import { KTIcon } from 'src/_metronic/helpers'
import { CoursesAPI } from 'src/apis/courses'
import LoadingTable from 'src/common/LoadingTable'
import SappTable from 'src/components/base/SappTable'
import ActionCell from 'src/components/base/action/ActionCell'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import ModalUploadFile from 'src/components/base/upload-file/ModalUploadFile/ModalUploadFile'
import { FORMAT_DATETIME, MOCKUP_HEADER, PageLink } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { IMeta, Role } from 'src/type'
import DownloadCell from './DownloadCell'
import Search from 'src/components/base/search'
import {
  IResource,
  RESOURCE_LOCATION,
} from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import useChecked from 'src/hooks/use-checked'
import { ResourcesAPI } from 'src/apis/resources'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'
import { DESCRIPTION_POPUPCONFIRM, LANG_COURSES } from 'src/constants/lang'
import Processbar from '../progress-bar'
import { useNavigate } from 'react-router-dom'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonDanger from 'src/components/ui/button-danger/ButtonDanger'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const headers = [
  {
    label: 'File name',
    key: 'file',
    className: 'min-w-250px',
  },
  {
    label: 'Size',
    key: 'size',
    className: 'min-w-200px',
  },
  {
    label: 'Created Date',
    key: 'created_at',
    className: 'min-w-200px',
  },
]

interface ISearchForm {
  currenPage?: number
  size?: number
  search_key?: string
}

interface IResources {
  id: any
  created_at: string
  updated_at: string
  name: string
  location: string
  resource_type: string
  suffix_type: string
  is_default: string
  thumbnail: string
  size: string
  status: string
  files: {
    id: string
    created_at: string
    updated_at: string
    type: string
    object_id: string
  }[]
}

const CourseResource = ({
  setStep,
  step,
  handleGotoStep,
}: {
  setStep?: any
  step?: any
  handleGotoStep: (index: number) => void
}) => {
  const [loading, setLoading] = useState(false)
  const [resources, setResources] = useState<IResources[]>([])
  const [meta, setMeta] = useState<IMeta>()
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const { confirm, contextHolder } = useConfirm()
  const { idCourse, course } = useCourseContext()
  // const [originalResources, setOriginalResources] = useState<IResources[]>([])
  const searchValues = useRef<ISearchForm>({ currenPage: 1, size: 10 })
  const navigate = useNavigate()

  useEffect(() => {
    getResources({ currenPage: 1, size: 10 })
  }, [])

  /**
   * @description Lấy danh sách Resources từ api
   *
   * @param {{
   *     currenPage?: number
   *     size?: number
   *     search_key?: string
   *   }} {
   *     currenPage,
   *     size,
   *     search_key,
   *   }
   */
  const getResources = async ({
    currenPage,
    size,
    search_key,
  }: {
    currenPage?: number
    size?: number
    search_key?: string
  }) => {
    try {
      setLoading(true)
      const response = await CoursesAPI.getCourseResource(idCourse, {
        page_index: currenPage || 1,
        page_size: size || 10,
        ...(search_key && { search_key }),
      })
      if (response?.data) {
        setResources(response.data.resources)
        setMeta(response.data.meta)
        // setOriginalResources(response.data.resources)
      }
    } finally {
      setLoading(false)
    }
  }
  /**
   * @description Hàm gọi get lại resources khi thay đổi page index hoặc page size
   *
   * @param {number} currenPage
   * @param {number} size
   */
  const handleChangeParams = (currenPage: number, size: number) => {
    searchValues.current = { ...searchValues.current, currenPage, size }
    getResources(searchValues.current)
  }
  /**
   * @description Lưu các file đã upload vào resources
   *
   * @param {*} resources
   */
  const handleSelectedFile = async (resources: any) => {
    const attachments: {
      resource_id: string
      type: string
      dom_id: string
      note: string
    }[] = resources.map((e: any) => ({
      resource_id: e.id,
      type: 'attached',
      dom_id: '',
      note: '',
    }))

    try {
      await CoursesAPI.addCourseResource(idCourse, attachments)
    } catch (error) {}

    getResources(searchValues.current)
  }

  const handleUnSelectedFile = async (resources: IResource[]) => {
    const attachments: string[] = resources.reduce((acc, e) => {
      if (e.files && e.files.length > 0) {
        e.files.filter((f) => {
          if (f.object_id === idCourse) {
            acc.push(f.id)
          }
        })
      }
      return acc
    }, [] as string[])
    try {
      if (attachments && attachments.length > 0) {
        const response = await CoursesAPI.deleteCourseResource(idCourse, attachments?.join(','))
        if (response) {
          getResources(searchValues.current)
        }
      }
    } catch (error) {}
  }

  /**
   * @description Xóa resource theo id
   *
   * @param {*} e
   * @param {any} resource
   */
  const handleDelete = (resource: any) => {
    const ids: string[] = resource.files?.map((e: any) => e.id)
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        try {
          const response = await CoursesAPI.deleteCourseResource(idCourse, ids?.join(','))
          if (response) {
            getResources(searchValues.current)
          }
        } catch (error) {}
      },
    })
  }
  const handleDeleteAll = async (resources: any[]) => {
    const filesArray = resources.flatMap((resource) => resource.files?.map((e: any) => e.id))
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        try {
          if (filesArray) {
            const response = await CoursesAPI.deleteCourseResource(idCourse, filesArray.join(','))
            if (response) {
              // Lấy lại searchValues.current sau mỗi lần xóa
              const searchValuesCopy = { ...searchValues }
              const currentPage = searchValuesCopy.current?.currenPage ?? 1
              const newPageIndex = Math.max(currentPage - 1, 1)
              if (searchValuesCopy.current) {
                searchValuesCopy.current.currenPage = newPageIndex
              } else {
                console.error('searchValues.current is undefined')
              }
              getResources(searchValuesCopy.current)
              toggleCheckAll(false, true)
            }
          }
        } catch (error) {
          // Xử lý lỗi nếu cần thiết
        }
      },
    })
  }

  const downloadFile = async (id: any) => {
    const resource = await ResourcesAPI.getUrl(id)
    ResourcesAPI.downloadFile({
      files: [
        {
          name: resource.data.name,
          file_key: resource.data.file_key,
        },
      ],
    })
  }
  const initialValues: any = {
    name: '',
    size: '',
    created_at: '',
  }
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll, listDataChecked } =
    useChecked<typeof initialValues>(resources)
  const getDefaultChecked = (resources: IResource[]): IResource[] => {
    const checkedResources = resources?.filter((e) => {
      if (e.files && e.files.length > 0 && e.files.some((e) => e.object_id === idCourse)) {
        return true
      }
      return false
    })
    return checkedResources || []
  }
  const formatSize = (sizeInBytes: any) => {
    const kilobyte = 1024
    const megabyte = kilobyte * 1024

    if (sizeInBytes < kilobyte) {
      return sizeInBytes + ' B'
    } else if (sizeInBytes < megabyte) {
      return (sizeInBytes / kilobyte).toFixed(2) + ' KB'
    } else {
      return (sizeInBytes / megabyte).toFixed(2) + ' MB'
    }
  }

  const [filter, setFilter] = useState({
    search_key: '',
    currenPage: 1,
    size: 10,
  })
  const [searchParams, setSearchParams] = useState<any>('')
  const handleSearchChange = (e: any) => {
    const newValue = e.target.value
    setSearchParams(newValue)
    if (!newValue.trim()) {
      setFilter({ ...filter, currenPage: 1 })
    }
  }
  const handleSearchActive = () => {
    setFilter({ ...filter, currenPage: 1, search_key: searchParams })
  }
  useEffect(() => {
    getResources(filter)
  }, [filter])
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_GR.CREATE_COURSE) ||
      hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  // TODO: hàm cancel mở popup confirm trở về màn course list
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.COURSES),
    })
  }

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={hanleCancel}
        onClick={() => handleGotoStep(3)}
        cancelButtonCaption={LANG_COURSES.courseList}
        okButtonCaption='Next'
      />
      {contextHolder}
      <div className='card'>
        <div className='card-header border-0 pt-5 sapp-mh-auto'>
          <div className='d-flex'>
            <div className='d-flex'>
              <div className='min-w-300px'>
                <Search
                  showSearch={true}
                  onChange={handleSearchChange}
                  placeholder={'Search'}
                  smallSearch={false}
                  isListScreen
                ></Search>
              </div>
              <div className='w-100'>
                <ButtonPrimary
                  title='Search'
                  onClick={() => handleSearchActive()}
                  className='ms-4'
                  size='small'
                />
              </div>
            </div>
          </div>
          {allowRenderEditCreateCourse && listDataChecked?.length < 1 ? (
            <ButtonIconPrimary
              title='Upload File'
              onClick={() => setOpenUploadModal(true)}
              iconName='file-up'
              iconType='outline'
              size='small'
            />
          ) : (
            <>
              {allowRenderEditCreateCourse && listDataChecked?.length > 0 && (
                <div className='d-flex'>
                  {listDataChecked?.length > 0 && (
                    <>
                      <div className='fw-bold sapp-checkbox-text-custom me-5 d-flex align-items-center'>
                        <span className='me-1'>{listDataChecked?.length}</span> Selected
                      </div>
                      <ButtonDanger
                        title={'Delete Selected'}
                        onClick={() => handleDeleteAll(listDataChecked)}
                        loading={loading}
                        size='small'
                      />
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className='card-body py-4'>
          <SappTable
            headers={headers}
            loading={loading}
            isCheckedAll={isCheckedAll}
            onChange={() => {
              toggleCheckAll(!isCheckedAll, true)
            }}
            data={resources}
          >
            {loading ? (
              <>
                {MOCKUP_HEADER.map((_header, i) => (
                  <LoadingTable key={i} headers={MOCKUP_HEADER} />
                ))}
              </>
            ) : (
              <>
                {resources?.map((resource, i) => {
                  const isChecked = checkedList && checkedList.includes(resource.id)
                  return (
                    <tr key={resource.id}>
                      <td>
                        <SAPPCheckbox
                          checked={isChecked}
                          onChange={() => {
                            toggleCheck(resource.id)
                          }}
                        />
                      </td>
                      <td>
                        <DownloadCell resource_id={resource.id} text={resource.name}></DownloadCell>
                      </td>
                      <td className='sapp-color-import-student sapp-fs-14'>
                        {formatSize(resource?.size)}
                      </td>
                      <td className='sapp-color-import-student sapp-fs-14'>
                        {resource?.created_at
                          ? `${format(new Date(resource.created_at), FORMAT_DATETIME)}`
                          : '-'}
                      </td>
                      {allowRenderEditCreateCourse && (
                        <td className='text-end w-35px'>
                          <ActionCell customWidth='w-150px'>
                            <div className='menu-item px-3' onClick={() => handleDelete(resource)}>
                              <div className='menu-link px-3'>Delete</div>
                            </div>
                            <div
                              className='menu-item px-3'
                              onClick={() => downloadFile(resource.id)}
                            >
                              <div className='menu-link px-3'>Download</div>
                            </div>
                          </ActionCell>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </>
            )}
          </SappTable>
          <PagiantionSAPP
            currentPage={meta?.page_index || 1}
            pageSize={meta?.page_size}
            totalItems={meta?.total_records}
            handleChangeParams={handleChangeParams}
          />
        </div>
      </div>
      <ModalUploadFile
        open={openUploadModal}
        setOpen={setOpenUploadModal}
        fileType={'ALL'}
        resourceLocation={RESOURCE_LOCATION.COURSE}
        title='Upload'
        setSelectedFile={handleSelectedFile}
        setUnSelectedFile={handleUnSelectedFile}
        isMultiple={true}
        getDefaultChecked={getDefaultChecked}
      ></ModalUploadFile>
    </>
  )
}

export default CourseResource
