import {Dispatch, SetStateAction, useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {KTCardBody} from 'src/_metronic/helpers'
import {NotificationAPI} from 'src/apis/notifications'
import LoadingTable from 'src/common/LoadingTable'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import SappTable from 'src/components/base/SappTable'
import ActionCell from 'src/components/base/action/ActionCell'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import {MOCKUP_HEADER, PageLink} from 'src/constants'
import {CODE_ADMIN, TITLE_GROUPS_GR} from 'src/constants/permission'
import {useUserContext} from 'src/context/UserProvider'
import {useConfirm} from 'src/hooks/use-confirm'
import {Role} from 'src/type'
import {INotificationGroupAll} from 'src/type/notification'
import {cleanParamsAPI} from 'src/utils'

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  list: INotificationGroupAll | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchList: (currentPage: number, pageSize: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px pe-5',
  },
  {
    label: 'Group name',
    className: 'min-w-275px pe-5 ',
  },
  {
    label: 'Members',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Date',
    className: 'min-w-150px pe-5',
  },
  {
    label: '',
    className: 'min-w-30px pe-5',
  },
]

const GroupReceiverTable = ({
  fetchList,
  filterParams,
  getParams,
  isCheckedAll,
  loading,
  list,
  queryParams,
  setLoading,
  toggleCheckAll,
  handleChangeParams,
}: IProps) => {
  const navigate = useNavigate()
  const {confirm, contextHolder} = useConfirm()
  const {search} = useLocation()
  const searchParams = new URLSearchParams(search)
  const page_size = parseInt(searchParams.get('page_size') as string)
  const page_index = parseInt(searchParams.get('page_index') as string)
  const filterParamsPagination = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.type ?? '',
      queryParams.sortType ?? ''
    )
  )
  const {profileMe} = useUserContext()
  const allowRenderEdit = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GROUPS_GR.EDIT_GROUP) || role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderDelete = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GROUPS_GR.REMOVE_GROUP) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const {setValue} = useForm<any>({
    mode: 'onChange',
  })

  useEffect(() => {
    setLoading(true)
    fetchList(page_index || 1, page_size || 10, filterParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    list?.groups
      ?.map((e: any) => ({label: e.status, value: e?.status?.toLowerCase()}))
      ?.forEach((option: any, index: any) => {
        setValue(`status${index}`, option.value)
      })
  }, [setValue, list?.groups])

  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchList(page_index, page_size, filterParamsPagination)
    handleChangeParams && handleChangeParams(page_index || 1, page_size || 10)
  }

  const handleDeleteGroup = async (id: string) => {
    const res = await NotificationAPI.deleteNotificationGroup(id)
    if (res?.success) {
      setLoading(true)
      fetchList(1, page_size || 10, filterParamsPagination)
    }
  }

  const handleDeleteConfirm = (id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: () => handleDeleteGroup(id),
      // onClose: () => cancel(true),
    })
  }

  return (
    <KTCardBody>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={list?.groups || []}
        isCheckedAll={isCheckedAll}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
        hasCheck={false}
      >
        {loading ? (
          <>
            {MOCKUP_HEADER.map((header, i) => (
              <LoadingTable key={header} headers={headers} />
            ))}
          </>
        ) : (
          <>
            {list?.groups?.map((group, index: number) => {
              //const isChecked = checkedList.includes(e.id)
              return (
                <tr key={group.id}>
                  <td>{index + 1 + (list?.meta?.page_index - 1) * list?.meta?.page_size}</td>
                  <td className='text-start'>
                    <Link
                      className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                      to={`${PageLink.UPDATE_GROUP}/${group.id}`}
                    >
                      {group?.name}
                    </Link>
                  </td>
                  <td className='text-start'>{group?.users?.length ?? 0}</td>

                  <td className='text-start'>
                    <DatetimeColumn updated_at={group?.updated_at} created_at={group?.created_at} />
                  </td>
                  {(allowRenderEdit || allowRenderDelete) && (
                    <td className='text-end sapp-absolute-column'>
                      <ActionCell customWidth='w-150px'>
                        {allowRenderEdit && (
                          <div
                            className='menu-item px-3'
                            onClick={() => navigate(`${PageLink.UPDATE_GROUP}/${group.id}`)}
                          >
                            <div className='menu-link px-3'>Edit</div>
                          </div>
                        )}
                        {allowRenderDelete && (
                          <div
                            className='menu-item px-3'
                            onClick={() => handleDeleteConfirm(group.id)}
                          >
                            <div className='menu-link px-3'>Delete</div>
                          </div>
                        )}
                      </ActionCell>
                    </td>
                  )}
                </tr>
              )
            })}
          </>
        )}
      </SappTable>

      <PagiantionSAPP
        currentPage={list?.meta?.page_index || 1}
        pageSize={list?.meta?.page_size || 10}
        totalItems={list?.meta?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </KTCardBody>
  )
}

export {GroupReceiverTable}
