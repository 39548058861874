// @ts-nocheck
import {StaffActionsCell} from './StaffActionsCell'
import {StaffSelectionHeader} from './StaffSelectionHeader'
import {StaffSelectionCell} from './StaffSelectionCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import UserCell from 'src/components/user-management/UserCell'
import UserStatusCell from 'src/components/user-management/UserStatusCell'
import UserCustomHeader from 'src/components/user-management/UserCustomHeader'
import AssignedTo from 'src/components/base/assigned'
import {STAFF_PROFILE} from 'src/constants'
import DatetimeColumn from 'src/components/base/DatetimeColumn'

const usersColumns: any = [
  {
    Header: (props) => <StaffSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <StaffSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Code' className='min-w-100px' />,
    id: 'code',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index]?.key} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='User' className='min-w-125px' />,
    id: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={props.data[props.row.index]}
        linkViewProfile={`${STAFF_PROFILE}/${props.data[props.row.index].id}/overview`}
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' />,
    id: 'username',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index]?.username} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Phone' />,
    title: 'Phone',
    id: 'phone',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index].detail?.phone} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Role' className='min-w-250px' />,
    id: 'role',
    Cell: ({...props}) => <AssignedTo roles={props.data[props.row.index]?.roles} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserStatusCell status={props.data[props.row.index]?.status} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-250px' />,
    id: 'date',
    Cell: ({...props}) => (
      <DatetimeColumn
        updated_at={props.data[props.row.index]?.updated_at}
        created_at={props.data[props.row.index]?.created_at}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-center sapp-absolute-column' />
    ),
    title: 'Actions',
    id: 'actions',
    Cell: ({...props}) => (
      <StaffActionsCell
        id={props.data[props.row.index].id}
        status={props.data[props.row.index]?.status}
      />
    ),
  },
]

export {usersColumns}
