import { Select, Skeleton } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { ReactNode } from 'react'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import { ArrowDownIcon } from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import './HookFormSelect.scss'

type FilterFunc<OptionType> = (inputValue: string, option?: OptionType) => boolean
interface IProps {
  isListScreen?: boolean
  name: string
  control: Control<any>
  defaultValue?: string | Array<string> | null
  children: ReactNode
  className?: string
  onChange?: (val: unknown) => void
  label?: string
  size?: 'large' | 'middle' | 'small'
  dropdownStyle?: React.CSSProperties | undefined
  placeholder?: string
  showSearch?: boolean
  filterOption?: boolean | FilterFunc<DefaultOptionType> | undefined
  filterSort?: ((optionA: DefaultOptionType, optionB: DefaultOptionType) => number) | undefined
  skeleton?: boolean
  required?: boolean
  customError?: boolean
  guideline?: string[]
  onSearch?: (value: string) => Promise<void> | any
  disabled?: boolean
  allowClear?: boolean
  onClear?: (() => void) | undefined
  loading?: boolean
  suffixIcon?: any
  handleNextPage?: any
  onBlur?: any
  onSelect?: any
  onFocus?: any
  isLocked?: boolean
  classNameHeight?: 'sapp-h-45px' | 'sapp-h-35px' | 'sapp-h-40'
}

const HookFormSelectAntd = ({
  name,
  control,
  children,
  className = '',
  onChange,
  label,
  size,
  guideline,
  dropdownStyle,
  placeholder,
  showSearch = false,
  filterOption,
  filterSort,
  skeleton,
  required,
  customError,
  onSearch,
  disabled,
  loading,
  allowClear = true,
  onClear,
  suffixIcon = <ArrowDownIcon />,
  handleNextPage,
  onBlur,
  onSelect,
  onFocus,
  isLocked = false,
  classNameHeight = 'sapp-h-45px',
  defaultValue = undefined,
}: IProps) => {
  return (
    <>
      {label && <SappLabel label={label} required={required} />}
      <Controller
        render={({ field, fieldState: { error } }) => (
          <>
            {!skeleton ? (
              <Select
                {...field}
                loading={loading}
                status={`${error || customError ? 'error' : ''}`}
                dropdownStyle={{
                  zIndex: '2000',
                  minWidth: '150px',
                  ...(dropdownStyle && dropdownStyle),
                }}
                size={size || 'middle'}
                onChange={(e) => {
                  const val = e
                  field.onChange(val)
                  onChange && onChange(val)
                }}
                className={`${className ?? ''} w-100 sapp-hook-form-select ${classNameHeight}`}
                placeholder={placeholder || 'Please Select'}
                value={field.value ? field.value : undefined}
                filterSort={filterSort}
                /* start:: showSearch */
                showSearch={showSearch}
                optionFilterProp='children'
                filterOption={
                  filterOption ??
                  (showSearch &&
                    ((input, option) => {
                      const optionText = (
                        option?.userName ??
                        option?.children ??
                        option?.label ??
                        ''
                      )
                        .toString()
                        .toLowerCase()
                      const optionEmail = (option?.email ?? '').toString().toLowerCase()
                      const trimmedInput = input?.toLowerCase()?.trimStart()?.trimEnd()
                      // Check if the trimmed option text includes the trimmed input

                      return optionText.includes(trimmedInput) || optionEmail.includes(trimmedInput)
                    }))
                }
                onSearch={onSearch}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                /* end:: showSearch */
                /* end:: showSearch */
                allowClear={allowClear}
                onClear={onClear}
                suffixIcon={!isLocked ? suffixIcon : null}
                onSelect={onSelect}
                // dropdownRender={(menu) => (
                //   <>
                //     {menu}

                //     <>
                //       <Divider style={{margin: '8px 0'}} />
                //       <Space
                //         style={{padding: '0 8px 4px', display: 'flex', justifyContent: 'center'}}
                //       >
                //         <Button type='text' onClick={() => {}}>
                //           Load more
                //         </Button>
                //       </Space>
                //     </>
                //   </>
                // )}
                onPopupScroll={(e) => {
                  const { target } = e
                  if (
                    Math.ceil((target as HTMLElement).scrollTop) +
                      Math.ceil((target as HTMLElement).offsetHeight) ===
                    Math.ceil((target as HTMLElement).scrollHeight)
                  ) {
                    if (handleNextPage) {
                      handleNextPage()
                    }
                  }
                }}
              >
                <>{children}</>
              </Select>
            ) : (
              <Skeleton.Input
                active
                size={size === 'middle' ? 'default' : size}
                className='w-100'
              ></Skeleton.Input>
            )}

            <ErrorMessage>{error?.message}</ErrorMessage>
          </>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      <GuidelineField guideline={guideline} />
    </>
  )
}

export default HookFormSelectAntd
