import { Popover } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { CaseStudyAPI } from 'src/apis/case-study'
import { CoursesAPI } from 'src/apis/courses'
import { PageLink, TYPE_COURSE } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { TEST_TYPE } from '../../shared/Interfaces'
import ChooseTypeOfTestModal, { COURSE_TEST_TYPES } from '../../test/ChooseTypeOfTestModal'
import '../Tree.scss'
import LinkActivity from '../course-link/LinkActivity'
import LinkSubsection from '../course-link/LinkSubsection'
import LinkUnit from '../course-link/LinkUnit'
import SortableTree, {
  expandCollageByPosition,
  expandParentsByActivityParentId,
  getNodeAtPath,
  removeNodeAtPath,
  toggleExpandedForAll,
} from '../tree-course'
import '../tree-course/node-renderer-default.css'
import '../tree-course/placeholder-renderer-default.css'
import '../tree-course/react-sortable-tree.css'
import '../tree-course/tree-node.css'
import { ParentsNode, Role } from 'src/type'
import { CourseCondition } from 'src/type/courses'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'

interface IProps {
  data: any
  id: string
  openCreateSection: {
    status: boolean
    data?: any
    type?: string
    id?: string
  }
  setOpenCreateSection: any
  openCreateLearning: {
    status: boolean
    data?: any
    type?: string
    id?: string
  }
  setOpenCreateLearning: any
  setOpenCreateTab: any
  treeData: any
  setTreeData: any
  setOpenCreateActivity: any
  setOpenPreviewActivity: React.Dispatch<
    React.SetStateAction<
      | {
          id: string
          open: boolean
        }
      | undefined
    >
  >
  template: number
  statusLock?: boolean
  clickedNode: any
  setClickedNode: Dispatch<SetStateAction<any>>
  rawCourseData: any
  typePractice: boolean
  courseCondition?: CourseCondition
  enableDrag: boolean
}

const TreeCourse = ({
  data,
  setOpenCreateLearning,
  setOpenCreateSection,
  setOpenCreateTab,
  treeData,
  setTreeData,
  setOpenCreateActivity,
  setOpenPreviewActivity,
  clickedNode,
  setClickedNode,
  rawCourseData,
  typePractice,
  courseCondition,
  enableDrag,
}: IProps) => {
  //TODO: state cho drawer cho link chapter
  const [openDrawerLinkChapter, setOpenDrawerLinkChapter] = useState<{
    status: boolean
    parentId?: string
    position?: number
  }>({
    status: false,
  })
  //TODO: state cho drawer cho link unit
  const [openDrawerLinkUnit, setOpenDrawerLinkUnit] = useState<{
    status: boolean
    parentId?: string
    position?: number
  }>({
    status: false,
  })
  //TODO: state cho drawer cho link activity
  const [openDrawerLinkActivity, setOpenDrawerLinkActivity] = useState<{
    status: boolean
    parentId?: string
    position?: number
  }>({
    status: false,
  })

  function findLastParent(array: any, child: any) {
    // Initialize a variable to store the current object
    let current = array.find((obj: any) => obj.id === child.id)
    // Initialize a variable to store the last parent
    let lastParent = {} as any
    // Loop until the current object has no parent
    while (current.parent_id !== null) {
      // Update the last parent to the current object's parent
      lastParent = current
      // Find the object that has the same id as the current object's parent
      current = array.find((obj: any) => obj.id === current.parent_id)
    }
    // Return the last parent
    return array.find((obj: any) => obj.id === lastParent.parent_id).id
  }
  const { setLoadingDelete, getCourseDetail, idCourse, idChild } = useCourseContext()
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const [openChooseTypeOfTestModal, setOpenChooseTypeOfTestModal] = useState<{
    type: string
    open: boolean
    id?: string
  }>({
    type: COURSE_TEST_TYPES.PART,
    open: false,
  })

  const handleDeleteNode = async (id: string, path: Array<number>, treeIndex: number) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          await CoursesAPI.deleteSection(id)
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  const handleDeleteLearnOutcome = (id: string, path: Array<number>, treeIndex: number) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          await CoursesAPI.deleteLearnOutcome(id)
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (error: any) {
          if (error?.response?.data?.error?.code === '400|5203') {
            confirm({
              okButtonCaption: 'Yes',
              cancelButtonCaption: 'No',
              body: [
                'Khóa học vẫn còn Learning Outcome liên kết với Activity',
                'Bạn có chắc chắn xóa không?',
              ],
              onClick: async () => {
                setLoadingDelete(true)
                try {
                  await CoursesAPI.deleteForceLearnOutcome(id)
                  // const getNodeKey = ({treeIndex}: any) => treeIndex
                  getCourseDetail(idCourse)
                  // setTreeData(removeNodeAtPath({treeData: treeData, path, getNodeKey}))
                } finally {
                  setLoadingDelete(false)
                }
              },
            })
          }
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  const handleDeleteQuiz = async (
    id: string | null | undefined,
    path: Array<number>,
    treeIndex: number
  ) => {
    try {
      await CoursesAPI.getQuizById(id)
    } catch (error) {
      return
    }

    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          if (id) {
            await CoursesAPI.deleteQuiz(id)
          }
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  const handleDeleteCaseStudy = async (id: string, path: Array<number>, treeIndex: number) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xóa không?'],
      onClick: async () => {
        setLoadingDelete(true)
        try {
          await CaseStudyAPI.delete(id)
          const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex
          setTreeData(removeNodeAtPath({ treeData: treeData, path, getNodeKey }))
          getCourseDetail(idCourse)
        } catch (err) {
        } finally {
          setLoadingDelete(false)
        }
      },
    })
  }

  const { id } = useParams()

  const onClickUnlinkSection = async (child_id?: string, course_section_parent_id?: string) => {
    const res = await CoursesAPI.unlinkCourse({
      course_id: id,
      child_id: child_id,
      course_section_parent_id: course_section_parent_id,
    })
    if (res?.success && id) {
      getCourseDetail(id)
    }
  }

  const onClickUnlinkSubsection = async (child_id?: string, course_section_parent_id?: string) => {
    const res = await CoursesAPI.unlinkCourse({
      child_id: child_id,
      course_section_parent_id: course_section_parent_id,
    })
    if (res?.success && id) {
      getCourseDetail(id)
    }
  }

  const handleConfirmUnlinkSection = (child_id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn gỡ học phần này khỏi khóa học không?'],
      onClick: () => onClickUnlinkSection(child_id),
      isMoreDescription: true,
    })
  }

  const handleConfirmUnlinkSubsection = (child_id: string, course_section_parent_id: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có các thay đổi chưa lưu.', 'Bạn có muốn quay lại trang Course Content?'],
      onClick: () => onClickUnlinkSubsection(child_id, course_section_parent_id),
      isMoreDescription: true,
    })
  }
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_GR.CREATE_COURSE) ||
      hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const canDragItem = (node: ParentsNode) => {
    if (node && node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME) {
      return false
    } else {
      return true
    }
  }
  const [showToast, setShowToast] = useState(false)
  const [isDraggingNode, setisDraggingNode] = useState(false)
  useEffect(() => {
    if (showToast && isDraggingNode === false) {
      toast.error(
        `Course Content can only be moved to a same-level position within its parent course content.`
      )
      const timeoutId = setTimeout(() => {
        setShowToast(false)
      }, 100)

      return () => clearTimeout(timeoutId)
    }
  }, [showToast])
  const onMoveNode = (nextParentNode: ParentsNode) => {
    if (nextParentNode && nextParentNode?.nextParentNode?.children) {
      const learningOutcomeNodeIndex = nextParentNode?.nextParentNode?.children?.findIndex(
        (child: ParentsNode) => child?.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
      )
      // nếu node type LearningOutcome và vị trí của node đó không đứng đầu
      if (learningOutcomeNodeIndex !== -1 && learningOutcomeNodeIndex !== 0) {
        toast.error(
          `Subcontents (which belong to a linked Course Content) or Learning Outcomes cannot be rearranged.`
        )
      }

      // nếu node type LearningOutcome => auto đưa lên đầu mảng
      if (learningOutcomeNodeIndex !== -1) {
        const learningOutcomeNode = nextParentNode?.nextParentNode?.children?.splice(
          learningOutcomeNodeIndex,
          1
        )[0]
        if (learningOutcomeNode) {
          nextParentNode?.nextParentNode?.children?.unshift(learningOutcomeNode)
        }
      }

      // thiết lập lại toàn bộ position của node trùng với index trong mảng nodeChildren
      for (const childNode of nextParentNode?.nextParentNode?.children) {
        childNode.position = nextParentNode?.nextParentNode?.children?.findIndex(
          (child: ParentsNode) => child?.id === childNode.id
        )
      }
    }
  }
  const [nextParentNodeId, setNextParentNodeId] = useState<string>()
  const [draggingNode, setDraggingNode] = useState<ParentsNode>()
  const [draggingNumber, setDraggingNumber] = useState(0)
  useEffect(() => {
    if (
      (!draggingNode?.parent_id && draggingNumber % 2 === 0 && nextParentNodeId) ||
      (draggingNode?.parent_id &&
        draggingNumber % 2 === 0 &&
        draggingNode?.parent_id !== nextParentNodeId)
    )
      toast.error(
        `Course Content can only be moved to a same - level position within its parent course content.`
      )
  }, [draggingNode, nextParentNodeId, draggingNumber])
  const treeId = expandParentsByActivityParentId(data, idChild)

  useEffect(() => {
    const positionPart = treeId?.find((node) => node.type === 'PART')?.position || 0
    const positionChapter = treeId?.find((node) => node.type === 'CHAPTER')?.position || 0
    const positionUnit = treeId?.find((node) => node.type === 'UNIT')?.position || 0

    if (idChild) {
      setTreeData(() => expandCollageByPosition(data, positionPart, positionChapter, positionUnit))
    } else {
      setTreeData(() => toggleExpandedForAll({ treeData: data, expanded: false }))
    }
  }, [data])
  return (
    <div style={{ marginLeft: '-60px' }}>
      {contextHolder}
      {treeData && (
        <SortableTree
          style={{ top: 0, right: 0, bottom: 0, left: 0, Zindex: -1 }}
          treeData={treeData}
          onDragStateChanged={({
            draggedNode,
            isDragging,
          }: {
            draggedNode: ParentsNode
            isDragging: boolean
          }) => {
            setisDraggingNode(isDragging)
            setDraggingNumber((prev) => prev + 1)
            if (draggedNode) setDraggingNode(draggedNode)
          }}
          onChange={(e: { e: ParentsNode }) => {
            setTreeData(e)
          }}
          isVirtualized={false}
          canDrop={({
            nextPath,
            prevPath,
            nextParent,
          }: {
            nextParent: ParentsNode
            prevPath: Array<number>
            nextPath: Array<number>
          }) => {
            if (nextParentNodeId !== nextParent?.id) {
              setNextParentNodeId(nextParent?.id)
            }

            const nextPathSlice = nextPath.slice(0, nextPath.length - 1)
            const prevPathSlice = prevPath.slice(0, prevPath.length - 1)
            const isSameLevel = JSON.stringify(nextPathSlice) === JSON.stringify(prevPathSlice)
            if (isDraggingNode === true && !isSameLevel) {
              setShowToast(true)
            }
            return isSameLevel
          }}
          canDrag={({ node }: { node: ParentsNode }) =>
            canDragItem(node) &&
            enableDrag &&
            courseCondition?.can_edit_course_content.can_drag_drop_course_section &&
            !node.is_linked_section_child
          }
          onMoveNode={onMoveNode}
          // nodeContentRenderer={NodeRendererDefault as any}
          generateNodeProps={({
            node,
            path,
            treeIndex,
            parentNode,
          }: {
            parentNode: ParentsNode
            treeIndex: number
            path: Array<number>
            node: ParentsNode
          }) => ({
            clickedNode: clickedNode,
            onClick: () => {
              setClickedNode(node)
            },
            buttons: [
              <>
                {node.course_section_type === TYPE_COURSE.PART ? (
                  <>
                    <Popover
                      trigger={'click'}
                      placement='leftTop'
                      arrow={false}
                      content={
                        <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                          {!node?.is_linked_section ? (
                            <>
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    setOpenCreateSection({
                                      status: true,
                                      data: undefined,
                                      type: TYPE_COURSE.SUBSECTION,
                                      id: node.id,
                                    })
                                  }}
                                >
                                  New Subsection
                                </div>
                              </div>
                              {typePractice &&
                                courseCondition?.can_edit_course_content
                                  ?.can_link_course_section && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        setOpenDrawerLinkChapter({
                                          status: true,
                                          parentId: node.id,
                                          position: clickedNode?.children?.length,
                                        })
                                      }}
                                    >
                                      Link SubSection
                                    </div>
                                  </div>
                                )}
                              {node?.quiz?.id
                                ? null
                                : courseCondition?.can_edit_course_content.can_add_quiz && (
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() =>
                                          navigate(
                                            `${
                                              PageLink.TEST
                                            }/${TEST_TYPE.TOPIC_TEST.toLocaleLowerCase()}/part/${idCourse}/${
                                              node.id
                                            }`
                                          )
                                        }
                                      >
                                        Add Part/ Topic Test
                                      </div>
                                    </div>
                                  )}

                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() =>
                                    setOpenCreateSection({
                                      status: true,
                                      data: node,
                                      type: TYPE_COURSE.SECTION,
                                      id: node.id,
                                      path: path,
                                      treeIndex: node.treeIndex,
                                    })
                                  }
                                >
                                  Edit
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div
                            onClick={() =>
                              window.open(`${PageLink.PREVIEW_PART}/${node.id}`, '_blank')
                            }
                            className='menu-item px-3'
                          >
                            <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                          </div>
                          {node?.is_linked_section && (
                            <div
                              className='menu-item px-3'
                              onClick={() => handleConfirmUnlinkSection(node.id)}
                            >
                              <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                            </div>
                          )}
                          {!node?.is_linked_section && (
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() => {
                                  handleDeleteNode(node.id, path, treeIndex)
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          )}

                          {/* {!statusLock && !node?.is_linked_section ? (
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() => {
                                  // removeItem(node.id)
                                  // deleteNode2()
                                  handleDeleteNode(node.id, path, treeIndex)
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          ) : (
                            <div
                              className='menu-item px-3'
                              onClick={() => handleConfirmUnlinkSection(node.id)}
                            >
                              <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                            </div>
                          )} */}
                        </div>
                      }
                    >
                      <div className='sapp-tree-popup'>
                        <KTIcon
                          iconName='dots-vertical'
                          className={`fs-2x text-gray-900 sapp-tree-popup-btn`}
                          iconType='solid'
                        />
                        {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                      </div>
                    </Popover>
                    <LinkSubsection
                      open={openDrawerLinkChapter?.status}
                      setOpen={setOpenDrawerLinkChapter}
                      title='Link Subsection'
                      parentId={openDrawerLinkChapter?.parentId}
                      position={openDrawerLinkChapter?.position || 0}
                      body='Create a new subsection by linking subsection from other course. '
                    />
                  </>
                ) : node.course_section_type === TYPE_COURSE.CHAPTER ? (
                  <>
                    {!node?.is_linked_section && !node?.is_linked_section_child && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {node?.course_learning_outcome?.id ? null : (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    if (node?.course_learning_outcome?.id) {
                                      toast.error('Each chapter can only have one learning outcome')
                                      return
                                    }
                                    setOpenCreateLearning({
                                      status: true,
                                      id: node.id,
                                    })
                                  }}
                                >
                                  Add Learning Outcome
                                </div>
                              </div>
                            )}
                            {courseCondition?.can_edit_course_content.can_add_quiz && (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      navigate(
                                        `${
                                          PageLink.TEST
                                        }/${TEST_TYPE.CHAPTER_TEST.toLocaleLowerCase()}/chapter/${idCourse}/${
                                          node.id
                                        }`
                                      )
                                    }}
                                  >
                                    Add Chapter/ Module Test
                                  </div>
                                </div>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      navigate(`${PageLink.CASE_STUDY}/${idCourse}/${node.id}`)
                                    }}
                                  >
                                    Add Case Study
                                  </div>
                                </div>
                              </>
                            )}
                            {typePractice && (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    setOpenDrawerLinkUnit({
                                      status: true,
                                      parentId: node.id,
                                      position: clickedNode?.children?.length,
                                    })
                                  }}
                                >
                                  Link Unit
                                </div>
                              </div>
                            )}
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() =>
                                  setOpenCreateSection({
                                    status: true,
                                    data: undefined,
                                    type: TYPE_COURSE.UNIT,
                                    id: node.id,
                                  })
                                }
                              >
                                Add Unit
                              </div>
                            </div>
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() =>
                                  setOpenCreateSection({
                                    status: true,
                                    data: node,
                                    type: TYPE_COURSE.SUBSECTION,
                                    id: node.id,
                                    path: path,
                                    treeIndex: node.treeIndex,
                                  })
                                }
                              >
                                Edit
                              </div>
                            </div>
                            <div className='menu-item px-3'>
                              <div
                                className='menu-link px-3 sapp-item-tree'
                                onClick={() => {
                                  handleDeleteNode(node.id, path, treeIndex)
                                  // removeItem(node.id)
                                  // deleteNode2()
                                }}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}
                    {node?.is_linked_section && !node?.is_linked_section_child && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {allowRenderEditCreateCourse && (
                              <div
                                className='menu-item px-3'
                                onClick={() =>
                                  handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                }
                              >
                                <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                              </div>
                            )}
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}

                    <LinkUnit
                      open={openDrawerLinkUnit?.status}
                      setOpen={setOpenDrawerLinkUnit}
                      title='Link Unit'
                      parentId={openDrawerLinkUnit?.parentId}
                      position={openDrawerLinkUnit?.position || 0}
                      body='Create a new unit by linking unit from other course. '
                    />
                  </>
                ) : node.course_section_type === TYPE_COURSE.ACTIVITY ? (
                  <>
                    <Popover
                      trigger={'click'}
                      placement='leftTop'
                      arrow={false}
                      content={
                        <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                          {courseCondition?.can_edit_course_content.can_add_activity ? (
                            <>
                              {!node?.is_linked_section && !node?.is_linked_section_child && (
                                <>
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() =>
                                        node?.total_tab && node.total_tab > 0
                                          ? setOpenCreateTab({
                                              status: true,
                                              course_section_id: node.id,
                                              edit: true,
                                              path: path,
                                              nodeData: node,
                                            })
                                          : setOpenCreateTab({
                                              status: true,
                                              course_section_id: node.id,
                                              path: path,
                                              nodeData: node,
                                            })
                                      }
                                    >
                                      {node?.total_tab && node.total_tab > 0
                                        ? `Edit Tab (${node.total_tab})`
                                        : 'Add Tab'}
                                    </div>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        const parentPath = path.slice(0, -2)
                                        const getNodeKey = ({ treeIndex }: { treeIndex: number }) =>
                                          treeIndex
                                        const parentNode = getNodeAtPath({
                                          treeData: treeData,
                                          path: parentPath,
                                          getNodeKey: getNodeKey,
                                        }) as any
                                        setOpenCreateActivity({
                                          status: true,
                                          selfId: node.id,
                                          lo: parentNode?.node?.course_learning_outcome,
                                          path: path,
                                          data: node,
                                        })
                                      }}
                                    >
                                      Edit Activity
                                    </div>
                                  </div>
                                </>
                              )}

                              {node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <div
                                    className='menu-item px-3'
                                    onClick={() =>
                                      handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                    }
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                                  </div>
                                )}

                              {!node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        const parentPath = path.slice(0, -2)
                                        const getNodeKey = ({ treeIndex }: { treeIndex: number }) =>
                                          treeIndex
                                        handleDeleteNode(node.id, path, treeIndex)
                                      }}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                )}
                            </>
                          ) : (
                            <>
                              {!node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <>
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() => {
                                          const parentPath = path.slice(0, -2)
                                          const getNodeKey = ({ treeIndex }: any) => treeIndex
                                          const parentNode = getNodeAtPath({
                                            treeData: treeData,
                                            path: parentPath,
                                            getNodeKey: getNodeKey,
                                          }) as any
                                          setOpenCreateActivity({
                                            status: true,
                                            selfId: node.id,
                                            lo: parentNode?.node?.course_learning_outcome,
                                            path: path,
                                            data: node,
                                            onlyEditName: true,
                                          })
                                        }}
                                      >
                                        Edit Activity
                                      </div>
                                    </div>
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() =>
                                          setOpenCreateTab({
                                            status: true,
                                            course_section_id: node.id,
                                            edit: true,
                                            path: path,
                                            nodeData: node,
                                            onlyEdit: true,
                                          })
                                        }
                                      >
                                        {`Edit Tab (${node.total_tab})`}
                                      </div>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                          <div
                            className='menu-item px-3'
                            onClick={() => {
                              setOpenPreviewActivity({
                                id: node.id,
                                open: true,
                              })
                            }}
                          >
                            <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                          </div>
                        </div>
                      }
                    >
                      <div className='sapp-tree-popup'>
                        <KTIcon
                          iconName='dots-vertical'
                          className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                          iconType='solid'
                        />
                        {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                      </div>
                    </Popover>
                  </>
                ) : node.course_section_type === TYPE_COURSE.UNIT ? (
                  <>
                    <Popover
                      trigger={'click'}
                      placement='leftTop'
                      arrow={false}
                      content={
                        <div className=' sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                          {courseCondition?.can_edit_course_content.can_add_unit ? (
                            <>
                              {!node?.is_linked_section &&
                              !node?.is_linked_section_child &&
                              allowRenderEditCreateCourse ? (
                                <>
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        // setClickedNode(node)
                                        const parentPath = path.slice(0, -1)
                                        const getNodeKey = ({ treeIndex }: { treeIndex: number }) =>
                                          treeIndex
                                        const parentNode = getNodeAtPath({
                                          treeData: treeData,
                                          path: parentPath,
                                          getNodeKey: getNodeKey,
                                        }) as any

                                        // setParentLO(parentNode?.node?.course_learning_outcome)
                                        setOpenCreateActivity({
                                          status: true,
                                          id: node.id,
                                          lo: parentNode?.node?.course_learning_outcome,
                                        })
                                        // };
                                      }}
                                    >
                                      New Activity
                                    </div>
                                  </div>
                                  {typePractice && (
                                    <div className='menu-item px-3'>
                                      <div
                                        className='menu-link px-3 sapp-item-tree'
                                        onClick={() => {
                                          setOpenDrawerLinkActivity({
                                            status: true,
                                            parentId: node.id,
                                            position: clickedNode?.children?.length,
                                          })
                                        }}
                                      >
                                        Link Activity
                                      </div>
                                    </div>
                                  )}
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        setOpenCreateSection({
                                          status: true,
                                          data: node,
                                          type: TYPE_COURSE.UNIT,
                                          id: node.id,
                                          path: path,
                                          treeIndex: node.treeIndex,
                                        })
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                  {/* <div
                                    onClick={() =>{
                                      const rootId = findLastParent(rawCourseData, node)
                                      window.open(
                                        `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                        '_blank'
                                      )
                                    }}
                                    className='menu-item px-3'
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                                  </div> */}
                                </>
                              ) : (
                                <>
                                  {/* <div
                                    className='menu-item px-3'
                                    onClick={() =>
                                      handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                    }
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                                  </div> */}
                                  {/* <div
                                    onClick={() => {
                                      const rootId = findLastParent(rawCourseData, node)
                                      window.open(
                                        `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                        '_blank'
                                      )
                                    }}
                                    className='menu-item px-3'
                                  >
                                    <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                                  </div> */}
                                </>
                              )}

                              {node?.is_linked_section &&
                                !node?.is_linked_section_child &&
                                allowRenderEditCreateCourse && (
                                  <>
                                    <div
                                      className='menu-item px-3'
                                      onClick={() =>
                                        handleConfirmUnlinkSubsection(node.id, node.parent_id)
                                      }
                                    >
                                      <div className='menu-link px-3 sapp-item-tree'>Unlink</div>
                                    </div>
                                  </>
                                )}
                            </>
                          ) : (
                            !node?.is_linked_section &&
                            !node?.is_linked_section_child &&
                            allowRenderEditCreateCourse && (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      setOpenCreateSection({
                                        status: true,
                                        data: node,
                                        type: TYPE_COURSE.UNIT,
                                        id: node.id,
                                        path: path,
                                        treeIndex: node.treeIndex,
                                      })
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                                {/* <div
                                  onClick={() => {
                                    const rootId = findLastParent(rawCourseData, node)
                                    window.open(
                                      `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                      '_blank'
                                    )
                                  }}
                                  className='menu-item px-3'
                                >
                                  <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                                </div> */}
                              </>
                            )
                          )}
                          <div
                            onClick={() => {
                              const rootId = findLastParent(rawCourseData, node)
                              window.open(
                                `${PageLink.PREVIEW_UNIT}/${rootId}/unit/${node.parent_id}`,
                                '_blank'
                              )
                            }}
                            className='menu-item px-3'
                          >
                            <div className='menu-link px-3 sapp-item-tree'>Preview</div>
                          </div>
                          {courseCondition?.can_edit_course_content.can_delete_section &&
                            !node?.is_linked_section &&
                            !node?.is_linked_section_child && (
                              <div className='menu-item px-3'>
                                <div
                                  className='menu-link px-3 sapp-item-tree'
                                  onClick={() => {
                                    handleDeleteNode(node.id, path, treeIndex)
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            )}
                        </div>
                      }
                    >
                      <div className='sapp-tree-popup'>
                        <KTIcon
                          iconName='dots-vertical'
                          className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                          iconType='solid'
                        />
                        {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                      </div>
                    </Popover>
                    <LinkActivity
                      open={openDrawerLinkActivity?.status}
                      setOpen={setOpenDrawerLinkActivity}
                      title='Link Activity'
                      parentId={openDrawerLinkActivity?.parentId}
                      position={openDrawerLinkActivity?.position || 0}
                      body='Create a new activity by linking activity from other course. '
                    />
                  </>
                ) : node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME ? (
                  <>
                    {!parentNode?.is_linked_section_child && !parentNode?.is_linked_section && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className='sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {courseCondition?.can_edit_course_content.can_add_learning_outcome ? (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      if (
                                        node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
                                      ) {
                                        setOpenCreateLearning({
                                          status: true,
                                          id: node.id,
                                          type: node.course_section_type,
                                        })
                                      }
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      if (
                                        node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
                                      ) {
                                        handleDeleteLearnOutcome(node.id, path, treeIndex)
                                      }
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {allowRenderEditCreateCourse && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        if (
                                          node.course_section_type === TYPE_COURSE.LEARNINGOUTCOME
                                        ) {
                                          setOpenCreateLearning({
                                            status: true,
                                            id: node.id,
                                            type: node.course_section_type,
                                            onlyEditName: true,
                                          })
                                        }
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}
                  </>
                ) : (
                  <>
                    {!node?.is_linked_section && !node?.is_linked_section_child && (
                      <Popover
                        trigger={'click'}
                        placement='leftTop'
                        arrow={false}
                        content={
                          <div className='sapp-popup-text menu-state-bg-light-primary w-250px py-4'>
                            {courseCondition?.can_edit_course_content.can_link_course_section ? (
                              <>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      if (
                                        Object.keys(TEST_TYPE).includes(node.course_section_type)
                                      ) {
                                        navigate(
                                          `${PageLink.TEST_EDIT}/${node.course_section_type}/${idCourse}/${node?.quiz?.id}`
                                        )
                                      }
                                      // Case study
                                      if (node?.quiz?.quiz_type === TYPE_COURSE.STORY) {
                                        navigate(
                                          `${PageLink.CASE_STUDY}/${idCourse}/${node.id}/${node?.quiz?.id}`
                                        )
                                      }
                                    }}
                                  >
                                    Edit
                                  </div>
                                </div>
                                <div className='menu-item px-3'>
                                  <div
                                    className='menu-link px-3 sapp-item-tree'
                                    onClick={() => {
                                      // Quiz
                                      if (
                                        Object.keys(TEST_TYPE).includes(node.course_section_type)
                                      ) {
                                        try {
                                          handleDeleteQuiz(node?.quiz?.id, path, treeIndex)
                                        } catch (error) {}
                                      }
                                      // Case study
                                      if (
                                        node?.quiz?.quiz_type === TYPE_COURSE.STORY &&
                                        node?.quiz?.id
                                      ) {
                                        handleDeleteCaseStudy(node.quiz.id, path, treeIndex)
                                      }
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {allowRenderEditCreateCourse && (
                                  <div className='menu-item px-3'>
                                    <div
                                      className='menu-link px-3 sapp-item-tree'
                                      onClick={() => {
                                        if (
                                          Object.keys(TEST_TYPE).includes(node.course_section_type)
                                        ) {
                                          navigate(
                                            `${PageLink.TEST_EDIT}/${node.course_section_type}/${idCourse}/${node?.quiz?.id}`,
                                            { state: { onlyEditName: true } }
                                          )
                                        }
                                        // Case study
                                        if (node?.quiz?.quiz_type === TYPE_COURSE.STORY) {
                                          navigate(
                                            `${PageLink.CASE_STUDY}/${idCourse}/${node.id}/${node?.quiz?.id}`,
                                            { state: { onlyEditName: true } }
                                          )
                                        }
                                      }}
                                    >
                                      Edit
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        }
                      >
                        <div className='sapp-tree-popup'>
                          <KTIcon
                            iconName='dots-vertical'
                            className={`fs-2x sapp-tree-popup-btn text-gray-900`}
                            iconType='solid'
                          />
                          {/* <i className='bi bi-three-dots-vertical fs-2x'></i> */}
                        </div>
                      </Popover>
                    )}
                  </>
                )}
              </>,
            ],
          })}
        />
      )}
      <ChooseTypeOfTestModal
        open={openChooseTypeOfTestModal}
        setOpen={setOpenChooseTypeOfTestModal}
      ></ChooseTypeOfTestModal>
    </div>
  )
}
export default TreeCourse
