export const TITLE_GR = {
  VIEW_USER_GR: 'gr_get_users',
  REMOVE_USER_GR: 'gr_remove_user',
  EDIT_USER_GR: 'gr_edit_user',
  GET_SECURITY_USER_GR: 'gr_get_security_user',
  REMOVE_DEVICE_USER_GR: 'gr_remove_device_user',
  REMOVE_SECURITY_USER_GR: 'gr_remove_security_user',
  MAKE_CONTACT_DEFAULT_GR: 'gr_make_contact_default_user',
  GET_ACTIVITIES_USER_GR: 'gr_get_activities_user',
  CREATE_USER_GR: 'gr_create_user',
  PUT_CHANGE_EMAIL_USER_GR: 'gr_put_change_email_user',
  PUT_RESET_PASSWORD_USER_GR: 'gr_put_reset_password_user',
  GET_EXPORT_DATA_USER_GR: 'gr_get_export_data_users',
}
export const TITLE_STAFF_GR = {
  GET_STAFF: 'gr_get_staffs',
  CREATE_STAFF: 'gr_create_staff',
  EDIT_STAFF: 'gr_edit_staff',
  CHANGE_EMAIL_STAFF: 'gr_put_change_email_staff',
  RESET_PASSWORD_STAFF: 'gr_put_reset_password_staff',
  GET_EXPORT_STAFF: 'gr_get_export_data_staffs',
  GET_IMPPORT_STAFF: 'gr_create_import_staffs',
  GET_ASSIGN_PERMISSION_STAFF: 'gr_assign_permission_staff',
}
export const TITLE_ROLE_GR = {
  GET_ROLES: 'gr_get_roles',
  CREATE_ROLES: 'gr_create_roles',
  EDIT_ROLES: 'gr_put_role',
  REMOVE_ROLES: 'gr_remove_role',
}

export const TITLE_COURSE_GR = {
  CREATE_COURSE: 'gr_create_courses',
  VIEW_COURSE: 'gr_get_courses',
  EDIT_COURSE: 'gr_edit_courses',
}

export const TITLE_COURSE_CATEGORY_GR = {
  CREATE_CATEGORY: 'gr_create_course_category',
  EDIT_CATEGORY: 'gr_edit_course_category',
}

export const TITLE_CERTIFICATE_GR = {
  VIEW_CERTIFICATE: 'gr_get_certificates',
  CREATE_CERTIFICATE: 'gr_create_certificate',
  REMOVE_CERTIFICATE: 'gr_remove_certificate',
  DUPLICATE_CERTIFICATE: 'gr_duplicate_certificate',
  EDIT_CERTIFICATE: 'gr_edit_certificate',
}

export const TITLE_QUESTIONS_GR = {
  CREATE_QUESTION: 'gr_create_question',
  EDIT_QUESTION: 'gr_edit_question',
  REMOVE_QUESTION: 'gr_remove_question',
  VIEW_QUESTION: 'gr_get_questions',
}

export const TITLE_TOPIC_GR = {
  CREATE_QUESTION_TOPIC: 'gr_create_question_topic',
  EDIT_QUESTION_TOPIC: 'gr_edit_question_topic',
  REMOVE_QUESTION_TOPIC: 'gr_remove_question_topic',
  VIEW_QUESTION_TOPIC: 'gr_get_question_topics',
}

export const TITLE_STORY_GR = {
  VIEW_LIST_STORY: 'gr_get_stories',
  CREATE_STORY: 'gr_create_story',
  REMOVE_STORY: 'gr_remove_story',
  EDIT_STORY: 'gr_edit_story',
}

export const TITLE_RESOURCES_GR = {
  DOWNLOAD_RESOURCES: 'gr_download_resources',
  GET_LIST_RESOURCES: 'gr_get_resources',
  VIEW_UPLOAD_FOLDER_RESOURCES: 'gr_get_upload_folder_resources',
  CREATE_RESOURCES: 'gr_create_resources',
  UPDATE_RESOURCES: 'gr_update_resources',
  REMOVE_RESOURCES: 'gr_remove_resources',
}

export const TITLE_QUIZ_GR = {
  VIEW_PARTICIPANT_LIST: 'gr_get_participants',
  CREATE_QUIZ: 'gr_create_quiz',
  EDIT_QUIZ: 'gr_edit_quiz',
  REMOVE_QUIZ: 'gr_remove_quiz',
  VIEW_LIST_QUIZ: 'gr_get_quizzes',
  PUBLIC_SCORE_DETAIL: 'gr_publish_detail_quiz_attempt',
}

export const TITLE_CASE_STUDY_GR = {
  CREATE_CASE_STUDY: 'gr_create_case_study',
  EDIT_CASE_STUDY: 'gr_edit_case_study',
  REMOVE_CASE_STUDY: 'gr_remove_case_study',
}
export const TITLE_SECTION_QUIZ_GR = {
  GET_SECTION_QUIZ: 'gr_get_sections_quiz',
  CREATE_SECTION_QUIZ: 'gr_create_section_quiz',
  EDIT_SECTION_QUIZ: 'gr_edit_section_quiz',
  REMOVE_SECTION_QUIZ: 'gr_remove_section_quiz',
}
export const TITLE_CLASS_GR = {
  VIEW_LIST_CLASS: 'gr_get_classes',
  EDIT_COURSE_CONTENT_CLASS: 'gr_edit_class_course_content',
  EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS: 'gr_edit_course_content_of_student_in_class',
  CREATE_CLASS: 'gr_create_class',
  EDIT_CLASS: 'gr_edit_class',
  REMOVE_STUDENT_IN_CLASS: 'gr_remove_student_in_class',
  CREATE_STUDENT_IN_CLASS: 'gr_create_student_in_class',
  IMPORT_CLASS: 'gr_create_import_class',
  IMPORT_STUDENT_IN_CLASS: 'gr_create_import_student_to_class',
  EDIT_DURATION: 'gr_edit_duration_class',
}

export const TITLE_GROUPS_GR = {
  VIEW_GROUP: 'gr_get_groups',
  CREATE_GROUP: 'gr_create_group',
  EDIT_GROUP: 'gr_edit_group',
  REMOVE_GROUP: 'gr_remove_group',
}
export const TITLE_NOTIFICATIONS_GR = {
  VIEW_LIST: 'gr_get_notifications',
  CREATE_NOTIFICATION: 'gr_create_notification',
  EDIT_NOTIFICATION: 'gr_edit_notification',
  VIEW_MAIL_LOG: 'gr_get_mail_logs',
}
export const TITLE_LOGS_GR = {
  VIEW_LIST_IMPORT_LOGS: 'gr_get_import_logs',
}

export const TITLE_MARKETING_GR = {
  CHECK_DUPLICATE_CONTACT: 'gr_import_check_duplicate_contact',
}

export const TITLE_ADMIN_GR = {
  REPROCESS_VIDEO: 'gr_process_stream_video',
  REPROCESS_LEARN_TRIAL_TICKET_FAIL: 'gr_reprocess_learn_trial_ticket_fail',
  REPROCESS_ENTRANCE_TEST_TICKET_FAIL: 'gr_reprocess_entrance_test_ticket_fail',
}

export const TITLE_EXAM_GR = {
  GET_EXAMS: 'gr_get_exams',
  CREATE_EXAM: 'gr_create_exam',
  EDIT_EXAM: 'gr_edit_exam',
  REMOVE_EXAM: 'gr_remove_exam',
}

export const CODE_ADMIN = {
  SUPER_ADMIN: 'superadmin',
}

export const ID_SIDE_BAR = {
  DASHBOARD: 1,
  // #region CLASSES
  CLASSES: 2,
  LIST_CLASS: 3,
  CREATE_CLASS: 4,
  REPORT_CLASS: 2111,
  REPORT_STUDENT: 2231,
  CLASSROOM_AREA: 'classes-area',
  CLASSROOM: 'classroom',
  CLASSES_SHIFT: 'classes-shift',
  // #endregion

  // #region COURSE
  COURSES: 5,
  COURSES_LIST: 32,
  COURSE_CATEGORY: 31,
  //#endregion COURSE

  QUESTION_BANK: 7,
  TOPIC_LIST: 33,
  CASE_STUDY: 34,
  ENTRANCE_TEST: 14444,
  ENTRANCE_TEST_LIST: 33333,
  PARTICIPANT_LIST: 34444,
  RESOURCE_MANAGER: 35,
  NOTIFICATIONS: 13,
  CREATE_NOTIFICATION: 14,
  LIST_NOTIFICATIONS: 15,
  LIST_GROUP: 32,
  CREATE_GROUP: 33,
  MAIL_LOGS: 34,
  STAFFS: 16,
  LIST_STAFF: 17,
  CREATE_STAFF: 18,
  IMPORT_REPORT: 19,
  STUDENT: 22,
  LIST_STUDENTS: 23,
  CREATE_STUDENTS: 24,
  IMPORT_REPORTS_STUDENT: 221,
  TEACHERS: 25,
  LIST_TEACHERS: 26,
  CREATE_TEACHERS: 27,
  IMPORT_REPORT_TEACHER: 251,
  CERTIFICATIONS: 36,
  ROLES: 28,
  ROLE: 29,
  PERMISSION: 30,
  SETTING: 31,
  VIDEO: 31,
  SYNC_HUBSPOT: 31,
  QUESTION_LIST: 37,
  REPORT_PARTICIPANT: 38,
  CREATE_SUPPORTER_GROUP: 35,
  LIST_SUPPORTER_GROUP: 36,
  SUPPORTER_GROUP: 39,
  COURSE_SUBJECT: 'course-subject',

  MARKETING: 4111,
  CHECK_DUPLICATE_CONTACT: 4112,
  CACHE: 31,

  EVENT_TEST: 'event-test',
  EVENT_TEST_LIST: 'event-test-list',
  CREATE_EVENT_TEST: 'create-event-test',
  ALL_PARTICIPANT: 'all-participant',

  EXAM: 'exams',
  ID_CFA_LIST: 'id-CFA-list',
  ID_ACCA_LIST: 'id-ACCA-list',
  ID_CMA_LIST: 'id-CMA-list',
  ID_EXAM_IMPORT_LOG: 'exam-import-log',
}
