import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { IStudentDetail } from 'src/type/students'
import TeacherProfileDetail from './TeacherProfileDetail'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const OverviewTeacherProfile = ({ teacherDetail, loading, setTeacherDetail }: IProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <TeacherProfileDetail
      teacherDetail={teacherDetail}
      loading={loading}
      setTeacherDetail={setTeacherDetail}
    />
  )
}

export default OverviewTeacherProfile
