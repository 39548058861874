import clsx from 'clsx'
import React from 'react'

interface IProps {
  title: string
  children: React.ReactNode
  icon?: React.ReactNode
  className?: string
}

const WarningText = ({ title, children, icon, className }: IProps) => {
  return (
    <div
      className={`alert alert-dismissible bg-light-alert-primary border border-alert-primary border-dashed d-flex flex-column flex-sm-row p-5 mb-0 align-items-center sapp-mt-32px ${clsx(
        !!className && className
      )}`}
    >
      {icon ? (
        icon
      ) : (
        <i className='ki-duotone ki-information-5 fs-2hx text-success me-4 mb-5 mb-sm-0'>
          <span className='path1'></span>
          <span className='path2'></span>
          <span className='path3'></span>
        </i>
      )}
      <div className='d-flex flex-column pe-0 pe-sm-10'>
        <h5 className='mb-1 sapp-title-alert'>{title}</h5>
        {children}
      </div>
    </div>
  )
}

export default WarningText
