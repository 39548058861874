import dayjs from 'dayjs'

export const convertUTCToLocalTime = (utc_time: Date | string): Date => {
  return new Date(utc_time)
}

export function convertMsToMinutes(milliseconds: number) {
  const minutes = Math.round(milliseconds / 60000)
  return minutes
}

export function convertSToMinutes(seconds: number) {
  const minutes = Math.round(seconds / 60)
  return minutes
}

export const formatDate = (
  date: Date | string | null | undefined,
  returnValue: null | string = '-'
): string | null => {
  if (!date || dayjs(date).isValid() === false) {
    return returnValue
  }
  return dayjs(date).format('DD/MM/YYYY')
}
