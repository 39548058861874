import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import { TeachersListWrapper } from 'src/components/teacher-list/TeachersList'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.TEACHERS}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'List Teachers',
  },
]

const Teachers = () => {
  return (
    <PageLayouts pageTitle='List Teachers' breadcrumbs={breadcrumbs}>
      <TeachersListWrapper />
    </PageLayouts>
  )
}

export default withAuthComponents(Teachers)
