import React from 'react'
import {Select} from 'antd'
import './sappselect.scss'
import {DownIcon} from 'src/common/icons'
import {isNull} from 'lodash'
import {DownOutlined} from '@ant-design/icons'

interface IProps {
  onChange?: (val: any) => void
  className?: string
  size: 'large' | 'middle' | 'small'
  value?: string | null
  options: Array<{label: string; value: string | number}> | undefined
  placeholder?: any
  showSearch?: boolean
  filterOption?: boolean
  suffixIcon?: any
  dropdownStyle?: React.CSSProperties | undefined
}

const SAPPSelect = ({
  onChange,
  className,
  size,
  value,
  options,
  placeholder,
  showSearch = false,
  suffixIcon = <DownOutlined />,
  dropdownStyle,
}: IProps) => {
  return (
    <Select
      onChange={onChange}
      className={`${className ?? ''} w-100 sapp-hook-form-select bg-gray-100`}
      value={value}
      placeholder={placeholder}
      size={size}
      showSearch={showSearch}
      dropdownStyle={{
        minWidth: '90px',
        ...(dropdownStyle && dropdownStyle),
      }}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={options}
      suffixIcon={isNull(suffixIcon) ? null : <DownIcon width={9} height={6} />}
    />
  )
}

export default SAPPSelect
