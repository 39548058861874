import { useEffect, useMemo } from 'react'
import { IPreviewProp } from './OneChoiceQuestion'
import HookFormCheckBoxTN from '../base/checkbox/HookFormCheckBoxTN'
import { getUppercaseByNumber } from 'src/utils'
import EditorReader from '../base/editor/EditorReader'
import { IAnswers } from 'src/type/grade'

const MultiChoiceQuestion = ({
  data,
  control,
  corrects,
  name,
  defaultValues,
  setValue,
}: IPreviewProp) => {
  const convertAnswer = useMemo(() => {
    let answers = []
    let number = 0
    if (data?.answers) {
      const oldData = [...data?.answers]
      const sorted = oldData?.sort(
        (a: IAnswers, b: IAnswers) => a?.answer_position - b?.answer_position
      )
      for (let e of sorted) {
        number++
        answers.push({
          label: `${getUppercaseByNumber(number)}. ${e?.answer}`,
          value: e?.id,
        })
      }
    }
    return answers
  }, [data])
  useEffect(() => {
    if (defaultValues) {
      setValue(name, defaultValues)
    } else {
      setValue(name, '')
    }
  }, [defaultValues])
  return (
    <div>
      <EditorReader text_editor_content={data?.question_content} className='sapp-questions' />
      <div
        className='sapp-answer-wrapper'
        style={{
          flexDirection: 'column',
        }}
      >
        <HookFormCheckBoxTN
          options={convertAnswer || []}
          control={control}
          name={name || 'multiples'}
          multiple
          corrects={corrects}
        />
      </div>
    </div>
  )
}
export default MultiChoiceQuestion
