/* eslint-disable react/jsx-no-duplicate-props */
import { Dispatch, SetStateAction, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfirm } from 'src/hooks/use-confirm'
import { PageLink } from 'src/constants'
import { TEST_TYPE } from 'src/components/courses/shared/Interfaces'
import { IETest } from 'src/type/entrancetest'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { DESCRIPTION_POPUPCONFIRM, LANG_SIDEBAR } from 'src/constants/lang'
import { TestChildRef } from 'src/components/courses/test/TestComponent'
import CreateEventTest from 'src/components/event-test/CreateEventTest'

const ListSection = ({
  listInfo,
  setStep,
  step,
  handleGotoStep,
  loading,
  setLoading,
}: {
  listInfo: IETest | undefined
  setStep?: any
  step?: any
  handleGotoStep: (id: number) => void
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}) => {
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.EVENT_TEST)
      },
    })
  }

  const childRef = useRef<TestChildRef>(null)
  const params = useParams()

  return (
    <>
      {contextHolder}
      <Processbar
        step={step}
        setNewStep={setStep}
        onCancel={handleCancel}
        onClick={() => childRef?.current?.onSubmit()}
        cancelButtonCaption={LANG_SIDEBAR.eventTest}
        className='sapp-mw-700px'
        loading={loading}
      />
      <CreateEventTest
        type={params.type as TEST_TYPE}
        id={params.id || ''}
        testFor={params.testFor || ''}
        editId={params.id}
        editOnlyName={false}
        ref={childRef}
        setLoading={setLoading}
      />
    </>
  )
}

export default ListSection
