import React, { Dispatch, SetStateAction } from 'react'
import { useUserContext } from 'src/context/UserProvider'
import { CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR } from 'src/constants/permission'
import { Role } from 'src/type'
import { PageLink } from 'src/constants'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import ButtonIconSecondary from 'src/components/ui/button-icon-secondary/ButtonIconSecondary'
interface IProps {
  setOpen: Dispatch<SetStateAction<boolean>>
  onClick: () => void
  titleImport?: string
  titleExport?: string
  titleAdd: string
  link?: string
  onClickAdd?: () => void
  loading: boolean
  notImport?: boolean
  location?: any
  allowRenderImport: boolean | undefined
}

const SAPPActionButton = ({
  onClick,
  setOpen,
  titleAdd,
  titleExport,
  titleImport,
  onClickAdd,
  loading,
  location,
  allowRenderImport,
}: IProps) => {
  const { profileMe } = useUserContext()

  const allowRenderExport = profileMe?.roles?.some(
    (role: Role) =>
      (role.permissions?.includes(TITLE_STAFF_GR.GET_EXPORT_STAFF) &&
        location === PageLink.STAFFS) ||
      (role.permissions?.includes(TITLE_GR.GET_EXPORT_DATA_USER_GR) &&
        location === PageLink.STUDENTS) ||
      (role.permissions?.includes(TITLE_GR.GET_EXPORT_DATA_USER_GR) &&
        location === PageLink.TEACHERS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderCreate = profileMe?.roles?.some(
    (role: Role) =>
      (role.permissions?.includes(TITLE_STAFF_GR.CREATE_STAFF) && location === PageLink.STAFFS) ||
      (role.permissions?.includes(TITLE_GR.CREATE_USER_GR) && location === PageLink.STUDENTS) ||
      (role.permissions?.includes(TITLE_GR.CREATE_USER_GR) && location === PageLink.TEACHERS) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <div className='row justify-content-xl-end mt-xl-0 mt-lg-3'>
      <div className='d-flex px-0 justify-content-xl-end'>
        {allowRenderImport && (
          <ButtonIconSecondary
            iconName={'file-up'}
            title={titleImport ?? 'Import'}
            size='small'
            onClick={() => setOpen(true)}
            iconType='outline'
            className='me-3'
          />
        )}
        {allowRenderExport && (
          <ButtonIconSecondary
            iconName={'file-down'}
            title={titleExport ?? 'Export'}
            size='small'
            onClick={onClick}
            iconType='outline'
            className='me-3'
          />
        )}
        {allowRenderCreate && (
          <ButtonIconPrimary
            iconName={'plus'}
            title={titleAdd}
            loading={loading}
            disabled={!allowRenderCreate}
            onClick={onClickAdd}
            size='small'
          />
        )}
      </div>
    </div>
  )
}
export default SAPPActionButton
