import { fetchFormData, fetcher } from 'src/services/request'
import { IResponse } from 'src/type'
import { IAuthor } from 'src/type/shop/author'
import {
  IEventCategory,
  IEventCategoryList,
  IEventCategoryUpdate,
  IEventForm,
  IEventList,
  IEventListItem,
} from 'src/type/shop/event'

export class EventAPI {
  static getEventCategories({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IEventCategoryList>> {
    return fetcher(`/shop-event/categories?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getEventCategory(id: string): Promise<IResponse<IAuthor>> {
    return fetcher(`/shop-event/categories/${id}`)
  }

  static createEventCategory({
    data,
  }: {
    data: IEventCategoryUpdate
  }): Promise<IResponse<IEventCategory>> {
    return fetcher('/shop-event/categories', { method: 'POST', data: data })
  }

  static editEventCategory({
    id,
    data,
  }: {
    id: string
    data: IEventCategoryUpdate
  }): Promise<IResponse<IEventCategory>> {
    return fetcher(`/shop-event/categories/${id}`, { method: 'PUT', data: data })
  }

  static deleteEventCategory(id: string): Promise<IResponse<IEventCategory>> {
    return fetcher(`/shop-event/categories/${id}`, { method: 'DELETE' })
  }

  static deleteBulkEventCategory(ids: string[]): Promise<IResponse<IEventCategory>> {
    return fetcher('/shop-event/categories/bulk-delete', {
      method: 'POST',
      data: { ids },
    })
  }

  // event
  static getEvents({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<IEventList>> {
    return fetcher(`/shop-events?page_index=${page_index}&page_size=${page_size}`, {
      params,
    })
  }

  static getEvent(id: string): Promise<IResponse<IEventListItem>> {
    return fetcher(`/shop-events/${id}`)
  }

  static createEvent({ data }: { data: IEventCategoryUpdate }): Promise<IResponse<IEventCategory>> {
    return fetcher('/shop-events', { method: 'POST', data: data })
  }

  static uploadThumbnail = (data: { id: string; thumbnail: File }) => {
    const formData = new FormData()
    formData.append('thumbnail_url', data.thumbnail)
    return fetchFormData({ url: `/shop-events/${data.id}/thumbnail`, formData })
  }

  static editEvent({
    id,
    data,
  }: {
    id: string
    data: IEventForm
  }): Promise<IResponse<IEventListItem>> {
    return fetcher(`/shop-events/${id}`, { method: 'PUT', data: data })
  }

  static updateStatus(id: string, status: string): Promise<IResponse<IEventListItem>> {
    return fetcher(`/shop-events/${id}/status`, { method: 'PUT', data: { status } })
  }

  static deleteEvent(id: string): Promise<IResponse<IEventCategory>> {
    return fetcher(`/shop-events/${id}`, { method: 'DELETE' })
  }

  static deleteBulkEvent(shop_event_ids: string[]): Promise<IResponse<IEventCategory>> {
    return fetcher('/shop-events/bulk-delete', {
      method: 'POST',
      data: { shop_event_ids },
    })
  }

  static duplicateEvent(id: string): Promise<IResponse<IEventCategory>> {
    return fetcher(`/shop-events/duplicate`, { method: 'POST', data: { id } })
  }
}
