import React from 'react'
import styles from './ButtonSecondary.module.scss'
import { Link } from 'react-router-dom'
import { IButtonProps } from 'src/type/button'

const ButtonSecondary = ({
  title,
  size = 'large',
  disabled,
  loading,
  link = '',
  className = '',
  onClick,
}: IButtonProps) => {
  const heightClass = size === 'large' ? 'sapp-h-45px' : 'sapp-h-40'

  if (link)
    return (
      <Link
        to={link}
        className={`btn ${heightClass} ${styles['sapp-btn--secondary']} ${className}`}
      >
        {title}
      </Link>
    )

  return (
    <button
      disabled={disabled || loading}
      className={`btn ${heightClass} ${styles['sapp-btn--secondary']} ${className}`}
      onClick={onClick && onClick}
    >
      {loading ? (
        <span className={`indicator-progress sapp-d-content`}>
          <span className='spinner-border spinner-border-sm align-middle'></span>
        </span>
      ) : (
        <>{title}</>
      )}
    </button>
  )
}

export default ButtonSecondary
