import { pageSizeOptions } from 'src/constants'
import { useState } from 'react'
import SappTable from 'src/components/base/SappTable'
import { KTCardBody } from 'src/_metronic/helpers'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'

interface IResultDetail {
  data: Record<string, any>[]
  loading: boolean
}

const ResultDetailCheckDuplicateContact = ({ data, loading }: IResultDetail) => {
  const [currenPage, setCurrenPage] = useState(1)
  const [pageSize, setPageSize] = useState(pageSizeOptions[0]?.value)

  // Calculate the start and end index for the current page
  const startIndex = (currenPage - 1) * pageSize
  const endIndex = startIndex + pageSize

  const generateHeader = (data: Record<string, any>[]) => {
    if (data.length <= 0) return []
    return [
      {
        label: '#',
        id: '#',
        className: 'min-w-50px',
      },
      ...Object.keys(data[0])
        // * Không hiện 2 cột Duplicate by Email, Duplicate by Phone
        .filter((key) => !['Duplicate By Email', 'Duplicate By Phone'].includes(key))
        .map((key) => ({
          label: key.charAt(0).toUpperCase() + key.slice(1),
          id: key,
          className: 'sapp-min-w-210px',
        })),
    ]
  }

  const handleChange = (page_index: number, page_size: number) => {
    setCurrenPage(page_index)
    setPageSize(page_size)
  }

  const headers = generateHeader(data)

  // Đánh index cho toàn bộ data trước khi phân trang
  data = data.map((obj, idx) => {
    return { ...obj, '#': idx + 1 }
  })

  // Slice the data to get the items for the current page
  const currentData = data?.slice(startIndex, endIndex)

  return (
    <KTCardBody className='pt-8 ms-4 me-4 ps-5 pe-5 pb-4'>
      <SappTable hasCheck={false} headers={headers} loading={loading} data={currentData}>
        {currentData?.map((obj: Record<string, any>, index: number) => (
          <tr key={index}>
            {headers.map((header) => {
              return (
                <td key={header.id}>
                  <div style={{ lineHeight: '35px' }}>{obj[header.id]}</div>
                </td>
              )
            })}
          </tr>
        ))}
      </SappTable>

      <PagiantionSAPP
        currentPage={currenPage || 1}
        pageSize={pageSize || 10}
        totalItems={data?.length}
        handlePaginationChange={handleChange}
        pageSizeOptionsDetail={pageSizeOptions}
      />
    </KTCardBody>
  )
}

export default ResultDetailCheckDuplicateContact
