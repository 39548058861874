import React from 'react'
import './YourAnswer.scss'

type Props = {
  show: boolean
}

const YourAnswer = ({ show }: Props) => {
  return (
    <>
      {show && (
        <div className='sapp-your-answer sapp-custom-background-layout'>Student’s Answer</div>
      )}
    </>
  )
}

export default YourAnswer
