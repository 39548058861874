import {Skeleton} from 'antd'
import React from 'react'
import {Control, Controller} from 'react-hook-form'
import ErrorMessage from 'src/common/ErrorMessage'
import GuidelineField from 'src/common/GuidelineField'
import SappLabel from 'src/components/base/label/SappLabel'

interface IProps {
  name: string
  control: Control<any>
  defaultValue?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  placeholder?: string
  className?: string
  rows?: number | undefined
  label?: string
  guideline?: string[]
  disabled?: boolean
  labelClass?: string
  required?: boolean
  skeleton?: boolean
  style?: any
  onClick?: (e?: any) => void
  onKeyDown?: (e?: any) => void
  id?: string
}

const HookFormTextArea = ({
  name,
  control,
  defaultValue,
  onChange,
  placeholder,
  className = '',
  rows,
  label,
  guideline,
  disabled,
  // labelClass = 'fs-6 fw-bold mb-3',
  required,
  skeleton,
  style,
  onClick,
  onKeyDown,
  id,
}: IProps) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({field, fieldState: {error}}) => (
          <>
            {label && <SappLabel label={label} required={required} />}
            {!skeleton ? (
              <textarea
                {...field}
                id={id}
                value={field.value ?? ''}
                defaultValue={field.value ? undefined : defaultValue}
                onChange={(value) => {
                  field.onChange(value)
                  onChange && onChange(value)
                }}
                className={`${className} form-control ${error?.message ? 'is-invalid' : ''}`}
                placeholder={placeholder}
                rows={rows ?? 3}
                disabled={disabled}
                style={style}
                onClick={onClick}
                onKeyDown={onKeyDown}
              />
            ) : (
              <Skeleton.Input active size='large' className='w-100 h-150px'></Skeleton.Input>
            )}
            <ErrorMessage>{error?.message ?? ''}</ErrorMessage>
          </>
        )}
      />
      <GuidelineField guideline={guideline} />
    </>
  )
}

export default HookFormTextArea
