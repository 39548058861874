import React, {useEffect, useState} from 'react'
import SappModal from 'src/components/base/SappModal'
import 'video.js/dist/video-js.css'
import './ModalVideo.scss'
import {RESOURCE_STATUS, RESOURCE_STATUS_MESSAGE} from 'src/type/resource'
import SAPPVideo from './SAPPVideo'
interface IProps {
  open: boolean
  setOpen: any
  src?: string
  resourceStatus?: RESOURCE_STATUS
}
const ModalPlayVideo = ({open, setOpen, src, resourceStatus}: IProps) => {
  const handleClose = () => {
    setNewSrc(undefined)
    setOpen({status: false})
  }
  const [newSrc, setNewSrc] = useState<string>()
  useEffect(() => {
    if (!src?.includes('http')) {
      // const key =
      //   src
      //     .replace('https://customer-qf43f9e6huohhr1o.cloudflarestream.com/', '')
      //     .replace('/manifest/video.m3u8', '') || ''

      setNewSrc(src)
    } else {
      setNewSrc(undefined)
    }
  }, [src])
  // const playerRef = React.useRef(null)
  // const handlePlayerReady = (player: any) => {
  //   playerRef.current = player

  //   // You can handle player events here, for example:
  //   player.on('waiting', () => {
  //     videojs.log('player is waiting')
  //   })

  //   player.on('dispose', () => {
  //     videojs.log('player will dispose')
  //   })
  // }

  const checkStatus = () => {
    if (!resourceStatus) {
      return <h3 className='text-danger text-center'>{RESOURCE_STATUS_MESSAGE['PROCESSING']}</h3>
    }
    if (resourceStatus === RESOURCE_STATUS.ERROR) {
      return <h3 className='text-danger text-center'>Video lỗi</h3>
    }
    if (
      resourceStatus === RESOURCE_STATUS.PROCESSING ||
      resourceStatus === RESOURCE_STATUS.PENDING ||
      resourceStatus === RESOURCE_STATUS.CREATED ||
      !resourceStatus
    ) {
      return (
        <h3 className='text-danger text-center'>
          {RESOURCE_STATUS_MESSAGE[RESOURCE_STATUS.PENDING]}
        </h3>
      )
    }
  }

  return (
    <SappModal
      title='Video Preview'
      open={open}
      handleClose={handleClose}
      showFooter={false}
      centered
      dialogClassName='sapp-preview-video'
    >
      <div className='my-0 mx-auto d-block w-100' style={{overflow: 'hidden'}}>
        {open && (
          <>
            {/* {checkStatus() || ( */}
            <>
              {newSrc && src ? (
                // <VideoJS
                //   options={{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     aspectRatio: '16:9',
                //     // fluid:true,
                //     sources: [
                //       {
                //         src: src,
                //       },
                //     ],
                //   }}
                //   onReady={handlePlayerReady}
                // />
                <SAPPVideo options={{src: newSrc}} />
              ) : (
                <div className='w-100 flex flex-col sapp-hh-fit-content'>
                  {checkStatus()}
                  <div className='flex-1'>
                    <video data-setup='{}' width={'100%'} height={'100%'} controls>
                      <source src={src} />
                    </video>
                  </div>
                </div>
              )}
            </>
            {/* )} */}
          </>
        )}
      </div>
    </SappModal>
  )
}
export default ModalPlayVideo
