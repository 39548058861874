import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import CreateNotiComponent, {
  NotificationChildRef,
} from 'src/components/notifications/components/createNotiComponent/CreateNotiComponent'
import { PageLink } from 'src/constants'
import { ITabs } from 'src/type'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'LMS',
  },
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: 'Gửi thông báo',
  },
]

const SendNotification = () => {
  let { id } = useParams()
  const childRef = useRef<NotificationChildRef>(null)
  return (
    <CreateEditLayout
      pageTitle={id ? 'Edit Notification/ Email' : 'Create Notification/ Email'}
      onCancel={() => childRef?.current?.handleCancel()}
      onSave={() => childRef?.current?.onSubmit()}
    >
      <CreateNotiComponent id={id} ref={childRef} />
    </CreateEditLayout>
  )
}

export default withAuthComponents(SendNotification)
