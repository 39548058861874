export const teacherImportLog = [
  {
    label: 'username',
    id: 'username',
    className: 'min-w-150px bg-white',
    tdClassName: 'bg-white',
  },
  {
    label: 'password',
    id: 'password',
    className: 'min-w-150px',
  },
  {
    label: 'email',
    id: 'email',
    className: 'min-w-200px',
  },
  {
    label: 'phone',
    id: 'phone',
    className: 'min-w-100px',
  },
  {
    label: 'Fullname',
    id: 'full_name',
    className: 'min-w-200px',
  },
  {
    label: 'address',
    id: 'address',
    className: 'min-w-200px',
    cellClassName: 'sapp-text-truncate-3',
  },
  {
    label: 'dob',
    id: 'dob',
    className: 'min-w-200px',
    cellClassName: 'text-nowrap',
  },
  {
    label: 'note',
    id: 'note',
    className: 'min-w-250px',
    cellClassName: 'sapp-text-truncate-3',
  },
  {
    label: 'gender',
    id: 'sex',
    className: 'min-w-150px',
  },
  {
    label: 'job title',
    id: 'job_title',
    className: 'min-w-100px',
  },
  {
    label: 'status',
    id: 'status',
    className: 'min-w-100px',
  },
  {
    label: 'facebook',
    id: 'facebook',
    className: 'min-w-100px',
  },
  {
    label: 'linkedin',
    id: 'linkedin',
    className: 'min-w-100px',
  },
  {
    label: 'youtube',
    id: 'youtube',
    className: 'min-w-100px',
  },
  {
    label: 'describe',
    id: 'describe',
    className: 'min-w-150px',
  },
  {
    label: 'teaching status',
    id: 'teacher_status',
    className: 'min-w-150px',
  },
]
