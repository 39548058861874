import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { CoursesAPI } from 'src/apis/courses'
import { PROGRAM } from 'src/type'
import { ICourseCategoies } from 'src/type/courses'

const useCourseCategory = (program: PROGRAM) => {
  // Step 1: Define state inside the hook
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies | null>(null)

  // Step 2: Use React Query to fetch the data
  const { data, isLoading, error } = useQuery<ICourseCategoies>(
    ['courseCategory', program],
    async () => {
      const res = await CoursesAPI.getCategory({
        page_index: 1,
        page_size: 50,
        params: {
          name: program,
        },
      })
      return res?.data
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  )

  // Step 3: Use effect to update the state when data is fetched
  useEffect(() => {
    if (data) {
      setCourseCategory(data) // Set course category in state
    }
  }, [data])

  // Step 4: Return both the state and the setter function along with other data
  return { courseCategory, setCourseCategory, isLoading, error }
}

export default useCourseCategory
