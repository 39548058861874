import { debounce } from 'lodash'
import { useRef, useState } from 'react'
import { SubjectAPI } from 'src/apis/subject'
import { ISubjectList } from 'src/type/subject'

const useSubjects = () => {
  const [subjects, setSubjects] = useState<ISubjectList>()
  const [isLoading, setIsLoading] = useState(false) // State to track loading status
  const requestOngoingRef = useRef(false)

  const debounceSearchSubject = debounce((params, pageSize = 10) => {
    getSubjects(params, pageSize)
  }, 500)

  const getSubjects = async (params: Object, pageSize = 10) => {
    setIsLoading(true)
    const resMentor = await fetchSubjects(1, pageSize, params)
    setIsLoading(false)
    setSubjects(resMentor?.data)
  }

  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await SubjectAPI.getSubjects(page_index, page_size, params)
      return res
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      setIsLoading(true)
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      setIsLoading(false)
      if (res) {
        const results = subjects.subjects.concat(res.data.subjects)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  return {
    debounceSearchSubject,
    handleNextPageSubject,
    subjects,
    isLoading,
    setSubjects,
    getSubjects,
  }
}

export default useSubjects
