import parseHTML, { Element } from 'html-react-parser'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { isUndefined } from 'lodash'
import { replaceTextAlignCenterToWebKitCenter } from 'src/utils/grade'
import { video_url } from 'src/constants'
import 'src/global.d.ts'
import SAPPVideo from 'src/components/form/editor/modalVideo/SAPPVideo'
import { StreamPlayerApi } from '@cloudflare/stream-react'
import ModalPreviewImage from '../ModalPreviewImage'

type Props = {
  text_editor_content: string | undefined
  className?: string
  extenalRef?: any
  id?: string
  onMouseUp?: any
  pinned?: boolean
}

const EditorReader = ({
  text_editor_content = '',
  className = '',
  extenalRef,
  id,
  onMouseUp,
  pinned,
}: Props) => {
  const refDocument = useRef<HTMLDivElement>(null)
  const [openPreviewImage, setOpenPreviewImage] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>()
  const [type, setType] = useState<'VIDEO' | 'IMG'>('VIDEO')
  const [content, setContent] = useState<any>()
  const editorRef = useRef<HTMLDivElement>(null)
  const videoRefs = useRef<Record<string, MutableRefObject<StreamPlayerApi | undefined>>>({})
  useEffect(() => {
    if (extenalRef) {
      extenalRef?.current?.addEventListener('click', handleOnclick)

      return () => {
        extenalRef?.current?.removeEventListener('click', handleOnclick)
      }
    } else {
      refDocument?.current?.addEventListener('click', handleOnclick)

      return () => {
        refDocument?.current?.removeEventListener('click', handleOnclick)
      }
    }
  }, [refDocument?.current, extenalRef?.current])

  useEffect(() => {
    setContent(text_editor_content)
  }, [text_editor_content])

  const convertMathToImage = async (element: any) => {
    if (typeof com !== 'undefined' && com?.wiris?.js?.JsPluginViewer) {
      const viewer = com?.wiris?.js?.JsPluginViewer
      if (element && (!isUndefined(viewer?.e) || viewer)) {
        try {
          await viewer.parseElement(element, true, function () {})
        } catch (error) {}
      }
    } else {
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const editor = editorRef?.current
      if (editor) {
        const mfencedElements = editor?.querySelectorAll('mfenced')
        mfencedElements.forEach((el: any) => {
          const openAttr = el?.getAttribute('open')
          const closeAttr = el?.getAttribute('close')
          if (openAttr !== null && closeAttr) {
            const replacements: { [key: string]: string } = {
              '|': '|',
              '||': '||',
              '>': '<',
              '}': '{',
              ']': '[',
              '&#62;': '&#60;',
            }
            if (replacements[closeAttr]) {
              el?.setAttribute('open', replacements[closeAttr])
            }
          }
        })

        // Replace quote in font family
        const mathElement = editor?.querySelectorAll('math')
        if (mathElement) {
          mathElement?.forEach((el: any) => {
            if (el?.hasAttribute('style')) {
              let styleValue = el?.getAttribute('style')
              styleValue = styleValue?.replaceAll('"', '')
              el?.setAttribute('style', styleValue)
            }
          })
          editor && convertMathToImage(editor)
        }
      }
    }, 100)
  }, [editorRef?.current, text_editor_content])

  const handleOnclick = async (e: MouseEvent) => {
    const target = e?.target as HTMLElement
    if (target.className === 'sapp_overlay_video') {
      // const overlay = target.nextSibling as any
      const video = target?.previousSibling as any
      const src = video?.querySelector('source')?.getAttribute('token')
      if (src && src !== 'null' && video.tagName === 'VIDEO') {
        var iframe = document.createElement('iframe')
        iframe.src = `${video_url}${src}/iframe?autoplay=true`
        iframe.id = video?.id
        iframe.className = video?.className
        iframe.style.cssText = video?.style.cssText
        iframe.allow = 'accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
        iframe.allowFullscreen = true
        video?.parentNode?.replaceChild(iframe, video)
        target?.classList.add('hidden')
      }
    } else if (target?.tagName === 'IMG') {
      const imageSrc = target?.getAttribute('src')
      if (imageSrc) {
        setOpenPreviewImage(true)
        setPreviewImage(imageSrc)
        setType('IMG')
      }
    }
  }

  /**
   * @description add class border theo editor khi border style khác none và hidden ở lần đầu component render
   */
  useEffect(() => {
    const tableElement = document?.querySelector('table')
    if (tableElement) {
      const style = window?.getComputedStyle(tableElement)
      const newBorderStyle = style?.borderStyle

      const thElements = document?.querySelectorAll('.editor-wrap td')
      thElements?.forEach((td) => {
        if (newBorderStyle !== 'none' && newBorderStyle !== 'hidden') {
          td?.classList?.add(`border-[1px]`)
        } else {
          td?.classList?.remove(`border-[1px]`)
        }
      })
    }
  })

  return (
    <>
      <div
        className={`${className} editor-wrap mce-content-body`}
        id={id || ''}
        onMouseUp={onMouseUp ? onMouseUp : () => {}}
        ref={editorRef}
      >
        <div
          ref={extenalRef || refDocument}
          className={`${pinned ? 'text-white pt-2' : 'text-bw-1'}`}
        >
          {parseHTML(replaceTextAlignCenterToWebKitCenter(content || ''), {
            replace: (domNode) => {
              if (domNode instanceof Element && domNode.name === 'video') {
                const sourceChild = (domNode.children as Element[]).find(
                  (child) => child.name === 'source'
                )
                const videoToken = sourceChild?.attribs?.token
                if (videoToken) {
                  if (!videoRefs.current[videoToken]) {
                    videoRefs.current[videoToken] = { current: undefined }
                  }
                  return (
                    <SAPPVideo
                      key={videoToken}
                      options={{ src: videoToken }}
                      streamRef={videoRefs.current[videoToken]}
                    />
                  )
                }
              }
            },
          })}
        </div>
      </div>
      {type === 'IMG' && (
        <ModalPreviewImage
          openPreview={openPreviewImage}
          setOpenPreview={setOpenPreviewImage}
          avatarResize={previewImage}
          title={'Preview Image'}
        />
      )}
    </>
  )
}

export default EditorReader
