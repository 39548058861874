import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import TableListSubject from 'src/components/courses/subject/TableListSubject'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import useChecked from 'src/hooks/use-checked'
import { ITabs } from 'src/type'
import { ICourseCategoies, ICourseSubjectList } from 'src/type/courses'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.AUTH_LOGIN}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: `${PageLink.COURSES}`,
    title: 'Subject List',
  },
]

const fieldNames = ['name', 'course_category_id', 'fromDate', 'toDate']

const initialValues = {
  name: '',
  course_category_id: '',
  fromDate: '',
  toDate: '',
}

const CourseSubject = () => {
  const [subjectList, setSubjectList] = useState<ICourseSubjectList>()
  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const [openCourse, setOpenCourse] = useState(false)
  const [dateField, setDateField] = useState<string>('updated_at')
  const [loading, setLoading] = useState(true)
  const { search } = useLocation()
  const navigate = useNavigate()
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked(
    subjectList?.subjects
  )

  const fetchCourseCategory = async (pageIndex: number = 1, pageSize: number = 100) => {
    try {
      const res = await CoursesAPI.getCategory({ page_index: pageIndex, page_size: pageSize })
      setCourseCategory(res?.data)
    } catch (error) {}
  }

  const newCourseCategory = courseCategory?.course_categories?.map((category) => ({
    label: category.name,
    value: category.id,
  }))

  const searchParams = new URLSearchParams(search)
  const queryParams = {
    name: searchParams.get('name') ?? '',
    course_category_id: searchParams.get('course_category_id') ?? '',
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const [idItem, setIdItem] = useState('')

  const getParams = (
    name: string,
    course_category_id: string,
    fromDate: Date | string,
    toDate: Date | string,
    dateField: string
  ) => ({
    name,
    course_category_id,
    fromDate: fromDate,
    toDate: toDate,
    dateField,
  })

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm({
    mode: 'onChange',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      name: getValues('name') ?? '',
      course_category_id: getValues('course_category_id') ?? '',
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    navigate(`?${queryString}`)
  }

  const fetchSubject = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CoursesAPI.getSubjects({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setSubjectList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleReset = () => {
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName as keyof typeof initialValues])
    })

    toggleCheckAll(false)

    setLoading(true)
    setDateField('')
    navigate(PageLink.COURSE_SUBJECT)
    reset()
    fetchSubject(1, 10)
  }

  const checkFromDateToDate = queryParams.name || queryParams.fromDate || queryParams.toDate

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        getValues('course_category_id')?.trimStart()?.trimEnd() ?? '',
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )

    setLoading(true)
    fetchSubject(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(queryParams.fromDate)
  const dateQueryToDate = getDateInfo(queryParams.toDate)

  const filterParams = cleanParamsAPI(
    getParams(
      queryParams.name.trimStart().trimEnd() ?? '',
      queryParams.course_category_id.trimStart().trimEnd() ?? '',
      queryParams.fromDate
        ? formatISOFromDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
        : '',
      queryParams.toDate
        ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
        : '',
      checkFromDateToDate ? 'updated_at' : 'created_at'
    )
  )

  const handleOpenCourse = () => {
    setOpenCourse(true)
    setIdItem('')
  }

  useEffect(() => {
    fetchCourseCategory()
  }, [])

  return (
    <PageLayouts pageTitle='Subject List' breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-9 border-0 pt-10 row'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='name'
              placeholder='Search'
              defaultValue={queryParams?.name}
              isListScreen
              onSubmit={onSubmit}
            />
            <HookFormSelectAntd
              control={control}
              name='course_category_id'
              placeholder='Program'
              defaultValue={queryParams.course_category_id ?? ''}
              showSearch
              classNameHeight='sapp-h-40'
            >
              {newCourseCategory?.map((category) => (
                <Option key={category.value} value={category.value}>
                  {category.label}
                </Option>
              ))}
            </HookFormSelectAntd>
            <HookFormDateTime
              control={control}
              name='fromDate'
              placeholder='From date'
              defaultValue={queryParams?.fromDate}
              isListScreen
            />
            <HookFormDateTime
              control={control}
              name='toDate'
              placeholder='To date'
              defaultValue={queryParams?.toDate}
              isListScreen
            />
          </ListFilterLayout>
        </div>

        <div className='card-header border-0 pt-6'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={onSubmit}
              resetClick={handleReset}
              disabled={loading}
              loading={loading}
            />
          </div>
          <>
            <ButtonIconPrimary
              title='Add Subject'
              iconName='plus'
              size='small'
              onClick={handleOpenCourse}
            />
          </>
        </div>
        {/* start:: table */}
        <TableListSubject
          categoryList={newCourseCategory}
          subjectList={subjectList}
          loading={loading}
          setLoading={setLoading}
          fetchSubject={fetchSubject}
          filterParams={filterParams}
          setOpenCourse={setOpenCourse}
          open={openCourse}
          idItem={idItem}
          setIdItem={setIdItem}
          queryParams={queryParams}
          getParams={getParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
        {/* end:: table */}
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(CourseSubject)
