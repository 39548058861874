import './addVideo.scss'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import React from 'react'
import ButtonIconOnly from 'src/components/base/button/ButtonIconOnly'
import { KTIcon } from 'src/_metronic/helpers'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'

interface IProps {
  onClick: () => void
  data?: any
  handleDelete: () => void
  handleEdit: (e: number) => void
  index: number
}

const AddVideoArea = ({ onClick, data, handleDelete, handleEdit }: IProps) => {
  return (
    <React.Fragment>
      {!data?.quiz_id ? (
        <>
          <div className='sapp-addVideo-area mb-5'></div>
          <div className='d-flex justify-content-end'>
            <ButtonIcon
              customButton='btn btn-light sapp-addVideo-btn'
              title='Upload'
              onClick={onClick}
            >
              <KTIcon iconName='file-up' iconType='outline' className='me-2' />
            </ButtonIcon>
          </div>
          <div style={{ borderBottom: '1px solid #DBDFE9', marginTop: 32, marginBottom: 32 }}></div>
        </>
      ) : (
        <>
          <div className='sapp-addVideo-area video-selected mb-6 sapp-video-selected-container'>
            <div className='sapp-video-uploaded-container'>
              <video width={'100%'} height={'100%'} controls>
                <source src={data?.sub_url} />
              </video>
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <div>
              <ButtonSecondary
                onClick={() => handleEdit(0)}
                // className='sapp-btn-color'
                // disabled={loadingDeleteTab}
                title='Edit Timeline'
                size='small'
              />
              <ButtonSecondary
                onClick={() => handleEdit(1)}
                // className='sapp-btn-color'
                // disabled={loadingDeleteTab}
                title='Edit Questions'
                size='small'
              />
            </div>
            <div>
              <ButtonIcon
                customButton='btn btn-light sapp-addVideo-btn me-4'
                title='Upload'
                onClick={onClick}
              >
                <KTIcon iconName='file-up' iconType='outline' className='me-2' />
              </ButtonIcon>
              <ButtonIconOnly
                iconName='trash'
                onClick={handleDelete}
                iconType='outline'
                bg={'gray-200'}
                activeColor='danger'
                className='sapp-h-40 sapp-w-40px'
              />
            </div>
          </div>
          <div style={{ borderBottom: '1px solid #DBDFE9', marginBottom: 32, marginTop: 32 }}></div>
        </>
      )}
    </React.Fragment>
  )
}
export default AddVideoArea
