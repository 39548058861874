import React, { Dispatch, SetStateAction, useState } from 'react'
import DeactivateAccount from 'src/components/my-profile/setting-profile/DeactivateAccount'
// import EmailPreferences from 'src/components/my-profile/setting-profile/EmailPreferences'
// import Notifications from 'src/components/my-profile/setting-profile/Notifications'
import { IStudentDetail } from 'src/type/students'
import EditStaffProfile from './EditStaffProfile'
import { useParams } from 'react-router-dom'
import { StaffAPI } from 'src/apis/staffs'
import toast from 'react-hot-toast'
import { useUserContext } from 'src/context/UserProvider'

interface IProps {
  staffDetail: IStudentDetail | undefined
  setStaffDetail: Dispatch<SetStateAction<any>>
  // setLoading: Dispatch<SetStateAction<boolean>>
  // loading: boolean
}

const SettingStaff = ({ staffDetail, setStaffDetail }: IProps) => {
  const { id } = useParams()
  const [confirmDeactive, setConfirmDeactive] = useState(false)
  const { getStaffDetail } = useUserContext()

  const blockedStaff = async () => {
    await StaffAPI.blocked([id as string]).then(() => toast.success('Blocked Successfully!'))
    await getStaffDetail(id).then((res) => setStaffDetail(res?.data))
  }

  return (
    <>
      <EditStaffProfile staffDetail={staffDetail} setStaffDetail={setStaffDetail} />
      {/* <ConnectAccount /> */}

      {/* <EmailPreferences />

      <Notifications /> */}

      <DeactivateAccount
        confirmDeactive={confirmDeactive}
        setConfirmDeactive={setConfirmDeactive}
        blockedUser={blockedStaff}
      />
    </>
  )
}

export default SettingStaff
