import {fetcher} from 'src/services/request'
import {IResponse} from 'src/type'
import {INotificationReq, INotificationRes} from 'src/type/notification'

export class NotificationAPI {
  static addNotificationGroup(name: string, users: string[]): Promise<IResponse<any>> {
    return fetcher('groups', {
      method: 'POST',
      data: {
        name,
        users,
      },
    })
  }

  static updateNotificationGroup(
    id: string,
    name: string,
    users: string[]
  ): Promise<IResponse<any>> {
    return fetcher(`groups/${id}`, {
      method: 'PUT',
      data: {
        name,
        users,
      },
    })
  }

  static getNotificationGroups(
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponse<any>> {
    return fetcher(`groups?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }

  static getNotificationSelectGroups(
    id: string | number,
    page_index: number,
    page_size: number,
    params?: Object
  ): Promise<IResponse<any>> {
    return fetcher(
      `notifications/${id}/select-groups?page_index=${page_index}&page_size=${page_size}`,
      {
        params: params,
      }
    )
  }

  static deleteNotificationGroup(id: string): Promise<IResponse<any>> {
    return fetcher(`groups/${id}`, {
      method: 'DELETE',
    })
  }

  static deleteUserGroup(users: string[], groupId: string | undefined): Promise<IResponse<any>> {
    return fetcher('groups/users', {
      method: 'DELETE',
      data: {
        users,
        groupId,
      },
    })
  }

  static detailNotificationGroup(id: string): Promise<IResponse<any>> {
    return fetcher(`groups/${id}`)
  }

  static addStudentGroup(users: Array<any>, groupId: string | undefined): Promise<IResponse<any>> {
    return fetcher('groups/users', {
      method: 'POST',
      data: {
        groupId,
        users,
      },
    })
  }

  static createNotification(data: INotificationReq): Promise<IResponse<any>> {
    return fetcher('notifications', {
      method: 'POST',
      data,
    })
  }
  static updateNotification(id: string, data: Partial<INotificationReq>): Promise<IResponse<any>> {
    return fetcher(`notifications/${id}`, {
      method: 'PUT',
      data,
    })
  }
  static getNotifications({
    page_index,
    page_size,
    params,
  }: {
    page_index: number
    page_size: number
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`notifications?page_index=${page_index}&page_size=${page_size}`, {
      params: params,
    })
  }
  static getNotificationById(id: string): Promise<IResponse<INotificationRes>> {
    return fetcher(`notifications/${id}`)
  }
}
