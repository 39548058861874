import { VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'

export const exhibitValidation = z
  .object({
    exhibit_name: z
      .string({ required_error: VALIDATION_FIELD })
      .trim()
      .min(1, VALIDATION_FIELD)
      .max(1000, VALIDATE_FIELD_MAX_LENGTH('Name', 1000)),
    exhibit_type: z.string({ required_error: VALIDATION_FIELD }).trim().min(1, VALIDATION_FIELD),
    exhibit_description: z.string().trim().optional(),
    exhibit_files: z.array(z.any().optional()).default([]),
  })
  .superRefine((arg: any, ctx) => {
    if (arg.exhibit_type === 'TEXT' && !arg.exhibit_description) {
      ctx.addIssue({
        message: VALIDATION_FIELD,
        code: 'custom',
        path: ['exhibit_description'],
      })
    }
    if (arg.exhibit_type === 'FILE' && arg.exhibit_files && arg.exhibit_files?.length <= 0) {
      ctx.addIssue({
        message: VALIDATION_FIELD,
        code: 'custom',
        path: ['exhibit_files'],
      })
    }
  })
