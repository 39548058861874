interface IHeader {
  label: string
  className?: string
}

export const headers: IHeader[] = [
  {
    label: 'Name',
    className: 'min-w-150px',
  },
  {
    label: 'Email',
  },
  {
    label: 'Phone',
  },
  {
    label: 'Class',
    className: 'min-w-150px',
  },
  {
    label: 'Level',
    className: 'text-center min-w-100px',
  },
  {
    label: 'Registed Exam Date',
    className: 'text-center min-w-100px',
  },
  {
    label: 'Defer',
    className: 'text-center min-w-100px',
  },
  {
    label: 'Reason',
  },
]
