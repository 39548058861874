import {z} from 'zod'

export const metaValidationSchema = z
  .object({
    index: z.boolean().optional(),
    no_index: z.boolean().optional(),
    no_image_index: z.boolean().optional(),
    no_follow: z.boolean().optional(),
    no_archive: z.oboolean().optional(),
    no_snippet: z.boolean().optional(),
    max_snippet: z.string().optional(),
    max_video_preview: z.string().optional(),
    max_image_preview: z.string().optional(),
    active_image_preview: z.boolean().optional(),
    active_snippet: z.boolean().optional(),
    active_video_preview: z.boolean().optional(),
  })
  .optional()

export const hanleTransferMedia = (val: string | File) => {
  if (val && typeof val !== 'string') {
    return URL.createObjectURL(val)
  }
  return val
}
