import { fetcher } from 'src/services/request'
import { ExamListResponse, IExamDetail, IExamPost, IResponse } from 'src/type'

export class ExamAPI {
  //#region Exams
  static getList({ params }: { params?: Object }): Promise<IResponse<ExamListResponse>> {
    return fetcher(`/examination`, {
      params: params,
    })
  }

  static getDetail({ id }: { id: string }): Promise<IResponse<IExamDetail>> {
    return fetcher(`/examination/${id}`, {
      method: 'GET',
    })
  }

  static create(data: any): Promise<IResponse<any>> {
    return fetcher('examination', {
      method: 'POST',
      data: data,
    })
  }

  static edit({ id, data }: { id: string; data: IExamPost }): Promise<IResponse<any>> {
    return fetcher(`/examination/${id}`, {
      method: 'PUT',
      data: data,
    })
  }

  static delete(exam_ids: string[]): Promise<IResponse<any>> {
    return fetcher('examination', {
      method: 'DELETE',
      data: {
        exam_ids,
      },
    })
  }

  //#endregion

  //#region Student Exams (Quản lý kỳ thi học viên)
  static getExamStudents({
    exam_id,
    params,
  }: {
    exam_id: string
    params?: Object
  }): Promise<IResponse<any>> {
    return fetcher(`/examination/${exam_id}/students`, {
      method: 'GET',
      params: params,
    })
  }

  static markRegisteredExamDates({
    exam_id,
    data,
  }: {
    exam_id: string
    data: {
      class_user_ids: string[]
    }
  }): Promise<IResponse<any>> {
    return fetcher(`/examination/${exam_id}/students/exam-subject`, {
      method: 'PUT',
      data: data,
    })
  }

  //#endregion
}
