import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import AddMore from 'src/common/AddMore'
import SappLabel from 'src/components/base/label/SappLabel'
import CreateAndUpdateSpeaker from './CreateAndUpdateSpeaker'
import ShopActionBox from '../shared/ShopActionBox'
import {INewOrUpdatePopup} from 'src/type/shop/common'
import {ShopSpeakerAPI} from 'src/apis/short-course/speaker'
import {ISpeaker} from 'src/type/shop/speaker'

const SpeakerContent = ({confirm}: {confirm: (option: any) => void}) => {
  const [openAddSpeaker, setOpenSpeaker] = useState<INewOrUpdatePopup>({
    isOpen: false,
    data: null,
    type: 'create',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [speakerData, setSpeakerData] = useState<ISpeaker[]>()
  const {id} = useParams()

  const loadData = async () => {
    if (id) {
      const res = await ShopSpeakerAPI.getSpeakers(id)
      setSpeakerData(res.data)
    }
  }
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => setOpenSpeaker({isOpen: false, data: null, type: 'create'}),
    })
  }

  const handleDelete = async (id: string) => {
    try {
      await ShopSpeakerAPI.deleteSpeaker(id)
      loadData()
    } catch (error) {
      // do nothing
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  const handleClose = () => {
    loadData()
    setOpenSpeaker({isOpen: false, data: null, type: 'create'})
  }

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <SappLabel label='Speakers' isLabel />
        {speakerData?.map((data) => (
          <>
            <div className='d-flex justify-content-between px-5'>
              <div>
                <div
                  className='d-flex align-items-center cursor-pointer'
                  onClick={() => {
                    setOpenSpeaker({isOpen: true, data, type: 'edit'})
                  }}
                >
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-4'>
                    <div className='symbol-label'>
                      <img
                        src={data.avatar?.ORIGIN}
                        alt='speaker'
                        className='w-100 h-100 object-fit-cover'
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='text-gray-800 fw-semibold text-hover-primary mb-1'>
                      {data.name}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ShopActionBox
                  id={data?.id}
                  setLoading={setIsLoading}
                  reloadPage={loadData}
                  onDelete={handleDelete}
                  onEdit={() => {
                    setOpenSpeaker({isOpen: true, data, type: 'edit'})
                  }}
                />
              </div>
            </div>
            <div className='my-5' style={{border: '1px dashed #DBDFE9'}} />
          </>
        ))}
        <AddMore addNew={() => setOpenSpeaker({isOpen: true, data: null, type: 'create'})} />
        <CreateAndUpdateSpeaker
          open={openAddSpeaker.isOpen}
          data={openAddSpeaker.data}
          type={openAddSpeaker.type}
          loading={isLoading}
          setLoading={setIsLoading}
          onCancel={hanleCancel}
          onClose={handleClose}
          fetchData={loadData}
        />
      </div>
    </div>
  )
}

export default SpeakerContent
