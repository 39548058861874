import React, { Dispatch, SetStateAction } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { MY_PROFILE } from 'src/constants'
import { IStudentDetail } from 'src/type/students'
// import ActivityProfile from '../my-profile/activity-profile'
// import BillingProfile from '../my-profile/billing-profile'
// import LogsProfile from '../my-profile/logs-profile'
// import SecurityProfile from '../my-profile/security-profile'
// import StatementsProfile from '../my-profile/stament-profile'
import OverviewStudentProfile from './student-overview'
import SettingStudent from './student-setting'
// import CourseList from '../my-profile/CourseList'
import SecurityStudent from './student-security'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'
import ClassUserManagement from '../class-user-management'

interface IProps {
  studentDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setStudentDetail: Dispatch<SetStateAction<any>>
}

const RenderStudentProfile = ({ studentDetail, setLoading, loading, setStudentDetail }: IProps) => {
  const location = useLocation()
  const { id } = useParams()
  const { profileMe } = useUserContext()

  const hasPermission = (roles: Role[] | undefined, permission: string): boolean =>
    roles?.some(
      (role) => role.permissions?.includes(permission) || role.code === CODE_ADMIN.SUPER_ADMIN
    ) || false

  const allowRenderEdit = hasPermission(profileMe?.roles, TITLE_GR.EDIT_USER_GR)

  const renderProfile = () => {
    switch (location.pathname.split(`/${id}/`)[1]) {
      case MY_PROFILE.OVERVIEW:
        return (
          <OverviewStudentProfile
            studentDetail={studentDetail}
            loading={loading}
            setStudentDetail={setStudentDetail}
          />
        )
      case MY_PROFILE.SETTING:
        return (
          <SettingStudent
            studentDetail={studentDetail}
            setLoading={setLoading}
            loading={loading}
            setStudentDetail={setStudentDetail}
          />
        )
      case MY_PROFILE.CLASS:
        return <ClassUserManagement id={id!} />
      case MY_PROFILE.SECURITY:
        return (
          <SecurityStudent
            studentDetail={studentDetail}
            setLoading={setLoading}
            loading={loading}
            setStudentDetail={setStudentDetail}
          />
        )
      // case MY_PROFILE.ACTIVITY:
      //   return <ActivityProfile />
      // case MY_PROFILE.BILLING:
      //   return <BillingProfile />
      // case MY_PROFILE.STATEMENTS:
      //   return <StatementsProfile />
      // case MY_PROFILE.LOGS:
      //   return <LogsProfile />
      // case MY_PROFILE.COURSE:
      //   return <CourseList />
      default:
        return (
          <OverviewStudentProfile
            studentDetail={studentDetail}
            loading={loading}
            setStudentDetail={setStudentDetail}
          />
        )
    }
  }
  return <>{renderProfile()}</>
}

export default RenderStudentProfile
