import { PageLink } from 'src/constants'
import ModalImportTeacher from '../teacher-edit-modal/ModalImportTeacher'
import { useEffect, useState } from 'react'
import { UserExportHelper } from 'src/helper/export'
import SAPPActionButton from 'src/common/SAPPActionButton'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context/UserProvider'
import { Role } from 'src/type'
import { CODE_ADMIN, TITLE_GR } from 'src/constants/permission'

interface IProps {
  searchTerm: string | null
  sortSex: string | null
  sortStatus: string | null
  fromDate: Date | null
  toDate: Date | null
  course_category_id: string | null
  subject_id: string | null
  location?: any
}

const TeachersListToolbar = ({
  searchTerm,
  sortSex,
  sortStatus,
  fromDate,
  toDate,
  course_category_id,
  subject_id,
  location,
}: IProps) => {
  const [openImport, setOpenImport] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const exportTeacher = async () => {
    setLoading(true)
    try {
      await UserExportHelper.exportToXlsxTeacher(
        sortSex,
        searchTerm,
        sortStatus,
        fromDate,
        toDate,
        course_category_id,
        subject_id
      )
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }
  const [isLocation, setIsLocation] = useState(false)

  useEffect(() => {
    setIsLocation(location.pathname)
  }, [location.pathname])
  const handleAddTeacher = () => {
    navigate(PageLink.CREATE_TEACHERS)
  }

  const { profileMe } = useUserContext()

  const allowRenderImport = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_GR.CREATE_USER_GR) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  return (
    <div className='sapp-height-list--grouping'>
      <SAPPActionButton
        link={PageLink.CREATE_TEACHERS}
        onClick={exportTeacher}
        setOpen={setOpenImport}
        onClickAdd={handleAddTeacher}
        titleAdd='Create'
        loading={loading}
        location={isLocation}
        allowRenderImport={allowRenderImport}
      />
      <ModalImportTeacher open={openImport} setOpen={setOpenImport} />
    </div>
  )
}

export { TeachersListToolbar }
