import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { format } from 'date-fns'
import { debounce } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { RolesAPI } from 'src/apis/roles'
import LoadingTable from 'src/common/LoadingTable'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import SappTable from 'src/components/base/SappTable'
import AssignedTo from 'src/components/base/assigned'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { FILTER_SELECTALL_SORTBY, STAFF_PROFILE } from 'src/constants'
import { ISelect } from 'src/type/common'
import { IRoleRes } from 'src/type/staffs.'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'

const { Option } = Select

interface IProps {
  userState: any
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  fetchListUsers: (page_index?: number, page_size?: number, params?: Object) => void
  getParams: any
  queryParams: any
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
  id?: string
  roleData?: any
}

// define headers
const headers = [
  {
    label: 'CODE',
    className: 'min-w-150px',
  },
  {
    label: 'NAME',
    className: 'min-w-200px',
  },
  {
    label: 'EMAIL',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'PHONE',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'ROLE',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const TableUsers = ({
  userState,
  loading,
  setLoading,
  fetchListUsers,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  id,
  roleData,
}: IProps) => {
  const initialValues: any = {
    text: '',
    role_code: '',
    status: 'ACTIVE',
    sortType: '',
    fromDate: '',
    toDate: '',
    condition_type: 'diffirence',
    dateField: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)
  const [roles, setRoles] = useState<IRoleRes>()
  const rolesNew = roles?.roles?.map((role) => ({ label: role?.name, value: role?.code }))

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params
  const dateQueryFromDate = getDateInfo(searchParams.fromDate)
  const dateQueryToDate = getDateInfo(searchParams.toDate)

  const showSearchParams =
    searchParams.text ||
    searchParams.role_code ||
    searchParams.status ||
    searchParams.sortType ||
    searchParams.fromDate ||
    searchParams.toDate

  const validationSchema = z.object({
    text: z.string().optional(),
    role_code: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
    condition_type: z.string().optional(),
  })
  const fieldNames = ['text', 'role_code', 'status', 'sortType']

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm<any>({
    resolver: zodResolver(validationSchema),
    mode: 'onChange',
  })

  const fetchRoles = async (page_index: number, page_size: number, params?: Object) => {
    try {
      const res = await RolesAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const handlNextPageRole = async (params: Object) => {
    const totalPages = roles?.metadata?.total_pages
    const pageIndex = roles?.metadata?.page_index as number
    const pageSize = roles?.metadata?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      const res: any = await fetchRoles(pageIndex + 1, pageSize, params)
      const results = roles?.roles?.concat(res?.data?.roles)
      setRoles({
        metadata: res?.data?.metadata,
        roles: results,
      })
    }
  }

  const getRoles = async (params?: Object) => {
    const res: any = await fetchRoles(1, 10, params)
    const results = res?.data?.roles
    setRoles({
      metadata: res?.data?.metadata,
      roles: results,
    })
  }

  const debounceSearchMentor = debounce((e: any) => {
    handlNextPageRole({ params: { name: e } })
  }, 500)

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.role_code,
        searchParams?.status,
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        searchParams.condition_type,
        showSearchParams ? 'updated_at' : 'created_at'
      )
    )
    fetchListUsers(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    setLoading(true)

    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.role_code,
        searchParams?.status,
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
          : '',
        searchParams.condition_type
      )
    )
    fetchListUsers(1, 10, cleanedParams)
    getRoles()
  }, [])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    const cleanedParams = cleanParamsAPI(
      getParams('', '', searchParams?.status, '', '', '', searchParams.condition_type, 'created_at')
    )
    setSearchParams(initialValues)
    setLoading(true)
    fetchListUsers(1, 10, cleanedParams)
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trim()),
        replaceValueAll(getValues('role_code')),
        replaceValueAll(getValues('status')),
        replaceValueAll(getValues('sortType')),
        getValues('fromDate')
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('toDate')
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'diffirence',
        'updated_at'
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    fetchListUsers(1, queryParams?.page_size || 10, { ...cleanedParams })
  }

  return (
    <div className='sapp-role-table-user card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search'
                  defaultValue={queryParams?.text}
                  className='sapp-fs-13'
                  onSubmit={onSubmit}
                />
              </div>
            </div>
            {/* end:: Search */}

            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                control={control}
                name='role_code'
                placeholder='Role'
                size='large'
                showSearch
                defaultValue={queryParams.role_code ?? ''}
                className='sapp-fs-select sapp-fs-13'
                onSearch={(e: any) => {
                  if (e === undefined) {
                    return
                  }
                  debounceSearchMentor(e)
                }}
                handleNextPage={(e: any) => handlNextPageRole({ params: { name: e } })}
              >
                {rolesNew &&
                  rolesNew
                    .filter((role: ISelect) => role.label !== roleData?.name)
                    .map((gender: ISelect) => (
                      <Option key={gender.value} value={gender.value}>
                        {gender.label}
                      </Option>
                    ))}
              </HookFormSelectAntd>
            </div>

            <div className='col-xl-3 col-sm-4 mt-2 d-none'>
              <HookFormSelectAntd
                size='large'
                control={control}
                name='status'
                placeholder='Status'
                defaultValue={'ACTIVE'}
                className='sapp-fs-select sapp-fs-13'
              >
                <Option key={'Active'} value={'ACTIVE'}>
                  Active
                </Option>
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                size='large'
                defaultValue={queryParams?.sortType ?? ''}
                className='sapp-fs-select sapp-fs-13'
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2 '>
              <HookFormDateTime
                control={control}
                name='fromDate'
                placeholder='From date'
                defaultValue={queryParams?.fromDate}
              />
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormDateTime
                control={control}
                name='toDate'
                placeholder='To date'
                defaultValue={queryParams?.toDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
                classNameCancel={'sapp-fs-13 btn-sapp-filter me-3 btn btn-primary'}
                classNameSubmit={'sapp-fs-13'}
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={userState?.staffs}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {userState?.staffs?.map((user: any, index: number) => {
              const isChecked = checkedList.includes(user?.id)

              return (
                <tr key={user?.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(user?.id!)
                      }}
                    />
                  </td>
                  <td className='text-start'>{user?.key}</td>
                  <td className='text-start'>
                    <Link
                      className='sapp-text-truncate-2 sapp-table-title-des text-wrap sapp-cursor-pointer text-hover-primary text-break'
                      to={`${STAFF_PROFILE}/${user?.id}/overview`}
                    >
                      {user?.detail?.full_name}
                    </Link>
                  </td>
                  <td className='text-start'>{user?.detail?.email}</td>
                  <td className='text-start'>{user?.detail?.phone}</td>
                  <td className='text-start'>
                    <AssignedTo roles={user?.roles} />
                  </td>
                  <td className='text-start'>
                    <div className='text-nowrap'>
                      Created: {format(new Date(user?.detail?.created_at), 'dd/MM/yyyy HH:mm')}
                    </div>
                    <div className='text-nowrap'>
                      Updated: {format(new Date(user?.detail?.updated_at), 'dd/MM/yyyy HH:mm')}
                    </div>
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={userState?.metadata?.page_index || 1}
        pageSize={userState?.metadata?.page_size || 10}
        totalItems={userState?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableUsers
