import React from 'react'
import { Select } from 'antd'
import { Control } from 'react-hook-form'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { QUIZ_ATTEMPT_STATUS_AUTO, QUIZ_ATTEMPT_STATUS_MANUAL } from 'src/constants'
import { useParams } from 'react-router-dom'
import useCourses from './use-get-mentor'

const { Option } = Select

interface IProps {
  control: Control
  fetchTestQuiz: (page_index: number, page_size: number, params?: Object) => void
  loading: boolean
  handleReset: () => void
  filterTestQuiz: Object
  quiz_id: { id: string; name: string; grading_method: string }
}

const HeaderTestQuizDetail = ({
  control,
  fetchTestQuiz,
  loading,
  handleReset,
  filterTestQuiz,
  quiz_id,
}: IProps) => {
  const onSubmit = () => {
    fetchTestQuiz(1, 10, filterTestQuiz)
  }
  const { id } = useParams()

  const { mentors, handleNextPageMentor, getMentors, debounceSearchCourse } = useCourses(id)

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between align-items-center gap-5 flex-wrap'>
        <div className='card-title my-0'>
          <div className='row'>
            <div className='col'>
              <div className='w-200px'>
                <HookFormSelectAntd
                  control={control}
                  name='status'
                  classNameHeight='sapp-h-40'
                  placeholder='Status'
                  allowClear
                >
                  {(quiz_id?.grading_method === 'MANUAL'
                    ? QUIZ_ATTEMPT_STATUS_MANUAL
                    : QUIZ_ATTEMPT_STATUS_AUTO
                  ).map((key) => (
                    <Option key={key.value} value={key.value}>
                      {key?.label}
                    </Option>
                  ))}
                </HookFormSelectAntd>
              </div>
            </div>
            {quiz_id.grading_method === 'MANUAL' && (
              <div className='col'>
                <div className='w-200px'>
                  <HookFormSelectAntd
                    control={control}
                    name='staff_id'
                    classNameHeight='sapp-h-40'
                    placeholder='Người chấm'
                    allowClear
                    onSearch={(e: any) => {
                      if (e === undefined) {
                        return
                      }
                      debounceSearchCourse(e)
                    }}
                    handleNextPage={(e: any) => handleNextPageMentor({ params: { name: e } })}
                    onFocus={async () => {
                      await getMentors({})
                    }}
                    showSearch
                  >
                    {mentors?.data?.map((course: any) => (
                      <Select.Option key={course?.mentor?.id} value={course?.mentor?.id}>
                        {course?.mentor?.detail?.full_name}
                      </Select.Option>
                    ))}
                  </HookFormSelectAntd>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='d-flex mt-4'>
        <SAPPFIlterButton
          titleReset='Reset'
          titleSubmit='Search'
          okClick={onSubmit}
          resetClick={handleReset}
          disabled={loading}
          loading={loading}
        />
      </div>
    </React.Fragment>
  )
}

export default HeaderTestQuizDetail
