import React, { useEffect } from 'react'
import './Tab.scss'
import TabContent from './tab/tabContent'
import { CourseCondition } from 'src/type/courses'
interface IProps {
  data: {
    tabId: string
    value?: any
    created?: boolean
    edit?: boolean
    creating?: boolean
  }[]
  setTabActive: React.Dispatch<React.SetStateAction<string>>
  tabActive: string
  setData: any
  handleAddTab: any
  closeModal: any
  course_section_id: string
  edit?: boolean
  isFistLoad?: boolean
  courseCondition: CourseCondition
}
const Tab = ({
  data,
  setTabActive,
  tabActive,
  setData,
  handleAddTab,
  closeModal,
  course_section_id,
  isFistLoad,
  courseCondition,
}: IProps) => {
  useEffect(() => {
    if (isFistLoad) {
      handleAddTab()
    } else {
      setTabActive(data[0]?.tabId || '')
    }
  }, [isFistLoad])
  return (
    <>
      <div className='tab-content' id='myTabContent'>
        {data.map((e, i) => {
          return (
            <TabContent
              closeModal={closeModal}
              course_section_id={course_section_id}
              data={data}
              tabIndex={i}
              tabData={e}
              setData={setData}
              setTabActive={setTabActive}
              tabActive={tabActive}
              courseCondition={courseCondition}
              key={i}
            />
          )
        })}
      </div>
    </>
  )
}
export default Tab
