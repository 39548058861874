import { Select } from 'antd'
import React, { useEffect } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import useSubjects from 'src/hooks/useSubjects'
import { ISubject } from 'src/type/subject'
import HookFormSelectAntd from './HookFormSelectAntd'
import { PROGRAM } from 'src/type'

interface ISelectSubject<T extends FieldValues> {
  control: Control<T>
  isListLoading?: boolean
  filters: T
  program?: PROGRAM
}

const HookFormSelectSubject = <T extends FieldValues>({
  control,
  isListLoading,
  filters,
  program,
}: ISelectSubject<T>) => {
  const { subjects, handleNextPageSubject, debounceSearchSubject, isLoading } = useSubjects() // Use the hook

  const subjectCourse = subjects?.subjects?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  const { Option } = Select

  useEffect(() => {
    debounceSearchSubject({ category_name: program })
  }, [program])

  return (
    <HookFormSelectAntd
      control={control}
      name='subject_id'
      placeholder='Subject'
      allowClear
      handleNextPage={(e: any) =>
        handleNextPageSubject({
          name: e,
        })
      }
      showSearch
      loading={isListLoading || isLoading}
      defaultValue={filters?.subject_id}
    >
      {subjectCourse?.map(
        (subject: {
          value: string
          label:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined
        }) => (
          <Option key={subject.value} value={subject.value}>
            {subject.label}
          </Option>
        )
      )}
    </HookFormSelectAntd>
  )
}

export default HookFormSelectSubject
