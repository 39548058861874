import React from 'react'
import CertificatesTemplate from 'src/components/certificate/CertificatesTemplate'

const CertificatesTemplatePage = () => {
  return (
    <div className='bg-white'>
      <CertificatesTemplate />
    </div>
  )
}

export default CertificatesTemplatePage
