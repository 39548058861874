import { Select } from 'antd'
import clsx from 'clsx'
import { debounce, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useParams } from 'react-router-dom'
import avatarDefault from 'src/_metronic/assets/media/avatars/blank.png'
import ErrorMessage from 'src/common/ErrorMessage'
import { DownIcon } from 'src/common/icons'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import SAPPHookUploadFile from 'src/components/base/file/SAPPHookUploadFile'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import {
  ACCEPT_UPLOAD_MIME_TYPE,
  DEFAULT_MAX_FILE_SIZE,
  GENDER_FORM,
  GUIDELINE_EMAIL,
  GUIDELINE_FULLNAME,
  GUIDELINE_PASSWORD,
  GUIDELINE_PHONE,
  GUIDELINE_USERNAME,
  STATUS_FORM,
} from 'src/constants'
import { LANG_PLACEHOLDER } from 'src/constants/lang'
import { teachingStatusArray } from 'src/constants/teacher'
import useStaffs from 'src/hooks/useStaffs'
import { EStatusUser } from 'src/type'
import { IExpandInfo } from 'src/type/students'
import { ITeacher, TeacherTeachableInstanceForm } from 'src/type/teacher'
import Subjects from './Subjects'
import styles from './TeacherPostForm.module.scss'

const { Option } = Select

export interface IUpdateTeacher extends IExpandInfo {
  email: string
  full_name: string
  phone: number
  username: string
  status: string | undefined
  sex: string | null
  avatar?: any
  teacher_teachable_instances?: TeacherTeachableInstanceForm[]
  category_primary_id?: string
}
interface IteacherPostForm {
  control: Control<ITeacher, any>
  setValue: UseFormSetValue<ITeacher>
  setError: UseFormSetError<ITeacher>
  errors: FieldErrors<ITeacher>
  watch: UseFormWatch<ITeacher>
  uploadSuccess?: boolean
}

interface SelectOption {
  name: string
  value: string
}

const TeacherPostForm = ({
  control,
  setValue,
  setError,
  errors,
  watch,
  uploadSuccess,
}: IteacherPostForm) => {
  const [text, setText] = useState<string | undefined>(undefined)
  const { id } = useParams()
  const { staffs, hasNextPage, fetchNextPage, refetch } = useStaffs({
    status: EStatusUser.ACTIVE,
    text: text,
  })

  const debounceSearchStaff = debounce((e) => {
    setText(e)
  }, 300)

  useEffect(() => {
    refetch()
  }, [text])

  return (
    <div className='form d-flex flex-column flex-lg-row position-relative'>
      <div className={clsx('d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10')}>
        <div className={clsx('card card-flush sapp-pt--image', styles.avatar)}>
          <div className='card-header'>
            <div className='card-title'>
              <h2>Avatar</h2>
            </div>
          </div>

          <div className='card-body text-center pt-0'>
            <SAPPHookUploadFile
              name='avatar'
              control={control}
              setValue={setValue}
              setError={setError}
              imagePreview={avatarDefault}
              accept={ACCEPT_UPLOAD_MIME_TYPE}
              maxFileSize={DEFAULT_MAX_FILE_SIZE}
              uploadSuccess={uploadSuccess}
              removeAvatar={avatarDefault}
            />
          </div>
        </div>

        {id && (
          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>Status</h2>
              </div>
            </div>

            <div className='card-body pt-0'>
              <HookFormSelectAntd
                size='large'
                name='status'
                control={control}
                dropdownStyle={{ minWidth: 'fit-content' }}
                placeholder='Status'
                filterOption={true}
                className='fs-6'
              >
                {STATUS_FORM.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
          </div>
        )}
      </div>

      <div>
        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_ecommerce_add_product_general'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                <div className='card card-flush py-4 pt-10'>
                  <div className='card-body pt-0'>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='full_name'
                        label={LANG_PLACEHOLDER.FULLNAME}
                        required
                        guideline={GUIDELINE_FULLNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='username'
                        label='Username'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_USERNAME}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField
                        control={control}
                        name='email'
                        label='Email'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_EMAIL}
                      />
                    </div>
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='password'
                          label='Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}
                    {!id && (
                      <div className='mb-10 fv-row'>
                        <HookFormTextField
                          control={control}
                          name='confirmPassword'
                          label='Confirm Password'
                          type='password'
                          required
                          guideline={GUIDELINE_PASSWORD}
                        />
                      </div>
                    )}
                    <div className={`${!id ? '' : 'mb-10'} fv-row`}>
                      <HookFormTextField
                        control={control}
                        name='phone'
                        label='Phone Number'
                        required
                        disabled={!isEmpty(id)}
                        guideline={GUIDELINE_PHONE}
                      />
                    </div>
                    <div className={`fv-row mt-10 mb-10`}>
                      <HookFormSelectAntd
                        name='sex'
                        control={control}
                        dropdownStyle={{ minWidth: 'fit-content' }}
                        placeholder='Please select'
                        label='Gender'
                        classNameHeight='sapp-h-45px'
                      >
                        {GENDER_FORM.map((gender) => (
                          <Option key={gender.label} value={gender.value}>
                            {gender.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='job_title' label='Job Title' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormSelectAntd
                        size='large'
                        control={control}
                        name='teacher_status'
                        label='Teaching Status'
                        required
                        showSearch
                        classNameHeight='sapp-h-40'
                      >
                        {teachingStatusArray.map((status) => (
                          <Option key={status.value} value={status.value}>
                            {status.label}
                          </Option>
                        ))}
                      </HookFormSelectAntd>
                    </div>
                    <div className='mb-10 fv-row'>
                      <SappLabel label={'Person in Charge'} required={true} />
                      <Controller
                        control={control}
                        name={'staff_ids'}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Select
                              {...field}
                              placeholder={'Person in Charge'}
                              style={{ width: '100%' }}
                              dropdownStyle={{ zIndex: '2000' }}
                              mode='multiple'
                              size='large'
                              className={`sapp-hook-form-select hookform-multi-select`}
                              showSearch
                              status={error ? 'error' : ''}
                              onSearch={(e: string) => {
                                debounceSearchStaff(e)
                              }}
                              filterOption={(input, option) => {
                                return (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }}
                              onBlur={() => setText(undefined)}
                              onPopupScroll={(e) => {
                                const { target } = e
                                if (
                                  (target as any).scrollTop + (target as any).offsetHeight ===
                                  (target as any).scrollHeight
                                ) {
                                  hasNextPage && fetchNextPage()
                                }
                              }}
                              suffixIcon={<DownIcon />}
                              options={
                                staffs
                                  ? staffs?.map((staff) => {
                                      return {
                                        value: staff.id,
                                        label: staff.username,
                                      }
                                    })
                                  : staffs
                              }
                            />

                            <ErrorMessage>{error?.message}</ErrorMessage>
                          </>
                        )}
                      />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='facebook' label='Facebook' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='linkedin' label='LinkedIn' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormTextField control={control} name='youtube' label='YouTube' />
                    </div>
                    <div className='mb-10 fv-row'>
                      <HookFormEditor
                        control={control}
                        name='describe'
                        placeholder=''
                        label='Describe'
                        resourceLocation={RESOURCE_LOCATION.USER}
                        object_id={''}
                        setDataFile={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errors?.category_primary_id?.message && (
          <p className='text-danger my-7 text-right'>{errors?.category_primary_id?.message}</p>
        )}
        <div className='mt-7'>
          <SappLabel label={'Teaching Program'} required={true} className='mb-0' />
          <Subjects control={control} setValue={setValue} setError={setError} watch={watch} />
        </div>
      </div>
    </div>
  )
}

export default TeacherPostForm
