import { FC } from 'react'

type Props = {
  status?: 'DRAFT' | 'PUBLIC' | 'BLOCKED' | 'ENDED' | 'CLOSE' | string | undefined
  className?: string
}

const ClassStatusCell: FC<Props> = ({ status, className }) => {
  const getStyle = () => {
    switch (status) {
      case 'active':
      case 'public':
        return 'public'
      case 'blocked':
      case 'close':
        return 'blocked'
      case 'ended':
        return 'ended'
    }
  }

  return (
    <div
      className={`sapp-class-icon sapp-class-icon-${getStyle()} f-flex justify-content-center ${
        className ? `${className}` : 'min-w-44px'
      }`}
    >
      {status === 'public'
        ? 'Public'
        : status === 'blocked'
        ? 'Blocked'
        : status === 'ended'
        ? 'Ended'
        : status === 'close'
        ? 'Close'
        : status}
    </div>
  )
}

export default ClassStatusCell
