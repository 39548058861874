export const TITLE_OPTIONS_ENTRANCE = {
  listEntrance: 'Entrance Test List',
  createEntranceTest: 'Create Entrance Test',
  EntranceDetail: 'Entrance Test Detail',
  testname: 'Test Name',
  quiz_timed: 'Duration',
  listsection: 'List Sections',
  describe: 'Describe',
  reason: 'Block Reason',
  course_category_id: 'Program',
  subject: 'Subject',
  detail: 'Detail',
  settingCategory: 'Cài đặt các Section cùng Question Category',
  participantList: 'Participant List',
}
export const LIST_SECTION_OPTION = {
  name: 'Section Name',
  question_category_id: 'Question Type',
  pass_point: 'Pass Point',
  pass_note: 'Nhận xét Pass',
  failure_note: 'Nhận xét không pass',
}

export const OPTIONS_OF_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Locked',
    value: 'LOCKED',
  },
  {
    label: 'Deactive',
    value: 'DEACTIVE',
  },
]

export const OPTIONS_OF_STATUS_DRAFT = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
]

export const OPTIONS_OF_STATUS_DEACTIVE = [
  {
    label: 'Deactive',
    value: 'DEACTIVE',
  },
]

export const OPTIONS_OF_STATUS_ACTIVE = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
]
