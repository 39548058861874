import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import LoadingTable from 'src/common/LoadingTable'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { useForm } from 'react-hook-form'
import { Select } from 'antd'
import { cleanParamsAPI, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import DatetimeColumn from 'src/components/base/DatetimeColumn'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { replaceValueAll } from 'src/utils/string'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import { ITeacher, ITeacherList } from 'src/type/teacher'

const { Option } = Select

interface IProps {
  currentPage?: number
  setCurrentPage?: Dispatch<SetStateAction<number>>
  teacherList: ITeacherList | undefined
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  handleChangeParams?: (currenPage: number, size: number) => void
  fetchListUsers: (currentPage?: number, pageSize?: number, params?: Object) => void
  filterParams: Object
  pageSize?: number
  getParams: (
    text: string,
    sortType: string,
    fromDate?: Date | string,
    toDate?: Date | string,
    dateField?: string
  ) => {
    text: string
    sortType: string
    fromDate?: Date | string
    toDate?: Date | string
    dateField?: string
  }
  queryParams: {
    text: string
    sortType: string
    fromDate: string
    toDate: string
    page_index: number
    page_size: number
  }
  checkedList: (string | undefined)[]
  toggleCheck: (id: string) => void
  toggleCheckAll: (isCheckedAll: boolean, memorize?: boolean) => void
  isCheckedAll: boolean
  open: { isOpen: boolean; sectionId: string }
}

// define headers
const headers = [
  {
    label: 'TEACHER NAME',
    className: 'min-w-200px',
  },
  {
    label: 'EMAIL',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'PHONE',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'DATE',
    className: 'min-w-100px pe-5',
  },
]

const TableTeacher = ({
  teacherList,
  loading,
  setLoading,
  // filterParams,
  fetchListUsers,
  getParams,
  queryParams,
  checkedList,
  isCheckedAll,
  toggleCheck,
  toggleCheckAll,
  open,
}: IProps) => {
  const initialValues = {
    text: '',
    sortType: '',
    fromDate: '',
    toDate: '',
    dateField: '',
  }
  const [searchParams, setSearchParams] = useState<any>(initialValues)

  //TODO: biến này sẽ lấy được ngày, tháng, năm của date khi mà có params gửi cho người khác
  const dateQueryFromDate = getDateInfo(searchParams.fromDate)
  const dateQueryToDate = getDateInfo(searchParams.toDate)

  const showSearchParams =
    searchParams.text || searchParams.sortType || searchParams.fromDate || searchParams.toDate

  const validationSchema = z.object({
    text: z.string().optional(),
    sortType: z.string().optional(),
    fromDate: z.any(),
    toDate: z.any(),
  })

  // Using validate for input
  const { control, getValues, reset, setValue } = useForm({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryToDate.year, dateQueryToDate.month, dateQueryToDate.day)
          : '',
        showSearchParams ? 'updated_at' : 'created_at'
      )
    )
    fetchListUsers(page_index, page_size, cleanedParams)
  }
  useEffect(() => {
    setLoading(true)

    const cleanedParams = cleanParamsAPI(
      getParams(
        searchParams?.text?.trim(),
        searchParams?.sortType,
        searchParams.fromDate
          ? formatISOFromDate(
              dateQueryFromDate.year,
              dateQueryFromDate.month,
              dateQueryFromDate.day
            )
          : '',
        searchParams.toDate
          ? formatISOToDate(dateQueryFromDate.year, dateQueryFromDate.month, dateQueryFromDate.day)
          : ''
      )
    )
    fetchListUsers(1, 10, cleanedParams)
  }, [open.sectionId])

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    setValue('text', '')
    setValue('lastRegistry', '')
    setValue('sortType', '')
    const cleanedParams = cleanParamsAPI(getParams('', '', '', '', 'created_at'))
    setSearchParams(initialValues)
    setLoading(true)
    fetchListUsers(1, 10, cleanedParams)
  }

  const onSubmit = () => {
    const dateInfoFromDate = getDateInfo(getValues('lastRegistry')?.fromDate)
    const dateInfoToDate = getDateInfo(getValues('lastRegistry')?.toDate)
    const cleanedParams = cleanParamsAPI(
      getParams(
        replaceValueAll(getValues('text')?.trim()),
        replaceValueAll(getValues('sortType')),
        getValues('lastRegistry')?.fromDate
          ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
          : '',
        getValues('lastRegistry')?.toDate
          ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
          : '',
        'updated_at'
      )
    )
    setSearchParams(() => {
      return cleanedParams
    })
    setLoading(true)
    fetchListUsers(1, queryParams?.page_size || 10, cleanedParams)
  }

  return (
    <div className='card-body py-5'>
      <div className='card-header border-0'>
        <div className='w-100'>
          <div className='row'>
            {/* begin:: Search */}
            <div className='col-xl-3 col-sm-4'>
              <div className='card-title justify-content-center mb-0 mx-0 mt-2'>
                <HookFormTextField
                  control={control}
                  name='text'
                  placeholder='Search teacher name'
                  defaultValue={queryParams?.text}
                  onSubmit={onSubmit}
                  isListScreen
                />
              </div>
            </div>
            {/* end:: Search */}
            <div className='col-xl-3 col-sm-4 mt-2'>
              <HookFormSelectAntd
                name='sortType'
                placeholder='Sort by'
                control={control}
                defaultValue={queryParams?.sortType ?? ''}
                className='sapp-fs-select fs-6'
                classNameHeight='sapp-h-40'
              >
                {FILTER_SELECTALL_SORTBY.map((status) => (
                  <Option key={status.label} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </HookFormSelectAntd>
            </div>
            <div className='col-xl-3 col-sm-4 mt-2'>
              <RangeDateTimePicker
                control={control}
                name='lastRegistry'
                allowClear={true}
                placeholder={['From date', 'To date']}
                className='sapp-h-datimepicker sapp-width-picker-range'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0 pt-6 mb-6 px-0'>
        <div className='container m-0'>
          <div className='row'>
            <div className='col-sm-4 col-xl-4 col-lg-8 px-xl-3 px-md-0'>
              <SAPPFIlterButton
                titleReset='Reset'
                titleSubmit='Search'
                okClick={onSubmit}
                resetClick={handleResetFilter}
                disabled={loading}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
      <SappTable
        headers={headers}
        loading={loading}
        data={teacherList?.data}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {loading ? (
          <>
            {[1, 2, 3, 4, 5].map((header, i) => (
              <LoadingTable key={header} headers={[1, 2, 3, 4, 5]} />
            ))}
          </>
        ) : (
          <>
            {teacherList?.data?.map((data: ITeacher, index: number) => {
              const isChecked = checkedList.includes(data.id)
              return (
                <tr key={data.id}>
                  <td>
                    <SAPPCheckbox
                      checkTarget='#kt_table_users .form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(data.id!)
                      }}
                    />
                  </td>
                  <td className='text-start text-break'>{data?.detail?.full_name}</td>
                  <td className='text-start'>{data?.user_contacts?.[0]?.email}</td>
                  <td className='text-start'>{data?.user_contacts?.[0]?.phone}</td>
                  <td className='text-start'>
                    <DatetimeColumn created_at={data?.created_at} updated_at={data?.updated_at} />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={teacherList?.metadata?.page_index || 1}
        pageSize={teacherList?.metadata?.page_size || 10}
        totalItems={teacherList?.metadata?.total_records}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default TableTeacher
