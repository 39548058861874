import React from 'react'
import {Skeleton} from 'antd'

const LoadingInput = () => {
  return (
    <>
      <Skeleton.Button active size='small' className='mb-1' />
      <Skeleton.Button active block size='large' className='sapp-h-45px' />
    </>
  )
}

export default LoadingInput
