import { Editor } from '@tinymce/tinymce-react'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
// import * from '@wiris/mathtype-tinymce6'
import { Spin } from 'antd'
import { VALID_UPLOAD_EDITOR } from 'src/constants/upload'
import { validateFile } from 'src/utils/upload'
import { v4 as uuid } from 'uuid'
import ModalPlayVideo from './modalVideo'
// import ModalUploadVideo from 'src/components/base/upload-file/ModalUploadVIdeo/ModalUploadVideo'
import { ResourcesAPI } from 'src/apis/resources'
import ModalUploadFile, {
  ResponsePreUploadParams,
} from 'src/components/base/upload-file/ModalUploadFile/ModalUploadFile'
import { RESOURCE_STATUS } from 'src/type/resource'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import ModalPreviewImage from 'src/components/base/ModalPreviewImage'
import axios, { CancelTokenSource } from 'axios'
import toast from 'react-hot-toast'
import { UploadAPI } from 'src/apis/upload'
import { throttle } from 'lodash'
// const jsDemoImagesTransform = document.createElement("script");
// jsDemoImagesTransform.type = "text/javascript";
// jsDemoImagesTransform.src =
//   "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
// document.head.appendChild(jsDemoImagesTransform);
// declare const window: any
// window.$ = $
// window.tinymce = require('tinymce')
// require('@wiris/mathtype-tinymce6')

interface IProps {
  onChange: (...event: any[]) => void
  valueText?: string
  className?: string
  height?: number
  math?: boolean
  placeholder?: string
  getContent?: (e: string) => void
  acceptFiles?: { type: string; size: number }[]
  disabled?: boolean
  editorKey?: number | string
  uploadVideoImage?: boolean
  resourceLocation: RESOURCE_LOCATION
  object_id: string | null | undefined
  setDataFile?: Dispatch<SetStateAction<any>> | undefined
  isGradeForm?: boolean
  is_resource?: boolean
}

interface IResponsePreUpload {
  data: {
    exist_type: 'BOTH' | 'OVERRIDE'
    type: string
    file_key: string
    upload_url: string
    display_name: string
  }
}

interface ImageDimensions {
  width: number
  height: number
}

const TinyEditor = ({
  onChange,
  valueText,
  className,
  height,
  math,
  placeholder,
  getContent,
  acceptFiles = VALID_UPLOAD_EDITOR,
  disabled,
  editorKey,
  uploadVideoImage,
  resourceLocation,
  object_id,
  setDataFile,
  isGradeForm = false,
  is_resource,
}: IProps) => {
  const editorRef = useRef(null) as any
  // const [initialValue, setInitialValue] = useState<any>('')
  const [classFocus, setClassFocus] = useState<boolean>(false)
  const [loaded, setLoaded] = useState(false)
  const [open, setOpen] = useState<{
    status: boolean
    type: 'IMAGE' | 'VIDEO' | 'DOCUMENT' | ''
  }>({ status: false, type: '' })
  const [openVideo, setOpenVideo] = useState<{
    status: boolean
    src?: string
    resourceStatus?: RESOURCE_STATUS
  }>({
    status: false,
  })
  const [openPreviewImage, setOpenPreviewImage] = useState<boolean>(false)
  const [previewImage, setPreviewImage] = useState<string>()
  // const acceptVideo = VALID_UPLOAD_EDITOR_VIDEO
  // const acceptImage = VALID_UPLOAD_EDITOR_IMAGE

  const handleSelectFile = async (e: any, p0: string) => {
    if (e.id || e?.[0]?.id) {
      const res = await ResourcesAPI.getUrl(e.id || e?.[0]?.id)
      if (open.type === 'IMAGE') {
        editorRef?.current?.insertContent(
          `<img id="${uuid()}" resource_id="${res.data.id}" title="${res.data.name}" src="${
            res.data.url
          }" width="200">`
        )
      } else {
        editorRef?.current?.insertContent(
          `<video
        width='900'
        height='504'
        preload='auto'
        id="${uuid()}" resource_id="${res.data.id}"
        poster='${res.data.thumbnail}'
        >
          <source src="null" token=${
            res.data.url
              ? res.data.url
                  .replace('https://customer-qf43f9e6huohhr1o.cloudflarestream.com/', '')
                  .replace('/manifest/video.m3u8', '') || ''
              : res.data.sub_url
          } id="${uuid()}" resource_id="${res.data.id}" resource_status='${res?.data?.status}'/>
        </video>`
        )
      }
      setOpen({ status: false, type: '' })
    }
  }
  // useEffect(() => setInitialValue(valueText), [])

  const [loading, setLoading] = useState(false)
  require('@wiris/mathtype-tinymce6')
  const handleFilePicker = (cb: any) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')

    const acceptedTypes = acceptFiles.map((file) => file.type)

    input.setAttribute('accept', acceptedTypes.join(', '))
    input.onchange = async function () {
      // showLoadingUpload(true)

      const files = input?.files as any

      for (let file of files) {
        if (!validateFile(file, acceptFiles)) continue

        const blob = URL.createObjectURL(file)
        cb(blob, { title: file.name })
      }

      // showLoadingUpload(false)
    }
    input.click()
  }

  useEffect(() => {
    if (loaded && getContent) {
      const rawContent = editorRef?.current?.getContent({ format: 'text' })
      getContent(rawContent)
    }
  }, [loaded, editorRef])

  const handleOpenModal = (status: boolean) => {
    setOpen({ status: status, type: '' })
  }

  const applyStylesToCells = (editor: any) => {
    editor.dom.select('table').forEach((table: any) => {
      const borderStyle = table.style.borderStyle
      if (borderStyle) {
        editor.dom.select('td, th', table).forEach((cell: any) => {
          editor.dom.setStyle(cell, 'border-style', borderStyle)
        })
      }
    })
  }

  // function này để lấy width, height từ link base64
  function getBase64ImageDimensions(base64String: string) {
    return new Promise((resolve, reject) => {
      const img = new Image() as HTMLImageElement
      img.onload = () => {
        resolve({ width: img.width, height: img.height })
      }
      img.onerror = reject
      img.src = base64String
    })
  }

  // function này khi paste ảnh sẽ convert từ link base64 ra file và gọi API upload
  async function handlePasteBase64Image(event: ClipboardEvent, editor: Editor) {
    const clipboardData = event?.clipboardData
    const items = clipboardData?.items as Array<any> | undefined

    for (let i = 0; i < (items?.length || 0); i++) {
      if (items?.[i].type.indexOf('image') !== -1) {
        const file = items?.[i].getAsFile()

        // Đọc file dưới dạng base64
        const base64String = (await fileToBase64(file)) as string

        // Chuyển base64 thành File để tải lên S3
        const convertedFile = base64ToFile(base64String as string, 'image.png')

        // Gọi API để tải ảnh lên
        if (convertedFile) {
          handleUploadFile(convertedFile, editor, base64String)
        }

        // Ngăn dán ảnh base64 ban đầu vào editor
        event.preventDefault()
        break
      }
    }
  }

  // Hàm chuyển file thành base64
  function fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }

  // Hàm chuyển base64 thành File
  function base64ToFile(base64String: string, fileName = 'image.png'): File {
    // Extract the mime type and data from the base64 string
    const [header, base64Data] = base64String.split(',')
    const mimeType = header.match(/data:(.*);base64/)?.[1]

    // Decode base64 data to binary data
    const byteString = atob(base64Data)
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uint8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i)
    }
    // Create a new file using the ArrayBuffer
    return new File([arrayBuffer], fileName, { type: mimeType || 'image/png' })
  }

  const sourceRef = useRef<CancelTokenSource>()

  const responseUploadedFiles: Array<any> = []

  /**
   * Xử lý quá trình tải lên tệp.
   *
   * @param u - Thông tin về tệp.
   * @param responsePreUpload - Phản hồi từ cuộc gọi API preUpload.
   * @param saveLocation - Vị trí lưu trữ tệp sẽ được lưu.
   * @param source - Axios CancelTokenSource để hủy tải lên.
   */
  const processFileUpload = async (
    u: File,
    responsePreUpload: IResponsePreUpload, // Điều chỉnh kiểu nếu cần thiết
    saveLocation: { location: string; parent_id: string } | undefined, // Điều chỉnh kiểu nếu cần thiết
    source: CancelTokenSource,
    editor: any,
    base64String: string
  ) => {
    try {
      // Gọi API để bắt đầu quá trình tải lên
      if (u) {
        const response = await UploadAPI.startUpload({
          content_type: u?.type,
          blob: u,
          size: u?.size?.toString() || '',
          description: '',
          name: u?.name || 'undefined',
          source,
          getProgress: () => {},
          responsePreUpload,
          saveLocation,
          is_grading: false,
        })

        setDataFile && setDataFile((prevData: Array<{ id: string }>) => [...prevData, response])
        // Xử lý phản hồi từ API tải lên
        if (response) {
          responseUploadedFiles.push(response)
        }
        const base64Images = editor.dom.select('img[src^="blob:http"]')
        base64Images.forEach((img: string) => editor.dom.remove(img))

        if (response?.url) {
          getBase64ImageDimensions(base64String)
            .then((dimensions: any) => {
              editor?.insertContent(
                `<img src='${response?.url}' width="${dimensions?.width}" height="${dimensions?.height}" />`
              )
            })
            .catch((error) => console.error('Error loading image:', error))
        }
      }
    } catch (uploadError) {
      // Xử lý lỗi tải lên
      toast.error('An error occurred during the upload process!')
    } finally {
      // Kết thúc quá trình loading

      // Nếu là tệp cuối cùng, đặt timeout để kết thúc quá trình
      // handleSelectFile && handleSelectFile(responseUploadedFiles, 'upload', editor)
      toast.success('Image uploaded successfully!')
    }
  }

  /**
   * Xử lý việc tải lên các tệp.
   *
   * @param currentIndex - Chỉ số của tệp hiện tại đang được xử lý.
   */
  const handleUploadFile = async (convertedFile: File, editor: Editor, base64String: string) => {
    // Nếu là tệp cuối cùng, đặt timeout để kết thúc quá trình
    // Cập nhật danh sách tệp đã tải lên và thông báo thành công

    // Thiết lập CancelTokenSource và biến theo dõi việc hủy
    sourceRef.current = axios.CancelToken.source()

    // Thiết lập source để tải lên và lấy vị trí lưu trữ
    const source = sourceRef.current || axios.CancelToken.source()

    const saveLocation = await UploadAPI.prepareSaveLocation({
      resourceLocation,
      source,
      object_id: object_id ?? undefined,
    })

    // Thiết lập thông tin pre-upload
    const responsePreUploadParams: ResponsePreUploadParams = {
      is_public: false,
      content_type: convertedFile?.type,
      name: (convertedFile?.name || 'undefined' || '').split('.').slice(0, -1).join('.'),
      location: saveLocation?.location || '',
      size: convertedFile?.size?.toString() || '',
      source,
      display_name: convertedFile?.name || 'undefined' || '',
      is_grading: false,
      parent_id: undefined,
    }

    try {
      // Gọi API pre-upload
      const responsePreUpload = await UploadAPI.preUpload(responsePreUploadParams)
      // Xử lý quá trình tải lên
      await processFileUpload(
        convertedFile,
        responsePreUpload,
        saveLocation,
        source,
        editor,
        base64String
      )
    } catch {}
  }

  const throttledHandlePaste = throttle((e, editor) => handlePasteBase64Image(e, editor), 500)

  return (
    <div className={`${className ?? ''} ${classFocus ? 'tox-tinymce_focus' : ''}`}>
      <ModalUploadFile
        open={open.status}
        setOpen={(e: any) => handleOpenModal(e)}
        setSelectedFile={handleSelectFile}
        fileType={open.type || 'IMAGE'}
        resourceLocation={resourceLocation || RESOURCE_LOCATION.CERTIFICATE}
        object_id={object_id}
        setDataFile={setDataFile}
        is_resource={is_resource}
        is_grading={isGradeForm}
      />
      <Spin spinning={loading}>
        <Editor
          {...(editorKey && { key: editorKey })}
          disabled={disabled}
          apiKey={process.env.REACT_APP_TINY_EDITDER_API_KEY_PUBLIC}
          initialValue={valueText}
          onInit={(evt, editor) => {
            editorRef.current = editor
          }}
          onFocusIn={() => setClassFocus(true)}
          onFocusOut={() => setClassFocus(false)}
          init={{
            placeholder: placeholder,
            font_size_formats: '8px 10px 12px 14px 16px 18px 20px 22px 24px 36px',
            height: height || 500,
            selector: 'textarea' as any,
            content_css: [
              'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
            ],
            font_family_formats:
              'Roboto=Roboto; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            external_plugins: math
              ? {
                  tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
                }
              : {},
            image_advtab: true,
            image_uploadtab: true,
            paste_data_images: true,
            tinydrive_token_provider: 'mtkwk0w43jdjib0f70dzupugcwokur6hr4htpa4c6q0kn6cp',
            plugins: [
              // 'mediaembed',
              // 'tinydrive',
              'lists',
              'advlist',
              'table',
              'code',
              'help',
              'wordcount',
              'fullscreen',
              'insertdatetime',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'media',
              'image',
              'link',
              'quickbars',
              'emoticons',
            ],
            mediaembed_max_width: 450,
            ...(isGradeForm && { menubar: false, toolbar_location: 'bottom' }),
            menu: {
              insert: {
                title: 'Insert',
                items: 'table charmap hr pagebreak nonbreaking anchor toc insertdatetime',
              },
            },
            toolbar: isGradeForm
              ? `undo redo | bold italic underline strikethrough tiny_mce_wiris_formulaEditor | ${
                  uploadVideoImage && 'uploadImage'
                }`
              : `${
                  uploadVideoImage && 'uploadVideo | uploadImage |'
                } openGroup | undo redo | blocks | fontfamily fontsize | bold italic underline strikethrough | link | table | mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat | tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry | code`,
            htmlAllowedTags: ['.*'],
            htmlAllowedAttrs: ['.*'],
            toolbar_mode: 'sliding',
            formats: {
              // Changes the default format for the bold button to produce a span with style with font-width: bold and font-style: italic
              bold: { inline: 'strong', styles: { 'font-weight': 'bold' } },
              italic: { inline: 'em', styles: { 'font-style': 'italic' } },
            },
            // extended_valid_elements: '*[.*]',

            content_style: `
          body 
          { 
            font-family: Roboto,sans-serif;
            color:#404041;
            font-size: 14px;
            line-height: 1.5;
            appearance: none;
          }
          .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
            color:#A1A5B7;
            font-weight: 500 ;
            opacity: 1;
            display: block;
            font-family: Roboto,sans-serif;
            font-size: 14px;
          }
          .mce-content-body [contentEditable=false][data-mce-selected] {
            cursor: default
          }
          `,
            setup: function (editor) {
              // tinymce.ScriptLoader.load('https://unpkg.com/video.js/dist/video.min.js')
              // var scriptLoader = new tinymce.dom.ScriptLoader()
              // scriptLoader.add('https://unpkg.com/video.js/dist/video-js.min.css')
              // scriptLoader.loadQueue()
              editor.ui.registry.addIcon(
                'block',
                '<svg width="20" height="20" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M144.667 448H59.3333C46.5333 448 38 439.467 38 426.667V85.3333C38 72.5333 46.5333 64 59.3333 64H144.667C157.467 64 166 72.5333 166 85.3333C166 98.1333 157.467 106.667 144.667 106.667H80.6667V405.333H144.667C157.467 405.333 166 413.867 166 426.667C166 439.467 157.467 448 144.667 448Z" fill="black"/><path d="M367.333 64H452.667C465.467 64 474 72.5333 474 85.3333V426.667C474 439.467 465.467 448 452.667 448H367.333C354.533 448 346 439.467 346 426.667C346 413.867 354.533 405.333 367.333 405.333H431.333V106.667H367.333C354.533 106.667 346 98.1333 346 85.3333C346 72.5333 354.533 64 367.333 64Z" fill="black"/><path d="M341.334 362C328.734 362 320.334 353.6 320.334 341C320.334 328.4 328.734 320 341.334 320C353.934 320 362.334 328.4 362.334 341C362.334 353.6 353.934 362 341.334 362Z" fill="black"/><path d="M256 362C243.4 362 235 353.6 235 341C235 328.4 243.4 320 256 320C268.6 320 277 328.4 277 341C277 353.6 268.6 362 256 362Z" fill="black"/><path d="M170.666 362C158.066 362 149.666 353.6 149.666 341C149.666 328.4 158.066 320 170.666 320C183.266 320 191.666 328.4 191.666 341C191.666 353.6 183.266 362 170.666 362Z" fill="black"/></svg>'
              )

              editor.on('paste', (e) => throttledHandlePaste(e, editor))

              editor.ui.registry.addButton('storage', {
                icon: 'browse',
                // text: 'Storage',
                onAction: function (e: any) {
                  setOpen({ status: true, type: 'IMAGE' })
                },
              })
              editor.ui.registry.addButton('uploadVideo', {
                icon: 'embed',
                // text: 'Storage',
                onAction: function (e: any) {
                  setOpen({ status: true, type: 'VIDEO' })
                },
              })
              editor.ui.registry.addButton('uploadImage', {
                icon: 'image',
                // text: 'Storage',
                onAction: function (e: any) {
                  setOpen({ status: true, type: 'IMAGE' })
                },
              })
              editor.ui.registry.addButton('openGroup', {
                // text: '[]',
                icon: 'block',
                onAction: function () {
                  editor.insertContent(
                    `<span id=${uuid()} class="question-content-tag" contenteditable="false">[_______]</span>`
                  )
                },
              })
              editor.on('click', function (e) {
                const element = e.target
                if (element.getAttribute('data-mce-object') === 'video') {
                  const content = element.querySelector('video source')?.getAttribute('token')
                  // if (content) {
                  const status = element?.querySelector('source')?.getAttribute('resource_status')
                  setOpenVideo({ status: true, src: content, resourceStatus: status })
                  // }
                  return
                }
                if (element.tagName === 'IMG') {
                  setOpenPreviewImage(true)
                  setPreviewImage(element?.src)
                }
              })
              editor.on('TableModified', (e) => {
                applyStylesToCells(editor)
              })
            },
            image_title: true,
            extended_valid_elements:
              'video[data-setup|src|width|height|class|poster|id|resource_id],img[*],source[*]',
            automatic_uploads: false,
            // images_file_types: 'jpeg png',
            images_file_types: 'png,jpg,gif',
            // file_picker_types: 'image media file',
            file_picker_callback: handleFilePicker,
          }}
          onEditorChange={(e) => {
            if (loaded) {
              onChange(e)
            }
          }}
          onLoadContent={(e: any) => {
            setLoaded(e.initial)
          }}
          // value={valueText}
        />
      </Spin>
      <ModalPlayVideo
        open={openVideo.status}
        src={openVideo.src}
        resourceStatus={openVideo.resourceStatus}
        setOpen={setOpenVideo}
      />
      <ModalPreviewImage
        openPreview={openPreviewImage}
        setOpenPreview={setOpenPreviewImage}
        avatarResize={previewImage}
        title={'Preview Image'}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </div>
  )
}

export default TinyEditor
