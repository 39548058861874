import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import { ITabs } from 'src/type'
import { replaceValueAll } from 'src/utils/string'
import {
  cleanParamsAPI,
  formatDate,
  formatISOFromDate,
  formatISOToDate,
  getDateInfo,
} from 'src/utils'
import useChecked from 'src/hooks/use-checked'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import HeaderEventTest from 'src/components/event-test/HeaderEventTest'
import { useForm } from 'react-hook-form'
import TableEventTest from 'src/components/event-test/TableEventTest'
import { EventTestAPI } from 'src/apis/event-test'

const breadcrumbs: ITabs[] = [
  {
    link: `${PageLink.DASHBOARD}`,
    title: LANG_SIDEBAR.lms,
  },
  {
    link: '',
    title: 'Event Tests',
  },
]

const fieldNames = [
  'name',
  'course_category_id',
  'course_level_id',
  'event_id',
  'sortType',
  'fromDate',
  'toDate',
  'status',
]

const initialValues: any = {
  name: '',
  course_category_id: '',
  course_level_id: '',
  event_id: '',
  sortType: '',
  // fromDate: '',
  // toDate: '',
  status: '',
  quizType: 'ENTRANCE_TEST',
}
const EventTestList = () => {
  const [EntranceTestList, setEntranceTestList] = useState<any>({
    quizzes: [{}],
    metadata: {},
  })
  const navigate = useNavigate()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const searchParams = new URLSearchParams(search)
  const [dateField, setDateField] = useState<string>('updated_at')
  const queryParams = {
    name: searchParams.get('name') ?? '',
    course_category_id: searchParams.get('course_category_id'),
    event_id: searchParams.get('event_id'),
    sortType: searchParams.get('sortType'),
    // fromDate: searchParams.get('fromDate') as unknown as Date,
    // toDate: searchParams.get('toDate') as unknown as Date,
    status: searchParams.get('status') as string,
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
    instruction_mode: searchParams.get('instruction_mode') ?? '',
    quizType: 'EVENT_TEST',
  }

  const { control, getValues, reset, setValue, watch } = useForm()

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      name: getValues('name'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      event_id: replaceValueAll(getValues('event_id')),
      sortType: replaceValueAll(getValues('sortType')),
      // fromDate: formatDate(getValues('fromDate')) ?? '',
      // toDate: formatDate(getValues('toDate')) ?? '',
      status: replaceValueAll(getValues('status')),
      page_index: currenPage,
      page_size: size,
      quizType: 'EVENT_TEST',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }
  const filterParams = cleanParamsAPI({
    name: queryParams.name.trimStart().trimEnd() ?? '',
    course_category_id: queryParams.course_category_id ?? '',
    event_id: queryParams.event_id ?? '',
    sortType: queryParams.sortType,
    // fromDate: queryParams.fromDate,
    // toDate: queryParams.toDate,
    status: queryParams.status ?? '',
  })

  const getParams = (
    name: string,
    course_category_id: string,
    event_id: string,
    sortType: string,
    // fromDate?: any,
    // toDate?: any,
    status?: string,
    quizType?: string,
    dateField?: string
  ) => ({
    name,
    course_category_id,
    event_id,
    sortType,
    // fromDate: fromDate,
    // toDate: toDate,
    status,
    quizType,
    dateField,
  })
  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    EntranceTestList?.quizzes
  )

  const fetchEntranceTestList = async (currentPage: number, pageSize: number, params?: any) => {
    try {
      const res = await EventTestAPI.getEventTest(currentPage, pageSize, params)
      setEntranceTestList(res?.data)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    // const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    // const dateInfoToDate = getDateInfo(getValues('toDate'))
    const cleanedParams = cleanParamsAPI(
      getParams(
        getValues('name').trimStart().trimEnd() ?? '',
        replaceValueAll(getValues('course_category_id')),
        replaceValueAll(getValues('event_id')),
        replaceValueAll(getValues('sortType')),
        // getValues('fromDate')
        //   ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        //   : '',
        // getValues('toDate')
        //   ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        //   : '',
        replaceValueAll(getValues('status'))
      )
    )
    setLoading(true)
    fetchEntranceTestList(1, queryParams.page_size || 10, cleanedParams)
    handleChangeParams(1, queryParams.page_size || 10)
  }

  const handleResetFilter = () => {
    reset()
    toggleCheckAll(false)
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })
    setDateField('')
    setLoading(true)
    navigate(PageLink.EVENT_TEST)
    fetchEntranceTestList(1, 10)
  }

  const dataAll = EntranceTestList?.quizzes
  const listSubject: { label: any; value: any }[] = []
  const listProgram: { label: any; value: any }[] = []

  dataAll.forEach((quizzes: any) => {
    listProgram.push({
      label: quizzes.quiz_type || '',
      value: quizzes.quiz_type || '',
    })

    listSubject.push({
      label: quizzes.subject || '',
      value: quizzes.subject || '',
    })
  })

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.eventTest} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <HeaderEventTest
          control={control}
          onSubmit={onSubmit}
          handleReset={handleResetFilter}
          watch={watch}
          setValue={setValue}
          queryParams={queryParams}
          loading={loading}
        />
        <TableEventTest
          EntranceTestList={EntranceTestList}
          loading={loading}
          setLoading={setLoading}
          handleChangeParams={handleChangeParams}
          fetchEntranceTestList={fetchEntranceTestList}
          filterParams={filterParams}
          getParams={getParams}
          queryParams={queryParams}
          checkedList={checkedList}
          toggleCheck={toggleCheck}
          toggleCheckAll={toggleCheckAll}
          isCheckedAll={isCheckedAll}
        />
      </div>
    </PageLayouts>
  )
}
export default withAuthComponents(EventTestList)
