// @ts-nocheck
import {Column} from 'react-table'
import {TeacherActionsCell} from './TeacherActionsCell'
import {User} from '../../core/_models'
import {TeacherSelectionHeader} from './TeacherSelectionHeader'
import {TeacherSelectionCell} from './TeacherSelectionCell'
import UserInfoCell from 'src/components/user-management/UserInfoCell'
import UserCell from 'src/components/user-management/UserCell'
import UserStatusCell from 'src/components/user-management/UserStatusCell'
import UserCustomHeader from 'src/components/user-management/UserCustomHeader'
import {TEACHER_PROFILE} from 'src/constants'
import DatetimeColumn from 'src/components/base/DatetimeColumn'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <TeacherSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <TeacherSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Code' className='min-w-100px' />,
    id: 'code',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index]?.key} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='User' className='min-w-125px' />,
    id: 'user',
    Cell: ({...props}) => (
      <UserInfoCell
        user={props.data[props.row.index]}
        linkViewProfile={`${TEACHER_PROFILE}/${props.data[props.row.index].id}/overview`}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Username' className='min-w-125px' />
    ),
    id: 'username',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index].username} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    id: 'phone',
    Cell: ({...props}) => <UserCell data={props.data[props.row.index].detail?.phone} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Gender' className='min-w-125px' />
  //   ),
  //   id: 'sex',
  //   Cell: ({...props}) => <UserCell data={props.data[props.row.index].detail?.sex} />,
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserStatusCell status={props.data[props.row.index]?.status} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-250px' />,
    id: 'updated_at',
    Cell: ({...props}) => (
      <DatetimeColumn
        updated_at={props.data[props.row.index]?.updated_at}
        created_at={props.data[props.row.index]?.created_at}
      />
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='' className='text-center sapp-absolute-column' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <TeacherActionsCell
        id={props.data[props.row.index].id}
        status={props.data[props.row.index]?.status}
      />
    ),
  },
]

export {usersColumns}
