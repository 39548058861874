import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import LoadingProfileDetail from 'src/components/my-profile/overview-profile/loading/LoadingProfileDetail'
import { CLASS_PROFILE, TITLE_OPTIONS_CLASS } from 'src/constants/classes'
import ItemClassProfile from './ItemClassProfile'

interface IProps {
  classDetail: any | undefined
  loading: boolean
}

const ClassProfileDetail = ({ classDetail, loading }: IProps) => {
  const { id } = useParams()
  const getDurationFixed =
    classDetail?.started_at !== null && classDetail?.finished_at != null
      ? `${format(new Date(classDetail?.started_at), 'dd/MM/yyyy')} - ${format(
          new Date(classDetail?.finished_at),
          'dd/MM/yyy'
        )}`
      : ''
  const getExam = classDetail?.examination_subject?.examination?.name || ''
  const getFlexiableDays =
    classDetail?.flexible_days != null
      ? `${classDetail?.flexible_days} ${classDetail?.flexible_days === 1 ? 'day' : 'days'}`
      : ''

  return (
    <>
      {loading ? (
        <LoadingProfileDetail loadingAvatar={false} />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <HeaderTab
            title='Overview'
            titleButton='Edit Class Info'
            link={`${CLASS_PROFILE}/${id}/setting`}
          />

          <div className='card-body p-9'>
            <ItemProfile title={TITLE_OPTIONS_CLASS.name} body={classDetail?.name} />
            <ItemProfile title={TITLE_OPTIONS_CLASS.code} body={classDetail?.code} />
            <ItemClassProfile
              title={TITLE_OPTIONS_CLASS.status}
              status={classDetail?.status.toLowerCase()}
              className='mb-7'
            />
            <ItemProfile title={TITLE_OPTIONS_CLASS.area} body={classDetail?.area?.name} />
            <ItemProfile
              title={TITLE_OPTIONS_CLASS.constructionMode}
              body={classDetail?.instruction_mode}
            />
            <ItemProfile title={TITLE_OPTIONS_CLASS.type} body={classDetail?.type} />
            <ItemProfile title={TITLE_OPTIONS_CLASS.maximumStudents} body={classDetail?.capacity} />
            {classDetail?.duration_type === 'FIXED' ? (
              <ItemProfile title={TITLE_OPTIONS_CLASS.duration} body={getDurationFixed} />
            ) : (
              <ItemProfile title={TITLE_OPTIONS_CLASS.duration} body={getFlexiableDays} />
            )}
            <ItemProfile title={TITLE_OPTIONS_CLASS.course} body={classDetail?.course?.name} />
            <ItemProfile title={TITLE_OPTIONS_CLASS.exam} body={getExam} />
            <ItemClassProfile
              title={TITLE_OPTIONS_CLASS.describe}
              body={classDetail?.description}
            />
            {classDetail.reason !== null && classDetail?.status === 'BLOCKED' && (
              <ItemProfile title={TITLE_OPTIONS_CLASS.reason} body={classDetail?.reason} />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ClassProfileDetail
