import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import AddMore from 'src/common/AddMore'
import SappLabel from 'src/components/base/label/SappLabel'
import CreateAndUpdateSponsor from './CreateAndUpdateSponsor'
import {ShopSponsorAPI} from 'src/apis/short-course/sponsor'
import {ISponsor} from 'src/type/shop/sponsor'
import {INewOrUpdatePopup} from 'src/type/shop/common'
import ShopActionBox from '../shared/ShopActionBox'

const SponsorContent = ({confirm}: {confirm: (option: any) => void}) => {
  const [openAddSponsor, setOpenSponsor] = useState<INewOrUpdatePopup>({
    isOpen: false,
    data: null,
    type: 'create',
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [sponsorData, setSponsorData] = useState<ISponsor[]>()
  const {id} = useParams()
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => setOpenSponsor({isOpen: false, data: null, type: 'create'}),
    })
  }

  const loadData = async () => {
    if (id) {
      const res = await ShopSponsorAPI.getSponsors(id)
      setSponsorData(res.data)
    }
  }

  const handleDelete = async (id: string) => {
    try {
      await ShopSponsorAPI.deleteSponsor(id)
      loadData()
    } catch (error) {}
  }

  const handleClose = () => {
    loadData()
    setOpenSponsor({isOpen: false, data: null, type: 'create'})
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className='card card-flush'>
      <div className='card-body pt-10'>
        <SappLabel label='Sponsors' isLabel />
        {sponsorData?.map((data) => (
          <>
            <div className='d-flex justify-content-between px-5'>
              <div>
                <div
                  onClick={() => setOpenSponsor({isOpen: true, data, type: 'edit'})}
                  className='d-flex align-items-center'
                >
                  <div className='symbol symbol-circle symbol-50px overflow-hidden me-4'>
                    <div className='symbol-label'>
                      <img
                        src={data.avatar?.ORIGIN}
                        alt='sponsor'
                        className='w-100 h-100 object-fit-cover'
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='text-gray-800 fw-semibold text-hover-primary mb-1'>
                      {data.name}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ShopActionBox
                  id={data?.id}
                  setLoading={setIsLoading}
                  reloadPage={loadData}
                  onDelete={handleDelete}
                  onEdit={() => {
                    setOpenSponsor({isOpen: true, data, type: 'edit'})
                  }}
                />
              </div>
            </div>
            <div className='my-5' style={{border: '1px dashed #DBDFE9'}} />
          </>
        ))}
        <AddMore addNew={() => setOpenSponsor({isOpen: true, data: null, type: 'create'})} />
        <CreateAndUpdateSponsor
          open={openAddSponsor.isOpen}
          data={openAddSponsor.data}
          type={openAddSponsor.type}
          loading={isLoading}
          setLoading={setIsLoading}
          onCancel={hanleCancel}
          onClose={handleClose}
        />
      </div>
    </div>
  )
}

export default SponsorContent
