import {IMetadata} from './question-bank'

export interface IMailLog {
  id: string
  title: string
  bodyHTML?: string
  email: string
  status: string
  updated_at: Date
  created_at: Date
}

export interface IMailAction {
  key: string
  value: string
}

export interface IMailLogList {
  mail_logs: IMailLog[]
  meta: IMetadata
  success: boolean
}

export const MAIL_LOG_STATUS: {[key: string]: string} = {
  NEW: 'Mới',
  SENT: 'Đã gửi',
  FAIL: 'Thất bại',
}
