import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { ClassesApi } from 'src/apis/classes'
import { UsersAPI } from 'src/apis/user'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextArea from 'src/components/base/textaria/HookFormTextArea'
import { VALIDATION_FIELD } from 'src/constants'
import { z } from 'zod'
import SappModal from '../../../base/SappModal'
import { Student } from './types'

interface IProps {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  student: Student
}

interface Iform {
  examination_subject_id: string
  note: string
}
const ExamDeferModal = ({ openModal, setOpenModal, student }: IProps) => {
  const schema = z.object({
    examination_subject_id: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    note: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  })
  const { handleSubmit, control, reset } = useForm<Iform>({
    resolver: zodResolver(schema),
  })
  const queryClient = useQueryClient()
  /**
   * @description sử dụng react-query để lấy data sau khi call API
   */
  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['examsBySubject'],
    queryFn: ({ pageParam = 1 }) => {
      const res = ClassesApi.getExamsBySubject({
        page_index: pageParam,
        page_size: 10,
        id: student.class.id,
        student_id: student.user.id,
      })
      return res
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage?.metadata?.page_index < lastPage?.metadata?.total_pages
        ? lastPage?.metadata.page_index + 1
        : undefined
    },
    enabled: openModal,
  })

  const { mutate, isLoading: isUpdatingExam } = useMutation({
    mutationFn: (data: Iform) =>
      UsersAPI.updateExam(student.user.id, {
        class_id: student.class.id,
        examination_subject_id: data.examination_subject_id,
        note: data.note,
      }),
    onSuccess: (res: any) => {
      toast.success(res.data.message)
      reset()

      void queryClient.invalidateQueries('ListExamStudents')
      setOpenModal(false)
    },
    onError: () => {
      toast.error("There's something wrong")
    },
  })
  const onSubmit: SubmitHandler<Iform> = (data) => {
    mutate(data)
  }

  return (
    <SappModal
      cancelButtonCaption='Cancel'
      okButtonCaption='OK'
      open={openModal}
      title={'Reschedule Exam'}
      handleClose={() => {
        reset()
        setOpenModal(false)
      }}
      centered
      classBody='pt-5 px-8 pb-8'
      handleSubmit={handleSubmit(onSubmit)}
      loading={isUpdatingExam}
    >
      <div className='d-flex flex-column'>
        <HookFormSelectAntd
          required
          label='Please select a new date for the exam'
          loading={isLoading || isFetchingNextPage}
          name={'examination_subject_id'}
          control={control}
          placeholder='Exam Date Registered'
          showSearch
          defaultValue={student?.examination_subject_id}
          handleNextPage={() => hasNextPage && fetchNextPage()}
        >
          {data?.pages.map((group, i) => (
            <React.Fragment key={i}>
              {group.data.map((exam: any) => (
                <Select.Option key={exam.id} value={exam.id}>
                  {exam.examination.name}
                </Select.Option>
              ))}
            </React.Fragment>
          ))}
        </HookFormSelectAntd>
        <div className='mt-5'>
          <HookFormTextArea name='note' control={control} label='Note' required />
        </div>
      </div>
    </SappModal>
  )
}

export default ExamDeferModal
