import { Select } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTable } from 'react-table'
import { CoursesAPI } from 'src/apis/courses'
import { UsersAPI } from 'src/apis/user'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { GENDER, PageLink, pageSizeOptions, STATUS } from 'src/constants'
import { BUTTON_TEXT } from 'src/constants/lang'
import { teachingStatusArray } from 'src/constants/teacher'
import useChecked from 'src/hooks/use-checked'
import useFeatureSections from 'src/hooks/useFeatureSection'
import useStaffs from 'src/hooks/useStaffs'
import useSubjects from 'src/hooks/useSubjects'
import { ICourseCategoies } from 'src/type/courses'
import { IFeatureSection } from 'src/type/feature-section'
import { ISubject } from 'src/type/subject'
import { formatDate, formatISOFromDate, formatISOToDate, getDateInfo } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import HookFormDateTime from '../base/datetime/HookFormDateTime'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import ListFilterLayout from '../layout/listFilter'
import UserListGrouping from '../user-management/UserListGrouping'
import { ListViewProvider } from './components/core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest } from './components/core/QueryRequestProvider'
import {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
} from './components/core/QueryResponseProvider'
import { TeachersListToolbar } from './components/header/TeacherListToolbar'
import { usersColumns } from './components/table/columns/_columns'
import { TeacherTable } from './components/table/TeacherTable'

const { Option } = Select

const fieldNames = [
  '',
  'text',
  'status',
  'fromDate',
  'toDate',
  'course_category_id',
  'subject_id',
  'feature_section_id',
]
const initialValues: any = {
  sex: '',
  text: '',
  status: '',
  fromDate: '',
  toDate: '',
  staff_ids: [],
  course_category_id: '',
  subject_id: '',
  feature_section_id: '',
}

const TeachersList = () => {
  const { updateState } = useQueryRequest()
  const [openBlocked, setOpenBlocked] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const { rows } = useTable({ columns, data })
  const { refetch, isLoading } = useQueryResponse()
  const navigate = useNavigate()

  const queryParams = {
    text: searchParams.get('text') ?? '',
    sex: searchParams.get('sex'),
    status: searchParams.get('status'),
    fromDate: searchParams.get('fromDate') as unknown as Date,
    toDate: searchParams.get('toDate') as unknown as Date,
    teacher_status: searchParams.get('teacher_status'),
    course_category_id: searchParams.get('course_category_id'),
    subject_id: searchParams.get('subject_id'),
    feature_section_id: searchParams.get('feature_section_id'),
    staff_ids: searchParams.get('staff_ids'),
    page_index: parseInt(searchParams.get('page_index') as string),
    page_size: parseInt(searchParams.get('page_size') as string),
  }

  const [currentPage, setCurrentPage] = useState(queryParams.page_index || 1)
  const [pageSize, setPageSize] = useState(queryParams.page_size || pageSizeOptions[0].value)
  const {
    setSubjects,
    getSubjects,
    debounceSearchSubject,
    handleNextPageSubject,
    isLoading: subjectLoading,
    subjects,
  } = useSubjects()

  const {
    setFeatureSections,
    getFeatureSections,
    debounceSearchFeatureSections,
    handleNextPageFeatureSections,
    isLoading: featureSeactionsLoading,
    featureSections,
  } = useFeatureSections()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  // Using validate for input
  const { control, getValues, reset, setValue, watch } = useForm<any>({
    mode: 'onSubmit',
  })

  const handleChangeParams = (currenPage: number, size: number) => {
    const queryParam = {
      page_index: currenPage,
      page_size: size,
      text: getValues('text'),
      sex: replaceValueAll(getValues('sex')),
      status: replaceValueAll(getValues('status')),
      teacher_status: getValues('teacher_status'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      subject_id: getValues('subject_id'),
      feature_section_id: getValues('feature_section_id'),
      staff_ids: getValues('staff_ids'),
      fromDate: formatDate(getValues('fromDate')) ?? '',
      toDate: formatDate(getValues('toDate')) ?? '',
    }

    const queryString = Object.entries(queryParam)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${queryString}`)
  }

  const { checkedList, toggleCheck, toggleCheckAll, isCheckedAll } = useChecked<any>(
    rows.map((row) => {
      return row.original
    })
  )

  const onSubmit = () => {
    //TODO: biến này sẽ lấy được ngày, tháng, năm của date
    const dateInfoFromDate = getDateInfo(getValues('fromDate'))
    const dateInfoToDate = getDateInfo(getValues('toDate'))

    updateState({
      page_index: 1,
      text: getValues('text'),
      sex: replaceValueAll(getValues('sex')),
      status: replaceValueAll(getValues('status')),
      teacher_status: getValues('teacher_status'),
      course_category_id: replaceValueAll(getValues('course_category_id')),
      subject_id: getValues('subject_id'),
      feature_section_id: getValues('feature_section_id'),
      staff_ids: getValues('staff_ids'),
      fromDate: getValues('fromDate')
        ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        : '',
      toDate: getValues('toDate')
        ? formatISOToDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        : '',
    })

    handleChangeParams(1, queryParams.page_size || 10)
    toggleCheckAll(false)
    setCurrentPage(1)
  }

  const onReset = () => {
    reset()
    fieldNames.forEach((fieldName) => {
      setValue(fieldName, initialValues[fieldName])
    })

    navigate(PageLink.TEACHERS)
    updateState({
      page_index: 1,
      page_size: 10,
      text: '',
      sex: '',
      status: '',
      staff_ids: [],
      course_category_id: '',
      subject_id: '',
      feature_section_id: '',
      teacher_status: '',
      fromDate: null,
      toDate: null,
    })
    setSubjects(undefined)
    setFeatureSections(undefined)
    toggleCheckAll(false)
    setCurrentPage(1)
    setPageSize(10)
  }

  const blockUser = useMutation(() => UsersAPI.blocked(checkedList), {
    onSuccess: () => {
      toggleCheckAll(false)
      toast.success('Block Successfully!')
      setOpenBlocked(false)
      cancel(true)
      setCurrentPage(1)
    },
  })

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setFeatureSections(undefined)
    setValue('subject_id', '')
    setValue('feature_section_id', '')
    const courseCategoryId = watch('course_category_id')
    if (courseCategoryId === '') {
      return
    }
    if (courseCategoryId === 'all') {
      await getSubjects({})
    } else {
      await getSubjects({ course_category_id: courseCategoryId })
    }
  }

  const handleCourseSubjectChange = async () => {
    setFeatureSections(undefined)
    setValue('feature_section_id', '')
    const subjectId = watch('subject_id')
    if (subjectId !== 'all' || subjectId !== '') {
      await getFeatureSections({ subject_id: subjectId })
    }
  }

  const [courseCategory, setCourseCategory] = useState<ICourseCategoies>()
  const newCourseCategory = useMemo(
    () =>
      courseCategory?.course_categories?.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [courseCategory]
  )

  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        const res = await CoursesAPI.getCategory({ page_index: 1, page_size: 100 })
        setCourseCategory(res?.data)
        if (watch('course_category_id') && watch('course_category_id') !== 'all') {
          await getSubjects({ course_category_ids: watch('course_category_id') })
        }
      } catch (error) {}
    }
    fetchCourseCategory()
  }, [])

  const subjectOptions = useMemo(
    () =>
      subjects?.subjects?.map((subject: ISubject) => ({
        label: subject.name,
        value: subject.id,
      })),
    [subjects]
  )
  const featureSectionsOptions = useMemo(
    () =>
      featureSections?.feature_sections?.map((subject: IFeatureSection) => ({
        label: subject.name,
        value: subject.id,
      })),
    [featureSections]
  )

  const {
    staffs,
    hasNextPage,
    fetchNextPage,
    isLoading: isStaffLoading,
    isFetchingNextPage,
  } = useStaffs({})

  return (
    <div className='card'>
      <div className='px-10 border-0 pt-10'>
        <ListFilterLayout>
          <div className='card-title justify-content-center mb-0 mx-0'>
            <HookFormTextField
              control={control}
              name='text'
              placeholder='Search'
              defaultValue={queryParams.text ?? ''}
              isListScreen
              onSubmit={onSubmit}
            />
          </div>
          <HookFormSelectAntd
            control={control}
            name='sex'
            placeholder='Gender'
            size='large'
            defaultValue={queryParams.sex ?? ''}
            classNameHeight='sapp-h-40'
          >
            {GENDER.map((gender) => (
              <Option key={gender.value} value={gender.value}>
                {gender.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            control={control}
            name='status'
            placeholder='Status'
            size='large'
            defaultValue={queryParams.status ?? ''}
            classNameHeight='sapp-h-40'
          >
            {STATUS.map((status) => (
              <Option key={status.value} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormDateTime
            control={control}
            name='fromDate'
            placeholder='From date'
            defaultValue={queryParams.fromDate ?? ''}
            isListScreen
          />
          <HookFormDateTime
            control={control}
            name='toDate'
            placeholder='To date'
            defaultValue={queryParams.toDate ?? ''}
            isListScreen
          />

          <HookFormSelectAntd
            size='large'
            control={control}
            name='teacher_status'
            placeholder='Teaching Status'
            defaultValue={queryParams.teacher_status ?? ''}
            showSearch
            classNameHeight='sapp-h-40'
          >
            {teachingStatusArray.map((status) => (
              <Option key={status.value} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            size='large'
            control={control}
            name='course_category_id'
            placeholder='Program'
            defaultValue={queryParams.course_category_id ?? ''}
            showSearch
            classNameHeight='sapp-h-40'
            onChange={handleCourseCategoryChange}
          >
            {newCourseCategory?.map((course_category_ids) => (
              <Option key={course_category_ids.value} value={course_category_ids.value}>
                {course_category_ids.label}
              </Option>
            ))}
          </HookFormSelectAntd>
          <HookFormSelectAntd
            control={control}
            name='subject_id'
            placeholder='Subject'
            onSearch={(e: any) => {
              if (e === undefined) {
                return
              }
              debounceSearchSubject(e)
            }}
            onChange={handleCourseSubjectChange}
            handleNextPage={(e: any) =>
              handleNextPageSubject({
                name: e,
                course_category_id:
                  watch('course_category_id') !== 'all' ? watch('course_category_id') : undefined,
              })
            }
            showSearch
            classNameHeight='sapp-h-40'
            loading={subjectLoading || isFetchingNextPage}
            defaultValue={queryParams?.subject_id ?? ''}
          >
            {subjectOptions?.map((subject) => (
              <Option key={subject.value} value={subject.value}>
                {subject.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            control={control}
            name='feature_section_id'
            placeholder='Section'
            onSearch={(e: any) => {
              if (e === undefined) {
                return
              }
              debounceSearchFeatureSections(e)
            }}
            handleNextPage={(e: any) =>
              handleNextPageFeatureSections({
                name: e,
                subject_id: watch('subject_id'),
              })
            }
            showSearch
            classNameHeight='sapp-h-40'
            loading={featureSeactionsLoading}
            defaultValue={queryParams?.feature_section_id ?? ''}
          >
            {featureSectionsOptions?.map((feature) => (
              <Option key={feature.value} value={feature.value}>
                {feature.label}
              </Option>
            ))}
          </HookFormSelectAntd>
          <HookFormSelectAntd
            size='large'
            control={control}
            name='staff_ids'
            placeholder='Person in Charge'
            defaultValue={queryParams.staff_ids ?? ''}
            showSearch
            classNameHeight='sapp-h-40'
            handleNextPage={() => {
              hasNextPage && fetchNextPage()
            }}
            loading={isStaffLoading}
          >
            {staffs?.map((staff) => (
              <Option key={staff.id} value={staff.id}>
                {staff.username}
              </Option>
            ))}
          </HookFormSelectAntd>
        </ListFilterLayout>
      </div>
      <div className='card-header border-0 pt-6'>
        <div className='d-flex'>
          <SAPPFIlterButton
            okClick={onSubmit}
            resetClick={onReset}
            titleReset='Reset'
            titleSubmit={BUTTON_TEXT.SEARCH}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
        <>
          {/* start:: button open modal */}
          {checkedList?.length > 0 ? (
            <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3 pe-xl-0'>
              <UserListGrouping
                selected={checkedList}
                blockUser={async () => await blockUser.mutateAsync()}
                openBlocked={openBlocked}
                setOpenBlocked={setOpenBlocked}
              />
            </div>
          ) : (
            <div className='col-xl-8 col-lg-9 col-sm-8 px-xl-3'>
              <TeachersListToolbar
                searchTerm={watch('text')}
                sortSex={watch('sex')}
                sortStatus={watch('status')}
                fromDate={watch('fromDate')}
                toDate={watch('toDate')}
                course_category_id={watch('course_category_id')}
                subject_id={watch('subject_id')}
                location={location}
              />
            </div>
          )}
          {/* end:: button open modal */}
        </>
      </div>
      <TeacherTable
        currentPage={currentPage}
        handleChangeParams={handleChangeParams}
        pageSize={pageSize}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
        sortSex={queryParams.sex}
        textSearch={queryParams.text}
        sortStatus={queryParams.status}
        fromDate={queryParams.fromDate}
        toDate={queryParams.toDate}
        course_category_id={queryParams.course_category_id}
        subject_id={queryParams.subject_id}
        // fetchTeacherList={fetchTeacherList}
      />
    </div>
  )
}

const TeachersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <TeachersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export { TeachersListWrapper }
