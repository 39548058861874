import { Collapse, ConfigProvider } from 'antd'
import clsx from 'clsx'
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import { ICourseCategory, SubjectInstance } from 'src/type/courses'
import { ITeacher } from 'src/type/teacher'
import styles from './TeacherPostForm.module.scss'
import { useParams } from 'react-router-dom'

interface ISectionCheckbox {
  category: ICourseCategory
  index: number
  subject: SubjectInstance
  setValue: UseFormSetValue<ITeacher>
  control: Control<ITeacher, any>
  watch: UseFormWatch<ITeacher>
}
const SubjectCollapse = ({
  index,
  subject,
  setValue,
  watch,
  control,
  category,
}: ISectionCheckbox) => {
  const teacherTeachableInstancesForm = watch('teacher_teachable_instances')
  const category_primary_id = watch('category_primary_id')
  const { id } = useParams()

  const extractMergedData = (
    data: any[] | undefined
  ): { feature_section_ids: string[]; category_id: string } => {
    const mergedFeatureSections = data?.reduce(
      (acc, current) => {
        return {
          feature_section_ids: [...acc.feature_section_ids, ...current.feature_section_ids],
          category_id: current.category_id, // Assuming all entries have the same category_id
        }
      },
      { feature_section_ids: [] as string[], category_id: '' }
    )

    return mergedFeatureSections
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
        },
      }}
    >
      <Collapse
        defaultActiveKey={id ? subject.id : []}
        items={[
          {
            key: subject.id,
            label: <span className={styles.strong}>{subject.name}</span>,
            children: (
              <div>
                {subject.feature_section_instances.map((section) => (
                  <label
                    className={clsx('d-flex gap-2 cursor-pointer', styles.sectionCheckbox)}
                    key={section.id}
                    htmlFor={section.id}
                  >
                    <Controller
                      name={`teacher_teachable_instances.${index}.feature_section_ids`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <SAPPCheckbox
                              checked={field.value?.includes(section.id) || false}
                              onChange={(e) => {
                                const updatedValue = e.target.checked
                                  ? [...(field.value || []), section.id] // Add section ID
                                  : field.value.filter((id: string) => id !== section.id)

                                const selectedInstances = teacherTeachableInstancesForm?.filter(
                                  (instance) =>
                                    instance.category_id === category?.id &&
                                    instance.feature_section_ids?.length > 0
                                )

                                const extract = extractMergedData(selectedInstances)

                                const isLastChecked =
                                  e.target.checked === false &&
                                  extract.feature_section_ids.length === 1 &&
                                  extract.category_id === category_primary_id

                                if (isLastChecked) {
                                  toast.error(
                                    `You cannot uncheck the last selected item of this Primary Responsibility`,
                                    {
                                      duration: 6000,
                                    }
                                  )
                                  return
                                }
                                field.onChange(updatedValue) // Update form state
                                if (updatedValue.length > 0) {
                                  setValue(
                                    `teacher_teachable_instances.${index}.category_id`,
                                    category.id
                                  )
                                  setValue(
                                    `teacher_teachable_instances.${index}.subject_id`,
                                    section.subject_id
                                  )
                                } else {
                                  setValue(`teacher_teachable_instances.${index}.category_id`, '')
                                  setValue(`teacher_teachable_instances.${index}.subject_id`, '')
                                }
                              }}
                              id={section.id}
                            />
                          </>
                        )
                      }}
                    />
                    <span className={clsx('flex-grow-1', styles.strong)}>{section.name}</span>
                  </label>
                ))}
              </div>
            ),
          },
        ]}
        expandIconPosition={'end'}
        className={clsx(styles.collapse)}
        style={{ backgroundColor: 'white', border: '1px solid #F1F1F4' }}
      />
    </ConfigProvider>
  )
}

export default SubjectCollapse
