import React from 'react'
import {Col} from 'react-bootstrap'
import ButtonIcon from '../base/button/ButtonIcon'
import {PageLink} from 'src/constants'
import {KTIcon} from 'src/_metronic/helpers/components/KTIcon'

interface IProps {
  participantName: string
  email: string
  phone: string
  university: string
  type: string
  major: string
  language: string
  testscore: string | number
  scoreoftest: string | number
  scoremajor: string | number
  typeofmajor: string
  ratio_score: string | number
  anotherScore: string | number
  manageScore: string
  studyPlan: string
  id: string
}

const DataRow = ({label, value}: {label: string; value: string | number}) => (
  <div className={`row py-4`}>
    <Col className='sapp-text--label fw-semibold sapp-fs-14 col-5 sapp-participant-label'>
      {label}
    </Col>
    <Col className='fw-bold leading-[24px] sapp-fs-14 col-7 text-gray-800'>{value}</Col>
  </div>
)

const ParticipantDetail = ({
  participantName,
  email,
  phone,
  university,
  type,
  major,
  language,
  ratio_score,
  scoremajor,
  typeofmajor,
  anotherScore,
  manageScore,
  studyPlan,
  id,
}: IProps) => {
  const participantsDetail = [
    {label: 'Name', value: participantName},
    {label: 'Email', value: email},
    {label: 'Phone', value: phone},
    {label: 'Trường đại học', value: university},
    {label: 'Hệ', value: type},
    {label: 'Chuyên ngành', value: major},
    {label: 'Năng lực ngoại ngữ', value: language},
    {label: 'Điểm bài thi', value: ratio_score},
  ]

  const participantPoints = [
    {label: 'Điểm chuyên ngành', value: scoremajor},
    {label: 'Phân loại điểm chuyên ngành', value: typeofmajor},
    {label: 'Điểm khác', value: anotherScore},
    {label: 'Phân loại điểm khác', value: manageScore},
    {label: 'Kế hoạch học tập', value: studyPlan},
  ]

  return (
    <>
      <div className='flex flex-row w-full items-start'>
        <div className='bg-white flex justify-between gap-[270px] w-full items-start'>
          <div className='flex gap-5 w-full h-[714px] items-start'>
            {participantsDetail?.map((data) => (
              <DataRow label={data.label} value={data.value} key={data.value} />
            ))}
            <div className='row sapp-py-12px'>
              <Col className='sapp-text--label fw-semibold sapp-fs-14 col-5 d-flex align-items-center sapp-participant-label'>
                Chi tiết bài thi
              </Col>
              <Col className='flex flex-col items-center justify-start sapp-fs-16px h-[34px] col-7'>
                <ButtonIcon
                  title={'Test Result'}
                  className='inline-flex sapp-color-parti align-items-center text-center sapp-fs-14 py-2 px-3'
                  customButton='justify-center align-items-center cursor-pointer fw-base text-center d-inline-flex'
                  link={`${PageLink.ENTRANCE_TEST_RESULT}/${id}`}
                >
                  <KTIcon iconName='notepad' className='fs-2 sapp-color-percent' />
                </ButtonIcon>
              </Col>
            </div>
            {participantPoints?.map((data) => (
              <DataRow label={data.label} value={data.value} key={data.value} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ParticipantDetail
