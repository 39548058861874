import { useState } from 'react'
import { TEST_TYPE } from 'src/components/courses/shared/Interfaces'
import TestComponent from 'src/components/courses/test/TestComponent'
import '../tabText/tabText.scss'

interface IProps {
  tabId: string
  setData: any
  onSubmit: any
  handleCancel: any
  // edit?: boolean
  doc?: any
}
const AddTabQuiz = ({ tabId, onSubmit, handleCancel, doc }: IProps) => {
  // format data de fill form edit
  const [loading, setloading] = useState(false)
  return (
    <div className='mb-6'>
      <TestComponent
        id={tabId}
        testFor=''
        type={TEST_TYPE.QUIZ}
        customOnSubmit={(e: any) => onSubmit(e)}
        customOnCancel={handleCancel}
        forTab
        oldData={doc?.quiz}
        buttonSaveTitle='Save Document'
        CancelSubmitBtnClass='d-flex justify-content-end'
        loading={loading}
        setLoading={setloading}
        isCreateEditLayout={true}
      />
    </div>
  )
}
export default AddTabQuiz
