import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { CommentAPI } from 'src/apis/comments'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import Search from 'src/components/base/search'
import UserListGrouping from 'src/components/user-management/UserListGrouping'
import useChecked from 'src/hooks/use-checked'
import { ISupporterInGroup, ISupporterList } from 'src/type/comment'
import { cleanParamsAPI } from 'src/utils'
import AddUserToGroup from './AddSupporter'
import CardMember from 'src/components/groups/CardMember'
import { useConfirm } from 'src/hooks/use-confirm'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonIconPrimary from 'src/components/ui/button-icon-primary/ButtonIconPrimary'

const SupporterList = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [supporters, setSupporters] = useState<ISupporterList>()
  const [openBlocked, setOpenBlocked] = useState(false)
  const { id } = useParams()

  const { confirm, contextHolder } = useConfirm()

  const initialValues = {
    text: '',
  }
  const getParams = (text: string) => ({
    text,
  })

  const [searchParams, setSearchParams] = useState<any>(initialValues)

  const getListSupporter = async (pageIndex?: number, pageSize?: number, params?: Object) => {
    setLoading(true)
    try {
      if (!id) return
      const res = await CommentAPI.getSupporterList({
        id,
        pageIndex: pageIndex || 1,
        pageSize: pageSize || 10,
        params,
      })
      setSupporters(res.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getListSupporter(page_index, page_size, cleanedParams)
  }

  useEffect(() => {
    getListSupporter()
  }, [])

  const { checkedList, listDataChecked, toggleCheckAll } = useChecked<ISupporterInGroup>(
    supporters?.data
  )

  const handleOnClick = () => {
    setOpen(true)
  }

  const blockUser = () => {
    setOpenBlocked(false)
    toggleCheckAll(!listDataChecked)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedParams = {
      text: e.target.value.toLowerCase(),
    }
    setSearchParams(() => {
      return cleanedParams
    })
  }

  const handleSearchActive = () => {
    const cleanedParams = cleanParamsAPI(getParams(searchParams?.text))
    getListSupporter(1, supporters?.metadata?.page_size || 10, cleanedParams)
  }

  const handleDeleteSupporter = async (supporterId: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn xoá không?'],
      onClick: async () => {
        try {
          await CommentAPI.deleteSupporter(id ?? '', { supporter_ids: supporterId })
          toast.success('Delete The Supporter Successfully !')
          getListSupporter(1, 10)
        } catch (error) {}
      },
    })
  }
  return (
    <div className='mt-15'>
      {contextHolder}
      <div className='card'>
        <div className='py-5 px-8'>
          <div className={`d-flex justify-content-between align-items-center gap-5 flex-wrap mb-5`}>
            <div className='sapp-flex-1 d-flex align-items-center'>
              <Search
                showSearch={true}
                onChange={handleSearchChange}
                placeholder={'Search Supporter'}
                smallSearch={true}
                isListScreen
                onSubmit={() => handleSearchActive()}
              />
              <ButtonPrimary
                title='Search'
                size='small'
                onClick={() => handleSearchActive()}
                className='ms-4'
                loading={loading}
              />
            </div>
            <>
              {checkedList.length > 0 ? (
                <div className='sapp-selected-user'>
                  <UserListGrouping
                    okButtonCaption='Yes'
                    cancelButtonCaption='No'
                    body='Bạn có chắc chắn muốn xóa không?'
                    selected={checkedList}
                    blockUser={blockUser}
                    openBlocked={openBlocked}
                    setOpenBlocked={setOpenBlocked}
                    title={'Delete Selected'}
                    className={'sapp-h-40'}
                  />
                </div>
              ) : (
                <>
                  <div className='d-flex justify-content-between'>
                    <ButtonIconPrimary
                      iconName={'plus'}
                      title={'Add Member'}
                      onClick={handleOnClick}
                      size='small'
                    />
                  </div>
                </>
              )}
            </>
          </div>
          <div className='h-xl-100 mt-3'>
            <div className='table-responsive'>
              <CardMember
                data={supporters?.data}
                action={(id: string) => handleDeleteSupporter(id)}
                currentPage={supporters?.metadata?.page_index ?? 1}
                pageSize={supporters?.metadata?.page_size ?? 10}
                loading={loading}
                allowRenderEdit={true}
                isId={false}
              />
            </div>
            <PagiantionSAPP
              currentPage={supporters?.metadata?.page_index || 1}
              pageSize={supporters?.metadata?.page_size || 10}
              totalItems={supporters?.metadata.total_records}
              handlePaginationChange={handlePaginationChange}
            />
          </div>
        </div>
      </div>
      <AddUserToGroup
        open={open}
        id={id ?? ''}
        setOpen={setOpen}
        fetchUserGroup={getListSupporter}
      />
    </div>
  )
}

export default SupporterList
