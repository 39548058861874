import {isEmpty} from 'lodash'
import {ReactNode} from 'react'

import './SappTableProfile.scss'
import NotData from 'src/components/NotData'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'

interface IProps {
  headers?: Array<{label: string; className?: string}>
  children: ReactNode
  loading: boolean
  data: Array<any> | undefined
  isCheckedAll: any
  onChange: (e: any) => void
  hasCheckAll?: boolean
  hasCheck?: boolean
  showHeader?: boolean
  showHashtag?: boolean
  classTable?: string
  classString?: string
}

const SappTableProfile = ({
  children,
  headers,
  loading,
  data,
  isCheckedAll,
  onChange,
  hasCheckAll = true,
  hasCheck = true,
  showHeader = true,
  showHashtag = false,
  classTable = 'table align-middle fs-6 gy-5 dataTable no-footer',
  classString,
}: IProps) => {
  return (
    <div className='table-responsive'>
      <table className={classTable}>
        {showHeader && (
          <thead>
            <tr
              className={`text-start text-muted fw-bolder fs-7 text-uppercase gs-0 line-Height  ${classString}`}
            >
              {hasCheck && (
                <th className='w-50px pe-5'>
                  {hasCheckAll && (
                    <SAPPCheckbox
                      checked={isCheckedAll}
                      ktCheck={isCheckedAll}
                      onChange={onChange}
                    />
                  )}
                </th>
              )}
              {showHashtag && (
                <th className='w-40px ' style={{lineHeight: '12px'}}>
                  #
                </th>
              )}
              {headers?.map((column) => (
                <th key={column.label} className={`${column.className} fs-7 fw-bold`}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className='text-gray-600 fw-semibold'>{children}</tbody>
      </table>

      {isEmpty(data) && !loading && <NotData />}
    </div>
  )
}

export default SappTableProfile
