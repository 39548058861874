import {DebouncedFunc, debounce} from 'lodash'
import {useMemo, useState} from 'react'
import {TagAPI} from 'src/apis/short-course/tags'
import {DEBOUNCED_NUMBER} from 'src/constants'
import {IShopFilter} from 'src/type/shop/common'

import {ITagListItem} from 'src/type/shop/tag'

export interface IMetadata {
  total_pages: number
  total_records: number
  page_index: number
  page_size: number
}

export interface ITagFilter {
  loading: boolean
  data: IShopFilter[]
  metadata?: IMetadata
  search?: string
}

const defaultValue = {
  loading: false,
  data: [],
  search: '',
}

export interface IUseShopFilterResult {
  tagList: ITagFilter
  setAuthorList: React.Dispatch<React.SetStateAction<ITagFilter>>
  getAllAuthors: (name?: string, page_index?: number, page_size?: number) => Promise<void>
  debounceGetCategories: DebouncedFunc<
    (name?: string, page_index?: number, page_size?: number) => Promise<void>
  >
  handleNextPage: DebouncedFunc<() => void>
}
/**
 * Hook dùng để chứa các function xử lý tag
 */
const useTagList = () => {
  const [tagList, setTagList] = useState<ITagFilter>(defaultValue)

  /**
   * Hook dùng để chứa các function xử lý tag
   * @param {name}: tên tag
   * @param {page_index}: trang hiện tại
   * @param {page_size}: số lượng bản ghi 1 size
   */
  const getAllTags = async (name?: string, page_index: number = 1, page_size: number = 10) => {
    try {
      setTagList((e) => ({
        ...e,
        loading: true,
      }))
      const response = await TagAPI.getTags({
        page_index,
        page_size,
        params: {name},
      })
      const tags =
        response.data?.blogTags?.map((item: ITagListItem) => ({id: item.id, name: item.name})) || []
      setTagList((prev: ITagFilter) => {
        return {
          ...prev,
          metadata: response.data.metadata,
          loading: false,
          data:
            name !== prev.search
              ? tags
              : [...(prev?.data || []), ...tags].filter(
                  (obj, index, self) => self.findIndex((o) => o.id === obj.id) === index
                ),
        }
      })
    } catch (error) {
      setTagList((e) => ({
        ...e,
        loading: false,
      }))
    }
  }
  /**
   * function dùng để debounce callback sau 1 khoảng timeout
   * @param {callback}: tên category
   * @param {Time}: debounced time
   */
  const debounceGetTags = debounce(getAllTags, DEBOUNCED_NUMBER)

  /**
   * Dùng để fetch data mỗi khi scroll down hết
   */
  const handleNextPage = () => {
    const total_pages = tagList.metadata?.total_pages
    const page_index = tagList.metadata?.page_index
    const page_size = tagList.metadata?.page_size
    if (total_pages) {
      if (page_index && page_index < total_pages) {
        getAllTags(tagList.search, page_index + 1, page_size)
      }
    }
  }

  const debouncedHandleNextPage = debounce(handleNextPage, DEBOUNCED_NUMBER)

  const useTagFilterResult = useMemo(
    () => ({
      tagList,
      setTagList,
      getAllTags,
      debounceGetTags,
      handleNextTagPage: debouncedHandleNextPage,
    }),
    [tagList]
  )
  return useTagFilterResult
}
export default useTagList
