import { Dispatch, SetStateAction, useState } from 'react'
// import ConnectAccount from 'src/components/my-profile/setting-profile/ConnectAccount'
// import EmailPreferences from 'src/components/my-profile/setting-profile/EmailPreferences'
// import Notifications from 'src/components/my-profile/setting-profile/Notifications'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import DeactivateAccount from 'src/components/my-profile/setting-profile/DeactivateAccount'
import { useUserContext } from 'src/context/UserProvider'
import { IStudentDetail } from 'src/type/students'
import EditProfileTeacher from './EditProfileTeacher'

interface IProps {
  teacherDetail: IStudentDetail | undefined
  setLoading: Dispatch<SetStateAction<boolean>>
  loading: boolean
  setTeacherDetail: Dispatch<SetStateAction<any>>
}

const SettingTeacher = ({ teacherDetail, setTeacherDetail, loading }: IProps) => {
  const { id } = useParams()
  const [confirmDeactive, setConfirmDeactive] = useState(false)
  const { getStudentDetail } = useUserContext()

  const blockedTeacher = async () => {
    await UsersAPI.blocked([id as string]).then(() => toast.success('Blocked Successfully!'))
    await getStudentDetail(id).then((res) => setTeacherDetail(res?.data))
  }

  return (
    <>
      <EditProfileTeacher
        teacherDetail={teacherDetail}
        setTeacherDetail={setTeacherDetail}
        loading={loading}
      />

      {/* <ConnectAccount /> */}

      {/* <EmailPreferences /> */}

      {/* <Notifications /> */}

      <DeactivateAccount
        confirmDeactive={confirmDeactive}
        setConfirmDeactive={setConfirmDeactive}
        blockedUser={blockedTeacher}
      />
    </>
  )
}

export default SettingTeacher
