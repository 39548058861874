import React from 'react'
import './HookFormCheckBoxTN.scss'
import { IPropsCheckbox } from 'src/type'
import clsx from 'clsx'
import { SIZES } from 'src/utils'

const STATE = {
  success: 'sapp-success-checkbox',
  error: 'sapp-error-checkbox',
  default: 'sapp-default-checkbox',
  primary: 'sapp-primary-checkbox',
}

const SAPPCheckboxTN = ({
  ktCheck,
  checkTarget,
  checked,
  onChange,
  className,
  disabled,
  lowerOptions = false,
  state = 'default',
  size = 'medium',
  value,
}: IPropsCheckbox) => {
  return (
    <div className={`sapp-checkbox ${className}`}>
      <input
        className={clsx(
          'd-block',
          SIZES[size],
          STATE[state],
          disabled && 'opacity-60 cursor-not-allowed',
          !disabled && 'cursor-pointer',
          lowerOptions && 'border-none'
        )}
        type='checkbox'
        data-kt-check={ktCheck}
        data-kt-check-target={checkTarget}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value?.toString()}
      />
    </div>
  )
}

export default SAPPCheckboxTN
