/* #region List filter */

export enum ExamFilters {
  NAME = 'name',
  CATEGORY_NAME = 'category_name',
  SORT_TYPE = 'sort_type',
  REGISTRATION_OPENING = 'registration_opening_date',
  STANDARD_REGISTRATION_DEADLINE = 'standard_registration_deadline',
  EARLY_REGISTRATION_DEADLINE = 'early_registration_deadline',
  SUBJECT_ID = 'subject_id',
  EXAM_MONTH = 'exam_month',
  EXAM_YEAR = 'exam_year',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  REGISTRATION_CLOSING_DATE = 'registration_closing_date',
}

export enum PROGRAM {
  CFA = 'CFA',
  ACCA = 'ACCA',
  CMA = 'CMA',
}

/* #endregion */
