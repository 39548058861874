import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import { ClassesApi } from 'src/apis/classes'
import PopupConfirm from 'src/common/PopupConfirm'
import ActionCell from 'src/components/base/action/ActionCell'
import { CLASS_TYPE } from 'src/constants'
import { CODE_ADMIN, TITLE_CLASS_GR } from 'src/constants/permission'
import { CLASS_USER_TYPES } from 'src/constants/student'
import { useUserContext } from 'src/context/UserProvider'
import { IConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import { IClassStudent } from 'src/type/classes'
import { IClassType } from 'src/type/courses'

interface IProps {
  id: any
  listUsers?: any
  setUser?: any
  action: (e: 'course' | 'duration' | 'transfer', studentId: string, data?: any) => void
  classId: string | undefined
  getClassStudents: (page_index?: number | undefined, page_size?: number | undefined) => void | any
  user: IClassStudent
  confirm: (option: IConfirm) => void
  isPassed?: boolean
  isEnded?: boolean
  normalClassConnections: number
  type: string
  classType: IClassType
}

export const StudentActions = ({
  id,
  action,
  classId,
  getClassStudents,
  user,
  confirm,
  isPassed,
  isEnded,
  normalClassConnections,
  type,
  classType,
}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalCancelTransfer, setOpenModalCancelTransfer] = useState(false)
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])
  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderDeleteStudent = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.REMOVE_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      (role.code === CODE_ADMIN.SUPER_ADMIN &&
        type !== CLASS_USER_TYPES.TRANSFERED_TO &&
        type !== CLASS_USER_TYPES.TRANSFERRED)
  )

  const allowPassFoundation = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) || role.code === CODE_ADMIN.SUPER_ADMIN
  )

  const allowRenderEditDuration = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.EDIT_DURATION) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      (role.code === CODE_ADMIN.SUPER_ADMIN && type !== CLASS_USER_TYPES.TRANSFERED_TO)
  )

  const allowRenderEditCourseContentClass = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_CLASS_GR.EDIT_COURSE_CONTENT_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_COURSE_CONTENT_OF_STUDENT_IN_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
      hasPermission(role, TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS) ||
      (role.code === CODE_ADMIN.SUPER_ADMIN && type !== CLASS_USER_TYPES.TRANSFERED_TO)
  )

  const allowRenderTransfer =
    profileMe?.roles?.some(
      (role: Role) =>
        hasPermission(role, TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS) ||
        hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
        hasPermission(role, TITLE_CLASS_GR.CREATE_CLASS) ||
        role.code === CODE_ADMIN.SUPER_ADMIN
    ) &&
    type === CLASS_USER_TYPES.NORMAL &&
    classType === CLASS_TYPE.LESSON.toUpperCase() &&
    user.learning_progress.total_course_sections_completed === 0 &&
    user.user.is_sapp_operator === false

  const allowRenderCancelTransfer =
    profileMe?.roles?.some(
      (role: Role) =>
        hasPermission(role, TITLE_CLASS_GR.CREATE_STUDENT_IN_CLASS) ||
        hasPermission(role, TITLE_CLASS_GR.EDIT_CLASS) ||
        hasPermission(role, TITLE_CLASS_GR.CREATE_CLASS) ||
        role.code === CODE_ADMIN.SUPER_ADMIN
    ) &&
    type === CLASS_USER_TYPES.TRANSFERED_TO &&
    classType === CLASS_TYPE.LESSON.toUpperCase()

  const deleteItem = async () => {
    try {
      const res = await ClassesApi.deleteStudentInClass({
        user: [id],
        classId,
      })
      const invalidStudent = res?.data?.invalid_student
      if (res) {
        if (invalidStudent.length > 0) {
          invalidStudent.forEach((errorCode: any) => {
            // Hiển thị thông báo tương ứng với mỗi mã lỗi
            toast.error(`${errorCode?.error_message}: ${errorCode?.students.join(', ')}`)
          })
        } else {
          toast.success('Delete Successfully!')
        }
        getClassStudents()
      }
    } catch (error) {
      toast.error('Delete Failed!')
    } finally {
      setOpenModalDelete(false)
    }
  }

  const cancelTransfer = async () => {
    try {
      const res = classId && (await ClassesApi.cancelTransferStudentInClass(classId, id))
      if (res) {
        toast.success('Cancel Transfer Successfully!')
        getClassStudents()
        setOpenModalCancelTransfer(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const allowFoundation = async () => {
    try {
      const res = await ClassesApi.allowFoundation([user?.user_id], classId)
      if (res.success) {
        toast.success('Update Successfully!')
        getClassStudents()
      }
    } catch (error) {
      toast.error('Failed!')
    }
  }

  const handleAllowFoundation = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn cho học viên pass foundation không?'],
      onClick: allowFoundation,
    })
  }

  return (
    <>
      {' '}
      {user?.user?.status !== 'MERGED' && (
        <ActionCell customWidth='w-200px'>
          <>
            {normalClassConnections > 0 && !isPassed && !isEnded && allowPassFoundation && (
              <div className='menu-item px-3' onClick={handleAllowFoundation}>
                <div className='menu-link px-3'>Allow Pass Foundation</div>
              </div>
            )}
            {allowRenderEditCourseContentClass && (
              <div
                className='menu-item px-3'
                onClick={() => {
                  action('course', id)
                }}
              >
                <div className='menu-link px-3'>Edit Course Content</div>
              </div>
            )}
            {user?.started_at && user?.finished_at && allowRenderEditDuration && (
              <div
                className='menu-item px-3'
                onClick={() => {
                  action('duration', id, user)
                }}
              >
                <div className='menu-link px-3'>Update Duration</div>
              </div>
            )}
            {!isEnded && (
              <>
                {allowRenderDeleteStudent && (
                  <div className='menu-item px-3' onClick={() => setOpenModalDelete(true)}>
                    <div className='menu-link px-3'>Delete</div>
                  </div>
                )}
              </>
            )}
            {allowRenderTransfer && (
              <div
                className='menu-item px-3'
                onClick={() => {
                  action('transfer', id)
                }}
              >
                <div className='menu-link px-3'>Chuyển nhượng</div>
              </div>
            )}
            {allowRenderCancelTransfer && (
              <div className='menu-item px-3' onClick={() => setOpenModalCancelTransfer(true)}>
                <div className='menu-link px-3'>Hủy Chuyển nhượng</div>
              </div>
            )}
          </>
        </ActionCell>
      )}
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={deleteItem}
        body='Bạn có chắc chắn muốn xóa không?'
      />
      <PopupConfirm
        open={openModalCancelTransfer}
        setOpen={setOpenModalCancelTransfer}
        onClick={cancelTransfer}
        body={'Bạn có muốn hủy chuyển nhượng không?'}
        okButtonCaption={'Hủy chuyển nhượng'}
        cancelButtonCaption={'Đóng'}
      />
    </>
  )
}
