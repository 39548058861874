import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import PopupConfirm from 'src/common/PopupConfirm'
import { MenuComponent } from 'src/_metronic/assets/ts/components'
import ActionCell from 'src/components/base/action/ActionCell'
import { DELETE_POPUPCONFIRM, DUPLICATE_POPUPCONFIRM } from 'src/constants/lang'

const dataMenu = ['Edit', 'Delete']

interface IProps {
  id: string
  setLoading: Dispatch<SetStateAction<boolean>>
  reloadPage: () => void
  onDelete: (id: string) => void
  onEdit: (id: string) => void
  onPreview?: (id: string) => void
  onDuplicate?: (id: string) => void
  menuList?: string[]
}

const ShopActionBox = ({
  id,
  setLoading,
  onDelete,
  onEdit,
  onPreview,
  onDuplicate,
  menuList = dataMenu,
}: IProps) => {
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalDuplicate, setOpenModalDuplicate] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const handleDelete = () => {
    onDelete(id)
    setLoading(false)
    setOpenModalDelete(false)
  }

  const handleDuplicate = () => {
    onDuplicate && onDuplicate(id)
    setLoading(false)
    setOpenModalDuplicate(false)
  }

  const handleSelection = (type: string) => {
    switch (type) {
      case 'Delete':
        setOpenModalDelete(true)
        break
      case 'Edit':
        onEdit(id)
        break
      case 'Preview':
        onPreview && onPreview(id)
        break
      case 'Duplicate':
        setOpenModalDuplicate(true)
        break
      default:
        break
    }
  }

  return (
    <>
      <ActionCell customWidth='w-150px'>
        {menuList?.map((data) => (
          <div
            className='menu-item px-3'
            key={data}
            onClick={() => {
              handleSelection(data)
            }}
          >
            <div className='menu-link px-3'>{data}</div>
          </div>
        ))}
        {/* end::Menu item */}
      </ActionCell>

      {/* Delete Modal */}
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDelete}
        setOpen={setOpenModalDelete}
        onClick={handleDelete}
        body={DELETE_POPUPCONFIRM}
      />

      {/* Duplicate Modal */}
      <PopupConfirm
        okButtonCaption='Yes'
        cancelButtonCaption='No'
        open={openModalDuplicate}
        setOpen={setOpenModalDuplicate}
        onClick={handleDuplicate}
        body={DUPLICATE_POPUPCONFIRM}
      />
    </>
  )
}

export default ShopActionBox
