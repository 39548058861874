import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import ErrorMessage from 'src/common/ErrorMessage'
import HookFormEditor from 'src/components/base/editor/HookFormEditor'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { RESOURCE_LOCATION } from 'src/components/base/upload-file/ModalUploadFile/UploadFileInterface'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import { TYPE_COURSE, VALIDATE_FIELD_MAX_LENGTH, VALIDATION_FIELD } from 'src/constants'
import { useCourseContext } from 'src/context/CourseProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import MenuLearningOutcome from 'src/pages/entrance-test/create/MenuLearningOutcome'
import { ICourseLearnOutCome } from 'src/type/courses'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'
import { PlusVideoIcon } from '../../../../common/icons'
import LearningOutcomeTab from './LearningOutcomeTab'

interface IProps {
  open: boolean
  setOpen: any
  course_section_id: string
  type?: string
  onlyEditName?: boolean
}

const defaultValues = {
  name: '',
  description: '',
  outcomes: [],
}

export interface ILearningOutcome {
  uuid?: string | undefined
  id?: string | undefined
  description: string
}

const validationSchema = z.object({
  name: z
    .string({ required_error: VALIDATION_FIELD })
    .trim()
    .min(1, VALIDATION_FIELD)
    .refine((e) => (e || '').length < 1000, {
      message: VALIDATE_FIELD_MAX_LENGTH('Name', 1000),
    }),
  description: z.string().optional().default(''),
  outcomes: z
    .array(
      z.object({
        uuid: z.string().optional(),
        id: z.string().optional(),
        description: z
          .string({ required_error: VALIDATION_FIELD })
          .trim()
          .min(1, VALIDATION_FIELD)
          .default(''),
      })
    )
    .refine((val) => val && val.length > 0, {
      message: 'Learning Outcome must contain at least 1 element(s)',
      path: ['outcomes_errors'],
    })
    .default([]),
})

const CreateLearningOutCome = ({
  open,
  setOpen,
  course_section_id,
  type,
  onlyEditName,
}: IProps) => {
  const learningOutcome = useRef<ICourseLearnOutCome>()
  const { getCourseDetail, idCourse, setIdChild } = useCourseContext()
  const { confirm, contextHolder } = useConfirm()
  const params = useParams()

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm<ICourseLearnOutCome>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues,
  })
  const { append, remove } = useFieldArray({
    control,
    name: 'outcomes',
  })
  const [loading, setLoading] = useState<boolean>()

  useEffect(() => {
    if (type === TYPE_COURSE.LEARNINGOUTCOME) {
      ;(async () => {
        setLoading(true)
        try {
          const response = await CoursesAPI.getLearnOutcome(course_section_id)
          if (response) {
            const data = {
              name: response.data?.name,
              description: response.data?.description || '',
              outcomes: response.data?.course_outcomes?.map((e: any) => ({
                id: e.id,
                description: e.description,
                uuid: uuid(),
              })),
            }
            setValue('name', data.name)
            setValue('description', data.description)
            setValue('outcomes', data.outcomes)
            learningOutcome.current = data
          }
        } catch (error) {
          reset(defaultValues)
          setOpen({ status: false })
        } finally {
          setLoading(false)
        }
      })()
    } else {
      learningOutcome.current = undefined
    }
  }, [course_section_id, type])

  /**
   * @description state này để lưu data của file sau khi update từ editor
   */
  const [fileEditor, setFileEditor] = useState<Array<{ id: string }>>([])

  /**
   * @description state này để lấy id của file sau khi update từ editor
   */
  const textEditorFiles =
    fileEditor?.map((data) => {
      return { id: data?.id }
    }) || []

  const onSubmit = async (dataValue: ICourseLearnOutCome) => {
    dataValue = {
      ...dataValue,
      name: dataValue?.name?.trim() || '',
      description: dataValue?.description?.trim() || '',
      outcomes:
        dataValue?.outcomes?.map((e) => ({
          ...e,
          description: e.description?.trim() || '',
          uuid: undefined,
        })) || [],
      text_editor_files: textEditorFiles,
    }

    setLoading(true)
    try {
      if (type !== TYPE_COURSE.LEARNINGOUTCOME) {
        const res = await CoursesAPI.createLearningOutcome({
          ...dataValue,
          course_section_id: course_section_id,
        })

        if (res?.success) {
          setFileEditor([])
        }

        setIdChild(res?.data?.id)
      } else {
        const res = await CoursesAPI.updateLearnOutcome({
          ...dataValue,
          id: course_section_id,
        })

        if (res?.success) {
          setFileEditor([])
        }

        setIdChild(res?.data?.id)
      }
      reset(defaultValues)
      setOpen({ status: false })
      getCourseDetail(idCourse)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    reset(defaultValues)
    setOpen({ status: false })
    learningOutcome.current = undefined
  }

  const handleDeleteOutCome = async (i: number, e?: any) => {
    if (e.id) {
      const result = await CoursesAPI.courseOutcomesConnections(e.id)
      if (result.success && result.data.length > 0) {
        confirm({
          okButtonCaption: 'Yes',
          cancelButtonCaption: 'No',
          body: ['OutCome này có liên kết với Activity!', 'Bạn có chắc chắn muốn xóa không?'],
          onClick: () => {
            remove(i)
          },
          onClose: () => {
            return
          },
        })
      } else {
        remove(i)
        const newTrigger = trigger as any
        newTrigger('outcomes.outcomes_errors')
      }
    } else {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: e?.id
          ? ['OutCome này có liên kết với Activity!', 'Bạn có chắc chắn muốn xóa không?']
          : ['Bạn có chắc chắn muốn xóa không?'],
        onClick: () => {
          remove(i)
          const newTrigger = trigger as any
          newTrigger('outcomes.outcomes_errors')
        },
        onClose: () => {
          return
        },
      })
    }
  }

  const handleAddOutCome = () => {
    append({
      uuid: uuid(),
      description: '',
    })

    // Trigger validation for the newly added items
    const newTrigger = trigger as any
    newTrigger('outcomes.outcomes_errors')

    // Set accordion state to true for all items
    // setAccordionStates((prevStates) => {
    //   // Create a new array with the same length as prevStates, filled with true
    //   return Array.from({ length: prevStates.length }, () => true)
    // })
    setIndex(((getValues('outcomes')?.length as number) - 1) as any)
  }

  // const accordionStyle = {
  //   height: accordionStates ? 'auto' : '64px',
  //   overflow: 'hidden',
  //   transition: 'height 0.3s ease-in-out',
  // }
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(-1)
  const onEditSection = (e: number) => {
    setIsEdit(!isEdit)
    setIndex(e)
  }
  const [showLabel, setShowLabel] = useState(false)
  const handleToggleLabel = () => {
    setShowLabel(!showLabel)
  }
  return (
    <Modal
      dialogClassName={`modal-dialog modal-dialog-scrollable modal-fullscreen modal-fullscreen-form`}
      centered={true}
      show={open}
      autoFocus
      restoreFocus={false}
    >
      {contextHolder}
      <CreateEditLayout
        pageTitle={
          type !== TYPE_COURSE.LEARNINGOUTCOME ? `Create Learning Outcome` : 'Edit Learning Outcome'
        }
        onSave={handleSubmit(onSubmit)}
        onCancel={handleClose}
        confirmOnCancel
      >
        <div className='mb-10'>
          <div className='mb-10'>
            <HookFormTextField required label='Name' control={control} name='name' placeholder='' />
          </div>
          <div>
            <HookFormEditor
              label='Mô tả'
              control={control}
              name='description'
              placeholder=''
              defaultValue={getValues('description')}
              required
              resourceLocation={RESOURCE_LOCATION.COURSE}
              object_id={params?.id}
              setDataFile={setFileEditor}
            />
          </div>
        </div>
        <ErrorMessage>{(errors.outcomes as any)?.outcomes_errors?.message}</ErrorMessage>
        {getValues('outcomes')?.map((outcome: ILearningOutcome, idx: number) => {
          return (
            <MenuLearningOutcome
              index={outcome.uuid}
              confirm={confirm}
              typeofSection={`Learning outcome ${idx + 1}`}
              onDeleteSection={() => handleDeleteOutCome(idx, outcome)}
              setEditAble={onEditSection}
              isEdit={outcome.id !== undefined}
              id={getValues('outcomes')?.[idx]?.id}
              toggleLabelCallback={handleToggleLabel}
              outcome={outcome}
              key={outcome.uuid}
              tabs={[
                {
                  id: 'tab1',
                  title: `Learning outcome ${idx + 1}`,
                  content: (
                    <LearningOutcomeTab
                      control={control}
                      index={idx}
                      getValues={getValues}
                      setFileLearningChild={setFileEditor}
                      course_section_id={course_section_id}
                      type={type}
                    ></LearningOutcomeTab>
                  ),
                },
              ]}
            />
          )
        })}

        {!onlyEditName && (
          <div
            onClick={(e) => {
              handleAddOutCome()
              // e.target.blur()
            }}
            className='d-flex justify-content-start align-items-center sapp-add-learn mt-10'
            // customButton='bg-transparent'
          >
            <span className='d-flex align-items-center me-2'>
              <PlusVideoIcon />
            </span>
            Add more learning outcome
          </div>
        )}
      </CreateEditLayout>
    </Modal>
  )
}
export default CreateLearningOutCome
