import { uniqueId } from 'lodash'
import { forwardRef, useEffect, useState } from 'react'
import EditorReader from '../base/editor/EditorReader'
import './index.scss'
import { Question } from 'src/type/grade'

interface IProps {
  data: Question
  defaultAnswer?: string[]
  corrects?: {
    id: string
    answer: string
    is_correct: boolean
    answer_position: number
  }[]
  solution?: string
}
const AddWordPreview = forwardRef(({ data, defaultAnswer, corrects, solution }: IProps) => {
  const [questionContent, setQuestionContent] = useState<Document>()
  const [answerContent, setAnswerContent] = useState<Document>()
  const str = data?.question_content
  const parser = new DOMParser()
  const isSelfReflection = data?.is_self_reflection
  const [key, setKey] = useState<string>(uniqueId('key'))

  useEffect(() => {
    const doc = parser?.parseFromString(str, 'text/html')
    const elements = doc?.querySelectorAll('.question-content-tag')
    const doc2 = parser?.parseFromString(str, 'text/html')
    const elementCorrects = doc2?.querySelectorAll('.question-content-tag')
    elements.forEach((element, index) => {
      const inputId = element?.id
      const inputValue = defaultAnswer?.[index] || ''

      let inputClass
      if (corrects) {
        const correctAnswer = corrects?.find(
          (ans) =>
            ans?.answer_position === index + 1 &&
            ans?.answer?.trim()?.toLowerCase() === inputValue?.trim()?.toLowerCase()
        )
        inputClass =
          correctAnswer || isSelfReflection === true
            ? 'sapp-border-success text-state-success'
            : 'sapp-border-danger text-state-error'
      }

      element.outerHTML = `
        <span>
          <input ${
            corrects ? 'disabled' : ''
          } type="text" id="${inputId}" class="sapp-input-preview ${inputClass}" stringHTML="true" value="${inputValue}" />
        </span>
      `
    })
    if (corrects) {
      elementCorrects?.forEach((element, index) => {
        const inputId = element?.id
        const inputValue = defaultAnswer?.[index] || ''

        let inputClass
        // if (corrects) {
        const correctAnswer = corrects?.filter((ans) => ans?.answer_position === index + 1)
        if (correctAnswer) {
          inputClass = 'text-base font-semibold text-state-success'
          // }
          element.outerHTML = `
                <span>
                <span id="${inputId}" class = "${inputClass}">${correctAnswer
            ?.map((e, i) => {
              if (i < correctAnswer.length - 1) {
                return e?.answer + ' / '
              } else return e?.answer
            })
            .join('')} <span/>
                </span>
                `
        }
      })
      setAnswerContent(doc2)
    }

    setQuestionContent(doc)
  }, [defaultAnswer])

  return (
    <>
      <EditorReader
        id='hightlight_area'
        key={key}
        className='sapp-questions fillText'
        text_editor_content={
          questionContent?.documentElement?.querySelector('body')?.innerHTML || ''
        }
      />
      {answerContent && (
        <>
          <h3 className='fw-bold'>Correct Answer</h3>
          <EditorReader
            text_editor_content={
              answerContent?.documentElement?.querySelector('body')?.innerHTML || ''
            }
          />
        </>
      )}
    </>
  )
})
AddWordPreview.displayName = 'AddWordPreview'
export default AddWordPreview
