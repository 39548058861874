import React from 'react'
import { UseFormReturn } from 'react-hook-form'

import './CreateAndUpdateContent.scss'
import SpeakerContent from 'src/components/shop/events/SpeakerContent'
import SponsorContent from 'src/components/shop/events/SponsorContent'
import FAQsContent from 'src/components/shop/events/FAQsContent'
import { useConfirm } from 'src/hooks/use-confirm'
import { IEventForm } from 'src/type/shop/event'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { Dropdown } from 'react-bootstrap'
import { IStepProgress } from 'src/type'

interface IProps extends IStepProgress {
  useFormProp: UseFormReturn<IEventForm>
  onNextStep: () => void
  onCancel: () => void
}

const CreateAndUpdateContent = ({ useFormProp, onNextStep, onCancel, step, setStep }: IProps) => {
  const { contextHolder, confirm } = useConfirm()

  return (
    <>
      {contextHolder}
      <div className='form d-flex flex-column flex-lg-row'>
        <Processbar
          step={step}
          setNewStep={setStep}
          showCancel
          onCancel={onCancel}
          onClick={onNextStep}
          showButtonPrimary={false}
          okButtonCaption='Next'
        >
          <Dropdown.Item>Preview</Dropdown.Item>
          <Dropdown.Item onClick={onNextStep}>Save & Next</Dropdown.Item>
        </Processbar>
        <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-5'>
          <div className='tab-content'>
            <div className='tab-pane fade show active'>
              <div className='d-flex flex-column gap-7 gap-lg-10'>
                {/* Speaker */}
                <SpeakerContent confirm={confirm} />
                {/* Speaker */}

                {/* Sponsors */}
                <SponsorContent confirm={confirm} />
                {/* Sponsors */}

                {/* FAQs */}
                <FAQsContent confirm={confirm} useFormProp={useFormProp} />
                {/* FAQs */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateAndUpdateContent
