import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { ShopBlogAPI } from 'src/apis/short-course/blogs/blog'
import PageLayouts from 'src/components/layout/PageLayouts'
import { PageLink, VALIDATION_FIELD } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { EBlogStatus, IBlog, IBlogUpdate } from 'src/type/shop/blog'
import { RobotMeta } from 'src/type/shop/common'
import { metaValidationSchema } from 'src/utils/shop/validation'
import { z } from 'zod'
import { IStep } from '../faqs/FaqDetail'
import CreateAndUpdatePost from './CreateAndUpdatePost'
import PostSetting from './PostSetting'

const PostDetail = () => {
  const { confirm, contextHolder } = useConfirm()
  const { id } = useParams()
  const navigate = useNavigate() // hook để điều hướng lập trình
  const [blog, setBlog] = useState<IBlog>()
  const [newStep, setNewStep] = useState<IStep[]>([
    {
      title: 'Content',
      status: 'current',
      link: ``,
    },
    {
      title: 'Setting',
      status: '',
      link: '',
    },
  ])

  /**
   * Validation cho các trường trong HookForm step Content
   */
  const contentSchema = z.object({
    title: z.string().min(1, VALIDATION_FIELD),
    content: z.string().optional(),
    thumbnail: z.any(),
  })

  /**
   * Validation cho các trường trong HookForm step Setting
   */
  const settingSchema = z.object({
    status: z.string().min(1, VALIDATION_FIELD),
    blog_time: z.coerce.date().optional(),
    blog_author_id: z.string().min(1, VALIDATION_FIELD),
    blog_category_ids: z.array(z.string().optional()).default([]),
    primary_blog_category_id: z.string().min(1, VALIDATION_FIELD),
    suffix_url: z.string().min(1, VALIDATION_FIELD),
    tag_id: z.string().optional(),
    meta_title: z.string().min(1, VALIDATION_FIELD),
    meta_description: z.string().min(1, VALIDATION_FIELD),
    focus_keyword: z.string().optional(),
    robot_meta: metaValidationSchema,
    canonical_url: z.string().optional(),
  })

  const blogSchema = z
    .object({
      ...contentSchema.shape,
      ...settingSchema.shape,
    })
    .refine(
      (data) => {
        if (data.status === EBlogStatus.TIMER) {
          return !!data.blog_time
        }
        return true
      },
      {
        message: 'Please select a time if the status is Timer',
        path: ['blog_time'],
      }
    )

  const BlogForm = useForm<IBlogUpdate>({
    mode: 'onChange',
    shouldUnregister: false,
    resolver: zodResolver(blogSchema),
  })

  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { isDirty, dirtyFields },
  } = BlogForm

  const loadData = async () => {
    if (id && id !== 'undefined') {
      const res = await ShopBlogAPI.getBlog(id)
      const data = res.data
      setBlog(data)
      const robotMeta = data?.robot_meta ? JSON.parse(data?.robot_meta) : ''
      setValue('title', data.title ?? '')
      setValue('content', data.content ?? '')
      setValue('thumbnail', data.thumbnail?.ORIGIN)
      setValue('blog_time', data.blog_time)
      setValue('tag_id', data.shop_blog_tag?.id ?? '')
      setValue('blog_author_id', data.shop_blog_author?.id ?? '')
      setValue('blog_time', data.blog_time || Date.now())
      setValue('start_time', data.start_time)
      setValue('end_time', data.end_time)
      setValue('prefix_url', data.prefix_url ?? '')
      setValue('suffix_url', data.suffix_url ?? '')
      setValue('focus_keyword', data.focus_keyword ?? '')
      setValue('canonical_url', data.canonical_url ?? '')
      setValue('primary_blog_category_id', data.primary_blog_category_id)
      setValue(
        'blog_category_ids',
        data?.shop_blog_categories?.map((item) => item.id)
      )
      setValue('meta_title', data.meta_title ?? '')
      setValue('meta_description', data.meta_description ?? '')
      setValue('status', data.status)
      Object.keys(robotMeta).forEach((key: string) => {
        setValue(`robot_meta.${key as keyof RobotMeta}`, robotMeta[key])
      })
    }
  }
  type UnknownObject = Record<string, unknown>
  type UnknownArrayOrObject = unknown[] | UnknownObject

  const getDirtyFields = (
    dirtyFields: UnknownArrayOrObject | boolean | any,
    allValues: UnknownArrayOrObject | unknown
  ): UnknownArrayOrObject | unknown => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues
    }

    const dirtyFieldsObject = dirtyFields as UnknownObject
    const allValuesObject = allValues as UnknownObject

    return Object.fromEntries(
      Object.keys(dirtyFieldsObject).map((key) => [
        key,
        getDirtyFields(dirtyFieldsObject[key], allValuesObject[key]),
      ])
    )
  }
  const onSubmit: SubmitHandler<IBlogUpdate> = async (data) => {
    try {
      if (!data?.tag_id?.trim()) {
        data.tag_id = undefined
      }

      if (id && id !== 'undefined') {
        await ShopBlogAPI.updateBlog({ id: id, data: getDirtyFields(dirtyFields, data) as any })
        if (data.thumbnail && typeof data.thumbnail !== 'string') {
          await ShopBlogAPI.uploadThumbnail({ blog_id: id, thumbnail: data.thumbnail })
        }
        toast.success('Update the blog successfully!')
      } else {
        const res = await ShopBlogAPI.createBlog({ data })
        if (data.thumbnail && res.data.id) {
          await ShopBlogAPI.uploadThumbnail({ blog_id: res.data.id, thumbnail: data.thumbnail })
        }
        toast.success('Create the blog successfully!')
      }
      navigate(PageLink.LIST_BLOGS)
      reset()
    } catch (error) {
      // do nothing
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => {
        navigate(PageLink.LIST_BLOGS)
      },
    })
  }

  return (
    <PageLayouts
      titleTab='New Post'
      pageTitle=''
      breadcrumbs={[]}
      showSideBar={false}
      classNameLayout='pt-0'
      showFooter={false}
      backgroudColor='bg-gray-100'
    >
      {contextHolder}
      <div className='sapp-mw-1200px mx-auto px-2'>
        <div className={newStep[0].status === 'current' ? 'd-block' : 'd-none'}>
          <CreateAndUpdatePost
            setStep={setNewStep}
            blog={blog}
            useFormProp={BlogForm}
            onCancel={handleCancel}
            step={newStep}
            trigger={trigger}
          />
        </div>

        <div className={newStep[1].status === 'current' ? 'd-block' : 'd-none'}>
          <PostSetting
            useFormProp={BlogForm}
            blog={blog}
            handleSubmit={handleSubmit(onSubmit)}
            onCancel={handleCancel}
            setStep={setNewStep}
            step={newStep}
            trigger={trigger}
            isDirty={isDirty}
          />
        </div>
      </div>
    </PageLayouts>
  )
}

export default PostDetail
