import {IMetadata} from './question-bank'

export interface IResource {
  created_at: Date
  files: Array<any>
  id: string
  is_default: boolean
  location: string | null
  name: string | null
  resource_type: 'FOLDER' | 'FILE'
  size: number | null
  status: 'CREATED'
  suffix_type: 'FOLDER' | 'VIDEO'
  thumbnail: string
  updated_at: Date
  file_key?: string
  parent_id?: string
}

export interface IResourceList {
  resources: IResource[]
  meta: IMetadata
  success: boolean
}

export interface ISearchResources {
  page_index?: number
  page_size?: number
  attachment_type?: string | null
  fromDate?: string | null
  toDate?: string | null
  dateField?: string | null
  course_id?: string | null
  course_section_id?: string | null
  parent_id?: string | null
  search_key?: string | null
  resource_type?: 'FOLDER' | 'FILE' | null
  suffix_types?: string | null
  status?: string | null
  name?: string | null
  load_time?: string
}

export const defaultSearchResources: ISearchResources = {
  page_index: 1,
  page_size: 10,
  attachment_type: null,
  fromDate: null,
  toDate: null,
  dateField: null,
  course_id: null,
  course_section_id: null,
  parent_id: 'null',
  search_key: '',
  resource_type: null,
  suffix_types: null,
  status: null,
  name: null,
}

export enum RESOURCE_TYPE {
  'FILE' = 'FILE',
  'FOLDER' = 'FOLDER',
}

export enum RESOURCE_STATUS {
  'PROCESSING' = 'PROCESSING',
  'READY_TO_STREAM' = 'READY_TO_STREAM',
  'PENDING' = 'PENDING',
  'CREATED' = 'CREATED',
  'ERROR' = 'ERROR',
}
export enum RESOURCE_STATUS_MESSAGE {
  'PROCESSING' = 'VIDEO đang được xử lý.',
  'PENDING' = 'VIDEO đang được xử lý',
  'CREATED' = 'Chúng tôi đang xử lý video của bạn. Vui lòng kiểm tra lại sau vài phút.',
  'ERROR' = 'Video upload lỗi.',
}

export const RESOURCE_STATUS_LABEL = [
  {
    label: 'Ready to stream',
    value: 'READY_TO_STREAM',
  },
  {
    label: 'Error',
    value: 'ERROR',
  },
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
  {
    label: 'Created',
    value: 'CREATED',
  },
]

export const SUFFIX_TYPE_LABEL = [
  {
    label: 'Video',
    value: 'VIDEO',
  },
  {
    label: 'Image',
    value: 'IMAGE',
  },
  {
    label: 'Sheet',
    value: 'SHEET',
  },
  {
    label: 'Pdf',
    value: 'PDF',
  },
  {
    label: 'Docx',
    value: 'WORD_DOCUMENT',
  },
]
