import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { CertificatesApi } from 'src/apis/certificates'
import { CoursesAPI } from 'src/apis/courses'
import { FilesFolders } from 'src/common/icons'
import LoadingTable from 'src/common/LoadingTable'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappDrawer from 'src/components/base/SappDrawer'
import { HEADER_CLASS_LIST, PageLink } from 'src/constants'
import { DESCRIPTION_POPUPCONFIRM, LANG_COURSES } from 'src/constants/lang'
import { CODE_ADMIN, TITLE_COURSE_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import { useConfirm } from 'src/hooks/use-confirm'
import { Role } from 'src/type'
import Processbar from '../progress-bar'
import styles from './CourseCertificate.module.scss'
import clsx from 'clsx'

const CourseCertificate = ({ setStep, step }: { setStep?: any; step?: any }) => {
  const [openModelUpdate, setOpenModalUpload] = useState<boolean>(false)
  const [certificateList, setCertificateList] = useState<any>()
  const [certificateId, setCertificateId] = useState<string | null>(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()
  const { contextHolder, confirm } = useConfirm()
  const [certificateTmp, setCertificateTmp] = useState<string | null>(null)
  const [courseCategoryId, setCourseCategoryId] = useState<string>('')
  const [certificateInfo, setCertificateInfo] = useState<any>(null)

  const fetchCertificateList = async (currentPage: number, pageSize: number, params?: Object) => {
    try {
      const res = await CertificatesApi.get({
        page_index: currentPage,
        page_size: pageSize,
        params: params,
      })
      setCertificateList(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const getCertificateDetail = async (id: string | null) => {
    if (id) {
      const { data } = await CertificatesApi.detail(id)
      setCertificateInfo(data)
    }
  }

  const getCourseInfo = async (id: any) => {
    try {
      const res = await CoursesAPI.getCoursesByIdInClass({ id: id })
      setCourseCategoryId(res?.data?.course_categories[0]?.id)
      setCertificateId(res?.data?.certificate_id ?? '')
      setCertificateTmp(res?.data?.certificate_id ?? '')
      if (res?.data?.certificate_id) {
        getCertificateDetail(res?.data?.certificate_id)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const queryParam = {
    courseCategoryId: courseCategoryId,
    status: 'PUBLISHED',
  }

  const onSubmit = async () => {
    setLoading(true)

    try {
      const request = {
        certificate_id: certificateId,
      }

      const response = await CoursesAPI.addCertificate({ id: id, data: request })
      if (response) {
        toast.success('Saved successfully!')
        navigate('/courses')
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpenModalUpload(false)
    setCertificateTmp(certificateId)
  }

  const handleSubmit = async () => {
    setCertificateId(certificateTmp)
    setOpenModalUpload(false)
    await getCertificateDetail(certificateTmp)
  }

  //TODO: call API khi change pagination
  const handlePaginationChange = (page_index: number, page_size: number) => {
    fetchCertificateList(page_index, page_size, queryParam)
  }

  const { profileMe } = useUserContext()
  const hasPermission = (role: Role, permission: string) => role.permissions?.includes(permission)

  const allowRenderEditCreateCourse = profileMe?.roles?.some(
    (role: Role) =>
      hasPermission(role, TITLE_COURSE_GR.CREATE_COURSE) ||
      hasPermission(role, TITLE_COURSE_GR.EDIT_COURSE) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )

  // TODO: hàm cancel mở popup confirm trở về màn course list
  const hanleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.COURSES),
    })
  }

  const removeCertificate = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có muốn huỷ chứng chỉ của khoá này không?'],
      onClick: () => {
        setCertificateId(null)
        setCertificateInfo(null)
      },
    })
  }

  useEffect(() => {
    setLoading(true)
    getCourseInfo(id)
  }, [])

  return (
    <>
      <Processbar
        step={step}
        setNewStep={setStep}
        okButtonCaption='Finish'
        onCancel={hanleCancel}
        onClick={() => {
          onSubmit()
        }}
        cancelButtonCaption={LANG_COURSES.courseList}
      />
      {contextHolder}
      <Row gutter={9} className=' d-flex sapp-certificate-content '>
        <Col
          className='position-relative overflow-auto alight-items-center pb-5  bg-white card'
          style={{ height: 'calc(100vh - 120px)' }}
          span={23}
        >
          {certificateInfo?.setting ? (
            <div className='position-relative m-auto sapp-w-fit-content'>
              {certificateInfo?.setting?.map((e: any, i: number) => {
                if (e.type === 'TEXT') {
                  return (
                    <div
                      key={e?.id}
                      className={`sapp-opacity-bg-border sapp-no-white-space position-absolute ${e?.tag}`}
                      style={{
                        fontFamily: e?.fontFamily,
                        fontSize: e?.fontSize,
                        color: e?.color,
                        fontWeight: e?.isBold ? '700' : '',
                        fontStyle: e?.isItalic ? 'italic' : 'normal',
                        textDecoration: e?.isThrough
                          ? 'line-through'
                          : e?.isUndefined
                          ? 'underline'
                          : '',
                        textTransform: e?.isUpperCase ? 'uppercase' : 'none',
                        textAlign: e?.textAlign,
                        lineHeight: e?.lineHeight,
                        width: e?.position?.width,
                        height: e?.position?.height,
                        top: e?.position?.top,
                        left: e?.position?.left,
                      }}
                    >
                      {e?.value}
                    </div>
                  )
                }

                if (e.type === 'IMAGE-DEFAULT') {
                  return (
                    <div style={{ position: 'relative' }} key={i}>
                      <img
                        src={e?.imageUrl}
                        alt={`Image-${i}`}
                        loading='lazy'
                        className='sapp-max-image-default object-fit-contain'
                      />
                    </div>
                  )
                }
              })}
            </div>
          ) : (
            <div className='position-absolute rst__icon start-50'>
              {allowRenderEditCreateCourse && (
                <ButtonIcon
                  title={'Choose Template'}
                  onClick={() => {
                    setOpenModalUpload(true)
                    fetchCertificateList(1, 10, queryParam)
                  }}
                  disabled={!allowRenderEditCreateCourse}
                  isListScreen
                >
                  <FilesFolders />
                </ButtonIcon>
              )}
            </div>
          )}
        </Col>
        {certificateInfo?.setting && allowRenderEditCreateCourse && (
          <Col span={1} className={clsx('cursor-pointer', styles.editAndCreateBtn)}>
            <div
              className={styles.iconButton}
              onClick={() => {
                setOpenModalUpload(true)
                fetchCertificateList(1, 10, queryParam)
              }}
            >
              <KTIcon iconName='add-files' iconType='outline' className='fs-1' />
            </div>
            <div className={styles.iconButton} onClick={removeCertificate}>
              <KTIcon iconName='trash' iconType='outline' className='fs-1' />
            </div>
          </Col>
        )}
      </Row>
      <SappDrawer
        title='Choose Certificate Template'
        cancelButtonCaption={'Cancel'}
        width='52%'
        okButtonCaption={'Confirm'}
        handleSubmit={handleSubmit}
        dialogClassName='modal-dialog sapp-custom-table-certificate'
        open={!!openModelUpdate}
        handleClose={handleClose}
        confirmOnclose
        classNameBody=''
      >
        <div>
          {loading ? (
            <>
              {HEADER_CLASS_LIST.map((header, i) => (
                <LoadingTable key={header} headers={HEADER_CLASS_LIST} />
              ))}
            </>
          ) : (
            <div className='sapp-table-certificate'>
              {certificateList?.topics?.map((data: any, index: number) => {
                //const isChecked = checkedList.includes(e.id)
                return (
                  <div
                    key={index}
                    className='cursor-pointer sapp-max-w-283'
                    onClick={() => {
                      setCertificateTmp(data?.id)
                    }}
                  >
                    <div
                      className={`position-relative sapp-item-certificate ${
                        certificateTmp === data?.id ? 'enable' : 'disable'
                      }`}
                    >
                      <span className='sapp-custom-dot position-absolute'></span>
                      <div className='sapp-image-certificate'>
                        {data?.setting?.map((e: any, i: number) => {
                          if (e.type === 'TEXT') {
                            return (
                              <div
                                key={e?.id}
                                className={`sapp-opacity-bg-border sapp-no-white-space position-absolute ${e?.tag}`}
                                style={{
                                  fontFamily: e?.fontFamily,
                                  fontSize: e?.fontSize,
                                  color: e?.color,
                                  fontWeight: e?.isBold ? '700' : '',
                                  fontStyle: e?.isItalic ? 'italic' : 'normal',
                                  textDecoration: e?.isThrough
                                    ? 'line-through'
                                    : e?.isUndefined
                                    ? 'underline'
                                    : '',
                                  textTransform: e?.isUpperCase ? 'uppercase' : 'none',
                                  textAlign: e?.textAlign,
                                  lineHeight: e?.lineHeight,
                                  width: e?.position?.width,
                                  height: e?.position?.height,
                                  top: e?.position?.top,
                                  left: e?.position?.left,
                                }}
                              >
                                {e?.value}
                              </div>
                            )
                          }

                          if (e.type === 'IMAGE-DEFAULT') {
                            return (
                              <img
                                key={e?.id}
                                src={e?.imageUrl}
                                alt={`Image-${i}`}
                                className='object-fit-contain sapp-w-fit-content sapp-h-fit-content'
                              />
                            )
                          }
                        })}
                      </div>
                    </div>
                    <div className='sapp-text-truncate-2 pt-2 sapp-text-certificate'>
                      {data?.name}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <PagiantionSAPP
          currentPage={certificateList?.meta?.page_index || 1}
          pageSize={certificateList?.meta?.page_size || 10}
          totalItems={certificateList?.meta?.total_records}
          handlePaginationChange={handlePaginationChange}
        />
      </SappDrawer>
    </>
  )
}

export default CourseCertificate
