import { DebouncedFunc, debounce } from 'lodash'
import { useMemo, useState } from 'react'
import { DEBOUNCED_NUMBER } from 'src/constants'
import { INameAndId, IShopFilter } from 'src/type/shop/common'

export interface IMetadata {
  total_pages?: number
  total_records?: number
  page_index: number
  page_size: number
}

export interface IFilter {
  loading: boolean
  data: INameAndId[]
  metadata?: IMetadata
  search?: string
}

const defaultValue = {
  loading: false,
  data: [],
  search: '',
}

type IProp = {
  callback: (x: any) => any
  type: 'faq' | 'product' | 'event' | 'blog' | 'faqlist'
  key: string
}

export interface IUseShopFilterResult {
  dataList: IFilter
  setDataList: React.Dispatch<React.SetStateAction<IFilter>>
  getData: (name?: string, page_index?: number, page_size?: number) => Promise<void>
  debounceGetData: DebouncedFunc<
    (name?: string, page_index?: number, page_size?: number) => Promise<void>
  >
  handleNextPage: DebouncedFunc<() => void>
}
/**
 * Hook dùng để chứa các function xử lý filter
 */
const useShopFilter = ({ callback, type, key }: IProp): IUseShopFilterResult => {
  const [dataList, setDataList] = useState<IFilter>(defaultValue)

  /**
   * Hook dùng để chứa các function xử lý category
   * @param {name}: tên category
   * @param {page_index}: trang hiện tại
   * @param {page_size}: số lượng bản ghi 1 size
   */
  const getData = async (name?: string, page_index: number = 1, page_size: number = 10) => {
    try {
      setDataList((e) => ({
        ...e,
        loading: true,
      }))
      const field = type === 'product' || type === 'faqlist' ? 'title' : 'name'
      const response = await callback({
        page_index,
        page_size,
        params: { [field]: name },
      })
      let data: IShopFilter[] = response.data[key].map((item: any) => ({
        id: item.id,
        name: item.name ?? item.title,
      }))

      setDataList((prev: IFilter) => {
        return {
          ...prev,
          metadata: response.data.metadata,
          loading: false,
          data:
            name !== prev.search
              ? data
              : [...prev.data, ...data].filter(
                  (obj, index, self) => self.findIndex((o) => o.id === obj.id) === index
                ),
        }
      })
    } catch (error) {
      setDataList((e: IFilter) => ({
        ...e,
        loading: false,
      }))
    }
  }
  /**
   * function dùng để debounce callback sau 1 khoảng timeout
   * @param {callback}: tên category
   * @param {Time}: debounced time
   */
  const debounceGetData = debounce(getData, DEBOUNCED_NUMBER)

  /**
   * Dùng để fetch data mỗi khi scroll down hết
   */
  const handleNextPage = (type: 'faq' | 'product' | 'blog' | 'event' | 'faqlist') => {
    const total_pages = dataList.metadata?.total_pages
    const page_index = dataList.metadata?.page_index
    const page_size = dataList.metadata?.page_size
    if (total_pages) {
      if (page_index && page_index < total_pages) {
        getData(dataList.search, page_index + 1, page_size)
      }
    }
  }

  const debouncedHandleNextPage = debounce(handleNextPage, DEBOUNCED_NUMBER)

  const useShopFilterResult = useMemo(
    () => ({
      dataList,
      setDataList,
      getData,
      debounceGetData,
      handleNextPage: debouncedHandleNextPage,
    }),
    [dataList]
  )
  return useShopFilterResult
}

export default useShopFilter
