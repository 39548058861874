import {FC} from 'react'
import {Link} from 'react-router-dom'
import avatar from 'src/_metronic/assets/media/avatars/blank.png'
import {CODE_ADMIN, TITLE_GR, TITLE_STAFF_GR} from 'src/constants/permission'
import {useUserContext} from 'src/context/UserProvider'
import {Role} from 'src/type'

type Props = {
  user: any
  linkViewProfile: string
}

const UserInfoCell: FC<Props> = ({user, linkViewProfile}) => {
  const {profileMe} = useUserContext()
  const hasPermission = (role: any, permission: string) => role.permissions?.includes(permission)

  const allowRenderEdit = profileMe?.roles?.some(
    (role) =>
      hasPermission(role, TITLE_STAFF_GR.EDIT_STAFF) ||
      hasPermission(role, TITLE_GR.EDIT_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_USER_GR) ||
      hasPermission(role, TITLE_GR.GET_SECURITY_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_DEVICE_USER_GR) ||
      hasPermission(role, TITLE_GR.REMOVE_SECURITY_USER_GR) ||
      hasPermission(role, TITLE_GR.MAKE_CONTACT_DEFAULT_GR) ||
      hasPermission(role, TITLE_GR.GET_ACTIVITIES_USER_GR) ||
      hasPermission(role, TITLE_GR.CREATE_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderCreate = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.CREATE_STAFF) ||
      role.permissions?.includes(TITLE_GR.CREATE_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const allowRenderView = profileMe?.roles?.some(
    (role: Role) =>
      role.permissions?.includes(TITLE_STAFF_GR.GET_STAFF) ||
      role.permissions?.includes(TITLE_GR.VIEW_USER_GR) ||
      role.code === CODE_ADMIN.SUPER_ADMIN
  )
  const emailUser = user?.user_contacts?.find((user: any) => user?.is_default === true)?.email

  return (
    <Link
      to={linkViewProfile}
      className='d-flex align-items-center'
      {...(allowRenderEdit || allowRenderCreate || allowRenderView
        ? {}
        : {onClick: (e) => e.preventDefault()})}
    >
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
        <div className='symbol-label'>
          <img
            src={user?.detail?.avatar?.['50x50'] ?? user?.detail?.avatar?.['ORIGIN'] ?? avatar}
            alt={user.name}
            className='w-100 h-100'
          />
        </div>
      </div>
      <div className='d-flex flex-column'>
        <div className='text-gray-800 text-hover-primary mb-1'>{user?.detail?.full_name}</div>
        <span className='text-gray-600'>{user?.detail?.email || emailUser}</span>
      </div>
    </Link>
  )
}

export default UserInfoCell
